import { observer } from 'mobx-react'
import React from 'react'
import { useParams } from 'react-router-dom'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import { MainPage } from 'src/Components/My'
import { t } from 'src/I18n'
import SavingCardForm from '../Insert/Components/SavingCardForm'
import { IFormValues } from '../Insert/Store'
import { savingCardEditStore } from './Store'

interface IProps {}

const Edit: React.FC<IProps> = () => {
  const params = useParams<'id'>()

  const onSubmit = (data: IFormValues) => {
    savingCardEditStore.EditSavingCard(data)
  }

  return (
    <MainPage>
      <BreadCrumbs
        currentPageTitle={t.savingcardEdit.title}
        previousPages={{ title: t.common.savingcards, to: '/savingcards' }}
      />
      <SavingCardForm
        store={savingCardEditStore}
        onSubmit={onSubmit}
        initialize={() => savingCardEditStore.InitializeEdit(params.id)}
      />
    </MainPage>
  )
}

export default observer(Edit)
