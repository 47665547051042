import { formatCentsAsEuro } from 'src/Helpers/CurrencyHelpers'
import { IEventTicketEditVTO } from 'src/Models/Server/Response/Event/IEventTicketEditVTO'
import {
  LocalizedStringDTO,
  SetMissingLanguageValues,
  TextBoxType,
} from 'src/Models/Server/Response/LocalizedString/LocalizedStringDTO'
import { persistedStore } from 'src/Stores/PersistedStore'

export interface IEventFormValues {
  Name: LocalizedStringDTO
  OnSale: boolean
  Price: number | string
}

export interface IState {
  viewModel: IEventFormValues
}

export const initialState: IState = {
  viewModel: undefined,
}

export type Actions = {
  type: 'init'
  viewModel: IEventFormValues
}

export const reducer = (state: IState, action: Actions): IState => {
  switch (action.type) {
    case 'init': {
      return {
        ...state,
        viewModel: action.viewModel,
      }
    }

    default:
      throw new Error('Invalid Reducer Action')
  }
}

export const reducerActions = (
  state: IState,
  dispatch: (action: Actions) => void
) => {
  return {
    initialize: (eventTicketEdit: IEventTicketEditVTO) => {
      if (eventTicketEdit) {
        //edit
        const viewModel: IEventFormValues = {
          Name: SetMissingLanguageValues(eventTicketEdit.Name),
          Price: formatCentsAsEuro(eventTicketEdit.Price),
          OnSale: eventTicketEdit.OnSale,
        }
        dispatch({ type: 'init', viewModel })
      } else {
        //insert
        const viewModel: IEventFormValues = {
          Name: new LocalizedStringDTO('Event Ticket', TextBoxType.TextBox),

          Price: '',
          OnSale: false,
        }

        dispatch({ type: 'init', viewModel })
      }
    },
  }
}
