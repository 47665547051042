import React from 'react'
import { Link } from 'react-router-dom'

// const BreadCrumbsDiv = StyledComponent<IBreadCrumbsDivProps, HTMLDivElement>(
//   styled.div
// )``

interface IBreadCrumb {
  to: string
  title: string
}

export interface IBreadCrumbsProps {
  currentPageTitle: string
  previousPages?: IBreadCrumb[] | IBreadCrumb | undefined
}

const previousLinks = (
  previousPages: IBreadCrumb[] | IBreadCrumb | undefined
) => {
  //console.log('prev', previousPages)
  if (previousPages === undefined) {
    return null
  }

  if (Array.isArray(previousPages)) {
    const previousPagesArray = previousPages as IBreadCrumb[]
    return previousPagesArray.map((b, index) => (
      <li key={index}>
        <Link to={b.to}>{b.title}</Link>
      </li>
    ))
  } else {
    return (
      <li>
        <Link to={previousPages.to}>{previousPages.title}</Link>
      </li>
    )
  }
}

const BreadCrumbs: React.FC<IBreadCrumbsProps> = ({
  currentPageTitle,
  previousPages,
}) => {
  return (
    <div>
      <div className="page-header">
        <h3 className="page-title">{currentPageTitle}</h3>
        <ol className="breadcrumb">
          <li>
            <Link to="/dashboard">Dashboard</Link>
          </li>
          {previousLinks(previousPages)}
          <li className="active">{currentPageTitle}</li>
        </ol>
      </div>
    </div>
  )
}

export default BreadCrumbs
