import { action, makeObservable, observable, runInAction } from 'mobx'

import { ISelectOption } from 'src/Models/Client'
import { enumToText } from 'src/Helpers/TextHelpers'
import { t } from 'src/I18n'
import { AutomaticChargeCollectionStatusTypesDTO } from 'src/Models/Server/Enums'
import IGenericError from 'src/Models/Server/IGenericError'
import { IAutomaticChargeCollectionVTO } from 'src/Models/Server/Response/AutomaticCharge/IAutomaticChargeCollectionVTO'
import CurrencyVTO from 'src/Models/Server/Response/Currency/CurrencyVTO'
import { IsAdmin } from 'src/Models/Server/Response/User/ManagerUserVTO'
import AutomaticChargeController from 'src/Services/ToprCoreApi/Axios/Controllers/AutomaticChargeController'
import CurrencyController from 'src/Services/ToprCoreApi/Axios/Controllers/CurrencyController'
import { appUiStore } from 'src/Stores/AppUiStore'

import BaseStore from 'src/Stores/BaseStore'
import { persistedStore } from 'src/Stores/PersistedStore'

const statusOptions: ISelectOption[] = [
  { value: '', label: '<Alle>' },
  {
    value: AutomaticChargeCollectionStatusTypesDTO.Canceled.toString(),
    label: enumToText(
      AutomaticChargeCollectionStatusTypesDTO.Canceled,
      AutomaticChargeCollectionStatusTypesDTO,
      t.stornoStatus.status
    ),
  },
  {
    value:
      AutomaticChargeCollectionStatusTypesDTO.CanceledMailMerged.toString(),
    label: enumToText(
      AutomaticChargeCollectionStatusTypesDTO.CanceledMailMerged,
      AutomaticChargeCollectionStatusTypesDTO,
      t.stornoStatus.status
    ),
  },
  {
    value: AutomaticChargeCollectionStatusTypesDTO.ReadyForBlock.toString(),
    label: enumToText(
      AutomaticChargeCollectionStatusTypesDTO.ReadyForBlock,
      AutomaticChargeCollectionStatusTypesDTO,
      t.stornoStatus.status
    ),
  },
  {
    value: AutomaticChargeCollectionStatusTypesDTO.Blocked.toString(),
    label: enumToText(
      AutomaticChargeCollectionStatusTypesDTO.Blocked,
      AutomaticChargeCollectionStatusTypesDTO,
      t.stornoStatus.status
    ),
  },
  {
    value: AutomaticChargeCollectionStatusTypesDTO.Collected.toString(),
    label: enumToText(
      AutomaticChargeCollectionStatusTypesDTO.Collected,
      AutomaticChargeCollectionStatusTypesDTO,
      t.stornoStatus.status
    ),
  },
]

export interface IFormValues {
  Statuses: ISelectOption[]
  SelectedStatus: ISelectOption
  Currencies: ISelectOption[]
  SelectedCurrency: ISelectOption
}

export default class Store extends BaseStore<IFormValues> {
  public Stornos: IAutomaticChargeCollectionVTO[] = []

  public IsStornosLoading = true

  public IsAdmin = false

  constructor() {
    super()
    this.BaseInitialize()

    makeObservable(this, {
      Stornos: observable,
      IsStornosLoading: observable,
      IsAdmin: observable,
      Initialize: action,
      GetMerchantCurrencies: action,
      GetStornosByCurrency: action,
      SendStornoLetter: action,
      GetMailData: action,
      ReadyForBlock: action,
      BlockStorno: action,
      UnblockStorno: action,
    })
  }

  /** Sets Initial state */

  public Initialize() {
    //console.log('sd', AutomaticChargeCollectionStatusTypesDTO.Canceled)

    this.BaseInitialize()

    this.IsAdmin = IsAdmin(persistedStore.SelectedManagerUser)
    this.GetMerchantCurrencies()
  }

  private SetSelectedStatus(
    data: IFormValues,
    status: AutomaticChargeCollectionStatusTypesDTO
  ) {
    if (data && data.Statuses) {
      const option = statusOptions.find((x) => x.value === status.toString())
      data.SelectedStatus = option
    }
  }

  public GetMerchantCurrencies() {
    this.IsLoading = true

    CurrencyController.GetMyMerchantCurrencies()
      .then((result: CurrencyVTO[]) => {
        runInAction(() => {
          const autoChargeCurrencies = result.filter(
            (x) => x.AutomaticChargeAllowed === true
          )

          if (autoChargeCurrencies.length > 0) {
            const currencyOptions = autoChargeCurrencies.map((cur) => ({
              value: cur.CurrencyGuid,
              label: cur.Name,
            }))

            let selectedCurrency = currencyOptions[0]
            if (this.IsAdmin) {
              const rtoCurrency = autoChargeCurrencies.find(
                (x) => x.Code === 'RTO'
              )
              if (rtoCurrency) {
                selectedCurrency = {
                  value: rtoCurrency.CurrencyGuid,
                  label: rtoCurrency.Name,
                }
              }
            }

            this.ViewModel = {
              Currencies: currencyOptions,
              SelectedCurrency: selectedCurrency,
              Statuses: statusOptions,
              SelectedStatus: statusOptions[0],
            }

            this.GetStornosByCurrency(this.ViewModel)
          }
          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsLoading = false))
        appUiStore.ShowError(error)
      })
  }

  public GetStornosByCurrency(data: IFormValues) {
    if (data !== undefined && data.SelectedCurrency !== undefined) {
      this.IsStornosLoading = true

      AutomaticChargeController.GetStornosByCurrency(
        data.SelectedCurrency.value,
        data.SelectedStatus.value
      )
        .then((result: IAutomaticChargeCollectionVTO[]) => {
          runInAction(() => {
            this.Stornos = result
            this.IsStornosLoading = false
          })
        })
        .catch((error: IGenericError) => {
          runInAction(() => (this.IsStornosLoading = false))
          appUiStore.ShowError(error)
        })
    }
  }

  public SendStornoLetter(data: IFormValues) {
    if (data !== undefined && data.SelectedCurrency !== undefined) {
      this.IsSubmitting = true

      AutomaticChargeController.SendStornoLetter(data.SelectedCurrency.value)
        .then(() => {
          runInAction(() => {
            this.SetSelectedStatus(
              data,
              AutomaticChargeCollectionStatusTypesDTO.CanceledMailMerged
            )

            this.GetStornosByCurrency(data)
            this.IsSubmitting = false
          })
        })
        .catch((error: IGenericError) => {
          runInAction(() => (this.IsLoading = false))
          appUiStore.ShowError(error)
        })
    }
  }

  public GetMailData(data: IFormValues) {
    if (data !== undefined && data.SelectedCurrency !== undefined) {
      this.IsSubmitting = true

      AutomaticChargeController.GetMailData(
        data.SelectedCurrency.value,
        data.SelectedStatus.value
      )
        .then(() => {
          runInAction(() => {
            this.IsSubmitting = false
          })
        })
        .catch((error: IGenericError) => {
          runInAction(() => (this.IsLoading = false))
          appUiStore.ShowError(error)
        })
    }
  }

  public ReadyForBlock(automaticChargeCollectionId: number) {
    this.IsSubmitting = true

    AutomaticChargeController.ReadyForBlock(automaticChargeCollectionId)
      .then(() => {
        //remove from Stornos
        runInAction(() => {
          const index = this.Stornos.findIndex(
            (x) => x.AutomaticChargeCollectionId === automaticChargeCollectionId
          )
          this.Stornos.splice(index, 1)
          this.IsSubmitting = false
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsLoading = false))
        appUiStore.ShowError(error)
      })
  }

  public BlockStorno(automaticChargeCollectionId: number) {
    this.IsSubmitting = true

    AutomaticChargeController.BlockStorno(automaticChargeCollectionId)
      .then(() => {
        runInAction(() => {
          //remove from Stornos
          const index = this.Stornos.findIndex(
            (x) => x.AutomaticChargeCollectionId === automaticChargeCollectionId
          )
          this.Stornos.splice(index, 1)
          this.IsSubmitting = false
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsLoading = false))
        appUiStore.ShowError(error)
      })
  }

  public UnblockStorno(automaticChargeCollectionId: number) {
    this.IsSubmitting = true

    AutomaticChargeController.UnblockStorno(automaticChargeCollectionId)
      .then(() => {
        //remove from Stornos
        runInAction(() => {
          const index = this.Stornos.findIndex(
            (x) => x.AutomaticChargeCollectionId === automaticChargeCollectionId
          )
          this.Stornos.splice(index, 1)
          this.IsSubmitting = false
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsLoading = false))
        appUiStore.ShowError(error)
      })
  }
}

export const stornoStatusStore = new Store()
