import { observer } from 'mobx-react'
import React, { useEffect, useReducer } from 'react'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import { IMyTab, MainPage, MyPageTabs } from 'src/Components/My'
import { t } from 'src/I18n'
import { AdditionalModules } from 'src/Models/Server/AdditionalModules'
import {
  HasModule,
  IsAdmin,
} from 'src/Models/Server/Response/User/ManagerUserVTO'
import { useGetConsumerSubscriptionsQuery } from 'src/Services/ToprCoreApi/RTK/Slices/SubscriptionSlice'
import { persistedStore } from 'src/Stores/PersistedStore'
import { initialState, reducer, reducerActions } from './Reducer'
import EventTab from './Tabs/EventTab/EventTab'
import FinanceTab from './Tabs/FinanceTab/FinanceTab'
import InfoTab from './Tabs/InfoTab/InfoTab'
import { userInfoStore } from './Tabs/InfoTab/Store'
import LoyaltyTab from './Tabs/LoyaltyTab/LoyaltyTab'
import PlanningTab from './Tabs/PlanningTab/PlanningTab'
import SubscriptionTab from './Tabs/SubscriptionTab/SubscriptionTab'
import WalletTab from './Tabs/WalletsTab/WalletTab'

const UserDetails: React.FC = () => {
  const tabs: IMyTab[] = [
    {
      key: 'info',
      title: 'Info',
      content: <InfoTab />,
      index: true, //selected tab when nothing is set in the url
    },
    {
      key: 'wallets',
      title: t.common.wallets,
      content: <WalletTab />,
    },
    {
      key: 'loyalty',
      title: 'Loyalty',
      content: <LoyaltyTab />,
    },
    {
      key: 'planning',
      title: 'Planning',
      content: <PlanningTab />,
    },
  ]

  const { data: subscriptions } = useGetConsumerSubscriptionsQuery()

  const [state, dispatch] = useReducer(reducer, initialState)
  const actions = reducerActions(state, dispatch)

  useEffect(() => {
    actions.initialize()
  }, [null])

  const { events } = state

  if (subscriptions !== undefined && subscriptions.length > 0) {
    tabs.push({
      key: 'subscriptions',
      title: t.subscription.index.title,
      content: <SubscriptionTab />,
    })
  }

  if (events.length > 0) {
    tabs.push({
      key: 'tickets',
      title: t.common.tickets,
      content: <EventTab />,
    })
  }

  const hasRtoStorno = HasModule(
    persistedStore.SelectedManagerUser,
    AdditionalModules.RtoStornoStatus
  )

  if (IsAdmin(persistedStore.SelectedManagerUser) || hasRtoStorno) {
    tabs.push({
      key: 'finance',
      title: t.topMenu.finance.title,
      content: <FinanceTab />,
    })
  }

  const { User } = userInfoStore
  const name = User !== null ? User.Name : t.common.user
  return (
    <MainPage>
      <BreadCrumbs
        currentPageTitle={name}
        previousPages={{
          to: '/helpdesk/users',
          title: t.helpdesk.title,
        }}
      />
      <MyPageTabs tabs={tabs} />
    </MainPage>
  )
}

export default observer(UserDetails)
