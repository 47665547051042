import { action, makeObservable, observable, runInAction } from 'mobx'

import { ISelectOption } from 'src/Models/Client'
import IGenericError from 'src/Models/Server/IGenericError'
import { SelectOptionsFromNameGuidVTO } from 'src/Models/Server/INameGuidVTO'
import { IMerchantPaymentVTO } from 'src/Models/Server/Response/Report/IMerchantPaymentVTO'
import MerchantController from 'src/Services/ToprCoreApi/Axios/Controllers/MerchantController'
import ReportController from 'src/Services/ToprCoreApi/Axios/Controllers/ReportController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export interface IFormValues {
  Merchants: ISelectOption[]
  SelectedMerchants: ISelectOption[]
  StartDate: Date
  EndDate: Date
}

export default class Store extends BaseStore<IFormValues> {
  public LoadingMerchantPayments: boolean = false
  public MerchantPayments: IMerchantPaymentVTO[] = []

  constructor() {
    super()

    makeObservable(this, {
      LoadingMerchantPayments: observable,
      MerchantPayments: observable,
      Initialize: action,
      GetMerchantPayments: action,
    })
    this.BaseInitialize()
  }

  /** Sets Initial state */

  public Initialize() {
    this.IsLoading = true
    MerchantController.GetMyMerchants()
      .then((merchants) => {
        const merchantOptions = SelectOptionsFromNameGuidVTO(merchants)
        const today = new Date()
        runInAction(() => {
          this.ViewModel = {
            Merchants: merchantOptions,
            SelectedMerchants: merchantOptions,
            StartDate: new Date(today.getFullYear(), today.getMonth(), 1),
            EndDate: today,
          }
          this.IsLoading = false

          this.GetMerchantPayments(this.ViewModel)
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsLoading = false))
        appUiStore.ShowError(error)
      })
  }

  public GetMerchantPayments(data: IFormValues) {
    const merchantGuids = data.SelectedMerchants.map((x) => x.value)
    this.LoadingMerchantPayments = true

    //console.log('data', data)

    ReportController.GetMerchantPayments(
      merchantGuids,
      data.StartDate,
      data.EndDate
    )
      .then((merchantPayments) => {
        runInAction(() => {
          this.LoadingMerchantPayments = false
          this.MerchantPayments = merchantPayments
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.LoadingMerchantPayments = false))
        appUiStore.ShowError(error)
      })
  }
}

export const merchantPaymentsStore = new Store()
