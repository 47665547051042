import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { ButtonToolbar } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { LoaderIcon } from 'src/Components'
import McButton from 'src/Components/McButton'
import McModal from 'src/Components/Modals/McModal'
import { MyLocalizedStringTabs } from 'src/Components/My'
import { t } from 'src/I18n'
import { IFieldValueEditVTO } from 'src/Models/Server/Response/Field/IFieldValueEditVTO'
import { fieldValueEditStore } from './Store'
interface IProps {
  onClose: () => void
  onSubmitted: () => void
  fieldValueId?: number
}

const FieldValueEditModal: React.FC<IProps> = ({
  fieldValueId,
  onClose,
  onSubmitted,
}) => {
  useEffect(() => {
    fieldValueEditStore.Initialize(fieldValueId)
  }, [fieldValueId])

  const { ViewModel, IsLoading, IsSubmitting } = fieldValueEditStore

  useEffect(() => {
    reset(ViewModel)
  }, [ViewModel])

  const useFormProps: UseFormReturn<IFieldValueEditVTO> =
    useForm<IFieldValueEditVTO>({
      defaultValues: ViewModel,
    })
  const { handleSubmit, reset } = useFormProps

  const onSubmit = (data: IFieldValueEditVTO) => {
    fieldValueEditStore.SaveFieldValue(data, onSubmitted)
  }

  const renderForm = () => {
    if (IsLoading) {
      return <LoaderIcon IsLoading={IsLoading} />
    }

    return (
      <MyLocalizedStringTabs
        formObject={ViewModel}
        useFormProps={useFormProps}
        name="Description"
        label={t.common.description}
        isRequired
      />
    )
  }

  if (fieldValueId === undefined) {
    return null
  }

  return (
    <>
      <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
        <McModal
          title={t.common.edit}
          footer={
            <div>
              <ButtonToolbar className="pull-right">
                <McButton onClick={() => onClose()}>{t.common.cancel}</McButton>
                <McButton
                  bsStyle="primary"
                  type="submit"
                  isLoading={IsSubmitting}
                >
                  {t.common.save}
                </McButton>
              </ButtonToolbar>
            </div>
          }
          closeClicked={() => onClose()}
        >
          {renderForm()}
        </McModal>
      </form>
    </>
  )
}

export default observer(FieldValueEditModal)
