import { observer } from 'mobx-react'
import React, { Fragment, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { McButton } from 'src/Components'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import { MainPage, MyDropzone } from 'src/Components/My'
import { ReadFileContent } from 'src/Helpers/FileHelper'
import { t } from '../../../../I18n'
import { fileEditStore, IFormValues } from './Store'

interface IProps {}

const Edit: React.FC<IProps> = () => {
  const params = useParams<'id'>()
  const navigate = useNavigate()
  useEffect(() => {
    fileEditStore.Initialize(params.id)
  }, [null])

  const { ViewModel, IsSubmitting } = fileEditStore

  useEffect(() => {
    reset(ViewModel)
  }, [ViewModel])

  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({})
  const { handleSubmit, reset, setValue } = useFormProps

  const onSubmit = (data: IFormValues) => {
    fileEditStore.Edit(data, navigate)
  }

  return (
    <MainPage>
      <br className="xs-10" />

      {ViewModel !== undefined && (
        <Fragment>
          <BreadCrumbs currentPageTitle={t.fileEdit.title} />
          <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
            <Portlet title={t.common.file}>
              <Row>
                <Col md={6}>
                  <MyDropzone
                    formObject={{} as IFormValues}
                    useFormProps={useFormProps}
                    name="File"
                    label={t.fileEdit.form.dataField.label}
                    dropFileText={t.fileEdit.form.dataField.dropfile}
                    rules={{
                      required: t.fileEdit.form.dataField.error.required,
                    }}
                    accept={['image/jpeg', 'image/png', 'application/pdf']}
                    onChangeFile={(files: File[] | null) => {
                      if (files === null) {
                        //removed
                        setValue('Data', '')
                        setValue('MimeType', '')
                        setValue('Name', '')
                      } else {
                        ReadFileContent(files, (base64, file) => {
                          setValue('Data', base64)
                          setValue('MimeType', file.type)
                          setValue('Name', file.name)
                        })
                      }
                    }}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col mdOffset={8} md={4}>
                  <McButton
                    block
                    bsStyle="primary"
                    type="submit"
                    isLoading={IsSubmitting}
                  >
                    {t.common.save}
                  </McButton>
                </Col>
              </Row>
            </Portlet>
          </form>
        </Fragment>
      )}
    </MainPage>
  )
}

export default observer(Edit)
