import { action, makeObservable, runInAction } from 'mobx'
import { createPageAlert } from 'src/Components/McPageAlert'
import { t } from 'src/I18n'
import ErrorCodes from 'src/Models/Server/ErrorCodes'
import IGenericError from 'src/Models/Server/IGenericError'
import UserController from 'src/Services/ToprCoreApi/Axios/Controllers/UserController'

import BaseStore from 'src/Stores/BaseStore'
import { toast } from 'react-toastify'

import { NavigateFunction } from 'react-router-dom'

export interface IFormValues {
  Password: string
  PasswordConfirm: string
}

export default class Store extends BaseStore {
  constructor() {
    super()
    makeObservable(this, {
      Submit: action,
    })
  }

  public Submit(
    requestId: string,
    password: string,
    navigate: NavigateFunction
  ) {
    this.IsSubmitting = true

    UserController.ResetPassword(requestId, password)
      .then(() => {
        navigate('/')
        toast.success(t.resetPassword.success)
      })
      .catch((error: IGenericError) => {
        runInAction(() => {
          this.IsSubmitting = false

          error.Message =
            t.resetPassword.serverErrors.REQUESTIDENTIFIER_NOT_FOUND
          this.PageAlert = createPageAlert(error, [
            ErrorCodes.USER_LOGIN_EMAIL_NOT_FOUND,
            'REQUESTIDENTIFIER_NOT_FOUND',
          ])
        })
      })
  }
}

export const resetPasswordStore = new Store()
