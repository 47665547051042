import { observer } from 'mobx-react'
import React from 'react'
interface IProps {
  checked: boolean
  label: string
}

const MyCheckboxLabel: React.FC<IProps> = ({ checked, label }) => {
  return (
    <div className="checkbox">
      <label title={label}>
        <input type="checkbox" checked={checked} readOnly={true} />
        {label}
      </label>
    </div>
  )
}

export default observer(MyCheckboxLabel)
