import React, { PropsWithChildren } from 'react'

interface IProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {}

const FlexRow: React.FC<PropsWithChildren<IProps>> = ({
  children,
  ...props
}) => {
  return (
    <div className="flexRow" {...props}>
      {children}
    </div>
  )
}

export default FlexRow
