import React from 'react'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import { MainPage } from 'src/Components/My'
import { t } from 'src/I18n'
import FormComponent from '../FormComponent/FormComponent'

const Insert: React.FC = () => {
  return (
    <>
      <MainPage>
        <BreadCrumbs
          currentPageTitle={t.loyaltyprogram.insert.title}
          previousPages={[
            {
              to: `/user/loyaltyprograms`,
              title: t.common.loyaltyPrograms,
            },
          ]}
        />
        <FormComponent
          title={t.loyaltyprogram.insert.title}
          //userGuid={params.userGuid}
        />
      </MainPage>
    </>
  )
}

export default Insert
