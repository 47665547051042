import React, { useEffect, useReducer } from 'react'
import { useParams } from 'react-router-dom'
import { LoaderIcon } from 'src/Components'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import { MainPage } from 'src/Components/My'
import { GetLocalText } from 'src/Helpers/TextHelpers'
import { t } from 'src/I18n'
import EventTicketForm from '../Form/EventTicketForm'
import { initialState, reducer, reducerActions } from './Reducer'

const EventTicketEdit: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const actions = reducerActions(state, dispatch)
  const params = useParams<'eventId' | 'id'>()
  useEffect(() => {
    actions.initialize(params.eventId, params.id)
  }, [null])

  const { event, isSubmitting, eventTicketEdit } = state

  const name = event !== undefined ? GetLocalText(event.Name) : ''
  const renderContent = () => {
    if (event === undefined) {
      return <LoaderIcon IsLoading={true} />
    }

    return (
      <>
        <EventTicketForm
          onSave={(data) => {
            actions.save(params.id, data)
          }}
          title={t.eventTicket.insert.title}
          isSubmitting={isSubmitting}
          eventTicketEdit={eventTicketEdit}
        />
      </>
    )
  }

  return (
    <>
      <MainPage>
        <BreadCrumbs
          currentPageTitle={t.eventTicket.insert.title}
          previousPages={[
            {
              to: `/event/details/${params.eventId}`,
              title: name, //name
            },
          ]}
        />
        {renderContent()}
      </MainPage>
    </>
  )
}

export default EventTicketEdit
