import React, { PropsWithChildren } from 'react'
import { NavLink } from 'react-router-dom'
import Tenant from 'src/Tenants/Tenant'
import Footer from '../../Components/Landing/Footer'
import Images from '../../Images'

export default class LandingLayout extends React.Component<
  PropsWithChildren<{}>
> {
  public componentDidMount() {
    document.body.className = `landing ${Tenant.SystemName.toLowerCase()}`
  }

  public render() {
    return (
      <div className="landing">
        <header className="navbar" role="banner">
          <div className="container">
            <div className="navbar-header">
              <NavLink to="/" className="navbar-brand navbar-brand-img">
                <img src={Images.Logo} alt="MVP Ready" />
              </NavLink>
              <button
                className="navbar-toggle"
                type="button"
                data-toggle="collapse"
                data-target=".navbar-collapse"
              >
                <span className="sr-only">Toggle navigation</span>
                <i className="fa fa-bars" />
              </button>
            </div>
          </div>
        </header>
        <div>{this.props.children}</div>
        <div className="footer-container">
          <Footer />
        </div>
      </div>
    )
  }
}

//export default Layout
