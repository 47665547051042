import React from 'react'
import { NavLink } from 'react-router-dom'
import { hasMatchedChildren } from 'src/Helpers/UrlHelpers'
import { IsAdmin } from 'src/Models/Server/Response/User/ManagerUserVTO'
import { persistedStore } from 'src/Stores/PersistedStore'
interface IProps {}

const AdminMenu: React.FC<IProps> = () => {
  const { SelectedManagerUser } = persistedStore
  if (!IsAdmin(SelectedManagerUser)) {
    return null
  }

  const hasActiveChildrenClassName = hasMatchedChildren(
    '/merchants',
    '/file/insert'
  )
    ? 'dropdown active'
    : 'dropdown'

  const fileHasActiveChildrenClassName = hasMatchedChildren('/file/insert')
    ? 'dropdown-submenu active'
    : 'dropdown-submenu'

  return (
    <li className={hasActiveChildrenClassName}>
      <a
        className="dropdown-toggle"
        data-toggle="dropdown"
        data-hover="dropdown"
      >
        Admin
        <i className="fa fa-chevron-down" />
      </a>

      <ul className="dropdown-menu" role="menu">
        <li>
          <NavLink to={'/merchants'}>
            <i className="fa fa-building dropdown-icon " />
            Merchants
          </NavLink>
        </li>
        <li className={fileHasActiveChildrenClassName}>
          <a>Files</a>
          <ul className="dropdown-menu" role="menu">
            <li>
              <NavLink to={'/file/insert'}>
                <i className="fa fa-building dropdown-icon " />
                Insert
              </NavLink>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  )
}

export default AdminMenu
