import React, { PropsWithChildren } from 'react'
import { Path, RegisterOptions, UseFormReturn } from 'react-hook-form'
import { GetAllMonthsOptions } from 'src/Helpers/DateHelpers'
import { ISelectOption } from 'src/Models/Client'
import MySelect from './MySelect'

interface IProps<T> {
  name: Path<T>
  formObject: T
  useFormProps: UseFormReturn<T>
  rules?: RegisterOptions
  //select
  label: string
  onSelectionChange?: (option: ISelectOption) => void
}

const MyMonthPicker = <ObjectType,>({
  name,
  useFormProps,
  rules,
  formObject,
  label,
  onSelectionChange,
}: PropsWithChildren<IProps<ObjectType>>) => {
  const options = GetAllMonthsOptions()

  //console.log('MyMonthPicker', options[2])
  return (
    <>
      <MySelect
        formObject={formObject}
        useFormProps={useFormProps}
        label={label}
        name={name}
        rules={rules}
        isMulti={false}
        options={options}
        onSelectionChange={onSelectionChange}
      />
    </>
  )
}

export default MyMonthPicker
