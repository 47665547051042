import React, { useEffect, useReducer } from 'react'
import { ButtonToolbar, Col, Row } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import McButton from 'src/Components/McButton'
import McModal from 'src/Components/Modals/McModal'
import { MyTextbox } from 'src/Components/My'
import { t } from 'src/I18n'
import { LoyaltyRuleTypes } from 'src/Models/Server/Enums'
import { loyaltyProgramDetailsStore } from '../Store'
import {
  IEditLoyaltyProgramRule,
  IFormValues,
  initialState,
  reducer,
  reducerActions,
} from './Reducer'

interface IProps {
  editLoyaltyProgramRule?: IEditLoyaltyProgramRule
  onClose: () => void
}

const EditArticleModal: React.FC<IProps> = ({
  editLoyaltyProgramRule,
  onClose,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const actions = reducerActions(state, dispatch)
  const { viewModel, isSubmitting } = state

  useEffect(() => {
    if (editLoyaltyProgramRule !== undefined) {
      actions.initialize(editLoyaltyProgramRule)
    }
  }, [editLoyaltyProgramRule])

  useEffect(() => {
    reset(viewModel)
  }, [viewModel])

  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({})
  const { handleSubmit, reset } = useFormProps

  const onSubmit = (data: IFormValues) => {
    actions.save(editLoyaltyProgramRule, data, () => {
      onClose()
      loyaltyProgramDetailsStore.Initialize(
        editLoyaltyProgramRule.loyaltyProgram.LoyaltyProgramId.toString()
      )
    })
  }

  if (editLoyaltyProgramRule === undefined) {
    return null
  }

  const renderContent = () => {
    const renderArticleCodeOrGroup = () => {
      const loyaltyRule =
        editLoyaltyProgramRule.loyaltyProgram.LoyaltyRules.find(
          (x) => x.LoyaltyRuleId == editLoyaltyProgramRule.loyaltyRuleId
        )

      switch (loyaltyRule.LoyaltyRuleTypeId) {
        case LoyaltyRuleTypes.Article:
          return (
            <MyTextbox
              formObject={viewModel}
              useFormProps={useFormProps}
              name="ArticleCode"
              label={t.loyaltyprogram.insert.fields.ArticleCode.label}
              rules={{
                required:
                  t.loyaltyprogram.insert.fields.ArticleCode.errors.required,
              }}
            />
          )
        case LoyaltyRuleTypes.ArticleGroup:
          return (
            <MyTextbox
              formObject={viewModel}
              useFormProps={useFormProps}
              name="ArticleGroup"
              label={t.loyaltyprogram.insert.fields.ArticleGroup.label}
              rules={{
                required:
                  t.loyaltyprogram.insert.fields.ArticleGroup.errors.required,
              }}
            />
          )
        default:
          return null
      }
    }

    return (
      <>
        <Row>
          <Col md={4}>
            <MyTextbox
              formObject={viewModel}
              useFormProps={useFormProps}
              name="Name"
              label={t.common.name}
              rules={{
                required: t.loyaltyprogram.insert.fields.Name.errors.required,
              }}
            />
          </Col>
          <Col md={4}>{renderArticleCodeOrGroup()}</Col>
        </Row>
      </>
    )
  }

  return (
    <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
      <McModal
        closeClicked={onClose}
        closeOnBackdrop={false}
        title={t.common.edit}
        footer={
          <ButtonToolbar className="pull-right">
            <McButton onClick={onClose}>{t.common.cancel}</McButton>
            <McButton bsStyle="primary" type="submit" isLoading={isSubmitting}>
              {t.common.edit}
            </McButton>
          </ButtonToolbar>
        }
      >
        {renderContent()}
      </McModal>
    </form>
  )
}

export default EditArticleModal
