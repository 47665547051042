import { action, makeObservable, observable } from 'mobx'
import { GetConsoleDate } from 'src/Helpers/DateHelpers'
import BaseStore from 'src/Stores/BaseStore'
import Connector from './signalRConnection'

export interface IFormValues {
  message: string
}

type LogItem = {
  date: string
  source: string
  message: string
  received: boolean
}

export class Store extends BaseStore<IFormValues> {
  public Logs: LogItem[] = []

  private instance: Connector

  //const { SendMerchantMessage, events } = Connector.getInstance()

  constructor() {
    super()
  }

  public Initialize() {
    //
    this.instance = Connector.getInstance()

    const { ReveivedEvents } = this.instance
    ReveivedEvents(
      (message) => this.AddLog('ReceiveMerchantMessage', message, true),
      (connectionId) => this.AddLog('onUserConnected', connectionId, true),
      (connectionId) => this.AddLog('onUserDisconnected', connectionId, true)
    )
    makeObservable(this, {
      Logs: observable,
      Initialize: action,
      SendMessage: action,
      AddLog: action,
    })
  }

  public AddLog(source: string, message: string, received: boolean = false) {
    this.Logs = [
      { date: GetConsoleDate(), message, source, received },
      ...this.Logs,
    ]
  }

  public SendMessage(message: string) {
    this.AddLog('SendMessage', message)
    this.instance.SendMerchantMessage(message)
  }
}
