import React, { ReactNode, useEffect, useRef } from 'react'
import Slim from './slim.module.js'
import './slim.min.css'
import FileDTO from 'src/Models/Server/File/FileDTO.js'

//https://pqina.nl/slim/

interface IProps {
  name: string
  //initialImage?: any
  initialFile?: FileDTO
  ratio?: string
  label?: string
  children?: ReactNode

  size?: { width: number; height: number }
  didConfirm?: (data) => void
  didTransform?: (data) => void
  didLoad?: (file, image, meta) => void
  didRemove?: (data) => void
}

const Slim2: React.FC<IProps> = (props) => {
  const containerRef = useRef<HTMLDivElement>()
  const slimRef = useRef<any>()
  //const { initialImage, size } = props
  const { initialFile, size } = props

  useEffect(() => {
    slimRef.current = Slim.create2(containerRef.current, props)
    return () => {
      slimRef.current.destroy()
    }
  }, [null])

  useEffect(() => {
    if (props.initialFile && slimRef.current) {
      //console.log('add image')

      const base64 = `data:image/jpeg;base64,${initialFile.Data}`
      slimRef.current.load(base64)
    }
  }, [initialFile])

  return <div ref={containerRef} style={{ width: size.width }}></div>
}

export default Slim2
