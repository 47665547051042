import { action, makeObservable, observable, runInAction } from 'mobx'
import { createPageAlert } from 'src/Components/McPageAlert'
import { ISelectOption } from 'src/Models/Client'
import { LatLng } from 'src/Models/Client/LatLng'
import { PosOrderDeliveryTypesDTO, TenantIds } from 'src/Models/Server/Enums'
import IGenericError from 'src/Models/Server/IGenericError'
import ILocationVTO from 'src/Models/Server/Response/Location/ILocationVTO'
import LocationController from 'src/Services/ToprCoreApi/Axios/Controllers/LocationController'
import MapsService from 'src/Services/ToprCoreApi/Axios/Controllers/MapsService'
import BaseStore from 'src/Stores/BaseStore'

export interface IFormValues extends ILocationVTO {
  SelectedTenant?: ISelectOption
  SelectedDeliveryType?: ISelectOption
}

export default class Store extends BaseStore<IFormValues> {
  public ShowMap = false
  public LatLong?: LatLng = undefined
  public TenantOptions: ISelectOption[] = [
    {
      value: TenantIds.Topr.toString(),
      label: TenantIds[TenantIds.Topr].toString(),
    },
    {
      value: TenantIds.Siedler.toString(),
      label: TenantIds[TenantIds.Siedler].toString(),
    },
  ]

  public DeliveryOptions: ISelectOption[] = [
    {
      value: PosOrderDeliveryTypesDTO.Delivery.toString(),
      label:
        PosOrderDeliveryTypesDTO[PosOrderDeliveryTypesDTO.Delivery].toString(),
    },
    {
      value: PosOrderDeliveryTypesDTO.Pickup.toString(),
      label:
        PosOrderDeliveryTypesDTO[PosOrderDeliveryTypesDTO.Pickup].toString(),
    },
    {
      value: PosOrderDeliveryTypesDTO.PickupAndDelivery.toString(),
      label:
        PosOrderDeliveryTypesDTO[
          PosOrderDeliveryTypesDTO.PickupAndDelivery
        ].toString(),
    },
  ]

  constructor() {
    super()
    makeObservable(this, {
      ShowMap: observable,
      LatLong: observable,
      ToggleMap: action,
      GeoCodeAddress: action,
      EditLocation: action,
    })
  }

  public Initialize(editLocation: ILocationVTO) {
    this.BaseInitialize(editLocation)

    if (editLocation.TenantId !== null) {
      runInAction(
        () =>
          (this.ViewModel = {
            ...this.ViewModel,
            SelectedTenant: this.TenantOptions.find(
              (x) => x.value === editLocation.TenantId.toString()
            ),
          })
      )
    }

    if (editLocation.PosOrderDeliveryTypeID !== null) {
      runInAction(
        () =>
          (this.ViewModel = {
            ...this.ViewModel,
            SelectedDeliveryType: this.DeliveryOptions.find(
              (x) => x.value === editLocation.PosOrderDeliveryTypeID.toString()
            ),
          })
      )
    }

    if (editLocation.Lat && editLocation.Long) {
      this.LatLong = new LatLng(editLocation.Lat, editLocation.Long)
    }
  }

  public ToggleMap() {
    this.ShowMap = !this.ShowMap
  }

  public GeoCodeAddress(data: ILocationVTO) {
    if (data.City === undefined) {
      return
    }

    MapsService.GeoCodeAddress(
      data.City,
      data.StreetName,
      data.StreetNumber,
      data.ZipCode
    )
      .then((result) => {
        if (result.results.length > 0) {
          const location = result.results[0].geometry
            .location as unknown as LatLng
          runInAction(() => {
            this.LatLong = location
          })
        } else {
          runInAction(() => {
            this.LatLong = undefined
          })
        }
      })
      .catch(() => {
        runInAction(() => {
          this.LatLong = undefined
        })
      })
  }

  public EditLocation(data: IFormValues) {
    return new Promise<void>((resolve, reject) => {
      if (data.SelectedTenant !== undefined) {
        data.TenantId = data.SelectedTenant.value
      }

      if (data.SelectedDeliveryType !== undefined) {
        data.PosOrderDeliveryTypeID = data.SelectedDeliveryType.value
      }

      if (this.LatLong) {
        data.Lat = this.LatLong.lat
        data.Long = this.LatLong.lng
      }

      console.log('data', data)
      this.IsSubmitting = true
      LocationController.Edit(data)
        .then(() => {
          runInAction(() => {
            this.IsSubmitting = false
          })
          resolve()
        })
        .catch((error: IGenericError) => {
          runInAction(() => {
            this.PageAlert = createPageAlert(error)
            this.IsSubmitting = false
          })
          reject()
        })
    })
  }
}

export const editLocationStore = new Store()
