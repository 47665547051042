import { AxiosError, AxiosResponse } from 'axios'
import { handleException } from 'src/Helpers/ResponseHelpers'
import IUserFieldVTO from 'src/Models/Server/Response/Field/IUserFieldVTO'
import { axiosAuth } from '../AuthAxios'

export default {
  SaveUserField(request: {
    UserGuid: string
    FieldGuid: string
    Value: string
  }) {
    return new Promise<IUserFieldVTO>((resolve, reject) => {
      axiosAuth
        .post(`/api/v1/userFields/save`, request)
        .then((response: AxiosResponse<IUserFieldVTO>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  GetUserFields(userId: number) {
    return new Promise<IUserFieldVTO[]>((resolve, reject) => {
      axiosAuth
        .get(`/api/v1/userfields/${userId}`)
        .then((response: AxiosResponse<IUserFieldVTO[]>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
}
