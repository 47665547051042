import { AxiosError, AxiosResponse } from 'axios'
import FileDownload from 'js-file-download'
import { FileDateTime } from 'src/Helpers/DateHelpers'
import { handleException } from 'src/Helpers/ResponseHelpers'
import { IExceptionError } from 'src/Models/Server/Response/Exception/ExceptionError'
import { IMerchantRefundFileDetailsDto } from 'src/Models/Server/Response/Refund/IMerchantRefundFileDetailsDto'
import { IMerchantRefundFileDto } from 'src/Models/Server/Response/Refund/IMerchantRefundFileDto'
import { axiosAuth } from '../AuthAxios'

export default {
  CreateMerchantRefundFile() {
    return new Promise<number>(
      (resolve, reject: (error: IExceptionError) => void) => {
        const url = `/api/v1/merchantrefund/create`

        axiosAuth
          .post(url, null)
          .then((response: AxiosResponse<number>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  GetMerchantRefundFiles(merchantId: number, page: number, pageSize: number) {
    return new Promise<IMerchantRefundFileDto[]>(
      (resolve, reject: (error: IExceptionError) => void) => {
        const url = `/api/v1/merchantrefunds/${merchantId}`

        axiosAuth
          .get(url, {
            params: {
              page,
              pageSize,
            },
          })
          .then((response: AxiosResponse<IMerchantRefundFileDto[]>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  Download(merchantRefundFileId: number) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        const url = `/api/v1/merchantrefund/download/${merchantRefundFileId}`

        axiosAuth
          .get(url)
          .then((response: AxiosResponse<any>) => {
            FileDownload(
              response.data as any,
              `merchantrefunds_${FileDateTime()}.xml`
            )
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  Details(merchantRefundFileId: string) {
    return new Promise<IMerchantRefundFileDetailsDto>(
      (resolve, reject: (error: IExceptionError) => void) => {
        const url = `/api/v1/merchantrefund/details/${merchantRefundFileId}`

        axiosAuth
          .get(url)
          .then((response: AxiosResponse<IMerchantRefundFileDetailsDto>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
}
