import { makeObservable, observable, action, runInAction } from 'mobx'
import { ISelectOption } from 'src/Models/Client'
import IGenericError from 'src/Models/Server/IGenericError'
import {
  SelectOptionsFromNameGuidVTO,
  SelectOptionsFromNameIdVTO,
} from 'src/Models/Server/INameGuidVTO'
import { ITransactionExtendedSTO } from 'src/Models/Server/Response/Transaction/ITransactionExtendedSTO'
import ReportController from 'src/Services/ToprCoreApi/Axios/Controllers/ReportController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export interface IFormValues {
  SelectedTokenTypes: ISelectOption[]
  SelectedCurrencies: ISelectOption[]
  StartDate: Date
  EndDate: Date
}

export default class Store extends BaseStore<IFormValues> {
  public LoadingTransactions: boolean = false
  public TokenTypes: ISelectOption[] = []
  public Currencies: ISelectOption[] = []

  public Transactions: ITransactionExtendedSTO[] = []

  constructor() {
    super()
    makeObservable(this, {
      TokenTypes: observable,
      Currencies: observable,
      LoadingTransactions: observable,
      Transactions: observable,
      Initialize: action,
      GetPaymentsTokenType: action,
    })
  }

  /** Sets Initial state */

  public Initialize() {
    this.IsLoading = true
    ReportController.GetTokenTypeFilter()
      .then((response) => {
        runInAction(() => {
          const tokenTypesOptions = SelectOptionsFromNameIdVTO(
            response.TokenTypes
          )
          const currencyOptions = SelectOptionsFromNameGuidVTO(
            response.Currencies
          )
          const today = new Date()
          this.TokenTypes = tokenTypesOptions
          this.Currencies = currencyOptions
          this.ViewModel = {
            SelectedTokenTypes: tokenTypesOptions,
            SelectedCurrencies: currencyOptions,
            StartDate: new Date(today.getFullYear(), today.getMonth(), 1),
            EndDate: new Date(),
          }

          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsLoading = false))
        appUiStore.ShowError(error)
      })
  }

  public GetPaymentsTokenType(data: IFormValues) {
    const tokenTypeIds = data.SelectedTokenTypes.map((x) => x.value)
    const currencyGuids = data.SelectedCurrencies.map((x) => x.value)
    const startDate = data.StartDate
    const endDate = data.EndDate

    this.IsSubmitting = true
    ReportController.GetPaymentsTokenType(
      tokenTypeIds,
      currencyGuids,
      startDate,
      endDate
    )
      .then(() => {
        runInAction(() => (this.IsSubmitting = false))
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsLoading = false))
        appUiStore.ShowError(error)
      })
  }
}

export const paymentsTokenTypeStore = new Store()
