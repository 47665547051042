import { action, makeObservable, observable, runInAction } from 'mobx'
import { createPageAlert } from 'src/Components/McPageAlert'
import { ISelectOption } from 'src/Models/Client'
import IGenericError from 'src/Models/Server/IGenericError'
import { ToSelectOptions } from 'src/Models/Server/INameIdVTO'
import ITerminalVTO from 'src/Models/Server/Response/Terminal/ITerminalVTO'
import TerminalController from 'src/Services/ToprCoreApi/Axios/Controllers/TerminalController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export interface IEditTerminalView {
  SelectedTerminalType: ISelectOption
  TerminalId: string
  Description: string
  TerminalTypeID: string
}

export default class Store extends BaseStore<IEditTerminalView> {
  public TerminalTypes: ISelectOption[] = []

  constructor() {
    super()
    makeObservable(this, {
      TerminalTypes: observable,
      Initialize: action,
      EditType: action,
    })
  }

  public Initialize(editTerminal: ITerminalVTO) {
    this.BaseInitialize({
      Description: editTerminal.Description,
      TerminalId: editTerminal.TerminalId.toString(),
      SelectedTerminalType: null,
      TerminalTypeID: editTerminal.TerminalTypeId,
    })

    TerminalController.GetTerminalTypes()
      .then((posTypes) => {
        runInAction(() => {
          this.TerminalTypes = ToSelectOptions(posTypes)

          const selectedTerminalType = this.TerminalTypes.find(
            (x) => x.value === editTerminal.TerminalTypeId.toString()
          )

          this.ViewModel = {
            ...this.ViewModel,
            SelectedTerminalType: selectedTerminalType,
          }

          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsLoading = false))
        appUiStore.ShowError(error)
      })
  }

  public EditType(data: IEditTerminalView) {
    this.IsSubmitting = true
    data.TerminalTypeID = data.SelectedTerminalType.value

    return new Promise<void>((resolve, reject) => {
      TerminalController.Edit(data)
        .then(() => {
          runInAction(() => (this.IsSubmitting = false))
          resolve()
        })
        .catch((error: IGenericError) => {
          runInAction(() => {
            this.PageAlert = createPageAlert(error)
            this.IsSubmitting = false
          })
          reject()
        })
    })
  }
}

export const editTerminalStore = new Store()
