import React, { useReducer } from 'react'
import { FormGroup } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { LoaderIcon, McButton } from 'src/Components'
import { MyLabelButton } from 'src/Components/My'
import { t } from 'src/I18n'
import { ITagVTO } from 'src/Models/Server/Response/Tag/ITagVTO'
import { appUiStore } from 'src/Stores/AppUiStore'
import AddUserTagModal from './Components/AddUserTagModal'
import { initialState, reducer, reducerActions } from './Reducer'
interface IProps {
  userTags: ITagVTO[]
  isUserTagsLoading: boolean
}

const UserTagsView: React.FC<IProps> = ({ userTags, isUserTagsLoading }) => {
  const params = useParams<'id'>() //userguid

  const [state, dispatch] = useReducer(reducer, initialState)
  const actions = reducerActions(state, dispatch)
  const { isAddUserTagModalVisible } = state

  const renderAddUserTagModal = () => {
    if (!isAddUserTagModalVisible) {
      return null
    }

    return (
      <AddUserTagModal
        userTags={userTags}
        onClose={() => actions.toggleAddModal(false)}
      />
    )
  }

  return (
    <>
      <FormGroup>
        <label>{t.tags.index.title}</label>
        <LoaderIcon IsLoading={isUserTagsLoading} />
        {userTags.map((tag, key) => (
          <MyLabelButton
            key={key}
            label={tag.Name}
            onButtonClick={() => {
              appUiStore.ShowConfirm({
                message: t
                  .formatString(t.tags.deleteUserTagConfirm, {
                    tagName: tag.Name,
                  })
                  .toString(),
                confirmActionText: t.common.delete,
                confirmAction: () => {
                  actions.deleteUserTag(params.id, tag.TagId)
                },
              })
            }}
          />
        ))}
        <McButton
          bsSize="xs"
          bsStyle="primary"
          onClick={(e) => {
            console.log('click')
            e.stopPropagation()
            actions.toggleAddModal(true)
          }}
        >
          <i className="fa fa-plus" /> {t.tags.insert.title}
        </McButton>
      </FormGroup>
      {renderAddUserTagModal()}
    </>
  )
}

export default UserTagsView
