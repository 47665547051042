import React, { Component, Fragment } from 'react'
import { t } from 'src/I18n'
import Images from 'src/Images'
import { persistedStore } from 'src/Stores/PersistedStore'

export default class FlagsNavItems extends Component {
  public render() {
    return (
      <Fragment>
        <li className={this.GetFlagActiveClassName('nl')}>
          <a
            onClick={() => {
              persistedStore.SetLanguage('nl', true)
            }}
          >
            <img src={Images.Flags.NL} />
          </a>
        </li>
        <li className={this.GetFlagActiveClassName('en')}>
          <a
            onClick={() => {
              persistedStore.SetLanguage('en', true)
            }}
          >
            <img src={Images.Flags.EN} />
          </a>
        </li>
        <li className={this.GetFlagActiveClassName('de')}>
          <a
            onClick={() => {
              persistedStore.SetLanguage('de', true)
            }}
          >
            <img src={Images.Flags.DE} />
          </a>
        </li>
      </Fragment>
    )
  }

  private GetFlagActiveClassName(lang: string) {
    return t.getLanguage() === lang ? 'active' : ''
  }
}
