import { GetLocalText } from 'src/Helpers/TextHelpers'
import { ISelectOption } from 'src/Models/Client'
import IGenericError from 'src/Models/Server/IGenericError'
import { IEventTicketVTO } from 'src/Models/Server/Response/Event/IEventTicketVTO'
import EventController from 'src/Services/ToprCoreApi/Axios/Controllers/EventController'
import { appUiStore } from 'src/Stores/AppUiStore'

export interface IState {
  isLoading: boolean
  isSubmitting: boolean
  showModal: boolean
  eventTicketOptions: ISelectOption[]
  viewModel: IAddTicketForm
}

export const initialState: IState = {
  isLoading: true,
  isSubmitting: false,
  showModal: false,
  eventTicketOptions: [],
  viewModel: undefined,
}

export interface IAddTicketForm {
  eventTicketOption: ISelectOption
  amount: number
}

export type Actions =
  | {
      type: 'initialize'
      eventTicketOptions: ISelectOption[]
      viewModel: IAddTicketForm
    }
  | {
      type: 'setIsSubmitting'
      isSubmitting: boolean
    }
  | {
      type: 'toggleModal'
      showModal: boolean
    }

export const itemsToOptions = (eventTickets: IEventTicketVTO[]) => {
  return eventTickets.map((csi) => ({
    value: csi.EventTicketId.toString(),
    label: `${GetLocalText(csi.Name)}`,
  }))
}

export const reducer = (state: IState, action: Actions): IState => {
  switch (action.type) {
    case 'initialize':
      return {
        ...state,
        isLoading: false,
        eventTicketOptions: action.eventTicketOptions,
        viewModel: action.viewModel,
      }
    case 'toggleModal':
      return { ...state, showModal: action.showModal }
    case 'setIsSubmitting':
      return { ...state, isSubmitting: action.isSubmitting }
    default:
      throw new Error('Invalid Reducer Action')
  }
}

export const reducerActions = (
  state: IState,
  dispatch: (action: Actions) => void
) => {
  return {
    initialize: (eventId: number) => {
      EventController.GetEventTickets(eventId.toString())
        .then((eventTickets) => {
          const eventTicketOptions: ISelectOption[] =
            itemsToOptions(eventTickets)

          const viewModel: IAddTicketForm = {
            eventTicketOption: eventTicketOptions[0],
            amount: 1,
          }
          dispatch({
            type: 'initialize',
            eventTicketOptions,
            viewModel,
          })
        })
        .catch((error: IGenericError) => {
          appUiStore.ShowError(error)
        })
    },
    toggleModal: (showModal: boolean) => {
      dispatch({ type: 'toggleModal', showModal })
    },
    save: (userGuid: string, data: IAddTicketForm, onComplete: () => void) => {
      dispatch({ type: 'setIsSubmitting', isSubmitting: true })

      EventController.UserEventTicketInsert(
        userGuid,
        data.eventTicketOption.value,
        data.amount
      )
        .then(() => {
          onComplete()
          dispatch({ type: 'setIsSubmitting', isSubmitting: false })
        })
        .catch((error: IGenericError) => {
          appUiStore.ShowError(error)
          dispatch({ type: 'setIsSubmitting', isSubmitting: false })
        })
    },
  }
}
