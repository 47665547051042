import { AxiosError, AxiosResponse } from 'axios'
import { handleException } from 'src/Helpers/ResponseHelpers'
import INameIdVTO from 'src/Models/Server/INameIdVTO'
import { IExceptionError } from 'src/Models/Server/Response/Exception/ExceptionError'
import { axiosAuth } from '../AuthAxios'

export default {
  GetAll() {
    return new Promise<Array<INameIdVTO<number>>>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/relations/all`)
          .then((response: AxiosResponse<Array<INameIdVTO<number>>>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
}
