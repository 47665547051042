import React from 'react'
import { useParams } from 'react-router-dom'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import { MainPage } from 'src/Components/My'
import { t } from 'src/I18n'
import UserScheduleBonusForm from '../Components/UserScheduleBonusForm'

const Insert: React.FC = () => {
  const params = useParams<'userGuid'>()

  return (
    <>
      <MainPage>
        <BreadCrumbs
          currentPageTitle={t.userScheduleBonus.insert.title}
          previousPages={[
            {
              to: `/user/details/${params.userGuid}/planning`,
              title: t.common.user,
            },
          ]}
        />
        <UserScheduleBonusForm
          title={t.userScheduleBonus.insert.title}
          userGuid={params.userGuid}
        />
      </MainPage>
    </>
  )
}

export default Insert
