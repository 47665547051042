import { toast } from 'react-toastify'
import { t } from 'src/I18n'
import IGenericError from 'src/Models/Server/IGenericError'
import AutomaticChargeController from 'src/Services/ToprCoreApi/Axios/Controllers/AutomaticChargeController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'
import { userFinanceStore } from '../Store'

export default class Store extends BaseStore {
  public TurnOff(
    automaticChargeId: number,
    userGuid: string,
    currencyGuid: string
  ) {
    this.IsConfirming = true
    AutomaticChargeController.TurnOff(userGuid, currencyGuid)
      .then(() => {
        toast.success(t.automaticChargesPortlet.turnOff.success, {
          type: toast.TYPE.SUCCESS,
        })
        userFinanceStore.TurnoffAutomaticCharge(automaticChargeId)
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
      })
  }
}

export const automaticChargesStore = new Store()
