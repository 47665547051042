import { createSlice } from '@reduxjs/toolkit'
import { IEditConsumerSubscriptionItemVTO } from 'src/Models/Server/Response/ConsumerSubscription/IEditConsumerSubscriptionItemVTO'

//example, cuurently not used ;)
export interface IState {
  editItem: IEditConsumerSubscriptionItemVTO
  isLoading: boolean
}

const slice = createSlice({
  name: 'editSubscriptionitem',
  initialState: { editItem: undefined, isLoading: null } as IState,
  reducers: {
    setItem: (state, { payload: { editItem } }: ActionPayload) => {
      state.editItem = editItem
    },
  },
})

export const { setItem } = slice.actions
export default slice.reducer

type ActionPayload = {
  payload: Partial<IState>
}
