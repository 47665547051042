import { action, makeObservable, observable, runInAction } from 'mobx'
import { toast } from 'react-toastify'
import { createPageAlert } from 'src/Components/McPageAlert'
import { t } from 'src/I18n'
import IGenericError from 'src/Models/Server/IGenericError'
import IHelpDeskUserVTO from 'src/Models/Server/Response/User/IHelpDeskUserVTO'
import UserController from 'src/Services/ToprCoreApi/Axios/Controllers/UserController'
import BaseStore from 'src/Stores/BaseStore'
export interface IFormValues {
  UserName: string
}
export default class Store extends BaseStore<IFormValues> {
  public Source: IHelpDeskUserVTO = null

  constructor() {
    super()

    makeObservable(this, {
      Source: observable,
      EditUserName: action,
    })
  }

  public Initialize(user: IHelpDeskUserVTO) {
    this.BaseInitialize({
      UserName: user.UserName,
    })
    this.Source = user
  }

  public EditUserName(userName: string) {
    this.IsSubmitting = true

    return new Promise<IHelpDeskUserVTO>((resolve, reject) => {
      UserController.EditUserName(this.Source.UserGuid, userName)
        .then(() => {
          runInAction(() => {
            this.IsSubmitting = false
            this.Source.UserName = userName
          })
          toast.success(t.editUserName.success)
          resolve(this.Source)
        })
        .catch((error: IGenericError) => {
          runInAction(() => {
            this.PageAlert = createPageAlert(error)
            this.IsSubmitting = false
          })
          reject()
        })
    })
  }
}

export const editUserNameStore = new Store()
