export default {
  addAppUserModal: {
    fields: {
      Email: {
        label: 'Email address',
        errors: {
          required: 'Enter the email address.',
          regex: 'Enter a valid email address.',
        },
      },
      Name: {
        label: 'Name',
        errors: {
          required: 'Enter the name.',
        },
      },
      Password: {
        label: 'Password',
        errors: {
          required: 'Enter the password.',
        },
      },
      Currency: {
        label: 'Currency',
        errors: {
          required: 'Select a Currency.',
        },
      },
    },
    checkEmail: 'Check e-mail address',
  },
  addManagerUserModal: {
    fields: {
      Email: {
        label: 'Email address',
        errors: {
          required: 'Enter the email address.',
          regex: 'Enter a valid email address.',
        },
      },
      Name: {
        label: 'Name',
        errors: {
          required: 'Enter the name.',
        },
      },
      Password: {
        label: 'Password',
        errors: {
          required: 'Enter the password.',
        },
      },
    },
  },
  automaticChargeInsert: {
    title: 'Set automatic charge (SEPA)',
    fields: {
      AccountHolder: {
        label: 'Accountholder',
        errors: {
          required: 'Enter the accountholder.',
        },
      },
      AccountNumber: {
        label: 'Account number (IBAN)',
        errors: {
          required: 'Enter the account number.',
          iban: 'Enter a valid IBAN account number in.',
        },
      },
      City: {
        label: 'City',
        errors: {
          required: 'Enter the city.',
        },
      },
      EmailAddress: {
        label: 'Email address',
        errors: {
          required: 'Enter the email address.',
          regex: 'Enter a valid email address.',
        },
      },
      LastName: {
        label: 'Lastname',
        errors: {
          required: 'Enter the lastname.',
        },
      },
      PhoneNumber: {
        label: 'PhoneNumber',
        errors: {
          required: 'Enter the phone number.',
        },
      },
      SelectedBank: {
        label: 'Bank',
        info: 'Bank van de klant',
        errors: {
          required: 'Select a bank.',
          bank: 'Invalid bank for this IBAN',
        },
      },
      SelectedCurrency: {
        info: 'The currency which will be topped up.',
        errors: {
          required: 'Select a currency.',
        },
      },
      SepaFileData: {
        label: 'SEPA form',
        info: 'The SEPA form supplied by the customer.',
        dropFile: 'Click here to upload the SEPA formu (.pdf/.jpg/.png).',
        errors: {
          required: 'Upload the SEPA form.',
        },
      },
      StepAmount: {
        label: 'Charge amount',
        info: 'The amount that will be topped up.',
        errors: {
          required: 'Enter the charge amount.',
        },
      },
      StepLimit: {
        label: 'Limit (per week)',
        info: 'The maximum amount of charges that will be done per week (1-10).',
        errors: {
          required: 'Enter the limit.',
        },
      },
      StreetName: {
        label: 'Street name',
        errors: {
          required: 'Enter the street name.',
        },
      },
      StreetNumber: {
        label: 'Street number',
        errors: {
          required: 'Enter the street number.',
        },
      },
      Zipcode: {
        label: 'Postcode',
        errors: {
          required: 'Enter the postcode in.',
        },
      },
    },
    success: 'Automatic charge enabled for: {currencyName}',
  },
  automaticChargeEdit: {
    title: 'Change Automatic Charge',
    fields: {
      FileData: {
        label: 'SEPA changeform',
        info: 'The SEPA changeform sent by the customer.',
        dropFile: 'Click to upload SEPA changeform (.pdf/.jpg/.png).',
        errors: {
          required: 'Upload the SEPA changeform.',
        },
      },
      StepAmount: {
        label: 'Charge amount',
        info: 'The amount which will be topped up (minimum €10 and maximum €150).',
        errors: {
          required: 'Enter the charge amount.',
        },
      },
      AccountHolder: {
        label: 'Accountholder',
        errors: {
          required: 'Enter the accountholder.',
        },
      },
      AccountNumber: {
        label: 'Account number (IBAN)',
        errors: {
          required: 'Enter the account number.',
          iban: 'Enter a valid IBAN account number in.',
        },
      },
      SelectedBank: {
        label: 'Bank',
        info: 'Bank van de klant',
        errors: {
          required: 'Selecteer een bank.',
          bank: 'Ongeldige bank voor dit rekeningnummer',
        },
      },
      StepLimit: {
        label: 'Limit (per week)',
        info: 'The maximum amount of charges with may by done per week (minimum 1, maximum 10).',
        errors: {
          required: 'Enter the limit.',
        },
      },
    },
    sepaChangeForm: 'SEPA change form',
    success: 'Automatisch charge changed',
  },
  automaticChargesPortlet: {
    chargeSettings: 'Settings',
    stepAmount: 'Charge amount',
    stepLimit: 'Max per week',
    turnOff: {
      action: 'Turn off',
      confirmMessage:
        'This automatic charge ({currencyName}) will be <b>turned off</b>.<br/>Are you sure?',
      success: 'Automatic charge turned off.',
    },
  },
  common: {
    accountNumber: 'Account number',
    active: 'active',
    activate: 'Activate',
    activationCode: 'Activation code',
    accepted: 'Accepted',
    add: 'Add',
    address: 'Address',
    always: 'always',
    all: '<All>',
    amountTypes: {
      default: {
        title: 'Amount',
        required: 'Enter the amount.',
      },
      currency: {
        title: 'Amount',
        required: 'Enter the amount.',
      },
      percentage: {
        title: 'Percentage (%)',
        required: 'Enter the percentage.',
      },
    },
    amount: 'Amount',
    amountText: 'Amount',
    authenticatedUser: 'authenticated user',
    back: 'Back',
    backTo: 'Back to',
    bank: 'Bank',
    blocked: 'Blocked',
    cancel: 'Cancel',
    city: 'City',
    charge: 'Topup',
    close: 'Close',
    confirm: 'Bevestigen',
    consumer: 'Consumer',
    consumers: 'Consumers',
    consumerName: 'Consumer name',
    coupon: 'Voucher',
    coupons: 'Vouchers',
    company: 'Company',
    created: 'Created',
    currencies: 'Currencies',
    currency: 'Currency',
    custom: 'custom...',
    date: 'Date',
    day: 'Day',
    deactivate: 'Deactivate',
    delete: 'Delete',
    description: 'Description',
    deselectAll: 'Deselect all',
    details: 'Details',
    download: 'Download',
    dropImage: 'Click here to add an image',
    edit: 'Edit',
    email: 'Email address',
    error: 'Error',
    example: 'Example',
    extraInformation: 'Extra information',
    file: 'File',
    filter: 'Filter',
    from: 'from',
    generate: 'Generate',
    grid: {
      name: {
        label: 'Name',
        placeholder: 'Search name',
      },
      email: {
        label: 'Email address',
        placeholder: 'Search eamil address',
      },
    },
    hidden: 'hidden',
    inActive: 'not active',
    info: 'Info',
    insert: 'Insert',
    image: 'Image',
    lastName: 'Last name',
    location: 'Location',
    locations: 'Locations',
    logoff: 'Logout',
    loyaltyProgram: 'Savings program',
    loyaltyPrograms: 'Savings programs',
    merchant: 'Merchant',
    merchants: 'Merchants',
    modified: 'Modified',
    module: 'Module',
    month: 'Month',
    myProfile: 'My profile',
    myCustomers: 'My customers',
    name: 'Name',
    no: 'No',
    noData: 'Geen data',
    other: 'Other',
    pagingText: 'Showing {from} to {to} of {size} entries',
    password: 'Password',
    payments: 'Payments',
    period: 'Period',
    phoneNumber: 'Phone number',
    pincode: 'Pin',
    processed: 'Processed',
    pos: 'Pos',
    preview: 'Preview',
    redeemed: 'Redeemed',
    revenue: 'Revenue',
    reward: 'Reward',
    save: 'Save',
    sam: 'Sam',
    savingcard: 'Savings card',
    savingcards: 'Savings cards',
    search: 'Search',
    selectAll: 'Select all',
    serverErrors: {
      defaultError: {
        Title: 'Error',
        Code: 'DefaultError',
        Message: 'Unfortunately something went wrong',
      },
    },
    settings: 'Settings',
    status: 'Status',
    streetName: 'Street name',
    streetNumber: 'Number',
    subscriptions: 'Subscriptions',
    sum: 'Sum',
    tenant: 'Tenant',
    tenants: 'Tenants',
    terminal: 'Terminal',
    terminals: 'Terminals',
    ticket: 'Ticket',
    tickets: 'Tickets',
    till: 'Till',
    title: 'Title',
    tokens: 'Tokens',
    tokenTypes: 'Token types',
    total: 'Total',
    totals: 'Totals',
    transactions: 'Transactions',
    turnoff: 'Turn off',
    type: 'Type',
    validity: 'Valid',
    validFrom: 'From',
    validTill: 'Up to and including',
    wallets: 'Wallets',
    warning: 'Warning',
    week: 'Week',
    withdraw: 'Withdraw',
    trip: 'Trip',
    time: 'Time',
    unlimited: 'Unlimited',
    untill: 'until',
    user: 'User',
    users: 'Users',
    username: 'Username',
    year: 'Year',
    zipCode: 'Zipcode',
  },
  commonErrors: {
    localizedStringRequired: 'Enter a {0} ({1}).',
  },
  commonFields: {
    Amount: {
      label: 'Amount',
      placeholder: {
        number: '0',
        decimal: '0,00',
      },
      errors: {
        required: 'Enter the amount.',
        minNumber: 'The minimal amount is 1.',
        minDecimal: 'Het minimal amount is 0,01',
      },
    },
  },
  confirmationSend: {
    header: 'Almost there!',
    heading: 'Confirmation sent',
    bodyTextLine1:
      '<p>Nice to have you aboard <b>{0}</b>!</p>' +
      '<p>An email has been sent to <b>{1}</b> with a confirmation link.' +
      ' When you click this link your account will be confirmed and you can start your first saving card!</p>',
  },
  coupons: {
    deleteUserCouponConfirm:
      'Are you sure you want to delete <i>{fullTitle}</i>?',
    userCouponAdd: {
      title: 'Add coupons',
      fields: {
        amount: {
          label: 'Coupons amount',
          required: 'Enter the coupon amount.',
        },
        coupon: {
          label: 'Coupon',
          required: 'Select a coupon.',
        },
      },
      successMessage:
        '{amount}x {couponTitle}>have been added to {count} customers.',
    },
  },
  dashboard: {
    totalUsers: 'Total users',
    totalActivated: 'Total activated users',
    newUsersThisWeek: 'New users this week',
    activeUsersThisWeek: 'Active users this week',
  },
  dashboardReport: {
    revenueToday: 'Revenue Today',
    paymentsToday: 'Payments Today',
    revenueMonth: 'Revenue this month',
    paymentsMonth: 'payments this month',
    revenueStats: 'Revenue Stats',
    top10Pos: 'Top 10 Pos this month',
    revenueHour: 'Revenue per hour',
    registeredUsers: 'Registered users',
    averageAmount: 'Average payment',
    outstandingBalance: 'Outstanding balance',
    redeemedCouponsToday: 'Redeemed vouchers today',
    issuedCouponsToday: 'Issued vouchers today ',
    redeemedCouponsMonth: 'Redeemed vouchers this month',
    issuedCouponsMonth: 'Issued vouchers this month',
    paymentsPerLocation: 'Payments by location today',
    revenuePerLocation: 'Revenue by location today',
  },
  editEmail: {
    title: 'Edit email address',
    emailField: {
      errors: {
        required: 'enter an email address',
        validateEmail: 'enter an valid email address',
      },
    },
    success: 'the email has been updated',
  },
  editName: {
    title: 'Edit name',
    nameField: {
      errors: {
        required: 'Enter a name',
      },
    },
    success: 'The name has been updated',
  },
  editPassword: {
    title: 'Edit password',
    nameField: {
      errors: {
        required: 'Enter a password',
      },
    },
    success: 'The password has been updated',
  },
  editUserName: {
    title: 'Edit username',
    userNameField: {
      errors: {
        required: 'Enter a username',
      },
    },
    success: 'The username has been updated',
  },
  enums: {
    savingCardStatusDTO: {
      Active: 'Active',
      NotYetActive: 'Not yet active',
      Expired: 'Expired',
      Deactivated: 'Deactivated',
    },
    LoyaltyRuleTypes: {
      Article: 'Article',
      ArticleGroup: 'Article group',
      Balance: 'Balance',
      TransactionAmountPositive: 'Charge', //opwaardering
      TransactionAmountNegative: 'Payment', //betaling
      RedeemCoupon: 'Redeem Coupon',
    },
    LoyaltyRewardTypes: {
      CouponReward: 'Voucher',
      CurrencyStaticReward: 'Currency, static amount',
      CurrencyOrderPercentageReward: 'Currency, percentage amount',
    },
  },
  event: {
    index: {
      title: 'Events',
      confirmDelete: 'Are you sure you want to remove the ticket<b>{name}</b>?',
    },
    edit: {
      title: 'Edit event',
    },
    insert: {
      title: 'Add event',
      fields: {
        name: {
          label: 'Name',
          required: 'Enter a name.',
        },
        description: {
          label: 'Description',
          required: 'Enter a description.',
        },
        start: {
          title: 'Start time',
          required: 'Select a start time.',
        },
        end: {
          title: 'End time',
          required: 'Select an end time.',
          greaterThan: 'The end time must be greater that the start time.',
        },
      },
    },
  },
  eventTicket: {
    insert: {
      title: 'Add ticket',
      fields: {
        name: {
          label: 'Name',
          info: 'For example: child up to 13 years old, adult or bronze, silver, gold etc.',
          required: 'Enter a name.',
        },
        onSale: {
          title: 'Available for online sale.',
        },
        price: {
          title: 'Price',
          required: 'Enter a price.',
        },
      },
    },
  },
  addTicket: {
    title: 'Add ticket',
    fields: {
      ticket: {
        title: 'Ticket',
      },
      amount: {
        title: 'Amount',
        required: 'Enter an amount.',
      },
    },
  },
  fileEdit: {
    title: 'Edit File',
    form: {
      dataField: {
        label: 'File (.*)',
        dropfile: 'Click here to upload file (.*)',
        error: {
          required: 'Upload file.',
        },
      },
      failed: 'File edit FAILED',
      success: 'File edit SUCCEEDED',
    },
  },
  fileInsert: {
    title: 'Insert File',
    form: {
      dataField: {
        label: 'File (.*)',
        dropfile: 'Click here to upload file (.*)',
        error: {
          required: 'Upload file.',
        },
      },
      failed: 'File upload FAILED',
      success: 'File upload SUCCEEDED',
    },
  },
  forgotPassword: {
    fieldEmailAddress: {
      label: 'Email address',
      errors: {
        required: 'Enter the email address.',
        regex: 'Enter a valid email address.',
      },
    },
    header: 'Forgot password',
    login: 'Inloggen',
    info: 'Enter email address and we will send you a link to reset your password.',
    submitText: 'Send',
    success: 'A mail with a reset link has been sent.',
  },
  generateAutomaticCharge: {
    availableAutomaticChargesText:
      'There are {count} direct debits available to generate.',
    noFile: 'No File',
    title: 'Generate SEPA file',
    fileBeingGenerated: 'File is being generated.',
  },
  helpdesk: {
    fieldSearch: {
      label: 'Search',
      placeholder: 'Name, username, activation code, email address',
    },
    fieldCardNumberSearch: {
      placeholder: 'Cardnumber',
    },
    loginUserUser: 'Login as user',
    selectTenantModal: {
      header: 'Select a tenant',
      loginActionText: 'Login',
    },
    submitText: 'Find customer',
    title: 'Helpdesk',
  },
  location: {
    insert: {
      title: 'New location',
      fields: {
        City: {
          label: 'City',
        },
        Email: {
          label: 'Email address',
        },
        Lat: {
          label: 'Latitude',
        },
        Long: {
          label: 'Longitude',
        },
        Name: {
          label: 'Name',
          errors: {
            required: 'Enter the name.',
          },
        },
        IsVisibleInApp: {
          label: 'IsVisibleInApp',
        },
        PhoneNumber: {
          label: 'Phone number',
        },
        POSOrderUrl: {
          label: 'POSOrderUrl',
        },
        StreetName: {
          label: 'Street name',
        },
        StreetNumber: {
          label: 'number',
        },
        ZipCode: {
          label: 'Zipcode',
        },
      },
      getCoordinates: 'Get coordinates',
      showMap: 'Show map',
    },
    setVisibleActive: 'Set Visible active ',
    setVisibleInActive: 'Set Visible inactive ',
  },
  login: {
    createAccount: 'Create an account!',
    fieldEmailAddress: {
      label: 'Email address',
      errors: {
        required: 'Enter your email address.',
      },
    },
    fieldPassword: {
      label: 'Password',
      errors: {
        required: 'Enter your password.',
      },
    },
    forgotPassword: 'Forgot password?',
    noAccount: "Don't have an account?",
    header: 'Welcome back to {name}!',
    remeberMe: 'Rememeber me',
    submitText: 'Login',
  },
  loyaltyprogram: {
    insert: {
      title: 'New savings program',
      fields: {
        Name: {
          label: 'Name',
          errors: {
            required: 'Enter the name.',
          },
        },
        LoyaltyProgramTypeId: {
          label: 'Type',
          errors: {
            required: 'Select a loyaltyprogram type.',
          },
        },
        CurrencyId: {
          label: 'Currency',
          errors: {
            required: 'Selecte a currency.',
          },
        },
        CouponId: {
          label: 'Voucher',
          errors: {
            required: 'Select a voucher.',
          },
        },
        ArticleCode: {
          label: 'Artikel code',
          errors: {
            required: 'Enter an article code.',
          },
        },
        ArticleGroup: {
          label: 'Artikel group',
          errors: {
            required: 'Enter an article group.',
          },
        },
        StartDate: {
          label: 'Start date',
          required: 'Enter a start date.',
        },
        EndDate: {
          label: 'Untill',
          errors: {
            greaterThan: 'The date must be greater than the start date.',
          },
        },
      },
    },
    details: {
      confirmDelete: 'Are you sure you want to delete this Savings program?',
      deleteSuccess: 'Savings program deleted.',
      rules: 'Rules',
      ruleRunOn: {
        Article: 'An article ({name} - {articleCode}) is paid.',
        ArticleGroup:
          'An article from article group ({name} - {articleGroup}) is paid.',
        TransactionAmountNegative:
          'When payment is made with this payment method ({currencyName}).',
        RedeemCoupon: "When a '{couponTitle}' voucher is redeemed.",
      },
      runWhen: 'Run when',
    },
  },
  merchant: {
    index: {},
    insert: {
      title: 'New merchant',
      fields: {
        FinanceEmailAddress: {
          label: 'Finance email address',
        },
        Name: {
          label: 'Name',
          errors: {
            required: 'Enter the name.',
          },
        },
        RelationID: {
          label: 'Relation',
          errors: {
            required: 'Select a relation.',
          },
        },
      },
    },
    details: {
      appUsers: {
        addPosModule: 'Add module',
        addUser: 'Add App Login',
        editUser: 'Edit App Login',
        deleteAppUserConfirm:
          'Are you sure you want to delete the app login <b>{0}</b>?',
        deletePosModuleConfirm:
          'Are you sure you want to delete the <b>{moduleName} ({currencyName})</b> module?',
        title: 'App logins',
      },
      managerUsers: {
        addUser: 'Add Manager Login',
        editUser: 'Edit Manager Login',
        deleteConfirm:
          'Are you sure you want to delete the manager login <b>{0}</b>?',
        title: 'Manager logins',
      },
    },
    addConsumerUser: {
      title: 'Add consumer',
      fields: {
        activationCode: {
          label: 'Activation Code',
          required: 'Enter an activation code.',
          minLength: 'An activiation code is always 8 characters long.',
        },
      },
      successMessage: '{activationCode} has been added.',
    },
  },
  merchantPayments: {
    title: 'Merchant payments',
  },
  merchantPaymentDetails: {
    title: 'Transactions',
  },
  merchantRefunds: {
    accountName: 'Account name',
    accountNumber: 'Account number',
    amount: 'Aantal refunds',
    amountText: 'Amount',
    downloaded: 'Gedownload op',
    generateFile: 'Generate file',
    refunds: 'Refunds',
    title: 'Merchant Refunds',
  },
  modules: {
    POS_PAYMENT: 'Payment',
    POS_TOPUP: 'Topup',
    POS_DEDUCT: 'Deduct',
    LOYALTY_REDEEM_COUPON: 'Redeem coupon',
    LOYALTY_PUSH_COUPON: 'Push coupon',
    POS_PRODUCT_REGISTER: 'Producten kassa',
    USER_INFO: 'Userinfo',
    LOYALTY_SAVINGCARDS: 'Savingcards',
    MYB_TRIPS: 'Trips module',
    PSDPP_PAY_BALANCE: 'PSDPP PAY BALANCE',
    PSDPP_BALANCE: 'PSDPP BALANCE',
    USER_ACCESS_CONTROL: 'Access control (subscription)',
    EVENT_USER_ACCESS_CONTROL: 'Access control (event)',
    SAVING_ARTICLE: 'Save via Articles',
    //LOYALTY_EXTERNAL_PAYMENT:'External payment',
  },
  months: {
    januari: 'Januari',
    februari: 'Februari',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
  },
  montlyTransactionTotals: {
    amountTotal: 'Total amount',
    amountValue: 'Amount with value',
    amountNoValue: 'Amount no value',
  },
  noMatch: {
    header: 'Page not found',
    text: "We can not find the page you're looking for.",
  },
  paymentsPerDay: {
    title: 'Payments per day',
  },
  paymentTransactions: {
    title: 'Payments',
  },
  paymentsTokenType: {
    title: 'Payments per token type',
  },
  pos: {
    activate: {
      action: 'Activate',
      confirmMessage: 'Do you want to <b>actvate</b> pos({name})?.',
      success: 'Pos({name}) has been activated.',
    },
    deactivate: {
      action: 'Deactivate',
      confirmMessage: 'Do you want to <b>deactvate</b> pos({name})?.',
      success: 'Pos({name}) has been deactivated.',
    },
    insert: {
      title: 'New pos',
      fields: {
        Name: {
          label: 'Name',
          errors: {
            required: 'Enter the name.',
          },
        },
        PosTypeID: {
          label: 'Type',
          errors: {
            required: 'Select a type.',
          },
        },
      },
    },
  },
  register: {
    header: 'Become a Topr!',
    heading: 'Register',
    headerHow: 'How does it work?',
    textHow:
      'You register through the form to the right. You will receive a confirmationmail after which you can start your first savingcard!',
    submitText: 'I am a Topr!',
    fieldBic: {
      label: 'Bank',
      info: 'EN text',
    },
    fieldContactName: {
      label: 'Contact',
      errors: {
        required: 'Enter the contact.',
      },
    },
    fieldMerchantName: {
      label: 'Company name',
      errors: {
        required: 'Enter the company name.',
      },
    },
    fieldPhonenumber: {
      label: 'Phone number',
      errors: {
        required: 'Enter the phone number.',
      },
    },
    fieldEmailAddress: {
      label: 'Email address',
      errors: {
        required: 'Enter the email address.',
        regex: 'Enter a valid email address.',
      },
    },
    fieldConfirmEmailAddress: {
      label: 'Confirm email address',
      errors: {
        required: 'Enter the confirm email address.',
        regex: 'Enter a valid confirm email address.',
        match: "The confirm email address doesn't match the email adress.",
      },
    },
    fieldZipcode: {
      label: 'Zipcode',
      errors: {
        required: 'Enter the zipcode.',
      },
    },
    fieldHouseNumber: {
      label: 'House number',
      errors: {
        required: 'Enter the house number.',
      },
    },
    fieldCity: {
      label: 'City',
      errors: {
        required: 'Enter the city.',
      },
    },
    fieldStreet: {
      label: 'Street',
      errors: {
        required: 'Enter the street.',
      },
    },
    fieldPassword: {
      label: 'Password',
      placeholder: 'Atleast 6 characters',
      errors: {
        required: 'Enter the password.',
        min: 'Enter atleast 6 characters.',
      },
    },
    fieldConfirmPassword: {
      label: 'Confirm password',
      errors: {
        required: 'Enter the confirm password.',
        min: 'Enter atleast 6 characters.',
        match: "The confirm password doesn't match the password.",
      },
    },
  },
  registerConfirm: {
    header: 'Get to work!',
    headingConfirming: 'Account confirm',
    headingRegisterSuccess: 'Account confirmed',
    loaderText: 'Confirming...',
    succesText:
      'You will be redirected in {0}s to your first saving card ' +
      'or click <a href="{1}">here</a>!',
  },
  resetPassword: {
    fields: {
      password: {
        label: 'Password',
        placeholder: 'Atleast 6 characters',
        errors: {
          required: 'Enter the password.',
          min: 'Enter atleast 6 characters.',
        },
      },
      passwordConfirm: {
        label: 'Confirm password',
        errors: {
          required: 'Enter the confirm password.',
          min: 'Enter atleast 6 characters.',
          match: "The confirm password doesn't match the password.",
        },
      },
    },
    header: 'Reset password',
    info: 'Enter a new password. The password must be atleast 6 characters long.',
    serverErrors: {
      REQUESTIDENTIFIER_NOT_FOUND:
        "This resetlink is not active anymore. <a href='/forgotpassword'>Send new reset link</a>.",
    },
    submitText: 'Reset password',
    success: 'Your password has been changed.',
  },
  sam: {
    block: {
      title: 'Block',
      success: 'Sam ({SamId}) has been blocked.',
    },

    insert: {
      title: 'New VSAM',
      fields: {
        SamTypeID: {
          label: 'Type',
          errors: {
            required: 'Select a type.',
          },
        },
      },
    },
    link: {
      title: 'Link sam',
      samId: {
        label: 'SamID',
        errors: {
          required: 'Enter a SamID.',
          alreadyLinked:
            'This sam is already linked to <a href="/terminal/details/{terminalId}">this terminal</a>. ',
        },
      },
    },
    unblock: {
      title: 'Unblock',
      success: 'Sam ({SamId}) has been unblocked.',
    },
    unlink: {
      action: 'Unlink',
      confirmMessage: 'Do you want to <b>unlink</b> sam({SamId})?.',
      success: 'Sam({SamId}) has been unlinked.',
    },
  },
  savingcard: {
    averageStamps: {
      text: 'The average stamps issued per saving card <strong>{0}</strong>.',
      title: 'Average stamps',
    },
    totalCards: {
      text: '<strong>{0}</strong> Saving cards issued.',
      title: 'Saving cards issued',
    },
    totalCardsProcessed: {
      text: '<strong>{0}</strong> Savingcards completed ',
      title: 'Savingcard completed',
    },
    confirmDelete:
      'Are you sure you want delete this saving card?' +
      'WARNING: Also for your users this saving card will be removed.',
  },
  savingcardDetails: {
    couponsIssued: 'Total Vouchers issued',
    couponsRedeemed: 'Vouchers redeemd',
    couponsNotRedeemed: 'Vouchers <i>not</i> redeemd',
    percentText: '{0}% of the savingscards have {1} stamp(s)',
    percentTitle: 'Percentage per stamp',
    savingcardsAverage: 'Stamps are issued per savings card on average',
    savingcardsCompleted: 'Savings card completed',
    savingcardsIssued: 'Savings card issued',
  },
  savingCardCreated: {
    title: 'Savings card created',
    contentIntro: 'The savings card <i>{0}</i> has been created.',
  },
  savingcardInsert: {
    title: 'New savings card',
    fields: {
      AutoRefresh: {
        label: 'Auto refresh',
        info: 'When checked, the customer automatically gets a new savings card when he complets his savings card',
      },
      CouponDescription: {
        label: 'Description',
        info:
          'The description/explanation for the voucher. <ul>' +
          '<li>Wat the customer will receive when he redeems this voucher.</li>' +
          '<li>The products or services this voucher applies to.</li>' +
          '<li>At which venues a customer can redeem this voucher.</li>' +
          '</ul>' +
          'Eg: <i>When you redeem this voucher you will receive a nice cup of koffie.</i>',

        errors: {
          required: 'Enter the description ({0}).',
        },
      },
      Description: {
        label: 'Description',
        info:
          'The description/explanation of the savings card. <ul>' +
          '<li>How to earn a stamp.</li>' +
          '<li>What reward is received when the savings card had been completed.</li>' +
          '</ul>',
        errors: {
          required: 'Enter a description ({0}).',
        },
      },
      DiscountAmount: {
        Amount: {
          label: 'Amount discount',
          placeholder: '1,50',
          info: 'The amount discount of the voucher.<br/>Eg: <i>€ 1,50</i>',
          errors: {
            required: 'Enter the amount.',
          },
        },
        Percentage: {
          label: 'Percentage discount',
          placeholder: '33',
          info: 'The percentage discount of the voucher.<br/>Eg: <i>33%</i>',
          errors: {
            required: 'Enter the percentage in.',
          },
        },
      },
      ImageFile: {
        label: 'Image',
        info: 'The image for the savings card',
        errors: {
          required: 'Add an image.',
        },
      },
      Reward: {
        label: 'Reward mailtext',
        info:
          'The text for the notification e-mail the customer receives when his savings card is completed. <ul>' +
          '</ul>',

        errors: {
          required: 'Enter the reward mailtext ({0}.',
        },
      },
      RewardAmount: {
        label: 'Amount',
        placeholder: '1,50',
        info: 'The mount of the reward.<br/>e.g.: <i>€ 1,50</i>',
        errors: {
          required: 'Enter the mount of the reward.',
        },
      },
      RewardType: {
        label: 'Beloning type',
      },
      Title: {
        label: 'Title',
        info: 'The title of the savings card. E.g. "Free cup of coffe"',
        errors: {
          required: 'Enter the title ({0}).',
        },
      },
      StampCount: {
        label: 'Stamps count',
        info: 'The stamps count of the savings card.',
        errors: {
          required: 'Enter the stamps count.',
        },
      },
      ValidFrom: {
        label: 'Starting date',
        info: 'Starting date of the savings card. If no starting date is entered, the savings card is active immediately.',
      },
      ValidTill: {
        label: 'End date',
        info: 'End date of the savings card. If no end date is entered, the savings card remains active.',
        errors: {
          fromGreater: 'The end date must be greater than the starting date.',
        },
      },
      VoucherType: {
        label: 'Voucher type',
        info:
          'Typeof the voucher the customer receives when completing the savings card<ul>' +
          '<li><b>Free product</b>, The customer receives a voucher for a free product.</li>' +
          '<li><b>Procent korting</b>, The customer receives a voucher for a percentage discount on one or more products.</li>' +
          '<li><b>Bedrag korting</b>, The customer receives a voucher for a amount discount on one or more products.</li>' +
          '</ul>',
      },
    },
    VoucherTypes: {
      FreeProduct: 'Free product',
      AmountDiscount: 'Amount discount',
      PercentageDiscount: 'Procent discount',
    },
    untill: 'Untill',
  },
  savingcardEdit: {
    title: 'Edit savings card',
    saveSuccess: 'Savings Card has been saved.',
  },
  siedlerChargeGuthaben: {
    title: 'Card Topups',
    actionText: 'Generieren',
  },
  siedlerCompanyCoupons: {
    title: 'Company Coupons',
    actionText: 'Generate',
    customerTitle: 'Daterange title',
  },
  siedlerInvoiceCoupons: {
    title: 'Invoice Coupons',
    actionText: 'Generate',
  },
  siedlerGebuhrCurrency: {
    title: 'Fees Knödels',
    actionText: 'Generate',
  },
  siedlerBearbeitungsGebuhr: {
    title: 'Processing Fees Guthaben',
    actionText: 'Generieren',
  },
  siedlerInvoiceCurrency: {
    title: 'Invoice Currencies',
    actionText: 'Generate',
  },
  siedlerRemove: {
    action: 'Remove from Siedler',
    confirmMessage:
      '{name} will be <b>removed</b> from Siedler.<br/>Are you sure?',
    success: '{name} has been removed from Siedler.',
  },
  stornos: {
    title: "Upload Storno's",
    form: {
      stornoField: {
        label: 'Storno bestand (.csv)',
        dropfile: 'Klik hier om het storno bestand (.csv) te uploaden.',
        error: {
          required: 'Voer het storno bestand in.',
        },
      },
      failed: "Het verwerken van de storno's is MISLUKT",
      success: "Het verwerken van de storno's is  GELUKT",
    },
  },
  stornoStatus: {
    title: "Storno's",
    status: {
      Canceled: 'Gestorneerd',
      CanceledMailMerged: 'Brief verstuurt',
      ReadyForBlock: 'Gereed voor blokkeren',
      Blocked: 'Geblokkeerd',
      Collected: 'Betaald',
    },
  },
  subscription: {
    index: {
      title: 'Subscriptions',
      confirmDelete:
        'Are you sure you want to delete item <b>{name}</b> from <i>{subscriptionName}</i>?',
    },
    insert: {
      title: 'Add subscription',
      fields: {
        name: {
          label: 'Name',
          required: 'Enter a name.',
        },
        systemName: {
          label: 'System name',
          required: 'Enter a system name.',
        },
      },
    },
    edit: {
      title: 'Edit subscription',
    },
    editItem: {
      title: 'Edit Item',
    },
    insertItem: {
      title: 'Add Item',
      fields: {
        amount: {
          label: 'Amount',
          labelEnum: {
            Day: 'Number of days',
            Week: 'Number of weeks',
            Month: 'number of months',
            Year: 'Number of years',
            Trips: 'Number of trips',
            Unlimited: 'Unlimited',
          },
          required: 'Enter an amount',
        },
        name: {
          label: 'Name',
          required: 'Enter a name',
        },
        description: {
          label: 'Description',
          required: 'Enter a description',
        },
      },
    },
    insertUserSubscription: {
      //
      title: 'Subscription toevoegen',
      fields: {
        ItemType: {
          label: 'Subscription',
          errors: {
            required: 'Select a subscription.',
          },
        },
        StartDate: {
          label: 'Start date',
          required: 'Enter a start date.',
        },
        EndDate: {
          label: 'Untill',
          errors: {
            greaterThan: 'The date must be greater than the start date.',
          },
        },
      },
    },
    editUserSubscription: {
      title: 'Edit Subscription',
    },
    expiredMessage: {
      header: 'Your subscription has expired.',
      text: 'Go to the <a href="/subscriptions">subscription page</a> to renew your subscription',
    },
  },
  tags: {
    index: {
      title: 'Tags',
    },
    insert: {
      title: 'Add tag',
      fields: {
        name: { title: 'Tag name', required: 'Enter a tag name.' },
      },
    },
    insertUserTag: {
      fields: {
        tag: { title: 'Tag', required: 'Select a tag.' },
      },
    },
    deleteUserTagConfirm:
      'Are you sure you want to remove the tag <b>{tagName}</b>?',
    edit: {
      title: 'Edit tag',
    },
  },
  terminal: {
    insert: {
      title: 'New terminal',
      fields: {
        Description: {
          label: 'Description',
          errors: {
            required: 'Enter a description.',
          },
        },
        TerminalTypeID: {
          label: 'Type',
          errors: {
            required: 'Select a type.',
          },
        },
      },
    },
  },
  topMenu: {
    changeMerchant: {
      label: 'Change merchant',
      required: 'Select a merchant',
    },
    consumers: {
      helpdesk: 'Find consumer',
      tags: 'Tags',
    },
    finance: {
      title: 'Finance',
      merchantPayments: 'Merchant payments',
      montlyTransactionTotals: 'Monthly payments',
    },
    loyalty: {
      title: 'Loyalty',
      savingCards: 'Saving Cards',
      vouchers: 'Vouchers',
    },
    reports: {
      title: 'Reports',
      common: {
        groupName: 'Common',
        payments: 'Payments',
      },
      payments: {
        groupName: 'Payments',
        paymentsPeriod: 'Payments in period',
        paymentsDay: 'Payments per dag',
      },
      wallets: {
        groupName: 'Wallets',
      },
      managerReports: {
        Mybility: {
          MybTrips: 'Trips',
        },
        Pdx: {
          PdxCardPaymentTotals: 'Payments per card',
        },
        Siedler: {
          SiedlerInvoiceCoupons: 'Invoice Werks Coupons',
          SiedlerGebuhrKnodels: 'Fees Knödels',
          SiedlerinvoiceCurrency: 'Invoice Currencies',
          SiedlerBearbeitungsGebuhr: 'Processing fees Guthaben',
          SiedlerCouponsUnternehmen: 'Coupons per Company',
          SiedlerChargeGuthaben: 'Card Topups',
        },
      },
    },
  },
  tokens: {
    activationCode: 'Activation code',
    blockToken: {
      action: 'Block',
      confirmMessage: 'The token will be <b>blocked</b>.<br/>Are you sure?',
      success: 'The token has been blocked.',
    },
    dateActivated: 'Activated',
    unblockToken: {
      action: 'Unblock',
      confirmMessage: 'The token will be <b>unblocked</b>.<br/>Are you sure?',
      success: 'The token has been unblocked.',
    },
    validThru: 'Valid thru',
  },
  tokenStatus: {
    activated: 'Active',
    notActivated: 'Not active',
    blocked: 'Blocked',
    expired: 'Expired',
  },
  transaction: {
    title: 'Zoek transactie',
  },
  trips: {
    bookedTrips: 'Booked trips',
    checkedInTrips: 'Checked in trips',
    completedTrips: 'Completed trips',
    merchants: 'Centrals',
    title: 'Trips',
    name: {
      label: 'Name',
      placeholder: 'Search name',
    },
  },
  userBalances: {
    title: 'User balances',
  },
  userDetails: {
    blockedStornos: "Blocked storno's",
    childUserConnections: 'Child gebruikers',
    parentUserConnections: 'Parent gebruiker',
    resetPassword: {
      action: 'Reset password',
      confirmResetPassword:
        'The password will be reset to <b>welkom123</b>.<br/>Are you sure?',
      success: 'The password has been updated',
    },
    saveUserFieldSuccess: '{fieldName} saved',
  },
  userScheduleBonus: {
    edit: {
      title: 'UserScheduleBonus edit',
    },
    insert: {
      title: 'Plan UserScheduleBonus',
      fields: {
        Type: {
          label: 'Type',
          errors: {
            required: 'Select type.',
          },
        },
        ReplenishCurrency: {
          label: 'Replenish amount untill?',
          tooltip:
            'When this is selected the balance for this user will be supplemented to the amount.',
        },
        ReplenishCoupon: {
          label: 'Replenish amount untill?',
          tooltip:
            'When this is selected the coupons for this user will be supplemented to the amount.',
        },
        ScheduleType: {
          label: 'Interval',
          enum: {
            Daily: 'Daily',
            Weekly: 'Weekly',
            Monthly: 'Monthly',
          },
        },
        ScheduleDay: {
          label: 'Day',
        },
        StartDate: {
          label: 'Start date',
          required: 'Enter a start date.',
        },
        EndDate: {
          label: 'Untill',
          errors: {
            greaterThan: 'The date must be greater than the start date.',
          },
        },
      },
    },
    status: {
      Active: 'Active',
      NotYetActive: 'Not yet active',
      Expired: 'Expired',
    },
  },
  walletBulkChange: {
    title: 'Update Balances (bulk)',
    tabGenerateFile: {
      title: '1. Generate template file',
      info:
        '<ol>' +
        '<li>Select a currency</li>' +
        '<li>Click Genererate template file</li>' +
        '</ol>',
      action: 'Genererate template file',
    },
    tabUploadChanges: {
      title: '2. Upload balance changes',
      info:
        'You can use the generated template file to adjust the balance ' +
        'for multiple customers.<br />' +
        'For each row in the import file you can update his/her balance.  <br /><br />' +
        "You can enter the amount via the column '<i>Amount</i>' in cents (i.e. enter 750 for an amount f 7.50).<br/>" +
        "Via the column '<i>Modifier</i>' you can make these changes:" +
        "<ul><li><b>+</b>, top up the customer's balance with the amount in the column '<i>Amount</i>'.</li>" +
        "<li><b>-</b>, top off the customer's balance with the " +
        "amount in the column '<i>Amount</i>'.</li>" +
        "<li><b>=</b>, top up the customer's balance up to and " +
        "including the amount in the column '<i>Amount</i>'.</li>" +
        "<li><b>0</b>, reset the customer's balance to 0.</li>" +
        '</ul>',
      codeExplained:
        '<li>The <b>{{currencyName}}</b> balance for Jan will be charged with <i>7,40</i></li>',
    },
  },
  walletCharge: {
    title: 'Topup',
    fields: {
      Currency: {
        label: 'Currency',
      },
      Description: { label: 'Description' },
    },
    successAlert: {
      message:
        '{amountFormatted} {currencyName} has been topped up for {count} customers.',
    },
    failedAlert: {
      message: 'Topup failed for {count} customers. ',
    },
  },
  walletWithdraw: {
    title: 'Withdraw',
    fields: {
      Description: { label: 'Description' },
    },
    successAlert: {
      message:
        '{amountFormatted} {currencyName} has been withdrawn for {count} customers.',
    },
    failedAlert: {
      message: 'Withdraw failed for {count} customers. ',
    },
  },
}
