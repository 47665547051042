import { AxiosError, AxiosResponse } from 'axios'
import { handleException } from 'src/Helpers/ResponseHelpers'
import { INameGuidVTO } from 'src/Models/Server/INameGuidVTO'
import INameIdVTO from 'src/Models/Server/INameIdVTO'
import IPageReportVTO from 'src/Models/Server/Report/IPagedReport'
import InsertMerchantRequest from 'src/Models/Server/Request/Merchant/IInsertMerchantRequest'
import { IExceptionError } from 'src/Models/Server/Response/Exception/ExceptionError'
import { IMerchantFileSTO } from 'src/Models/Server/Response/Merchant/IMerchantFile'
import IMerchantVTO from 'src/Models/Server/Response/Merchant/IMerchantVTO'
import { axiosAuth } from '../AuthAxios'

export default {
  GetActiveMerchants() {
    return new Promise<INameGuidVTO[]>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get('/api/v1/merchants/active')
          .then((response: AxiosResponse<INameGuidVTO[]>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  GetMerchantFiles(merchantId: number, page: number, pageSize: number) {
    return new Promise<IPageReportVTO<IMerchantFileSTO>>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/merchant/files/${merchantId}/${page}/${pageSize}`)
          .then((response: AxiosResponse<IPageReportVTO<IMerchantFileSTO>>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },

  GetMyMerchants() {
    return new Promise<INameGuidVTO[]>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get('/api/v1/merchants/my')
          .then((response: AxiosResponse<INameGuidVTO[]>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  GetMerchant(guid: string) {
    return new Promise<IMerchantVTO>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/merchant/details/${guid}`)
          .then((response: AxiosResponse<IMerchantVTO>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  GetRelations() {
    return new Promise<Array<INameIdVTO<number>>>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/merchant/relations`)
          .then((response: AxiosResponse<Array<INameIdVTO<number>>>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  Edit(request: IMerchantVTO) {
    return new Promise((resolve, reject: (error: IExceptionError) => void) => {
      axiosAuth
        .post(`/api/v1/merchant/edit`, request)
        .then((response: AxiosResponse) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  Insert(request: InsertMerchantRequest) {
    return new Promise<string>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/merchant/insert`, request)
          .then((response: AxiosResponse<string>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  AddConsumerUser(activationCode: string) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/merchant/user/add/${activationCode}`, null)
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
}
