import { AxiosError, AxiosResponse } from 'axios'
import { handleException } from 'src/Helpers/ResponseHelpers'
import { IExceptionError } from 'src/Models/Server/Response/Exception/ExceptionError'
import { IRefundVTO } from 'src/Models/Server/Response/Refund/IRefundVTO'
import { axiosAuth } from '../AuthAxios'

export default {
  GetUserRefunds(userGuid: string) {
    return new Promise<IRefundVTO[]>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/refunds/user/${userGuid}`)
          .then((response: AxiosResponse<IRefundVTO[]>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
}
