import React, { Fragment, useEffect, useState } from 'react'

import { Link, Outlet, Route, Routes, useLocation } from 'react-router-dom'

interface IProps {
  tabs: IMyTab[]
  name?: string
}

export interface IMyTab {
  key: string
  title: string
  content: React.ReactNode
  index?: boolean
}

const MyPageTabs: React.FC<IProps> = ({ tabs, name }) => {
  const [activeTabKey, setActiveTabKey] = useState(tabs[0].key)
  const location = useLocation()
  useEffect(() => {
    const paths = location.pathname.split('/')
    if (paths.length > 0) {
      const lastPart = paths[paths.length - 1]
      if (tabs.findIndex((x) => x.key === lastPart) > -1) {
        setActiveTabKey(lastPart)
      }
    }
  }, [null])

  const renderTabs = () => {
    return (
      <ul className="nav nav-tabs">
        {tabs.map((tab, key) => {
          const className = activeTabKey === tab.key ? 'active' : ''
          return (
            <li className={className} key={key}>
              <Link
                to={tab.key}
                onClick={() => {
                  setActiveTabKey(tab.key)
                }}
              >
                {tab.title}
              </Link>
            </li>
          )
        })}
      </ul>
    )
  }

  const renderRoutes = () => {
    return (
      <Routes>
        {tabs.map((tab, key) => {
          if (tab.index === true) {
            //both index and a tab.route
            return (
              <Fragment key={key}>
                <Route index={tab.index} element={tab.content} />
                <Route path={tab.key} element={tab.content} />
              </Fragment>
            )
          }

          return <Route key={key} path={tab.key} element={tab.content} />
        })}
      </Routes>
    )
  }

  return (
    <>
      {renderTabs()}
      {renderRoutes()}
      <Outlet />
    </>
  )
}

export default MyPageTabs
