import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { MainPage } from 'src/Components/My'
import { t } from 'src/I18n'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import UserSubscriptionForm from '../Components/UserSubscriptionForm/UserSubscriptionForm'
import { useParams } from 'react-router-dom'
import { useGetUserSubscriptionQuery } from 'src/Services/ToprCoreApi/RTK/Slices/SubscriptionSlice'
import { LoaderIcon } from 'src/Components'
interface IProps {}

const EditUserSubscription: React.FC<IProps> = (props) => {
  const params = useParams<
    'userGuid' | 'userSubscriptionId' | 'consumerSubscriptionId'
  >()

  const { data: userSubscription, isLoading } = useGetUserSubscriptionQuery(
    params.userSubscriptionId
  )

  const renderForm = () => {
    if (isLoading) {
      return <LoaderIcon IsLoading={isLoading} />
    }
    return (
      <UserSubscriptionForm
        consumerSubscriptionId={params.consumerSubscriptionId}
        userGuid={params.userGuid}
        title={t.subscription.editUserSubscription.title}
        userSubscription={userSubscription}
      />
    )
  }

  return (
    <MainPage>
      <BreadCrumbs
        currentPageTitle={t.subscription.insertUserSubscription.title}
        previousPages={[
          {
            to: `/user/details/${params.userGuid}/subscriptions`,
            title: t.common.user,
          },
        ]}
      />
      {renderForm()}
    </MainPage>
  )
}

export default observer(EditUserSubscription)
