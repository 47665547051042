import React from 'react'
interface ICircleStatProps {
  stat: number | string
  icon?: string
  iconText?: string
  additionalClassName?: string
}

const CircleStat: React.FC<ICircleStatProps> = ({
  stat,
  icon,
  iconText,
  additionalClassName,
}) => {
  const className =
    additionalClassName !== undefined
      ? `circle-stat ${additionalClassName}`
      : 'circle-stat'

  return (
    <div className={className}>
      <h4>{stat}</h4>
      {icon !== undefined && <i className={icon} />}
      {iconText !== undefined && <span>{iconText}</span>}
    </div>
  )
}

export default CircleStat
