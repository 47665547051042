import React, { PropsWithChildren, useEffect, useReducer } from 'react'
import { Badge } from 'react-bootstrap'
import { t } from 'src/I18n'
import { initialState, reducer } from './reducer'
interface IMcLabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  infoText?: string
  isRequired?: boolean
}

const MyLabel: React.FC<PropsWithChildren<IMcLabelProps>> = ({
  children,
  htmlFor,
  className,
  infoText,
  isRequired,
}) => {
  useEffect(() => {
    //close outside of info popover click
    const windowClick = () => {
      dispatch({ type: 'closeTooltip' })
    }
    if (infoText) {
      window.addEventListener('click', windowClick)
    }
    return () => {
      if (infoText) {
        window.removeEventListener('click', windowClick)
      }
    }
    //}
  }, [null])

  const [state, dispatch] = useReducer(reducer, initialState)
  const { top, left, isVisible } = state
  const renderPopover = () => {
    if (!isVisible) {
      return null
    }

    return (
      <div
        className="popover fade right in"
        style={{
          display: 'block',
          top: -top,
          left,
        }}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <div className="arrow"></div>
        <h3 className="popover-title">{t.common.info}</h3>
        <div
          className="popover-content"
          dangerouslySetInnerHTML={{ __html: infoText }}
        ></div>
      </div>
    )
  }

  const renderInfoText = () => {
    if (infoText === undefined) {
      return null
    }

    return (
      <>
        <i className="far fa-question-circle popover-info"></i>
        {renderPopover()}
      </>
    )
  }

  return (
    <>
      <label
        htmlFor={htmlFor}
        className={className}
        onClick={(e) => {
          if (infoText === undefined) {
            return
          }
          e.stopPropagation()
          const label = e.currentTarget
          const totalWidth = label.offsetWidth
          dispatch({
            type: 'toggleTooltip',
            left: totalWidth,
            top: label.offsetHeight,
            isVisible: !isVisible,
          })
        }}
      >
        {children} {isRequired && <em>*</em>}
        {renderInfoText()}
      </label>
    </>
  )
}

export default MyLabel
