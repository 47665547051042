import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { persistedStore } from 'src/Stores/PersistedStore'
import { rootStore } from 'src/Stores/Redux/RootStore'

const Test: React.FC = () => {
  useEffect(() => {
    //DashboardController.GetDashboard()
  })
  return (
    <>
      <Provider store={rootStore}>
        <h1>Test</h1>
        <button
          onClick={() => {
            persistedStore.Logoff()
          }}
        >
          log out
        </button>
      </Provider>
    </>
  )
}

export default Test

/*
const Test: React.FC = () => (
  <>
    
    <ul className="nav nav-tabs">
      <li className="">
        <a href="#info" data-toggle="tab" aria-expanded="false">
          Info
        </a>
      </li>
      <li className="active">
        <a href="#profile" data-toggle="tab" aria-expanded="true">
          Wallets
        </a>
      </li>
    </ul>
    <div>nested router</div>
    <ul className="nav nav-tabs my-tabs">
      <li className="">Info</li>
      <li className="">Wallets</li>
      <li className="">Loyalty</li>
      <li className="active">Finance</li>
      <li className="">Planning</li>
    </ul>
  </>
)

export default observer(Test)
*/
