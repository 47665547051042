import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { ButtonToolbar } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { McPageAlert } from 'src/Components'
import McButton from 'src/Components/McButton'
import McModal from 'src/Components/Modals/McModal'
import { MySelect, MyTextbox } from 'src/Components/My'
import { t } from 'src/I18n'
import IMerchantVTO from 'src/Models/Server/Response/Merchant/IMerchantVTO'
import { IFormValues, merchantEditStore } from './Store'

interface IProps {
  onClose: () => void
  //onSubmitSuccess: (editMerchant: IMerchantVTO) => void
  editMerchant: IMerchantVTO
}

const EditMerchantModal: React.FC<IProps> = ({
  editMerchant,
  //onSubmitSuccess,
  onClose,
}) => {
  useEffect(() => {
    merchantEditStore.Initialize(editMerchant)
  }, [null])

  const { ViewModel, IsSubmitting, Relations, PageAlert, IsLoading } =
    merchantEditStore

  useEffect(() => {
    //set edit values
    reset(ViewModel)
  }, [ViewModel])

  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({})
  const { handleSubmit, reset } = useFormProps

  if (ViewModel === undefined || IsLoading) {
    return null
  }

  const onSubmit = (data: IFormValues) => {
    merchantEditStore.EditMerchant(data)
  }

  return (
    <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
      <McModal
        title={t.common.edit}
        footer={
          <div>
            <McPageAlert pageAlert={PageAlert} />
            <ButtonToolbar className="pull-right">
              <McButton onClick={() => onClose()}>{t.common.cancel}</McButton>
              <McButton
                bsStyle="primary"
                type="submit"
                isLoading={IsSubmitting}
              >
                {t.common.save}
              </McButton>
            </ButtonToolbar>
          </div>
        }
        closeClicked={() => onClose()}
      >
        <MyTextbox
          formObject={{} as IFormValues}
          label={t.merchant.insert.fields.Name.label}
          name="Name"
          useFormProps={useFormProps}
          rules={{
            required: t.merchant.insert.fields.Name.errors.required,
          }}
          placeholder={t.merchant.insert.fields.Name.label}
          tabIndex={1}
        />
        <MyTextbox
          formObject={{} as IFormValues}
          label={t.merchant.insert.fields.FinanceEmailAddress.label}
          placeholder={t.merchant.insert.fields.FinanceEmailAddress.label}
          name="FinanceEmailAddress"
          useFormProps={useFormProps}
        />
        <MySelect
          formObject={{} as IFormValues}
          useFormProps={useFormProps}
          label={t.merchant.insert.fields.RelationID.label}
          name="SelectedRelation"
          rules={{
            required: t.merchant.insert.fields.RelationID.errors.required,
          }}
          options={Relations}
        />
      </McModal>
    </form>
  )
}

export default observer(EditMerchantModal)
