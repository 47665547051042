import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { McButton } from 'src/Components'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import { MainPage, MyMonthYearPicker, MySelect } from 'src/Components/My'
import { t } from 'src/I18n'
import { bearbeitungsGebuhrStore, IFormValues } from './Store'

interface IProps {}

const BearbeitungsGebuhr: React.FC<IProps> = () => {
  useEffect(() => {
    bearbeitungsGebuhrStore.Initialize()
  }, [null])

  const { ViewModel, IsSubmitting, MerchantOptions } = bearbeitungsGebuhrStore

  useEffect(() => {
    reset(ViewModel)
  }, [ViewModel])

  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({})
  const { handleSubmit, reset } = useFormProps

  const onSubmit = (data: IFormValues) => {
    bearbeitungsGebuhrStore.GetSiedlerGebuhrCurrency(data)
  }

  const renderForm = () => {
    return (
      <>
        <Portlet>
          <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={6}>
                <MyMonthYearPicker
                  useFormProps={useFormProps}
                  name="SelectedMonthYear"
                />
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={6}>
                    <MySelect
                      formObject={ViewModel}
                      useFormProps={useFormProps}
                      label={t.common.merchant}
                      name="SelectedMerchant"
                      options={MerchantOptions}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <McButton
                  block
                  bsStyle="primary"
                  bsSize="large"
                  type="submit"
                  isLoading={IsSubmitting}
                >
                  {t.siedlerBearbeitungsGebuhr.actionText}
                </McButton>
              </Col>
            </Row>
          </form>
        </Portlet>
      </>
    )
  }

  return (
    <>
      <MainPage>
        <BreadCrumbs currentPageTitle={t.siedlerBearbeitungsGebuhr.title} />
        {renderForm()}
      </MainPage>
    </>
  )
}

export default observer(BearbeitungsGebuhr)
