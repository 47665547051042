import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { FormGroup } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { McButton, McPageAlert } from 'src/Components'
import { MainPage, MyTextbox } from 'src/Components/My'
import { t } from 'src/I18n'
import { MyRegExps } from 'src/Models/Client'

import { forgotPasswordStore, IFormValues } from './Store'

const ForgotPassword: React.FC = () => {
  useEffect(() => {
    forgotPasswordStore.BaseInitialize()
  }, [null])

  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({
    defaultValues: {
      EmailAddress: '',
    },
  })
  const { handleSubmit } = useFormProps

  const onSubmit = (data: IFormValues) => {
    forgotPasswordStore.Submit(data.EmailAddress)
  }

  const { IsSubmitting, PageAlert } = forgotPasswordStore

  return (
    <MainPage>
      <br className="xs-80" />
      <McPageAlert pageAlert={PageAlert} />
      <div className="account-wrapper">
        <div className="account-body">
          <h3>{t.forgotPassword.header}</h3>
          {t.forgotPassword.info}
          <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
            <MyTextbox
              formObject={{} as IFormValues}
              label={t.forgotPassword.fieldEmailAddress.label}
              name="EmailAddress"
              labelClassName="placeholder-hidden"
              useFormProps={useFormProps}
              rules={{
                required: t.forgotPassword.fieldEmailAddress.errors.required,
                pattern: {
                  value: MyRegExps.EmailAdress,
                  message: t.forgotPassword.fieldEmailAddress.errors.regex,
                },
              }}
              placeholder={t.forgotPassword.fieldEmailAddress.label}
              tabIndex={1}
            />

            <FormGroup>
              <McButton
                block
                bsStyle="primary"
                bsSize="large"
                type="submit"
                isLoading={IsSubmitting}
                tabIndex={3}
              >
                {t.forgotPassword.submitText}
              </McButton>
            </FormGroup>
            <FormGroup>
              <Link to="/">
                <i className="fa fa-angle-double-left" />
                &nbsp;
                {t.forgotPassword.login}
              </Link>
            </FormGroup>
          </form>
        </div>
      </div>
    </MainPage>
  )
}

export default observer(ForgotPassword)
