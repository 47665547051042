import { createApi } from '@reduxjs/toolkit/query/react'
import { baseAuthQuery } from './BaseQuery'

export const toprCoreApi = createApi({
  reducerPath: 'api',
  baseQuery: async (args, api, extraOptions) => {
    return await baseAuthQuery(args, api, extraOptions)
  },
  endpoints: () => ({}),
  // endpoints: (builder) => ({
  //   //<ResultType, QueryArg>
  //   getEditConsumerSubscriptionItem: builder.query<
  //     IEditConsumerSubscriptionItemVTO,
  //     string
  //   >({
  //     // The URL for the request is '/fakeApi/posts'
  //     query: (consumerSubscriptionItemId) =>
  //       `/api/v1/subscription/item/${consumerSubscriptionItemId}/edit`,
  //   }),
  // }),
})
