export enum AdditionalModules {
  HelpDesk = 310,
  RtoStornoStatus = 313,
  Test = 500
}

export const AdditionalModulesValues = Object.keys(AdditionalModules)
  .filter((k) => {
    //console.log(k, typeof AdditionalModules[k])
    return typeof AdditionalModules[k] === 'string'
  })
  .map((x) => parseInt(x, 10))
