import { t } from 'src/I18n'
import {
  ConsumerSubscriptionItemType,
  LoyaltyDateRangeStatus,
} from 'src/Models/Server/Enums'
import { IConsumerSubscriptionItemSto } from 'src/Models/Server/Response/ConsumerSubscription/IConsumerSubscriptionItemSto'
import { ILocalizedStringSto } from 'src/Models/Server/Response/LocalizedString/ILocalizedStringSto'
import { ILocalizedStringVTO } from 'src/Models/Server/Response/LocalizedString/ILocalizedStringVTO'
import { persistedStore } from 'src/Stores/PersistedStore'

export const enumToText = (number: number, myEnum: any, translations) => {
  //
  const key = myEnum[number]
  return translations[key]
}

export const GetLoyaltyDateRangeStatus = (
  start: string,
  end: string | null
) => {
  const now = new Date()
  const startDate = new Date(start)
  const endDate = new Date(end)
  endDate.setHours(23, 59, 59)
  const isActive = now > startDate && (end === null || now < endDate)

  if (isActive) {
    return LoyaltyDateRangeStatus.Active
  }

  if (now < startDate) {
    return LoyaltyDateRangeStatus.NotYetActive
  }

  if (end !== null && now > endDate) {
    return LoyaltyDateRangeStatus.Expired
  }

  return undefined
}

export const GetLocalText = (
  localizedString: ILocalizedStringSto | ILocalizedStringVTO
) => {
  const lang = persistedStore.Language.toUpperCase()
  const lsv = localizedString.Values.find((x) => x.Language === lang)
  if (lsv === undefined) {
    return `lsv ${lang} not found`
  }
  return lsv.Value
}

export const getItemTypeText = (csi: IConsumerSubscriptionItemSto) => {
  const typeAmountText = enumToText(
    csi.ConsumerSubscriptionItemTypeId,
    ConsumerSubscriptionItemType,
    t.subscription.insertItem.fields.amount.labelEnum
  )

  const subText =
    csi.ConsumerSubscriptionItemTypeId !==
    ConsumerSubscriptionItemType.Unlimited
      ? `${typeAmountText} ${csi.Amount}`
      : typeAmountText

  return subText
}
