import { action, makeObservable, observable, runInAction } from 'mobx'
import { NavigateFunction } from 'react-router-dom'
import { ISelectOption } from 'src/Models/Client'
import IGenericError from 'src/Models/Server/IGenericError'
import { ToSelectOptions } from 'src/Models/Server/INameIdVTO'
import { IInsertPosRequest } from 'src/Models/Server/Request/Pos/IInsertPosRequest'
import IMerchantVTO from 'src/Models/Server/Response/Merchant/IMerchantVTO'
import MerchantController from 'src/Services/ToprCoreApi/Axios/Controllers/MerchantController'
import PosController from 'src/Services/ToprCoreApi/Axios/Controllers/PosController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export interface IFormValues extends IInsertPosRequest {
  SelectedPosType: ISelectOption
}

export default class Store extends BaseStore<IFormValues> {
  public Merchant: IMerchantVTO = undefined
  public PosTypes: ISelectOption[] = []

  constructor() {
    super()

    makeObservable(this, {
      Merchant: observable,
      PosTypes: observable,
      Initialize: action,
      Insert: action,
    })
  }

  public Initialize(
    locationGuid: string,
    merchantGuid: string,
    locationName: string
  ) {
    this.BaseInitialize({
      LocationGuid: locationGuid,
      MerchantGuid: merchantGuid,
      Name: locationName,
      PosTypeID: '-1',
      SelectedPosType: null,
    })

    this.IsLoading = true

    Promise.all([
      MerchantController.GetMerchant(merchantGuid),
      PosController.GetPosTypes(),
    ])
      .then((values) => {
        runInAction(() => {
          this.Merchant = values[0]
          this.PosTypes = ToSelectOptions(values[1])
          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsLoading = false))
        appUiStore.ShowError(error)
      })
  }

  public Insert(
    posTypeId: string,
    locationGuid: string,
    merchantGuid: string,
    name: string,
    navigate: NavigateFunction
  ) {
    this.IsSubmitting = true
    const data: IInsertPosRequest = {
      PosTypeID: posTypeId,
      LocationGuid: locationGuid,
      MerchantGuid: merchantGuid,
      Name: name,
    }
    PosController.Insert(data)
      .then((PosGuid) => {
        runInAction(() => (this.IsSubmitting = false))
        navigate(`/pos/details/${PosGuid}`)
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsSubmitting = false))
        appUiStore.ShowError(error)
      })
  }
}

export const insertPosStore = new Store()
