import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { FormGroup } from 'react-bootstrap'
import {
  Path,
  RegisterOptions,
  useController,
  UseFormReturn,
} from 'react-hook-form'
import Slim from 'src/Components/Slim'
import { t } from 'src/I18n'
//import { observer } from 'mobx-react'
import FileDTO from 'src/Models/Server/File/FileDTO'
import MyLabel from './MyLabel/MyLabel'

interface IProps<T> {
  //name: string
  name: Path<T>
  formObject: T
  useFormProps: UseFormReturn<T>
  rules?: RegisterOptions
  //slim
  label: string
  labelClassName?: string
  infoText?: string
  sizeSlim?: { width: number; height: number }
}

const MySlim = <ObjectType,>({
  name,
  useFormProps,
  rules,
  label,
  labelClassName,
  infoText,
  sizeSlim,
}: PropsWithChildren<IProps<ObjectType>>) => {
  const {
    control,
    formState: { errors },
  } = useFormProps

  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
    rules,
  })

  const ratio = `${sizeSlim.width}:${sizeSlim.height}`

  // const [fileId, setFiledId] = useState(-1)
  //const [initialBase64, setInitialBase64] = useState<string>(undefined)
  const [fileDto, setFileDto] = useState<FileDTO>(undefined)
  const [isEditorChange, setIsEditorChange] = useState(false)

  useEffect(() => {
    if (value !== null && value !== undefined && !isEditorChange) {
      //set initial image, do not change when editor change the image (crop, remove)
      const file = value as FileDTO
      setFileDto(file)
      //setInitialBase64(`data:image/jpeg;base64,${file.Data}`)
      //    setFileDto(file)
    }
  }, [value])

  const errorMessage =
    errors !== undefined && errors[name.toString()] !== undefined
      ? errors[name.toString()].message
      : undefined
  const required = rules !== undefined && rules.required !== undefined
  const hasError = errorMessage !== undefined

  const renderErrorText = () => {
    if (!hasError) {
      return null
    }

    return (
      <ul className="parsley-error-list filled">
        <li className="parsley-required">{errorMessage}</li>
      </ul>
    )
  }

  const didTransform = (data) => {
    const base64: string = data.output.image.toDataURL()
    const file: FileDTO = {
      Name: data.input.name,
      MimeType: data.input.type,
      Data: base64.split(',')[1],
      //FileId: -1,
      //FileGuid: '',
      //onEdit no fileId or fileGuid
    }

    setIsEditorChange(true)
    onChange(file)
  }

  //didTransform(null)

  return (
    <FormGroup>
      <MyLabel
        htmlFor={name}
        isRequired={required}
        className={labelClassName}
        infoText={infoText}
      >
        {label} ({`${sizeSlim.width}px x ${sizeSlim.height}px`})
      </MyLabel>
      <Slim
        name={name}
        size={sizeSlim}
        ratio={ratio}
        label={t.common.dropImage}
        //initialImage={initialBase64}
        initialFile={fileDto}
        didTransform={(data) => {
          didTransform(data)
        }}
        didRemove={() => {
          onChange(undefined)
        }}
      />
      {renderErrorText()}
    </FormGroup>
  )
}

export default MySlim
