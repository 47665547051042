import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { LoaderIcon } from 'src/Components'
import { userWalletsStore } from './Store'
import TransactionsPortlet from './TransactionsPortlet/TransactionsPortlet'
import WalletsPortlet from './WalletsPortlet/WalletsPortlet'

interface IProps {}

const WalletTab: React.FC<IProps> = (props) => {
  const params = useParams<'id'>()
  useEffect(() => {
    userWalletsStore.Initialize(params.id)
  }, [null])

  const {
    IsLoading,
    Wallets,
    Transactions,
    UserCurrencies,
    MerchantCurrencies,
  } = userWalletsStore

  if (IsLoading) {
    return <LoaderIcon IsLoading />
  }

  return (
    <>
      <WalletsPortlet
        userGuid={params.id}
        wallets={Wallets}
        merchantCurrencies={MerchantCurrencies}
      />
      <TransactionsPortlet
        transactions={Transactions}
        userCurrencies={UserCurrencies}
      />
    </>
  )
}

export default observer(WalletTab)
