import React from 'react'
interface IProps {
  icon?: string
  label: string
  onClick?: () => void
}

const MyListItem: React.FC<IProps> = ({ icon, label, onClick }) => {
  return (
    <li>
      <a onClick={onClick}>
        {icon !== undefined && <i className={`fa ${icon} dropdown-icon`}></i>}
        {label}
      </a>
    </li>
  )
}

export default MyListItem
