import { action, makeObservable, runInAction } from 'mobx'
import { NavigateFunction } from 'react-router-dom'
import { ISelectOption } from 'src/Models/Client'
import IGenericError from 'src/Models/Server/IGenericError'
import { ToSelectOptions } from 'src/Models/Server/INameIdVTO'
import InsertMerchantRequest from 'src/Models/Server/Request/Merchant/IInsertMerchantRequest'
import MerchantController from 'src/Services/ToprCoreApi/Axios/Controllers/MerchantController'
import RelationController from 'src/Services/ToprCoreApi/Axios/Controllers/RelationController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export interface IFormValues extends InsertMerchantRequest {
  SelectedRelation: ISelectOption
}

export default class Store extends BaseStore {
  public Relations: ISelectOption[] = []

  /**
   *
   */
  constructor() {
    super()
    makeObservable(this, {
      Initialize: action,
      Insert: action,
    })
  }

  public Initialize() {
    this.IsLoading = true
    this.BaseInitialize()

    RelationController.GetAll()
      .then((relations) => {
        runInAction(() => {
          this.Relations = ToSelectOptions(relations)
          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        runInAction(() => {
          this.IsLoading = false
        })
      })
  }

  public Insert(data: IFormValues, navigate: NavigateFunction) {
    this.IsSubmitting = true
    data.RelationID = data.SelectedRelation.value
    MerchantController.Insert(data)
      .then((merchantGuid) => {
        runInAction(() => {
          this.IsSubmitting = false
        })
        navigate(`/merchant/details/${merchantGuid}`)
      })
      .catch((error: IGenericError) => {
        runInAction(() => {
          this.IsSubmitting = false
        })
        appUiStore.ShowError(error)
      })
  }
}

export const merchantInsertStore = new Store()
