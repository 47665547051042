import React, { ReactNode } from 'react'
import CardImageHeader, { CardImageHeaderType } from './CardImageHeader'
import CardActions, { CardActionsType } from './CardActions'
import CardFooter, { CardFooterTitleType } from './CardFooterTitle'

//CardFooter
//CardCations
//https://stackoverflow.com/questions/60883388/using-dot-notation-with-functional-component-in-typescript

interface IProps {
  children?: ReactNode | undefined
}
/*
const Card: React.FC<IProps> = ({ children }) => {
  return <div className="my-card">{children}</div>
}

export const MenuItem: React.FC<{ children?: ReactNode }> = ({ children }) => (
  <div className="menu-item">{children}</div>
)
*/

type CardType = React.FC<IProps> & {
  CardImageHeader: CardImageHeaderType
  FooterTitle: CardFooterTitleType
  FooterActions: CardActionsType
}

const Card: CardType = ({ children }) => {
  return <div className="my-card">{children}</div>
}

Card.CardImageHeader = CardImageHeader
Card.FooterTitle = CardFooter
Card.FooterActions = CardActions

export default Card
