import { observer } from 'mobx-react'
import React, { Fragment, useEffect } from 'react'
import { Col, FormGroup, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import LoaderIcon from 'src/Components/LoaderIcon'
import McButton from 'src/Components/McButton'
import { MainPage } from 'src/Components/My'
import { t } from 'src/I18n'
import EditTerminalModal from './EditTerminalModal/EditTerminalModal'
import SamsGrid from './SamsGrid/SamsGrid'
import { terminalDetailsStore } from './Store'

const Details: React.FC = () => {
  const params = useParams<'terminalId'>()
  useEffect(() => {
    terminalDetailsStore.Initialize(params.terminalId)
  }, [null])
  const { ViewModel, IsLoading, ShowEditTerminal } = terminalDetailsStore

  const renderEditLocation = () => {
    if (!ShowEditTerminal) {
      return null
    }

    return (
      <EditTerminalModal
        onClose={() => terminalDetailsStore.ToggleShowEditTerminal(false)}
        onSubmitSuccess={() => {
          //terminalDetailsStore.UpdateViewModel(viewModel)
          terminalDetailsStore.Initialize(params.terminalId)
        }}
        editTerminal={{ ...ViewModel }}
      />
    )
  }

  return (
    <MainPage>
      <LoaderIcon IsLoading={IsLoading} />

      <br className="xs-10" />
      {!IsLoading && ViewModel !== undefined && (
        <Fragment>
          <BreadCrumbs
            currentPageTitle={ViewModel.Description}
            previousPages={[
              {
                to: '/merchants',
                title: t.common.merchants,
              },
              {
                to: `/merchant/details/${ViewModel.MerchantGuid}`,
                title: ViewModel.MerchantName,
              },
              {
                to: `/location/details/${ViewModel.LocationGuid}`,
                title: ViewModel.LocationName,
              },
              {
                to: `/pos/details/${ViewModel.PosGuid}`,
                title: ViewModel.PosName,
              },
            ]}
          />

          <Portlet title={t.common.terminal}>
            <Row className="details">
              <Col md={6}>
                <FormGroup>
                  <label>Id</label>
                  <span>{ViewModel.TerminalId}</span>
                </FormGroup>
                <FormGroup>
                  <label>
                    {t.terminal.insert.fields.Description.label}
                    <McButton
                      iconLeft="fa-edit"
                      bsStyle="primary"
                      bsSize="xsmall"
                      pullRight
                      onClick={() => {
                        terminalDetailsStore.ToggleShowEditTerminal(true)
                      }}
                    >
                      {t.common.edit}
                    </McButton>
                  </label>
                  <span>{ViewModel.Description}</span>
                </FormGroup>
                <FormGroup>
                  <label>{t.terminal.insert.fields.TerminalTypeID.label}</label>
                  <span>{ViewModel.TerminalTypeName}</span>
                </FormGroup>
              </Col>
            </Row>
          </Portlet>
          <Portlet title={t.common.sam}>
            <SamsGrid terminalId={ViewModel.TerminalId.toString()} />
          </Portlet>
        </Fragment>
      )}
      {renderEditLocation()}
    </MainPage>
  )
}

export default observer(Details)
