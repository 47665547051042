import React from 'react'
import {
  MenuItem,
  OverlayTrigger,
  Popover,
  Dropdown,
  Glyphicon,
} from 'react-bootstrap'
import { GetDateText } from 'src/Helpers/DateHelpers'
import { GetImageFileUrl } from 'src/Images'
import { ISavingCardSummaryDTO } from 'src/Models/Server/Response/SavingCard/ISavingCardSummaryDTO'
import { t } from '../../I18n'
import LoyaltyStatusBadge from './LoyaltyStatusBadge'
interface ISavingCardSummaryReport {
  savingcardSummary: ISavingCardSummaryDTO
  onDetailsClick: () => void
  onEditClick: () => void
  setIsActive: (savingCardGuid: string, isActive: boolean) => void
  onDeleteClick: (savingCardGuid: string) => void
}

const SavingCardSummaryReport: React.FC<ISavingCardSummaryReport> = ({
  savingcardSummary,
  onDetailsClick,
  onEditClick,
  setIsActive,
  onDeleteClick,
}) => {
  const renderActivateMenuItem = () => {
    if (savingcardSummary.SavingCard.IsActive) {
      return (
        <MenuItem
          onClick={() =>
            setIsActive(savingcardSummary.SavingCard.SavingCardGuid, false)
          }
        >
          <i className="far fa-square" /> {t.common.deactivate}
        </MenuItem>
      )
    } else {
      return (
        <MenuItem
          onClick={() =>
            setIsActive(savingcardSummary.SavingCard.SavingCardGuid, true)
          }
        >
          <i className="fa fa-check-square" /> {t.common.activate}
        </MenuItem>
      )
    }
  }
  return (
    <div className="portlet savingcard portlet-boxed">
      <div className="portlet-header">
        <Dropdown id="dropdown-custom-1" className="pull-right">
          <Dropdown.Toggle noCaret>
            <Glyphicon glyph="gears" />
            <i className="fa fa-cogs" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <MenuItem onClick={() => onEditClick()}>
              <i className="fa fa-edit" /> {t.common.edit}
            </MenuItem>
            {renderActivateMenuItem()}

            <MenuItem divider />
            <MenuItem
              onClick={() => {
                onDeleteClick(savingcardSummary.SavingCard.SavingCardGuid)
              }}
              className="danger"
            >
              <i className="fa fa-times" /> {t.common.delete}
            </MenuItem>
          </Dropdown.Menu>
        </Dropdown>
        <h4 className="portlet-title">{savingcardSummary.SavingCard.Title}</h4>
        <h5 className="portlet-title">
          {GetDateText(
            savingcardSummary.SavingCard.ValidFrom,
            savingcardSummary.SavingCard.ValidTill
          )}
          <LoyaltyStatusBadge status={savingcardSummary.SavingCard.Status} />
        </h5>
      </div>
      <div
        className="portlet-body"
        style={{
          backgroundImage: `url(
            ${GetImageFileUrl(savingcardSummary.SavingCard.ImageFileGuid)}
          )`,
        }}
      >
        <div className="portlet-footer cursor" onClick={() => onDetailsClick()}>
          <OverlayTrigger
            trigger="click"
            placement="top"
            rootClose
            overlay={
              <Popover
                id="totalCardsPopover"
                title={t.savingcard.totalCards.title}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: t
                      .formatString(
                        t.savingcard.totalCards.text,
                        savingcardSummary.TotalSavingCards
                      )
                      .toString(),
                  }}
                />
              </Popover>
            }
          >
            <div className="circle-stat" onClick={(e) => e.stopPropagation()}>
              <h4>{savingcardSummary.TotalSavingCards}</h4>
              <span>#</span>
            </div>
          </OverlayTrigger>
          <OverlayTrigger
            trigger="click"
            placement="top"
            rootClose
            overlay={
              <Popover
                id="totalStampsPopover"
                title={t.savingcard.totalCardsProcessed.title}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: t
                      .formatString(
                        t.savingcard.totalCardsProcessed.text,
                        savingcardSummary.TotalSavingCardsProcessed
                      )
                      .toString(),
                  }}
                />
              </Popover>
            }
          >
            <div className="circle-stat" onClick={(e) => e.stopPropagation()}>
              <h4>{savingcardSummary.TotalSavingCardsProcessed}</h4>
              <i className="fas fa-stamp" />
            </div>
          </OverlayTrigger>
          <OverlayTrigger
            trigger="click"
            placement="top"
            rootClose
            overlay={
              <Popover
                id="averageSTampsPopover"
                title={t.savingcard.averageStamps.title}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: t
                      .formatString(
                        t.savingcard.averageStamps.text,
                        savingcardSummary.SavingCardsAverageStamps.toFixed(2)
                      )
                      .toString(),
                  }}
                />
              </Popover>
            }
          >
            <div className="circle-stat" onClick={(e) => e.stopPropagation()}>
              <h4>{savingcardSummary.SavingCardsAverageStamps.toFixed(2)}</h4>
              <span className="lg">~</span>
            </div>
          </OverlayTrigger>
          <h4>
            {t.common.details}
            <i className="fa fa-chevron-right" />
          </h4>
        </div>
      </div>
    </div>
  )
}

export default SavingCardSummaryReport
