import { action, makeObservable, runInAction } from 'mobx'

import { AlertType } from 'src/Components/McPageAlert'
import { t } from 'src/I18n'
import { ISelectOption } from 'src/Models/Client'
import IGenericError from 'src/Models/Server/IGenericError'
import CurrencyVTO from 'src/Models/Server/Response/Currency/CurrencyVTO'
import CurrencyController from 'src/Services/ToprCoreApi/Axios/Controllers/CurrencyController'
import FinanceController from 'src/Services/ToprCoreApi/Axios/Controllers/FinanceController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export interface IFormValues {
  Storno: File
  StornoFile: string
  Currencies: ISelectOption[]
  SelectedCurrency: ISelectOption
}

export default class Store extends BaseStore<IFormValues> {
  constructor() {
    super()
    this.BaseInitialize()

    makeObservable(this, {
      Initialize: action,
      GetMerchantCurrencies: action,
    })
  }

  /** Sets Initial state */

  public Initialize() {
    this.BaseInitialize()
    this.GetMerchantCurrencies()
  }

  public GetMerchantCurrencies() {
    this.IsLoading = true

    CurrencyController.GetMyMerchantCurrencies()
      .then((result: CurrencyVTO[]) => {
        runInAction(() => {
          const autoChargeCurrencies = result.filter(
            (x) => x.AutomaticChargeAllowed === true
          )
          if (autoChargeCurrencies.length > 0) {
            const currencyOptions = autoChargeCurrencies.map((cur) => ({
              value: cur.Code,
              label: cur.Name,
            }))

            let selectedCurrency = currencyOptions[0]

            const rtoCurrency = autoChargeCurrencies.find(
              (x) => x.Code === 'RTO'
            )
            if (rtoCurrency) {
              selectedCurrency = {
                value: rtoCurrency.Code,
                label: rtoCurrency.Name,
              }
            }

            this.ViewModel = {
              Currencies: currencyOptions,
              SelectedCurrency: selectedCurrency,
              StornoFile: '',
              Storno: null,
            }

            this.IsLoading = false
          }
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsLoading = false))
        appUiStore.ShowError(error)
      })
  }

  public UploadStorno(data: IFormValues) {
    this.IsSubmitting = true

    FinanceController.UploadStorno(data.StornoFile, data.SelectedCurrency.value)
      .then(() => {
        runInAction(() => {
          this.IsSubmitting = false
          this.PageAlert = {
            message: t.stornos.form.success,
            type: AlertType.Success,
          }
        })
      })
      .catch(() => {
        runInAction(() => {
          this.IsSubmitting = false
          this.PageAlert = {
            message: t.stornos.form.failed,
            type: AlertType.Danger,
          }
        })
      })
  }
}

export const uploadStornoStore = new Store()
