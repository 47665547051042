import { LoyaltyRuleTypes } from 'src/Models/Server/Enums'
import IGenericError from 'src/Models/Server/IGenericError'
import { IEditArticleRuleRequest } from 'src/Models/Server/Request/LoyaltyProgram/IEditArticleRuleRequest'
import { ILoyaltyProgramDetailsVTO } from 'src/Models/Server/Response/LoyaltyProgram/ILoyaltyProgramDetailsVTO'
import LoyaltyProgramController from 'src/Services/ToprCoreApi/Axios/Controllers/LoyaltyProgramController'
import { appUiStore } from 'src/Stores/AppUiStore'

export interface IState {
  isSubmitting: boolean
  viewModel: IFormValues
}

export interface IEditLoyaltyProgramRule {
  loyaltyProgram: ILoyaltyProgramDetailsVTO
  loyaltyRuleId: number
}

export const initialState: IState = {
  isSubmitting: false,
  viewModel: undefined,
}

export interface IFormValues {
  Name: string
  ArticleCode: string
  ArticleGroup: string
}

export type Actions =
  | {
      type: 'initialize'
      viewModel: IFormValues
    }
  | {
      type: 'setIsSubmitting'
      isSubmitting: boolean
    }

export const reducer = (state: IState, action: Actions): IState => {
  switch (action.type) {
    case 'initialize':
      return {
        ...state,
        viewModel: action.viewModel,
      }
    case 'setIsSubmitting':
      return { ...state, isSubmitting: action.isSubmitting }
    default:
      throw new Error('Invalid Reducer Action')
  }
}

export const reducerActions = (
  state: IState,
  dispatch: (action: Actions) => void
) => {
  return {
    initialize: (editLoyaltyProgramRule: IEditLoyaltyProgramRule) => {
      const loyaltyRule =
        editLoyaltyProgramRule.loyaltyProgram.LoyaltyRules.find(
          (x) => x.LoyaltyRuleId == editLoyaltyProgramRule.loyaltyRuleId
        )
      dispatch({
        type: 'initialize',
        viewModel: {
          ArticleCode: loyaltyRule.Article,
          ArticleGroup: loyaltyRule.ArticleGroup,
          Name: editLoyaltyProgramRule.loyaltyProgram.Name,
        },
      })
    },

    save: (
      editLoyaltyProgramRule: IEditLoyaltyProgramRule,
      data: IFormValues,
      onComplete: () => void
    ) => {
      const loyaltyRule =
        editLoyaltyProgramRule.loyaltyProgram.LoyaltyRules.find(
          (x) => x.LoyaltyRuleId == editLoyaltyProgramRule.loyaltyRuleId
        )

      const isArticle =
        loyaltyRule.LoyaltyRuleTypeId === LoyaltyRuleTypes.Article
      const request: IEditArticleRuleRequest = {
        LoyaltyProgramId:
          editLoyaltyProgramRule.loyaltyProgram.LoyaltyProgramId,
        LoyaltyRuleId: editLoyaltyProgramRule.loyaltyRuleId,
        ArticleCode: isArticle ? data.ArticleCode : null,
        ArticleGroup: !isArticle ? data.ArticleGroup : null,
        Name: data.Name,
      }

      dispatch({ type: 'setIsSubmitting', isSubmitting: true })
      LoyaltyProgramController.EditLoyaltyRule(request)
        .then(() => {
          onComplete()
          dispatch({ type: 'setIsSubmitting', isSubmitting: false })
        })
        .catch((error: IGenericError) => {
          appUiStore.ShowError(error)
          dispatch({ type: 'setIsSubmitting', isSubmitting: false })
        })
    },
  }
}
