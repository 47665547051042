import IGenericError from 'src/Models/Server/IGenericError'

import { ITagVTO } from 'src/Models/Server/Response/Tag/ITagVTO'
import TagController from 'src/Services/ToprCoreApi/Axios/Controllers/TagController'
import { appUiStore } from 'src/Stores/AppUiStore'

export interface IState {
  tags: ITagVTO[]
  isLoading: boolean
  editTag?: ITagVTO
  isAddTagModalVisible: boolean
}

export const initialState: IState = {
  tags: [],
  isLoading: true,
  isAddTagModalVisible: false,
}

export type Actions =
  | {
      type: 'initialized'
      tags: ITagVTO[]
    }
  | {
      type: 'toggleAddTagModal'
      isVisible: boolean
    }
  | {
      type: 'toggleEditTagModal'
      editTag?: ITagVTO
    }

export const reducer = (state: IState, action: Actions): IState => {
  switch (action.type) {
    case 'initialized':
      return {
        ...state,
        tags: action.tags,
        isLoading: false,
      }
    case 'toggleAddTagModal':
      return { ...state, isAddTagModalVisible: action.isVisible }
    case 'toggleEditTagModal':
      return { ...state, editTag: action.editTag }
    default:
      throw new Error('Invalid Reducer Action')
  }
}

export const reducerActions = (
  state: IState,
  dispatch: (action: Actions) => void
) => {
  return {
    toggleAddTagModal: (isVisible: boolean) => {
      dispatch({ type: 'toggleAddTagModal', isVisible })
    },
    toggleEditTagModal: (editTag?: ITagVTO) => {
      dispatch({ type: 'toggleEditTagModal', editTag })
    },
    getTags: () => {
      TagController.GetTags()
        .then((tags) => {
          dispatch({ type: 'initialized', tags })
        })
        .catch((error: IGenericError) => {
          appUiStore.ShowError(error)
        })
    },
  }
}
