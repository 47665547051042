import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { ButtonToolbar, Col, Row } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { McPageAlert } from 'src/Components'
import ClickSlider from 'src/Components/ClickSlider'
import McButton from 'src/Components/McButton'
import McMap from 'src/Components/McMap'
import McModal from 'src/Components/Modals/McModal'
import { MyCheckbox, MySelect, MyTextbox } from 'src/Components/My'
import { t } from 'src/I18n'
import ILocationVTO from 'src/Models/Server/Response/Location/ILocationVTO'
import { editLocationStore, IFormValues } from './Store'

interface IProps {
  onClose: () => void
  onSubmitSuccess: (editLocation: ILocationVTO) => void
  editLocation: ILocationVTO
}

interface IProps {}

const EditLocationModal: React.FC<IProps> = ({
  onClose,
  onSubmitSuccess,
  editLocation,
}) => {
  const {
    ViewModel,
    IsSubmitting,
    ShowMap,
    PageAlert,
    LatLong,
    TenantOptions,
    DeliveryOptions,
  } = editLocationStore
  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({})
  const { handleSubmit, getValues, reset } = useFormProps

  useEffect(() => {
    editLocationStore.Initialize(editLocation)
    //reset(editLocation)
  }, [null])

  useEffect(() => {
    reset(ViewModel)
  }, [ViewModel])

  if (ViewModel === undefined) {
    return null
  }

  const onSubmit = (data: IFormValues) => {
    editLocationStore.EditLocation(data).then(() => {
      onSubmitSuccess(data)
      close()
    })
  }

  const close = () => {
    if (!editLocationStore.IsSubmitting) {
      onClose()
    }
  }

  console.log('LatLong', LatLong)

  const lat = LatLong === undefined ? '' : LatLong.lat.toString()
  const long = LatLong === undefined ? '' : LatLong.lng.toString()

  return (
    <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
      <McModal
        title={t.common.edit}
        className="max-height"
        footer={
          <div>
            <McPageAlert pageAlert={PageAlert} />
            <ButtonToolbar className="pull-right">
              <McButton onClick={() => close()}>{t.common.cancel}</McButton>
              <McButton
                bsStyle="primary"
                type="submit"
                isLoading={IsSubmitting}
              >
                {t.common.save}
              </McButton>
            </ButtonToolbar>
          </div>
        }
        closeClicked={() => close()}
      >
        <ClickSlider
          isSlideLeft={ShowMap}
          leftSlide={
            <>
              <MyTextbox
                formObject={{} as ILocationVTO}
                label={t.location.insert.fields.Name.label}
                placeholder={t.location.insert.fields.Name.label}
                name="Name"
                useFormProps={useFormProps}
                rules={{
                  required: t.location.insert.fields.Name.errors.required,
                }}
                tabIndex={1}
              />
              <MyTextbox
                formObject={{} as ILocationVTO}
                label={t.location.insert.fields.Email.label}
                placeholder={t.location.insert.fields.Email.label}
                name="EmailAddress"
                useFormProps={useFormProps}
              />

              <MySelect
                formObject={ViewModel}
                label="Tenant"
                name="SelectedTenant"
                useFormProps={useFormProps}
                options={TenantOptions}
              />
              <MyTextbox
                formObject={{} as ILocationVTO}
                label={t.location.insert.fields.POSOrderUrl.label}
                placeholder={'/order/e5ea94a288a840dc9402c3fc9e49cdc8'}
                name="POSOrderUrl"
                useFormProps={useFormProps}
              />
              <MySelect
                formObject={ViewModel}
                label="Delivery Type"
                name="SelectedDeliveryType"
                useFormProps={useFormProps}
                options={DeliveryOptions}
              />
              <MyCheckbox
                formObject={ViewModel}
                label={t.location.insert.fields.IsVisibleInApp.label}
                name="IsVisibleInApp"
                useFormProps={useFormProps}
              />
              <Row>
                <Col md={6}>
                  <MyTextbox
                    formObject={{} as ILocationVTO}
                    label={t.location.insert.fields.ZipCode.label}
                    placeholder={t.location.insert.fields.ZipCode.label}
                    name="ZipCode"
                    useFormProps={useFormProps}
                  />
                </Col>
                <Col md={6}>
                  <MyTextbox
                    formObject={{} as ILocationVTO}
                    label={t.location.insert.fields.StreetNumber.label}
                    placeholder={t.location.insert.fields.StreetNumber.label}
                    name="StreetNumber"
                    useFormProps={useFormProps}
                    onBlur={() => editLocationStore.GeoCodeAddress(getValues())}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <MyTextbox
                    formObject={{} as ILocationVTO}
                    label={t.location.insert.fields.StreetName.label}
                    placeholder={t.location.insert.fields.StreetName.label}
                    name="StreetName"
                    useFormProps={useFormProps}
                    onBlur={() => editLocationStore.GeoCodeAddress(getValues())}
                  />
                </Col>
                <Col md={6}>
                  <MyTextbox
                    formObject={{} as ILocationVTO}
                    label={t.location.insert.fields.City.label}
                    placeholder={t.location.insert.fields.City.label}
                    name="City"
                    useFormProps={useFormProps}
                    onBlur={() => editLocationStore.GeoCodeAddress(getValues())}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <MyTextbox
                    formObject={{} as ILocationVTO}
                    label={t.location.insert.fields.Lat.label}
                    placeholder={t.location.insert.fields.Lat.label}
                    name="Lat"
                    value={lat}
                    useFormProps={useFormProps}
                    disabled={true}
                  />
                </Col>
                <Col md={6}>
                  <MyTextbox
                    formObject={{} as ILocationVTO}
                    label={t.location.insert.fields.Long.label}
                    placeholder={t.location.insert.fields.Long.label}
                    name="Long"
                    value={long}
                    useFormProps={useFormProps}
                    disabled={true}
                  />
                  <McButton
                    title={t.location.insert.showMap}
                    onClick={() => {
                      editLocationStore.ToggleMap()
                    }}
                    bsSize="xs"
                  >
                    {t.location.insert.showMap}
                  </McButton>
                </Col>
              </Row>
            </>
          }
          rightSlide={
            <>
              {LatLong !== undefined && (
                <>
                  <a onClick={() => editLocationStore.ToggleMap()}>
                    {t.common.back}
                  </a>
                  <McMap lat={LatLong.lat} lng={LatLong.lng} zoom={16} />
                </>
              )}
            </>
          }
        />
      </McModal>
    </form>
  )
}

export default observer(EditLocationModal)
