import { AxiosError, AxiosResponse } from 'axios'
import { handleException } from 'src/Helpers/ResponseHelpers'
import INameIdVTO from 'src/Models/Server/INameIdVTO'
import IInsertTerminalRequest from 'src/Models/Server/Request/Terminal/IInsertTerminalRequest'
import { IExceptionError } from 'src/Models/Server/Response/Exception/ExceptionError'
import ITerminalVTO from 'src/Models/Server/Response/Terminal/ITerminalVTO'
import { axiosAuth } from '../AuthAxios'

export default {
  GetTerminalTypes() {
    return new Promise<Array<INameIdVTO<number>>>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/terminal/types`)
          .then((response: AxiosResponse<Array<INameIdVTO<number>>>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },

  Insert(request: IInsertTerminalRequest) {
    return new Promise<number>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/terminal/insert`, request)
          .then((response: AxiosResponse<number>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  Edit(request: {
    TerminalId: string
    Description: string
    TerminalTypeID: string
  }) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/terminal/edit`, request)
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  GetTerminal(id: string) {
    return new Promise<ITerminalVTO>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/terminal/${id}`)
          .then((response: AxiosResponse<ITerminalVTO>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  GetTerminalsByPos(guid: string) {
    return new Promise<ITerminalVTO[]>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/terminals/pos/${guid}`)
          .then((response: AxiosResponse<ITerminalVTO[]>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
}
