import { action, makeObservable, runInAction } from 'mobx'
import { createPageAlert } from 'src/Components/McPageAlert'
import { ISelectOption } from 'src/Models/Client'
import IGenericError from 'src/Models/Server/IGenericError'
import { ToSelectOptions } from 'src/Models/Server/INameIdVTO'
import IMerchantVTO from 'src/Models/Server/Response/Merchant/IMerchantVTO'
import MerchantController from 'src/Services/ToprCoreApi/Axios/Controllers/MerchantController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'
import { merchantDetailsStore } from '../Store'

export interface IFormValues extends IMerchantVTO {
  SelectedRelation: ISelectOption
}

export default class Store extends BaseStore<IFormValues> {
  public Relations: ISelectOption[] = []

  /**
   *
   */
  constructor() {
    super()

    makeObservable(this, {
      Initialize: action,
      EditMerchant: action,
    })
  }

  public Initialize(editTerminal: IMerchantVTO) {
    this.BaseInitialize()
    this.IsLoading = true
    MerchantController.GetRelations()
      .then((relations) => {
        runInAction(() => {
          this.Relations = ToSelectOptions(relations)

          const selectedRelation = this.Relations.find(
            (x) => x.value === editTerminal.RelationID.toString()
          )

          this.ViewModel = {
            SelectedRelation: selectedRelation,
            ...editTerminal,
          }

          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        runInAction(() => {
          this.IsLoading = false
        })
      })
  }

  public EditMerchant(data: IFormValues) {
    this.IsSubmitting = true
    data.RelationID = data.SelectedRelation.value
    MerchantController.Edit(data)
      .then(() => {
        runInAction(() => {
          this.IsSubmitting = false
        })
        merchantDetailsStore.Initialize(data.MerchantGuid)
        merchantDetailsStore.ToggleEditMerchantModal(null)
      })
      .catch((error: IGenericError) => {
        runInAction(() => {
          this.PageAlert = createPageAlert(error)
          this.IsSubmitting = false
        })
      })
  }
}

export const merchantEditStore = new Store()
