import { LoyaltyRuleTypes } from 'src/Models/Server/Enums'
import IGenericError from 'src/Models/Server/IGenericError'
import { IEditArticleRuleRequest } from 'src/Models/Server/Request/LoyaltyProgram/IEditArticleRuleRequest'
import { IEditLoyaltyProgramRequest } from 'src/Models/Server/Request/LoyaltyProgram/IEditLoyaltyProgramRequest'
import { ILoyaltyProgramDetailsVTO } from 'src/Models/Server/Response/LoyaltyProgram/ILoyaltyProgramDetailsVTO'
import LoyaltyProgramController from 'src/Services/ToprCoreApi/Axios/Controllers/LoyaltyProgramController'
import { appUiStore } from 'src/Stores/AppUiStore'

export interface IState {
  isSubmitting: boolean
  viewModel: IFormValues
}

export const initialState: IState = {
  isSubmitting: false,
  viewModel: undefined,
}

export interface IFormValues {
  StartDate: Date
  EndDate?: Date
}

export type Actions =
  | {
      type: 'initialize'
      viewModel: IFormValues
    }
  | {
      type: 'setIsSubmitting'
      isSubmitting: boolean
    }

export const reducer = (state: IState, action: Actions): IState => {
  switch (action.type) {
    case 'initialize':
      return {
        ...state,
        viewModel: action.viewModel,
      }
    case 'setIsSubmitting':
      return { ...state, isSubmitting: action.isSubmitting }
    default:
      throw new Error('Invalid Reducer Action')
  }
}

export const reducerActions = (
  state: IState,
  dispatch: (action: Actions) => void
) => {
  return {
    initialize: (editLoyaltyProgram: ILoyaltyProgramDetailsVTO) => {
      dispatch({
        type: 'initialize',
        viewModel: {
          StartDate: new Date(editLoyaltyProgram.StartDate),
          EndDate:
            editLoyaltyProgram.EndDate !== null
              ? new Date(editLoyaltyProgram.EndDate)
              : null,
        },
      })
    },

    save: (
      editLoyaltyProgram: ILoyaltyProgramDetailsVTO,
      data: IFormValues,
      onComplete: () => void
    ) => {
      const request: IEditLoyaltyProgramRequest = {
        LoyaltyProgramId: editLoyaltyProgram.LoyaltyProgramId,
        StartDate: data.StartDate,
        EndDate: data.EndDate,
      }

      dispatch({ type: 'setIsSubmitting', isSubmitting: true })
      LoyaltyProgramController.EditLoyaltyProgram(request)
        .then(() => {
          onComplete()
          dispatch({ type: 'setIsSubmitting', isSubmitting: false })
        })
        .catch((error: IGenericError) => {
          appUiStore.ShowError(error)
          dispatch({ type: 'setIsSubmitting', isSubmitting: false })
        })
    },
  }
}
