import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import LoadContent from 'src/Components/LoadContent'
import { MainPage, MyDataTable } from 'src/Components/My'
import { sortDate, sortNumber } from 'src/Helpers/TableHelpers'
import { t } from 'src/I18n'
import { merchantPaymentDetails } from './Store'

const MerchantPaymentDetails: React.FC = () => {
  const params = useParams<'id'>()
  useEffect(() => {
    merchantPaymentDetails.Initialize(params.id)
  }, [null])

  const { IsLoading, Transactions } = merchantPaymentDetails

  const renderTransactions = () => {
    const columns = [
      {
        dataField: 'RequestDateFormatted',
        text: t.common.date,
        sort: true,
        sortFunc: (order, dataField, rowA, rowB) => {
          return sortDate(order, rowA, rowB, 'RequestDate')
        },
      },
      {
        dataField: 'AmountFormatted',
        text: t.common.amount,
        sort: true,
        sortFunc: (order, dataField, rowA, rowB) => {
          return sortNumber(order, rowA, rowB, 'Amount')
        },
      },
      {
        dataField: 'UserName',
        text: t.common.consumerName,
        sort: true,
        // filter: textFilter({
        //   placeholder: t.trips.name.placeholder,
        // }),
      },
      {
        dataField: 'PosName',
        text: t.common.pos,
        sort: true,
        //filter: textFilter(),
      },
      {
        dataField: 'Currency.Name',
        text: t.common.currencies,
        sort: true,
      },
    ]

    return <MyDataTable data={Transactions} columns={columns} />
  }
  return (
    <MainPage>
      <BreadCrumbs
        currentPageTitle={t.paymentTransactions.title}
        previousPages={{
          title: t.merchantPayments.title,
          to: '/finance/merchantpayments',
        }}
      />

      <LoadContent IsLoading={IsLoading}>
        <Portlet title={t.paymentTransactions.title}>
          {renderTransactions()}
        </Portlet>
      </LoadContent>
    </MainPage>
  )
}

export default observer(MerchantPaymentDetails)
