import { action, makeObservable, observable, runInAction } from 'mobx'
import IGenericError from 'src/Models/Server/IGenericError'
import DashboardController from 'src/Services/ToprCoreApi/Axios/Controllers/DashboardController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export default class Store extends BaseStore<{}> {
  public MachineName = ''

  constructor() {
    super()

    makeObservable(this, {
      Boot: action,
      MachineName: observable,
    })
  }

  public Boot() {
    this.IsLoading = true
    DashboardController.Boot()
      .then((response) => {
        runInAction(() => {
          this.IsLoading = false
          this.MachineName = response
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        runInAction(() => (this.IsLoading = false))
      })
  }
}

export const bootStore = new Store()
