import { AxiosError, AxiosResponse } from 'axios'
import { handleException } from 'src/Helpers/ResponseHelpers'
import INameIdVTO from 'src/Models/Server/INameIdVTO'
import { ICreateLoyaltyProgramRequest } from 'src/Models/Server/Request/LoyaltyProgram/ICreateLoyaltyProgramRequest'
import { IEditArticleRuleRequest } from 'src/Models/Server/Request/LoyaltyProgram/IEditArticleRuleRequest'
import { IEditLoyaltyProgramRequest } from 'src/Models/Server/Request/LoyaltyProgram/IEditLoyaltyProgramRequest'
import { IEditLoyaltyRewardRequest } from 'src/Models/Server/Request/LoyaltyProgram/IEditLoyaltyRewardRequest'
import { IExceptionError } from 'src/Models/Server/Response/Exception/ExceptionError'
import { ILoyaltyProgramDetailsVTO } from 'src/Models/Server/Response/LoyaltyProgram/ILoyaltyProgramDetailsVTO'
import { ILoyaltyProgramVTO } from 'src/Models/Server/Response/LoyaltyProgram/ILoyaltyProgramVTO'
import { axiosAuth } from '../AuthAxios'

export default {
  Insert(request: ICreateLoyaltyProgramRequest) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/loyaltyprogram/insert`, request)
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  EditLoyaltyRule(request: IEditArticleRuleRequest) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/loyaltyprogram/rule/edit`, request)
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  EditLoyaltyReward(request: IEditLoyaltyRewardRequest) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/loyaltyprogram/reward/edit`, request)
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  EditLoyaltyProgram(request: IEditLoyaltyProgramRequest) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/loyaltyprogram/edit`, request)
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  DeleteLoyaltyProgram(loyaltyProgramId: number) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .delete(`/api/v1/loyaltyprogram/delete/${loyaltyProgramId}`)
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },

  GetLoyaltyPrograms() {
    return new Promise<ILoyaltyProgramVTO[]>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/loyaltyprograms`)
          .then((response: AxiosResponse<ILoyaltyProgramVTO[]>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  GetLoyaltyProgramTypes() {
    return new Promise<Array<INameIdVTO<number>>>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/loyaltyprogram/types`)
          .then((response: AxiosResponse<Array<INameIdVTO<number>>>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  GetLoyaltyProgram(loyaltyProgramId: string) {
    return new Promise<ILoyaltyProgramDetailsVTO>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/loyaltyprogram/${loyaltyProgramId}/details`)
          .then((response: AxiosResponse<ILoyaltyProgramDetailsVTO>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
}
