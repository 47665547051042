import { observer } from 'mobx-react'
import React, { Fragment, useEffect, useState } from 'react'
import { Col, Row, Tab, Tabs } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { McButton, McPageAlert } from 'src/Components'
import Portlet from 'src/Components/Authenticated/Portlet'
import { McModal } from 'src/Components/Modals'
import {
  InputMasks,
  MyCheckbox,
  MyCurrencyInput,
  MyDatePicker,
  MyLocalizedStringTabs,
  MySelect,
  MySlim,
  MyTextbox,
} from 'src/Components/My'
import { DayFullMonth } from 'src/Helpers/DateHelpers'
import { t } from 'src/I18n'
import Images from 'src/Images'
import { ISelectOption } from 'src/Models/Client'
import { VoucherTypes } from 'src/Models/Server/Enums'
import { GetImageDataUrl } from 'src/Models/Server/File/FileDTO'
import { GetValueForLang } from 'src/Models/Server/Response/LocalizedString/LocalizedStringDTO'
import { SavingCardRewardTypes } from 'src/Models/Server/Response/SavingCard/SavingCardRewardTypes'
import { persistedStore } from 'src/Stores/PersistedStore'
import { IFormValues, SavingStore } from '../Store'

interface IProps {
  //useFormProps: UseFormReturn<IFormValues>
  store: SavingStore
  onSubmit: (data: IFormValues) => void
  initialize: () => void
}

const SavingCardForm: React.FC<IProps> = ({
  //useFormProps,
  store,
  onSubmit,
  initialize,
}) => {
  const [showSavingCardPreview, setShowSavingCardPreview] = useState(false)
  const [showCouponPreview, setShowCouponPreview] = useState(false)

  const {
    ViewModel,
    PageAlert,
    IsSubmitting,
    CurrencyOptions,
    SelectedCurrency,
    RewardOptions,
    VoucherTypeOptions,
  } = store

  useEffect(() => {
    initialize()
  }, [null])

  useEffect(() => {
    reset(ViewModel)
    //console.log('reset', ViewModel !== undefined ? ViewModel.RewardAmount : '')
  }, [ViewModel])

  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({
    //when checking for changes (like RewardType change), reset is set after first render
    //so also set defaultvalues, otherwise getValues() = {}
    defaultValues: ViewModel,
  })
  const { handleSubmit, reset, getValues, setValue, trigger } = useFormProps

  const data = getValues()

  if (ViewModel === undefined || Object.keys(data).length === 0) {
    return null
  }

  const renderDates = () => {
    if (data.ValidFrom || data.ValidTill) {
      return (
        <div>
          <br />
          {data.ValidFrom && (
            <div className="cap">{`${t.common.from}: ${DayFullMonth(
              data.ValidFrom
            )}`}</div>
          )}
          {data.ValidTill && (
            <div>{`${t.savingcardInsert.untill}: ${DayFullMonth(
              data.ValidTill
            )}`}</div>
          )}
        </div>
      )
    }
    return null
  }

  const renderSavingCardPreview = () => {
    const { SelectedManagerUser } = persistedStore

    const renderStamps = () => {
      if (data.StampCount) {
        const stamps = []
        for (let index = 0; index < data.StampCount; index++) {
          stamps.push(
            <div key={index} className="stamp">
              <div className="stamp-border" />
              {index === 0 && <img src={Images.Stamp} />}
            </div>
          )
        }

        return stamps
      }
      return null
    }

    const dataUrl = GetImageDataUrl(data.ImageFile)

    if (showSavingCardPreview) {
      return (
        <McModal
          title={t.common.preview}
          closeClicked={() => setShowSavingCardPreview(false)}
        >
          <Tabs id="tabs">
            {SelectedManagerUser.MerchantLanguages.map((lang) => {
              const title = GetValueForLang(data.Title, lang)
              return (
                <Tab
                  key={lang}
                  eventKey={lang}
                  title={<img title={lang} src={Images.Flags[lang]} />}
                >
                  <Row>
                    <Col md={4}>
                      <div
                        className="saving-card tile"
                        style={{ backgroundImage: `url(${dataUrl})` }}
                      >
                        {title !== '' && (
                          <div className="container">
                            <h4>{GetValueForLang(data.Title, lang)}</h4>
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col md={8}>{GetValueForLang(data.Description, lang)}</Col>
                  </Row>
                  {renderDates()}
                  <hr />
                  <div className="stamp-container clearfix">
                    {renderStamps()}
                  </div>
                </Tab>
              )
            })}
          </Tabs>
        </McModal>
      )
    }

    return null
  }

  const renderCouponPreview = () => {
    const { SelectedManagerUser } = persistedStore

    const dataUrl = GetImageDataUrl(data.ImageFile)

    if (showCouponPreview) {
      return (
        <McModal
          title={`${t.common.preview} ${t.common.coupon}`}
          closeClicked={() => setShowCouponPreview(false)}
        >
          <Tabs id="tabs">
            {SelectedManagerUser.MerchantLanguages.map((lang) => {
              const title = GetValueForLang(data.Title, lang)
              return (
                <Tab
                  key={lang}
                  eventKey={lang}
                  title={<img title={lang} src={Images.Flags[lang]} />}
                >
                  <Row>
                    <Col md={4}>
                      <div
                        className="saving-card tile"
                        style={{ backgroundImage: `url(${dataUrl})` }}
                      >
                        {title !== '' && (
                          <div className="container">
                            <h4>{GetValueForLang(data.Title, lang)}</h4>
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col md={8}>
                      {GetValueForLang(data.CouponDescription, lang)}
                    </Col>
                  </Row>
                  {renderDates()}
                </Tab>
              )
            })}
          </Tabs>
        </McModal>
      )
    }

    return null
  }

  const renderButtonRow = () => {
    const { SelectedRewardOption } = data
    const isCouponRewardType =
      SelectedRewardOption != null &&
      SelectedRewardOption.value === SavingCardRewardTypes.Coupon.toString()

    return (
      <Fragment>
        {renderSavingCardPreview()}
        {renderCouponPreview()}
        <McPageAlert pageAlert={PageAlert} />
        <hr />
        <Row>
          <Col md={4}>
            <McButton
              block
              bsSize="large"
              type="button"
              icon="fa-eye"
              onClick={() => {
                setShowSavingCardPreview(true)
              }}
            >
              {t.common.savingcard}
            </McButton>
          </Col>
          <Col md={4}>
            {isCouponRewardType && (
              <McButton
                block
                bsSize="large"
                type="button"
                icon="fa-eye"
                onClick={() => {
                  setShowCouponPreview(true)
                }}
              >
                {t.common.coupon}
              </McButton>
            )}
          </Col>
          <Col md={4}>
            <McButton
              block
              bsStyle="primary"
              bsSize="large"
              type="submit"
              isLoading={IsSubmitting}
            >
              {t.common.save}
            </McButton>
          </Col>
        </Row>
      </Fragment>
    )
  }

  const renderCouponDiscountAmount = () => {
    const { SelectedVoucherTypeOption } = data
    switch (SelectedVoucherTypeOption.value) {
      case VoucherTypes.AmountDiscount.toString():
        return (
          <MyTextbox
            formObject={ViewModel}
            useFormProps={useFormProps}
            icon="fa-euro-sign"
            label={t.savingcardInsert.fields.DiscountAmount.Amount.label}
            infoText={t.savingcardInsert.fields.DiscountAmount.Amount.info}
            placeholder={
              t.savingcardInsert.fields.DiscountAmount.Amount.placeholder
            }
            name="DisplayDiscountAmount"
            inputMask={InputMasks.Currency}
            rules={{
              required:
                t.savingcardInsert.fields.DiscountAmount.Amount.errors.required,
            }}
            type="text"
            maxLength={6}
          />
        )

      case VoucherTypes.PercentageDiscount.toString():
        return (
          <MyTextbox
            formObject={ViewModel}
            useFormProps={useFormProps}
            icon="fa-percent"
            label={t.savingcardInsert.fields.DiscountAmount.Percentage.label}
            infoText={t.savingcardInsert.fields.DiscountAmount.Percentage.info}
            placeholder={
              t.savingcardInsert.fields.DiscountAmount.Percentage.placeholder
            }
            name="DisplayDiscountAmount"
            inputMask={InputMasks.Percent}
            rules={{
              required:
                t.savingcardInsert.fields.DiscountAmount.Percentage.errors
                  .required,
            }}
            type="text"
            maxLength={3}
          />
        )

      default:
        return null
    }
  }

  const renderCouponReward = () => {
    const { SelectedRewardOption } = data
    if (
      SelectedRewardOption != null &&
      SelectedRewardOption.value === SavingCardRewardTypes.Currency.toString()
    ) {
      return null
    }

    return (
      <Portlet title={`${t.common.reward} - ${t.common.coupon}`}>
        <Row>
          <Col md={6}>
            <MySelect
              name="SelectedVoucherTypeOption"
              formObject={ViewModel}
              useFormProps={useFormProps}
              label={t.savingcardInsert.fields.VoucherType.label}
              infoText={t.savingcardInsert.fields.VoucherType.info}
              onSelectionChange={() => {
                trigger('SelectedVoucherTypeOption')
              }}
              options={VoucherTypeOptions}
            />
            {renderCouponDiscountAmount()}
          </Col>
          <Col md={6}>
            <MyLocalizedStringTabs
              formObject={ViewModel}
              useFormProps={useFormProps}
              label={t.savingcardInsert.fields.CouponDescription.label}
              infoText={t.savingcardInsert.fields.CouponDescription.info}
              name="CouponDescription"
              isRequired
            />
          </Col>
        </Row>
        {renderButtonRow()}
      </Portlet>
    )
  }

  const renderCurrencyReward = () => {
    const { SelectedRewardOption } = data
    if (
      SelectedRewardOption != null &&
      SelectedRewardOption.value === SavingCardRewardTypes.Coupon.toString()
    ) {
      return null
    }

    return (
      <Portlet title={`${t.common.reward} - ${t.common.currency}`}>
        <Row>
          <Col md={6}>
            <MySelect
              formObject={ViewModel}
              useFormProps={useFormProps}
              name="SelectedCurrencyOption"
              label={t.common.currency}
              options={CurrencyOptions}
              onSelectionChange={(option: ISelectOption) => {
                store.SetSelectedCurrency(option)
              }}
            />
          </Col>
          <Col md={6}>
            <MyCurrencyInput
              formObject={{} as IFormValues}
              label={t.savingcardInsert.fields.RewardAmount.label}
              currency={SelectedCurrency}
              onChangeCents={(amountCents) => {
                setValue('RewardAmount', amountCents)
              }}
              name="DisplayRewardAmount"
              useFormProps={useFormProps}
            />
          </Col>
        </Row>
        {renderButtonRow()}
      </Portlet>
    )
  }

  return (
    <>
      <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
        <Portlet title={t.common.savingcard}>
          <Row>
            <Col md={6}>
              <MyLocalizedStringTabs
                formObject={ViewModel}
                useFormProps={useFormProps}
                name="Title"
                label={t.savingcardInsert.fields.Title.label}
                infoText={t.savingcardInsert.fields.Title.info}
                isRequired
              />
              <MyLocalizedStringTabs
                formObject={ViewModel}
                useFormProps={useFormProps}
                name="Description"
                label={t.savingcardInsert.fields.Description.label}
                infoText={t.savingcardInsert.fields.Description.info}
                isRequired
              />

              <Row>
                <Col xs={6} md={4}>
                  <MyTextbox
                    formObject={ViewModel}
                    useFormProps={useFormProps}
                    name="StampCount"
                    label={t.savingcardInsert.fields.StampCount.label}
                    infoText={t.savingcardInsert.fields.StampCount.info}
                    rules={{
                      required:
                        t.savingcardInsert.fields.StampCount.errors.required,
                    }}
                    type="number"
                    min={3}
                    max={10}
                    maxLength={2}
                  />
                </Col>
                <Col md={6}>
                  <MyCheckbox
                    formObject={ViewModel}
                    useFormProps={useFormProps}
                    name="AutoRefresh"
                    label={t.savingcardInsert.fields.AutoRefresh.label}
                    title={t.savingcardInsert.fields.AutoRefresh.info}
                    rules={{
                      required:
                        t.savingcardInsert.fields.StampCount.errors.required,
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <MyDatePicker
                    formObject={ViewModel}
                    useFormProps={useFormProps}
                    name="ValidFrom"
                    label={t.savingcardInsert.fields.ValidFrom.label}
                    infoText={t.savingcardInsert.fields.ValidFrom.info}
                  />
                </Col>
                <Col md={6}>
                  <MyDatePicker
                    formObject={ViewModel}
                    useFormProps={useFormProps}
                    name="ValidTill"
                    label={t.savingcardInsert.fields.ValidTill.label}
                    infoText={t.savingcardInsert.fields.ValidTill.info}
                    rules={{
                      validate: (validTill: Date) => {
                        let result = true
                        const validFrom = getValues('ValidFrom') as Date
                        if (validFrom && validTill) {
                          // validTill must be greater than validfrom when set
                          result = validFrom < validTill
                        }

                        return (
                          result ||
                          t.savingcardInsert.fields.ValidTill.errors.fromGreater
                        )
                      },
                    }}
                  />
                </Col>
              </Row>
              <MySelect
                formObject={ViewModel}
                useFormProps={useFormProps}
                name="SelectedRewardOption"
                label={t.savingcardInsert.fields.RewardType.label}
                onSelectionChange={(newRewardType: ISelectOption) => {
                  //console.log('new option', newRewardType)
                  store.OnRewardTypeChange(newRewardType, getValues())

                  trigger('SelectedRewardOption')
                }}
                options={RewardOptions}
              />
            </Col>
            <Col md={6}>
              <MySlim
                name="ImageFile"
                formObject={ViewModel}
                useFormProps={useFormProps}
                label={t.savingcardInsert.fields.ImageFile.label}
                infoText={t.savingcardInsert.fields.ImageFile.info}
                rules={{
                  required: t.savingcardInsert.fields.ImageFile.errors.required,
                }}
                sizeSlim={{ width: 600, height: 600 }}
              />
            </Col>
          </Row>
        </Portlet>
        {renderCouponReward()}
        {renderCurrencyReward()}
      </form>
    </>
  )
}

export default observer(SavingCardForm)
