import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import LoaderIcon from 'src/Components/LoaderIcon'
import McButton from 'src/Components/McButton'
import { MainPage, MyMonthPeriodSelect, MySelect } from 'src/Components/My'
import { ISelectOption } from 'src/Models/Client'
import { t } from 'src/I18n'
import { IFormValues, paymentsTokenTypeStore } from './Store'

const PaymentsTokenType: React.FC = () => {
  useEffect(() => {
    paymentsTokenTypeStore.Initialize()
  }, [null])
  const { IsLoading, ViewModel, IsSubmitting, TokenTypes, Currencies } =
    paymentsTokenTypeStore

  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({})
  const { handleSubmit, reset, setValue } = useFormProps

  useEffect(() => {
    reset(ViewModel)
  }, [ViewModel])

  const onSubmit = (data: IFormValues) => {
    paymentsTokenTypeStore.GetPaymentsTokenType(data)
  }

  return (
    <MainPage>
      <BreadCrumbs currentPageTitle={t.paymentsTokenType.title} />

      <LoaderIcon IsLoading={IsLoading} />
      {!IsLoading && ViewModel !== undefined && (
        <Portlet title={t.paymentsTokenType.title}>
          <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={6}>
                <MyMonthPeriodSelect
                  onChange={(startDate, endDate) => {
                    setValue('StartDate', startDate)
                    setValue('EndDate', endDate)
                    handleSubmit((data) =>
                      paymentsTokenTypeStore.GetPaymentsTokenType(data)
                    )()
                  }}
                />
              </Col>
              <Col md={6}>
                <MySelect
                  formObject={{} as IFormValues}
                  useFormProps={useFormProps}
                  name="SelectedTokenTypes"
                  label={t.common.tokenTypes}
                  options={TokenTypes}
                  isMulti
                  onSelectionChange={(options: ISelectOption[]) => {
                    setValue('SelectedTokenTypes', options)
                    handleSubmit((data) =>
                      paymentsTokenTypeStore.GetPaymentsTokenType(data)
                    )()
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <MySelect
                  formObject={{} as IFormValues}
                  useFormProps={useFormProps}
                  name="SelectedCurrencies"
                  label={t.common.currencies}
                  options={Currencies}
                  isMulti
                  onSelectionChange={(options: ISelectOption[]) => {
                    setValue('SelectedCurrencies', options)
                    handleSubmit((data) =>
                      paymentsTokenTypeStore.GetPaymentsTokenType(data)
                    )()
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <McButton
                  bsStyle="primary"
                  pullRight
                  isLoading={IsSubmitting}
                  type="submit"
                  // onClick={() => {
                  //   this.Store.GetPaymentsTokenType()
                  // }}
                >
                  {t.common.download}
                </McButton>
              </Col>
            </Row>
          </form>
        </Portlet>
      )}
    </MainPage>
  )
}

export default observer(PaymentsTokenType)
