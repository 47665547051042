import { observer } from 'mobx-react'
import React, { Fragment, useEffect } from 'react'
import { ButtonToolbar } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { McPageAlert } from 'src/Components'
import LoaderIcon from 'src/Components/LoaderIcon'
import McButton from 'src/Components/McButton'
import McModal from 'src/Components/Modals/McModal'
import { MySelect, MyTextbox } from 'src/Components/My'
import { t } from 'src/I18n'
import { MyRegExps } from 'src/Models/Client'
import IAppUserVTO from 'src/Models/Server/Response/User/IAppUserVTO'
import { appUsersGridStore } from '../Store'
import { addAppUserStore, IFormValues } from './Store'

interface IProps {
  onClose: () => void
  merchantId: number
}

interface IProps {}

const AddAppUserModal: React.FC<IProps> = ({ merchantId, onClose }) => {
  useEffect(() => {
    addAppUserStore.Initialize(merchantId)
  }, [null])

  const { ViewModel, IsSubmitting, CheckEmail, HasExistingUser, PageAlert } =
    addAppUserStore

  useEffect(() => {
    reset(ViewModel)
  }, [ViewModel])

  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({})
  const { handleSubmit, reset } = useFormProps

  if (ViewModel === undefined) {
    return null
  }

  const onSubmit = (data: IFormValues) => {
    const { CheckEmail } = addAppUserStore

    if (CheckEmail) {
      addAppUserStore.GetUserByEmail(data)
    } else {
      addAppUserStore.AddAppUser(data).then(() => {
        onClose()
      })
    }
  }

  const buttonText = CheckEmail ? t.addAppUserModal.checkEmail : t.common.save

  const renderOtherFormFields = () => {
    if (!CheckEmail) {
      return (
        <Fragment>
          <MyTextbox
            formObject={ViewModel}
            label={t.addAppUserModal.fields.Name.label}
            name="Name"
            useFormProps={useFormProps}
            rules={{
              required: t.addAppUserModal.fields.Name.errors.required,
            }}
            maxLength={50}
            placeholder="Jan Jansen"
          />
          {!HasExistingUser && (
            <MyTextbox
              formObject={ViewModel}
              label={t.addAppUserModal.fields.Password.label}
              name="Pincode"
              useFormProps={useFormProps}
              rules={{
                required: t.addAppUserModal.fields.Password.errors.required,
              }}
              maxLength={4}
              placeholder="0000"
            />
          )}
        </Fragment>
      )
    }

    return null
  }
  return (
    <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
      <McModal
        title={t.merchant.details.appUsers.addUser}
        closeOnBackdrop={false}
        footer={
          <div>
            <McPageAlert pageAlert={PageAlert} />

            <ButtonToolbar className="pull-right">
              <McButton onClick={() => onClose()}>{t.common.cancel}</McButton>
              <McButton
                bsStyle="primary"
                type="submit"
                isLoading={IsSubmitting}
              >
                {buttonText}
              </McButton>
            </ButtonToolbar>
          </div>
        }
        closeClicked={() => onClose()}
      >
        <MyTextbox
          formObject={ViewModel}
          label={t.addAppUserModal.fields.Email.label}
          name="Email"
          useFormProps={useFormProps}
          rules={{
            required: t.addAppUserModal.fields.Email.errors.required,
            pattern: {
              value: MyRegExps.EmailAdress,
              message: t.addAppUserModal.fields.Email.errors.regex,
            },
          }}
          maxLength={100}
          placeholder="jj@multicard.nl"
          tabIndex={1}
        />
        {renderOtherFormFields()}
      </McModal>
    </form>
  )
}

export default observer(AddAppUserModal)
