import React from 'react'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import { MainPage } from 'src/Components/My'
import { t } from 'src/I18n'
import { useInsertConsumerSubscriptionMutation } from 'src/Services/ToprCoreApi/RTK/Slices/SubscriptionSlice'
import SubscriptionForm from '../Components/Subscription/SubscriptionForm'

const SubscriptionInsert: React.FC = () => {
  const [insertSubscription, { isLoading }] =
    useInsertConsumerSubscriptionMutation()

  return (
    <MainPage>
      <BreadCrumbs
        currentPageTitle={t.subscription.insert.title}
        previousPages={[
          {
            to: `/subscriptions`,
            title: t.subscription.index.title,
          },
        ]}
      />
      <SubscriptionForm
        isSubmitting={isLoading}
        onSave={(data) => {
          insertSubscription({
            Name: data.Name,
            SystemName: data.SystemName,
          })
        }}
        title={t.subscription.insert.title}
      />
    </MainPage>
  )
}

export default SubscriptionInsert
