import { action, makeObservable, observable, runInAction } from 'mobx'
import { ISelectOption } from 'src/Models/Client'
import IGenericError from 'src/Models/Server/IGenericError'
import ManagerUserController from 'src/Services/ToprCoreApi/Axios/Controllers/ManagerUserController'
import { persistedStore } from 'src/Stores/PersistedStore'
import BaseStore from 'src/Stores/BaseStore'
import { appUiStore } from 'src/Stores/AppUiStore'
import { NavigateFunction } from 'react-router-dom'

export interface IFormValues {
  SelectedManagerUserOption: ISelectOption
}

export default class Store extends BaseStore {
  public ShowManagerUserModal = false
  public ManagerUserOptions: ISelectOption[] = []

  constructor() {
    super()
    makeObservable(this, {
      ShowManagerUserModal: observable,
      ManagerUserOptions: observable,
      SwitchManagerUser: action,
      ToggleManagerUserModal: action,
    })
  }

  public SwitchManagerUser(userGuid: string, navigate: NavigateFunction) {
    this.IsSubmitting = true
    persistedStore
      .SwitchSelectedManagerUser(userGuid)
      .then(() => {
        runInAction(() => {
          this.IsSubmitting = false
          this.ShowManagerUserModal = false
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => {
          this.IsSubmitting = false
          this.ShowManagerUserModal = false
        })
        appUiStore.ShowError(error)
      })
  }

  public ToggleManagerUserModal(showManagerUserModal: boolean) {
    this.ShowManagerUserModal = showManagerUserModal

    if (showManagerUserModal) {
      this.IsLoading = true
      ManagerUserController.GetManagerUsersForUser(
        persistedStore.AuthenticateUserResponse.AuthenticatedUserGuid
      )
        .then((managerUsers) => {
          runInAction(() => {
            this.ManagerUserOptions = managerUsers.map((nameValue) => {
              return { value: nameValue.Id, label: nameValue.Name }
            })
            this.IsLoading = false
          })
        })
        .catch((error: IGenericError) => {
          runInAction(() => (this.IsLoading = false))
          appUiStore.ShowError(error)
        })
    }
  }
}

export const mainPageStore = new Store()
