import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { LoadContent } from 'src/Components'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import { MainPage, MyDataTable } from 'src/Components/My'
import { t } from 'src/I18n'
import { IRefundDto } from 'src/Models/Server/Response/Refund/RefundDto'
import { merchantRefundDetailsStore } from './Store'

const Details: React.FC = () => {
  const params = useParams<'id'>()
  useEffect(() => {
    merchantRefundDetailsStore.Initialize(params.id)
  }, [null])

  const { MerchantRefundFileDetails, IsLoading } = merchantRefundDetailsStore

  const columns = [
    {
      dataField: 'account',
      isDummyField: true,
      text: t.merchantRefunds.refunds,
      sort: false,
      formatter: (cell, data: IRefundDto) => {
        return (
          <Row>
            <Col md={6}>
              <div>
                <label>{t.merchantRefunds.amountText}</label>
                <span>{data.Amount}</span>
              </div>

              <div>
                <label>{t.merchantRefunds.accountNumber}</label>
                <span>{data.AccountIban}</span>
              </div>
            </Col>
            <Col md={6}>
              <div>
                <label>{t.common.email}</label>
                <span>{data.EmailAddress}</span>
              </div>
              <div>
                <label>{t.merchantRefunds.accountName}</label>
                <Link to={`/user/details/${data.UserGuid}`}>
                  {data.AccountName}
                </Link>
              </div>
            </Col>
          </Row>
        )
      },
    },
  ]

  return (
    <MainPage>
      <BreadCrumbs
        currentPageTitle={`${t.common.details} ${params.id}`}
        previousPages={{
          title: t.merchantRefunds.title,
          to: '/merchantrefunds',
        }}
      />
      <Portlet title={t.merchantRefunds.title}>
        <LoadContent IsLoading={IsLoading}>
          {MerchantRefundFileDetails !== undefined && (
            <MyDataTable
              data={MerchantRefundFileDetails.Refunds}
              columns={columns}
            />
          )}
        </LoadContent>
      </Portlet>
    </MainPage>
  )
}

export default observer(Details)
