import { action, makeObservable, observable, runInAction } from 'mobx'
import { ISelectOption } from 'src/Models/Client'
import { GetCurrentMonthOption, GetCurrentYear } from 'src/Helpers/DateHelpers'
import { IMonthYear } from 'src/Models/Client'
import IGenericError from 'src/Models/Server/IGenericError'
import { SelectOptionsFromNameGuidVTO } from 'src/Models/Server/INameGuidVTO'
import MerchantController from 'src/Services/ToprCoreApi/Axios/Controllers/MerchantController'
import ReportController from 'src/Services/ToprCoreApi/Axios/Controllers/ReportController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export interface IFormValues {
  SelectedMonthYear: IMonthYear
  SelectedMerchant: ISelectOption
}

export class Store extends BaseStore<IFormValues> {
  public MerchantOptions: ISelectOption[] = []

  constructor() {
    super()
    makeObservable(this, {
      MerchantOptions: observable,
      Initialize: action,
      GetFilter: action,
      GetSiedlerGebuhrCurrency: action,
    })
  }

  public Initialize() {
    this.ViewModel = undefined
    this.MerchantOptions = []

    this.GetFilter()
  }

  public GetFilter() {
    this.IsLoading = true

    MerchantController.GetMyMerchants()
      .then((merchants) => {
        runInAction(() => {
          const merchantOptions = SelectOptionsFromNameGuidVTO(merchants)

          this.ViewModel = {
            SelectedMonthYear: {
              Month: GetCurrentMonthOption().value,
              Year: GetCurrentYear().toString(),
            },
            SelectedMerchant:
              merchantOptions.length > 0 ? merchantOptions[0] : undefined,
          }

          this.MerchantOptions = merchantOptions
          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsLoading = false))
        appUiStore.ShowError(error)
      })
  }

  public GetSiedlerGebuhrCurrency(data: IFormValues) {
    this.IsSubmitting = true
    ReportController.GetSiedlerBearbeitungsGebuhr(
      data.SelectedMonthYear,
      data.SelectedMerchant
    )
      .then(() => {
        runInAction(() => (this.IsSubmitting = false))
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsSubmitting = false))
        appUiStore.ShowError(error)
      })
  }
}

export const bearbeitungsGebuhrStore = new Store()
