import { action, makeObservable, observable } from 'mobx'

import { ISelectOption } from 'src/Models/Client'
import { GetLastMonthsOptions, GetToday } from 'src/Helpers/DateHelpers'
import { t } from 'src/I18n'
import { IDateRange } from 'src/Models/Client'
import BaseStore from 'src/Stores/BaseStore'

export interface IFormValues {
  SelectedMonth: ISelectOption

  DateRange: IDateRange
  //  StartDate: any
  //  EndDate: any
}

export default class Store extends BaseStore<IFormValues> {
  public ShowCustomDate: boolean = false
  public Months: ISelectOption[] = []

  constructor() {
    super()

    makeObservable(this, {
      Months: observable,
      ShowCustomDate: observable,
      Initialize: action,
      SetShowCustomDate: action,
    })
  }

  public Initialize() {
    const today = new Date()
    this.Months = GetLastMonthsOptions(5)
    this.Months.push({ label: t.common.custom, value: 'custom' })
    this.ViewModel = {
      DateRange: {
        StartDate: new Date(today.getFullYear(), today.getMonth(), 1),
        EndDate: GetToday(),
      },
      SelectedMonth: this.Months[0],
    }
  }

  public SetShowCustomDate(show: boolean) {
    this.ShowCustomDate = show
  }

  // public HandleMonthChange(value: ISelectOption) {
  //   this.HandleChange(value, 'SelectedMonth')

  //   if (this.ViewModel.SelectedMonth.value === 'custom') {
  //     //show custom date selector
  //     this.ShowCustomDate = true
  //   } else {
  //     this.ShowCustomDate = false
  //     const startDate = new Date(this.ViewModel.SelectedMonth.value)
  //     const endDate = moment(startDate).endOf('month').toDate()
  //     this.HandleChange(startDate, 'StartDate')
  //     this.HandleChange(endDate, 'EndDate')
  //   }
  // }
}
export const monthPeriodStore = new Store()
