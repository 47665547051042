import { getTenant } from './Tenants/Tenant'
import { GetBaseUrl } from './Helpers/ServiceHelpers'
import FileDTO from './Models/Server/File/FileDTO'

const tenant = getTenant()

const images = {
  Logo: require(`./Tenants/${tenant.SystemName}/Images/logo.png`),

  Flags: {
    NL: require('./Assets/Images/Flags/icon-flag-NL.png'),
    EN: require('./Assets/Images/Flags/icon-flag-EN.png'),
    DE: require('./Assets/Images/Flags/icon-flag-DE.png'),
    FR: require('./Assets/Images/Flags/icon-flag-FR.png'),
  },
  Stamp: require('./Assets/Images/stamp.png'),
}

export default images

export const CleanGuid = (id: string) => {
  return id.replace('{', '').replace('}', '')
}

export const GetFileUrl = (guid: string) => {
  const url = GetBaseUrl() + `/api/content/v1/file/${CleanGuid(guid)}`

  return url
}

export const GetFileIdUrl = (fileId: number) => {
  return GetBaseUrl() + `/api/content/v1/file/data/${fileId}`
}

export const GetImageFileUrl = (guid: string) => {
  const rand = Math.floor(Math.random() * 1000)
  //api/content/v1/file/{fileguid}
  return GetBaseUrl() + `/api/content/v1/file/${CleanGuid(guid)}?rand=${rand}`
}

export const GetLocalizedImageFileUrl = (guid: string) => {
  return GetBaseUrl() + `/api/content/v1/localizedimage/${CleanGuid(guid)}`
}

export const GetLocalizedImageFileIdUrl = (id: string) => {
  return GetBaseUrl() + `/api/content/v1/localizedimage/id/${id}`
}

export const Base64ImageUrl = (file: FileDTO) => {
  return `data:${file.MimeType};base64,${file.Data}`
}
