import { action, makeObservable, observable, runInAction } from 'mobx'

import { ISelectOption } from 'src/Models/Client'
import { TransactionSearchType } from 'src/Models/Server/Enums'
import IGenericError from 'src/Models/Server/IGenericError'
import { ITransactionExtendedSTO } from 'src/Models/Server/Response/Transaction/ITransactionExtendedSTO'
import CurrencyController from 'src/Services/ToprCoreApi/Axios/Controllers/CurrencyController'
import TransactionController from 'src/Services/ToprCoreApi/Axios/Controllers/TransactionController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export interface IFormValues {
  Search: string
  SearchType: ISelectOption
  SelectedCurrency: ISelectOption
}

export default class Store extends BaseStore<IFormValues> {
  public Transactions: ITransactionExtendedSTO[] = []
  public Currencies: ISelectOption[] = []
  public SearchTypes: ISelectOption[] = [
    { value: TransactionSearchType.Reference.toString(), label: 'Reference' },
    { value: TransactionSearchType.Guid.toString(), label: 'Guid' },
  ]

  constructor() {
    super()

    this.BaseInitialize()

    makeObservable(this, {
      Transactions: observable,
      Currencies: observable,
      FindTransaction: action,
      GetMyMerchantCurrencies: action,
      Initialize: action,
    })
  }

  public Initialize() {
    this.BaseInitialize()
    this.IsLoading = false
    this.IsSubmitting = false
    this.Transactions = []
    this.GetMyMerchantCurrencies()
  }

  public GetMyMerchantCurrencies() {
    CurrencyController.GetMyMerchantCurrencies()
      .then((currencies) => {
        runInAction(() => {
          this.Currencies = currencies.map((cur) => ({
            label: cur.Name,
            value: cur.CurrencyGuid,
          }))

          this.ViewModel = {
            Search: '',
            SearchType: this.SearchTypes[0],
            SelectedCurrency: this.Currencies[0],
          }
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        runInAction(() => (this.IsSubmitting = false))
      })
  }

  public FindTransaction(
    search: string,
    searchType: string,
    currencyGuid: string
  ) {
    if (search !== '') {
      this.IsSubmitting = true
      TransactionController.Find(search, currencyGuid, searchType)
        .then((transaction) => {
          runInAction(() => {
            if (transaction !== null) {
              this.Transactions = [transaction]
            }
            this.IsSubmitting = false
          })
        })
        .catch((error: IGenericError) => {
          //console.log(error)
          //appUiStore.ShowError(error)
          runInAction(() => (this.IsSubmitting = false))
        })
    }
  }
}

export const transactionStore = new Store()
