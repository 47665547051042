import React, { PropsWithChildren } from 'react'
import { Col, Row } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Path, useController, UseFormReturn } from 'react-hook-form'
import { t } from 'src/I18n'
import { IDateRange } from 'src/Models/Client'
import { MyLabel } from '..'

interface IProps<T> {
  name: Path<T>
  formObject: T
  useFormProps: UseFormReturn<T>

  //date range
  onDateRangeChange?: (daterange: IDateRange) => void
}

const MyDateRangePicker = <ObjectType,>({
  name,
  useFormProps,
  onDateRangeChange,
}: PropsWithChildren<IProps<ObjectType>>) => {
  const { control } = useFormProps

  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
  })

  if (value === undefined) {
    return null
  }

  const dateRange = value as IDateRange
  const startDate = dateRange.StartDate
  const endDate = dateRange.EndDate

  return (
    <div className="form-group">
      <>
        <Row>
          <Col md={6}>
            <MyLabel htmlFor={name}>{t.common.from}</MyLabel>
            <DatePicker
              selectsStart
              selected={startDate}
              startDate={startDate}
              endDate={endDate}
              maxDate={endDate}
              locale={t.getLanguage()}
              className="form-control"
              dateFormat="dd-MM-yyyy"
              autoComplete="off"
              onChange={(e) => {
                const newValue = e !== null ? new Date(e) : null

                if (newValue !== null && newValue <= (endDate as Date)) {
                  const newDateRange: IDateRange = {
                    StartDate: newValue,
                    EndDate: endDate,
                  }
                  onChange(newDateRange) //react-form-hook state

                  if (onDateRangeChange) {
                    onDateRangeChange(newDateRange) //component change
                  }
                }
              }}
            />
          </Col>
          <Col md={6}>
            <label>{t.common.till}</label>
            <DatePicker
              selectsEnd
              selected={endDate}
              minDate={startDate}
              startDate={startDate}
              endDate={endDate}
              locale={t.getLanguage()}
              className="form-control"
              dateFormat="dd-MM-yyyy"
              autoComplete="off"
              onChange={(e) => {
                const newValue = e !== null ? new Date(e) : null

                if (newValue !== null && newValue >= (startDate as Date)) {
                  const newDateRange: IDateRange = {
                    StartDate: startDate,
                    EndDate: newValue,
                  }
                  onChange(newDateRange) //react-form-hook state
                  if (onDateRangeChange) {
                    onDateRangeChange(newDateRange) //component change
                  }
                }
              }}
            />
          </Col>
        </Row>
      </>
    </div>
  )
}

export default MyDateRangePicker
