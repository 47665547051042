import IGenericError from 'src/Models/Server/IGenericError'

import { IEventVTO } from 'src/Models/Server/Response/Event/IEventVTO'
import EventController from 'src/Services/ToprCoreApi/Axios/Controllers/EventController'
import { appUiStore } from 'src/Stores/AppUiStore'

export interface IState {
  events: IEventVTO[]
  isLoading: boolean
}

export const initialState: IState = {
  events: [],
  isLoading: true,
}

export type Actions = {
  type: 'initialized'
  events: IEventVTO[]
}

export const reducer = (state: IState, action: Actions): IState => {
  switch (action.type) {
    case 'initialized':
      return {
        ...state,
        events: action.events,
        isLoading: false,
      }
    default:
      throw new Error('Invalid Reducer Action')
  }
}

export const reducerActions = (
  state: IState,
  dispatch: (action: Actions) => void
) => {
  //

  return {
    getEvents: () => {
      EventController.GetEvents()
        .then((events) => {
          dispatch({ type: 'initialized', events })
        })
        .catch((error: IGenericError) => {
          appUiStore.ShowError(error)
        })
    },
  }
}
