import React, { useEffect, useReducer } from 'react'
import { Button, ButtonToolbar, Col, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { Card, LoaderIcon, McButton } from 'src/Components'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import { MainPage } from 'src/Components/My'
import { GetLocalText } from 'src/Helpers/TextHelpers'
import { GetFileIdUrl } from 'src/Images'
import { t } from '../../../../I18n'
import { initialState, reducer, reducerActions } from './Reducer'

const EventIndex: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const actions = reducerActions(state, dispatch)
  const { isLoading, events } = state
  useEffect(() => {
    actions.getEvents()
  }, [null])

  const navigate = useNavigate()

  const renderContent = () => {
    if (isLoading) {
      return <LoaderIcon IsLoading={isLoading} />
    }

    const renderEventCards = () => {
      return (
        <Row>
          {events.map((event, key) => {
            const name = GetLocalText(event.Name)
            return (
              <Col md={4} key={key}>
                <Card>
                  <Card.CardImageHeader
                    imageUrl={GetFileIdUrl(event.ImageFileId)}
                  />
                  <Card.FooterTitle>
                    <h4>{name}</h4>
                  </Card.FooterTitle>
                  <Card.FooterActions>
                    <Link to={`/event/details/${event.EventId}`}>
                      {t.common.details}
                    </Link>

                    <Link to={`/event/edit/${event.EventId}`}>
                      {t.common.edit}
                    </Link>
                  </Card.FooterActions>
                </Card>
              </Col>
            )
          })}
        </Row>
      )
    }

    return (
      <Portlet
        title={t.event.index.title}
        headerContent={
          <ButtonToolbar className="pull-right">
            <McButton
              iconLeft="fa-plus"
              bsStyle="primary"
              bsSize="xs"
              title={t.event.insert.title}
              onClick={() => navigate(`/event/insert`)}
            >
              {t.common.add}
            </McButton>
          </ButtonToolbar>
        }
      >
        {renderEventCards()}
      </Portlet>
    )
  }
  return (
    <MainPage>
      <BreadCrumbs currentPageTitle={t.event.index.title} />
      {renderContent()}
    </MainPage>
  )
}

export default EventIndex
