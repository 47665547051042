import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { toast } from 'react-toastify'
import { t } from 'src/I18n'
import IGenericError from 'src/Models/Server/IGenericError'
import IUserFieldVTO from 'src/Models/Server/Response/Field/IUserFieldVTO'
import { ITagVTO } from 'src/Models/Server/Response/Tag/ITagVTO'
import { ITokenSTO } from 'src/Models/Server/Response/Token/ITokenSTO'
import IHelpDeskUserVTO from 'src/Models/Server/Response/User/IHelpDeskUserVTO'
import { IUserConnectionVTO } from 'src/Models/Server/Response/User/IUserConnectionVTO'
import TagController from 'src/Services/ToprCoreApi/Axios/Controllers/TagController'
import TokenController from 'src/Services/ToprCoreApi/Axios/Controllers/TokenController'
import UserController from 'src/Services/ToprCoreApi/Axios/Controllers/UserController'
import UserFieldController from 'src/Services/ToprCoreApi/Axios/Controllers/UserFieldController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'
import { usersStore } from '../../../Index/Store'

export interface IFormValues {}

export class Store extends BaseStore<IFormValues> {
  public UserGuid: string = ''
  public User: IHelpDeskUserVTO = null
  public ShowEditUserNameModal: boolean = false
  public ShowEditNameModal: boolean = false
  public ShowEditEmailModal: boolean = false
  public ShowEditPasswordModal: boolean = false
  public RemovingConsumerUser: boolean = false
  public UserFields: IUserFieldVTO[] = []
  public UserConnections: IUserConnectionVTO[] = []
  public UserFieldsLoading: boolean = false
  public Tokens: ITokenSTO[] = []
  public UserTags: ITagVTO[] = []
  public IsUserTagsLoading = false

  public get ChildUserConnections(): IUserConnectionVTO[] {
    if (this.User === null) {
      return []
    }
    return this.UserConnections.filter(
      (x) => x.Parent.Id === this.User.UserGuid
    )
  }

  public get ParentUserConnections(): IUserConnectionVTO[] {
    if (this.User === null) {
      return []
    }
    return this.UserConnections.filter((x) => x.Child.Id === this.User.UserGuid)
  }

  constructor() {
    super()
    makeObservable(this, {
      UserGuid: observable,
      User: observable,
      ShowEditUserNameModal: observable,
      ShowEditNameModal: observable,
      ShowEditPasswordModal: observable,
      ShowEditEmailModal: observable,
      RemovingConsumerUser: observable,
      Tokens: observable,
      UserFields: observable,
      UserConnections: observable,
      UserFieldsLoading: observable,
      HandleUserFieldCheckBoxChange: observable,
      IsUserTagsLoading: observable,
      UserTags: observable,
      ChildUserConnections: computed,
      ParentUserConnections: computed,
      Initialize: action,
      GetHelpdeskUser: action,
      ToggleEditUserNameModal: action,
      ToggleEditNameModal: action,
      ToggleEditEmailModal: action,
      ToggleEditPasswordModal: action,
      SetUser: action,
      GetUserConnections: action,
      RemoveMerchantConsumer: action,
      GetUserFields: action,
      GetTokensUser: action,
      SetTokenBlocked: action,
      GetUserTags: action,
    })
  }

  public Initialize(userGuid: string) {
    this.BaseInitialize()
    //this.User = null
    this.UserGuid = userGuid
    //get user
    //this.RemovingConsumerUser = false

    this.GetHelpdeskUser(userGuid)
  }

  public GetHelpdeskUser(userGuid: string) {
    this.IsLoading = true

    this.UserTags = []
    this.GetUserTags(userGuid)
    UserController.GetHelpdeskUser(userGuid)
      .then((user) => {
        this.GetUserFields(user.UserId)
        this.GetUserConnections(user.UserId)
        this.GetTokensUser()

        runInAction(() => {
          this.User = user
          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        runInAction(() => (this.IsLoading = false))
      })
  }

  public GetUserTags(userGuid: string) {
    this.IsUserTagsLoading = true
    TagController.GetMerchantUserTags(userGuid)
      .then((userTags) => {
        runInAction(() => {
          this.UserTags = userTags
          this.IsUserTagsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
      })
  }

  public GetUserConnections(userId: number) {
    this.UserConnections = []
    UserController.GetUserConnections(userId)
      .then((result) => {
        runInAction(() => (this.UserConnections = result))
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
      })
  }

  public GetUserFields(userId: number) {
    this.UserFieldsLoading = true
    this.UserFields = []
    UserFieldController.GetUserFields(userId)
      .then((userFields) => {
        runInAction(() => {
          this.UserFields = userFields
          this.UserFieldsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        runInAction(() => (this.UserFieldsLoading = false))
      })
  }

  public HandleUserFieldCheckBoxChange(userField: IUserFieldVTO, event) {
    const target = event.currentTarget //check if ok and not .target

    const user = { ...this.User }
    const value = target.checked ? 'True' : 'False'

    const userFieldIndex = this.UserFields.findIndex(
      (uf) => uf.Field.FieldGuid === userField.Field.FieldGuid
    )
    if (userFieldIndex > -1) {
      UserFieldController.SaveUserField({
        UserGuid: user.UserGuid,
        FieldGuid: this.UserFields[userFieldIndex].Field.FieldGuid,
        Value: value,
      })
        .then((userFieldVto) => {
          runInAction(() => {
            this.UserFields[userFieldIndex] = userFieldVto
            this.User = user
          })
          toast.success(
            t.formatString(t.userDetails.saveUserFieldSuccess, {
              fieldName: userFieldVto.Field.Title,
            })
          )
        })
        .catch((error: IGenericError) => {
          appUiStore.ShowError(error)
          runInAction(() => (this.IsLoading = false))
        })
    }
  }

  public GetTokensUser() {
    this.Tokens = []
    TokenController.GetTokensUser(this.UserGuid)
      .then((tokens) => {
        runInAction(() => (this.Tokens = tokens))
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
      })
  }

  public SetTokenBlocked(tokenGuid: string, blocked: boolean) {
    const token = this.Tokens.find((x) => x.TokenGUID === tokenGuid)
    if (token) {
      token.IsBlocked = blocked

      this.Tokens = [...this.Tokens]
    }
  }

  public RemoveMerchantConsumer(userId: number) {
    this.RemovingConsumerUser = true

    UserController.RemoveMerchantConsumerUser(userId)
      .then(() => {
        usersStore.Initialize()
        window.close()
        toast.success(
          t.formatString(t.siedlerRemove.success, {
            name: this.User.Name,
          })
        )
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
      })
  }

  public ToggleEditUserNameModal() {
    this.ShowEditUserNameModal = !this.ShowEditUserNameModal
  }

  public ToggleEditNameModal() {
    this.ShowEditNameModal = !this.ShowEditNameModal
  }

  public ToggleEditEmailModal() {
    this.ShowEditEmailModal = !this.ShowEditEmailModal
  }

  public ToggleEditPasswordModal() {
    this.ShowEditPasswordModal = !this.ShowEditPasswordModal
  }

  public SetUser(user: IHelpDeskUserVTO) {
    this.User = user
  }
}

export const userInfoStore = new Store()
