import React, { Fragment, PropsWithChildren, useState } from 'react'
import { ButtonToolbar } from 'react-bootstrap'
import 'react-datepicker/dist/react-datepicker.css'
import Dropzone from 'react-dropzone'
import {
  Path,
  RegisterOptions,
  useController,
  UseFormReturn,
} from 'react-hook-form'
import McButton from 'src/Components/McButton'
import { McModal } from 'src/Components/Modals'
import { t } from 'src/I18n'
import { MyLabel } from '..'

interface IProps<T> {
  name: Path<T>
  formObject: T
  useFormProps: UseFormReturn<T>
  rules?: RegisterOptions
  //date range
  onChangeFile: (files: File[] | null) => void
  label?: string
  infoText?: string
  dropFileText: string
  accept?: string | string[]
}

const MyDropzone = <ObjectType,>({
  name,
  useFormProps,
  rules,
  accept,
  label,
  infoText,
  dropFileText,
  onChangeFile,
}: PropsWithChildren<IProps<ObjectType>>) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormProps

  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
    rules,
  })

  const [previewFile, setPreviewFile] = useState<File>(null)

  const errorMessage =
    errors !== undefined && errors[name.toString()] !== undefined
      ? errors[name.toString()].message
      : undefined
  const required = rules !== undefined && rules.required !== undefined
  const hasError = errorMessage !== undefined

  const errorText = () => {
    if (!hasError) {
      return null
    }
    return (
      <ul className="parsley-error-list filled">
        <li className="parsley-required">{errorMessage}</li>
      </ul>
    )
  }

  const renderPreview = () => {
    if (previewFile === null) {
      return null
    }

    return (
      <McModal
        closeClicked={() => {
          setPreviewFile(null)
        }}
        title={t.common.preview}
        size="lg"
      >
        <img src={URL.createObjectURL(previewFile)} className="preview" />
      </McModal>
    )
  }

  return (
    <>
      {label && (
        <MyLabel htmlFor={name} isRequired={required} infoText={infoText}>
          {label}
        </MyLabel>
      )}
      <Dropzone
        accept={accept}
        multiple={false}
        onDropAccepted={(files: File[]) => {
          onChange(files)
          onChangeFile(files)
        }}
      >
        {({ getRootProps, getInputProps, acceptedFiles }) => {
          const acceptedFilesItems = acceptedFiles.map((file) => {
            if (value === null) {
              return null
            }

            const isImageFile = file.type.indexOf('image') > -1
            return (
              <Fragment key={file.name}>
                {isImageFile && <img src={URL.createObjectURL(file)} />}
                <span>
                  {file.name} - {file.size} bytes
                </span>
                <ButtonToolbar>
                  <McButton
                    icon="fa-trash"
                    bsStyle="danger"
                    onClick={(e) => {
                      e.stopPropagation()
                      //console.log(name)
                      setValue(name, null)
                      onChangeFile(null)
                      //setPreviewFile(file)
                    }}
                  >
                    {t.common.delete}
                  </McButton>
                  {isImageFile && (
                    <McButton
                      onClick={(e) => {
                        e.stopPropagation()
                        setPreviewFile(file)
                      }}
                    >
                      {t.common.preview}
                    </McButton>
                  )}
                </ButtonToolbar>
              </Fragment>
            )
          })

          return (
            <section>
              <div {...getRootProps()} className="dropzone">
                <input name={name} id={name} {...getInputProps()} />
                <p>{dropFileText}</p>
                {acceptedFilesItems}
              </div>
            </section>
          )
        }}
      </Dropzone>
      {errorText()}
      {renderPreview()}
    </>
  )
}

export default MyDropzone
