import React, { ReactNode } from 'react'

interface IProps {
  children?: ReactNode | undefined
}

export type CardActionsType = React.FC<IProps>

const CardActions: CardActionsType = ({ children }) => {
  return <div className="footer-actions">{children}</div>
}

export default CardActions
