import React from 'react'
import { Panel } from 'react-bootstrap'
import FlexRow from 'src/Components/Authenticated/FlexRow'
import { formatCurrency } from 'src/Helpers/CurrencyHelpers'
import { GetLocalizedImageFileIdUrl } from 'src/Images'
import { ISavingCardRewardDTO } from 'src/Models/Server/Response/SavingCard/ISavingCardRewardDTO'
import CircleStat from '../../../../../Components/Authenticated/CircleStat'
import { t } from '../../../../../I18n'

interface IProps {
  savingCardReward: ISavingCardRewardDTO
}

const CurrencRewardPortlet: React.FC<IProps> = ({ savingCardReward }) => {
  if (
    savingCardReward.Currency === null ||
    savingCardReward.Currency === undefined
  ) {
    return null
  }

  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title componentClass="h4">
          {savingCardReward.Currency.Description}
        </Panel.Title>
      </Panel.Heading>
      <Panel.Body className="nopad">
        <FlexRow>
          <div
            className="bgimg min-y-300  f-col-md-6"
            style={{
              backgroundImage: `url(${GetLocalizedImageFileIdUrl(
                savingCardReward.Currency.AppPhotoLocalizedImageId
              )})`,
            }}
          ></div>
          <div className="circle-stats even p-xy-15 f-col-md-6">
            <div className="circle-stat-row">
              <CircleStat
                stat={formatCurrency(
                  savingCardReward.Amount,
                  savingCardReward.Currency.IsDecimal,
                  savingCardReward.Currency.StringFormat
                )}
                iconText="€"
              />
              <span>{t.common.sum}</span>
            </div>
          </div>
        </FlexRow>
      </Panel.Body>
    </Panel>
  )
}

export default CurrencRewardPortlet
