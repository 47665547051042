import { action, makeObservable, runInAction } from 'mobx'
import { toast } from 'react-toastify'
import { createPageAlert } from 'src/Components/McPageAlert'
import {
  CentsToCurrencyAmount,
  CentsToEuroAmount,
} from 'src/Helpers/CurrencyHelpers'
import { t } from 'src/I18n'
import { VoucherTypes } from 'src/Models/Server/Enums'
import IGenericError from 'src/Models/Server/IGenericError'
import { SavingCardRewardTypes } from 'src/Models/Server/Response/SavingCard/SavingCardRewardTypes'
import SavingCardController from 'src/Services/ToprCoreApi/Axios/Controllers/SavingCardController'
import { IFormValues, SavingStore } from '../Insert/Store'

class EditSavingStore extends SavingStore {
  constructor() {
    super()

    makeObservable(this, {
      InitializeEdit: action,
      EditSavingCard: action,
    })
  }

  public InitializeEdit(id: string) {
    this.GetMerchantCurrencies()
      .then(() => {
        SavingCardController.GetSavingCardEdit(id)
          .then((savingCardEdit) => {
            const selectedRewardOption = this.RewardOptions.find(
              (x) => x.value === savingCardEdit.RewardType.toString()
            )

            runInAction(() => {
              this.SelectedCurrency = this.Currencies.find(
                (x) => x.CurrencyGuid === savingCardEdit.CurrencyGuid
              )

              //only when currency reward
              const displayRewardAmount =
                savingCardEdit.RewardType === SavingCardRewardTypes.Currency
                  ? CentsToCurrencyAmount(
                      savingCardEdit.RewardAmount,
                      this.SelectedCurrency.IsDecimal,
                      this.SelectedCurrency.StringFormat
                    )
                  : ''
              const selectedCurrencyOption =
                savingCardEdit.RewardType === SavingCardRewardTypes.Currency
                  ? this.CurrencyOptions.find(
                      (x) => x.value === savingCardEdit.CurrencyGuid
                    )
                  : this.CurrencyOptions[0]

              //only when coupon reward
              const displayDiscountAmount =
                savingCardEdit.RewardType === SavingCardRewardTypes.Coupon
                  ? CentsToEuroAmount(savingCardEdit.DiscountAmount)
                  : ''
              const selectedVoucherTypeOption =
                savingCardEdit.RewardType === SavingCardRewardTypes.Coupon
                  ? this.VoucherTypeOptions.find(
                      (x) => x.value === savingCardEdit.CouponTypeId.toString()
                    )
                  : this.VoucherTypeOptions.find(
                      (x) => x.value === VoucherTypes.FreeProduct.toString()
                    )

              this.BaseInitialize({
                ...savingCardEdit,
                SelectedRewardOption: selectedRewardOption,
                SelectedCurrencyOption: selectedCurrencyOption,
                SelectedVoucherTypeOption: selectedVoucherTypeOption,
                DisplayDiscountAmount: displayDiscountAmount,
                DisplayRewardAmount: displayRewardAmount,
              })

              this.IsLoading = false
            })
          })
          .catch((error) => {
            runInAction(() => {
              this.PageAlert = createPageAlert(error)
              this.IsLoading = false
            })
          })
      })
      .catch((error: IGenericError) => {
        runInAction(() => {
          this.PageAlert = createPageAlert(error)
          this.IsLoading = false
        })
      })
  }

  public EditSavingCard(data: IFormValues) {
    this.SetRewardFormValues(data)

    this.IsSubmitting = true
    SavingCardController.Edit(data)
      .then(() => {
        toast.success(t.savingcardEdit.saveSuccess)
        runInAction(() => (this.IsSubmitting = false))
      })
      .catch((error: IGenericError) => {
        runInAction(() => {
          this.PageAlert = createPageAlert(error)
          this.IsSubmitting = false
        })
      })
  }
}

export const savingCardEditStore = new EditSavingStore()
