import { action, makeObservable, observable, runInAction } from 'mobx'
import { ISelectOption } from 'src/Models/Client'
import IGenericError from 'src/Models/Server/IGenericError'
import {
  SelectOptionsFromNameGuidVTO,
  SelectOptionsFromNameIdVTO,
} from 'src/Models/Server/INameGuidVTO'
import { ITotalTransactionAmountPerUserDTOnsPerDayDTO } from 'src/Models/Server/Response/Transaction/ITotalTransactionAmountPerUserDTOnsPerDayDTO'
import ReportController from 'src/Services/ToprCoreApi/Axios/Controllers/ReportController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export interface IFormValues {
  SelectedTokenType: ISelectOption
  SelectedCurrency: ISelectOption
  StartDate: Date
  EndDate: Date
}

export default class Store extends BaseStore<IFormValues> {
  public TokenTypes: ISelectOption[] = []
  public Currencies: ISelectOption[] = []

  public LoadingTransactions: boolean = false
  public Transactions: ITotalTransactionAmountPerUserDTOnsPerDayDTO[] = []

  //tabe defaults
  public Page = 1
  public TotalRows = 1
  public PageSize = 10
  public SortOrder = 'desc'
  public SortField = 'TotalAmountFormatted'

  constructor() {
    super()

    makeObservable(this, {
      TokenTypes: observable,
      Currencies: observable,
      LoadingTransactions: observable,
      Transactions: observable,
      Page: observable,
      TotalRows: observable,
      PageSize: observable,
      SortOrder: observable,
      SortField: observable,
      Initialize: action,
      SetPagination: action,
      SetSort: action,
      GetTransactionsPaged: action,
      ExportToCsv: action,
    })
  }

  /** Sets Initial state */

  public Initialize() {
    this.IsLoading = true
    ReportController.GetTokenTypeFilter()
      .then((response) => {
        runInAction(() => {
          const tokenTypesOptions = SelectOptionsFromNameIdVTO(
            response.TokenTypes
          )
          const currencyOptions = SelectOptionsFromNameGuidVTO(
            response.Currencies
          )
          this.TokenTypes = tokenTypesOptions
          this.Currencies = currencyOptions

          const today = new Date()
          this.ViewModel = {
            SelectedTokenType: tokenTypesOptions[0],
            SelectedCurrency: currencyOptions[0],
            StartDate: new Date(today.getFullYear(), today.getMonth(), 1),
            EndDate: new Date(),
          }

          this.GetTransactionsPaged(this.ViewModel)

          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsLoading = false))
        appUiStore.ShowError(error)
      })
  }

  public SetPagination(page: number, pageSize) {
    this.Page = page
    this.PageSize = pageSize
  }

  public SetSort(sortField: string, sortOrder: string) {
    this.SortField = sortField
    this.SortOrder = sortOrder
  }

  public GetTransactionsPaged(data: IFormValues, resetPaging: boolean = true) {
    if (resetPaging) {
      this.Page = 1
    }

    this.LoadingTransactions = true
    ReportController.GetCardPaymentTotals(
      data.SelectedTokenType.value,
      data.SelectedCurrency.value,
      data.StartDate,
      data.EndDate,
      this.Page,
      this.PageSize,
      this.SortField,
      this.SortOrder
    )
      .then((result) => {
        runInAction(() => {
          this.Transactions = result.Rows
          this.TotalRows = result.TotalRows
          this.LoadingTransactions = false

          //console.log('result', result)
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.LoadingTransactions = false))
        appUiStore.ShowError(error)
      })
  }

  public ExportToCsv(data: IFormValues) {
    ReportController.GetCardPaymentTotals(
      data.SelectedTokenType.value,
      data.SelectedCurrency.value,
      data.StartDate,
      data.EndDate,
      this.Page,
      this.PageSize,
      this.SortField,
      this.SortOrder,
      true
    )
      .then()
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
      })
  }
}

export const cardPaymentTotalsStore = new Store()
