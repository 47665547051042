import { action, makeObservable, runInAction } from 'mobx'
import IGenericError from 'src/Models/Server/IGenericError'
import { IFieldValueEditVTO } from 'src/Models/Server/Response/Field/IFieldValueEditVTO'
import FieldController from 'src/Services/ToprCoreApi/Axios/Controllers/FieldController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export class Store extends BaseStore<IFieldValueEditVTO> {
  constructor() {
    super()
    makeObservable(this, {
      Initialize: action,
      SaveFieldValue: action,
    })
  }

  public Initialize(fieldValueId: number) {
    this.IsLoading = true
    this.ViewModel = undefined
    if (fieldValueId) {
      FieldController.GetFieldValueEdit(fieldValueId)
        .then((fieldValueEdit) => {
          runInAction(() => {
            this.ViewModel = fieldValueEdit
            this.IsLoading = false
          })
        })
        .catch((error: IGenericError) => {
          runInAction(() => (this.IsLoading = false))
          appUiStore.ShowError(error)
        })
    }
  }

  public SaveFieldValue(data: IFieldValueEditVTO, onClose: () => void) {
    this.IsSubmitting = true
    FieldController.SaveFieldValueEdit(data)
      .then(() => {
        onClose()
        runInAction(() => {
          this.IsSubmitting = false
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsSubmitting = false))
        appUiStore.ShowError(error)
      })
  }
}

export const fieldValueEditStore = new Store()
