import { AxiosError } from 'axios'
import { handleException } from 'src/Helpers/ResponseHelpers'
import { IExceptionError } from 'src/Models/Server/Response/Exception/ExceptionError'
import { axiosAuth } from '../AuthAxios'

export default {
  UploadStorno(fileData: string, currencyCode: string) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        const url = `/api/v1/finance/storno`
        const data = {
          FileData: fileData,
          CurrencyCode: currencyCode,
        }
        axiosAuth
          .post(url, data)
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
}
