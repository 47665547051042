import React, { useEffect, useReducer } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { LoaderIcon, McButton } from 'src/Components'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import { InputMasks, MainPage, MySelect, MyTextbox } from 'src/Components/My'
import { t } from 'src/I18n'
import { IFormValues, initialState, reducer, reducerActions } from './Reducer'

const AddUserCoupon: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const actions = reducerActions(state, dispatch)

  useEffect(() => {
    actions.initialize()
  }, [null])

  const { isSubmitting, isLoading, coupons, viewModel } = state

  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>()
  const { handleSubmit, reset } = useFormProps

  const location = useLocation()

  const { ids } = location.state as { ids: number[] }

  useEffect(() => {
    reset(viewModel)
  }, [viewModel])

  const onSubmit = (data: IFormValues) => {
    actions.save(data, ids)
  }

  const renderContent = () => {
    if (viewModel === undefined) {
      return <LoaderIcon IsLoading={isLoading} />
    }

    return (
      <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
        <Portlet title={t.coupons.userCouponAdd.title}>
          <Row>
            <Col md={6}>
              <MyTextbox
                useFormProps={useFormProps}
                formObject={viewModel}
                name="amount"
                label={t.coupons.userCouponAdd.fields.amount.label}
                type="number"
                inputMask={InputMasks.Number}
                maxLength={2}
                rules={{
                  required: t.coupons.userCouponAdd.fields.amount.required,
                }}
              />
              <MySelect
                useFormProps={useFormProps}
                formObject={viewModel}
                name="couponOption"
                options={coupons}
                label={t.coupons.userCouponAdd.fields.coupon.label}
                rules={{
                  required: t.coupons.userCouponAdd.fields.coupon.required,
                }}
              />
              <McButton
                block
                bsStyle="primary"
                bsSize="large"
                type="submit"
                isLoading={isSubmitting}
              >
                {t.common.save}
              </McButton>
            </Col>
          </Row>
        </Portlet>
      </form>
    )
  }

  return (
    <>
      <MainPage>
        <BreadCrumbs currentPageTitle={t.coupons.userCouponAdd.title} />
        {renderContent()}
      </MainPage>
    </>
  )
}

export default AddUserCoupon
