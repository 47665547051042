import { action, makeObservable, runInAction } from 'mobx'
import IGenericError from 'src/Models/Server/IGenericError'
import ITerminalVTO from 'src/Models/Server/Response/Terminal/ITerminalVTO'
import TerminalController from 'src/Services/ToprCoreApi/Axios/Controllers/TerminalController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export default class Store extends BaseStore<ITerminalVTO[]> {
  constructor() {
    super()

    this.BaseInitialize([])

    makeObservable(this, {
      GetTerminalsByPos: action,
    })
  }

  public GetTerminalsByPos(posGuid: string) {
    this.IsLoading = true
    TerminalController.GetTerminalsByPos(posGuid)
      .then((terminals) => {
        runInAction(() => {
          this.ViewModel = terminals
          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        runInAction(() => (this.IsLoading = false))
      })
  }
}

export const terminalsGrid = new Store()
