import { observer } from 'mobx-react'
import React from 'react'
import { useParams } from 'react-router-dom'
import { LoaderIcon } from 'src/Components'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import { MainPage } from 'src/Components/My'
import { t } from 'src/I18n'
import UserScheduleBonusForm from '../Components/UserScheduleBonusForm'

const Edit: React.FC = () => {
  const params = useParams<'userGuid' | 'userScheduleBonusId'>()

  return (
    <>
      <MainPage>
        <BreadCrumbs
          currentPageTitle={t.userScheduleBonus.edit.title}
          previousPages={[
            {
              to: `/user/details/${params.userGuid}/planning`,
              title: t.common.user,
            },
          ]}
        />
        <UserScheduleBonusForm
          title={t.userScheduleBonus.edit.title}
          userScheduleBonusId={params.userScheduleBonusId}
          userGuid={params.userGuid}
        />
      </MainPage>
    </>
  )
}

export default observer(Edit)
