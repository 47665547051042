import React, { PropsWithChildren, useEffect, useState } from 'react'
import {
  Path,
  RegisterOptions,
  useController,
  UseFormReturn,
} from 'react-hook-form'
import Select, { StylesConfig } from 'react-select'
import { t } from 'src/I18n'
import { ISelectOption } from 'src/Models/Client'
import colors from 'src/Style/colors'
import MyLabel from './MyLabel/MyLabel'

interface IProps<T> {
  name: Path<T>
  formObject: T
  useFormProps: UseFormReturn<T>
  rules?: RegisterOptions
  //select
  options: ISelectOption[]
  isMulti?: boolean
  isDisabled?: boolean
  infoText?: string
  label?: string
  onSelectionChange?: (option: ISelectOption | ISelectOption[]) => void
  actionButton?: {
    text: string
    type: 'submit' | 'button'
    clicked?: () => void
    disabled?: boolean
  }
  isClearable?: boolean
}

const MySelect = <ObjectType,>({
  name,
  useFormProps,
  rules,
  options,
  isMulti,
  infoText,
  label,
  isDisabled,
  onSelectionChange,
  actionButton,
  isClearable,
}: PropsWithChildren<IProps<ObjectType>>) => {
  const {
    control,
    formState: { errors },
  } = useFormProps

  const errorMessage =
    errors !== undefined && errors[name.toString()] !== undefined
      ? errors[name.toString()].message
      : undefined
  const required = rules !== undefined && rules.required !== undefined
  const hasError = errorMessage !== undefined

  const [allItemsSelected, setAllItemsSelected] = useState(false)

  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
    rules,
  })

  useEffect(() => {
    if (value !== undefined && isMulti) {
      const valueArray = value as ISelectOption[]
      if (valueArray.length === options.length) {
        setAllItemsSelected(true)
      }
    }
  }, [value])

  const errorText = () => {
    if (!hasError) {
      return null
    }

    return (
      <ul className="parsley-error-list filled">
        <li className="parsley-required">{errorMessage}</li>
      </ul>
    )
  }

  const renderSelectAll = () => {
    if (isMulti) {
      const text = allItemsSelected ? t.common.deselectAll : t.common.selectAll
      return (
        <a
          onClick={() => {
            const allOptionsOrNothing = allItemsSelected ? [] : options
            if (onSelectionChange) {
              onSelectionChange(allOptionsOrNothing)
            }
            onChange(allOptionsOrNothing) //set all options selected or [] (nothing selected)
            setAllItemsSelected(!allItemsSelected)
          }}
          className="pull-right"
        >
          {text}
        </a>
      )
    }
    return null
  }

  const renderActionButton = () => {
    if (actionButton) {
      const disabled =
        actionButton.disabled === undefined ? false : actionButton.disabled

      return (
        <span className="input-group-btn">
          <button
            onClick={() => {
              if (actionButton.clicked) {
                actionButton.clicked()
              }
            }}
            className="btn btn-primary"
            type={actionButton.type}
            disabled={disabled}
          >
            {actionButton.text}
          </button>
        </span>
      )
    }
    return null
  }

  const inputGroupCss = actionButton !== undefined ? 'input-group' : ''
  const colourStyles: StylesConfig = {
    option: (styles) => {
      return {
        ...styles,
        color: 'black',
      }
    },
  }
  return (
    <div className="form-group">
      <>
        {label !== undefined && (
          <MyLabel htmlFor={name} isRequired={required} infoText={infoText}>
            {label}
          </MyLabel>
        )}
        {renderSelectAll()}

        <div className={inputGroupCss}>
          <Select
            name={name}
            styles={colourStyles}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: colors.greenLight,
                primary50: colors.greenLight,
                primary: colors.green,
              },
            })}
            value={value as any}
            isDisabled={isDisabled}
            classNamePrefix="select"
            isMulti={isMulti}
            options={options}
            isClearable={isClearable}
            onChange={(option: ISelectOption | ISelectOption[]) => {
              if (onSelectionChange) {
                onSelectionChange(option)
              }

              if (isMulti) {
                if ((option as ISelectOption[]).length === 0) {
                  setAllItemsSelected(false)
                }
              }
              onChange(option)
            }}
          />
          {renderActionButton()}
        </div>
      </>
      {errorText()}
    </div>
  )
}

export default MySelect
