import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { McButton } from 'src/Components'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import LoaderIcon from 'src/Components/LoaderIcon'
import { MainPage, MyDataTable, MySelect } from 'src/Components/My'
import { t } from 'src/I18n'

import { ILoyaltyProgramVTO } from 'src/Models/Server/Response/LoyaltyProgram/ILoyaltyProgramVTO'
import { loyaltyProgramsStore } from './Store'

const LoyaltyPrograms: React.FC = () => {
  useEffect(() => {
    loyaltyProgramsStore.Initialize()
  }, [null])

  const { LoyaltyPrograms, IsLoading } = loyaltyProgramsStore
  const navigate = useNavigate()
  const cellFormatter = (cell, program: ILoyaltyProgramVTO) => {
    return (
      <Link to={`/loyaltyprogram/details/${program.LoyaltyProgramId}`}>
        {program.Name}
      </Link>
    )
  }

  const columns = [
    {
      dataField: 'Name',
      text: t.common.grid.name.label,
      formatter: cellFormatter,
    },
  ]

  return (
    <MainPage>
      <BreadCrumbs currentPageTitle={t.common.loyaltyPrograms} />
      {/* <Link to="/loyaltyprogram/insert">
        <Button bsStyle="primary">{t.loyaltyprogram.insert.title}</Button>
      </Link> */}
      <LoaderIcon IsLoading={IsLoading} />
      {!IsLoading && (
        <Portlet
          title={t.common.loyaltyPrograms}
          headerContent={
            <McButton
              iconLeft="fa-plus"
              bsStyle="primary"
              bsSize="xs"
              title={t.subscription.insert.title}
              pullRight
              onClick={() => navigate(`/loyaltyprogram/insert`)}
            >
              {t.common.add}
            </McButton>
          }
        >
          {/* <MySelect
            formObject={{} as IFormValues}
            useFormProps={useFormProps}
            name="SelectedMerchant"
            label={t.common.merchant}
            options={Merchants}
            onSelectionChange={(option: ISelectOption) => {
              setValue('SelectedMerchant', option)
              handleSubmit((data) =>
                loyaltyProgramsStore.GetLoyaltyPrograms(data)
              )()
            }}
          />
          <br className="xs-10" /> */}
          <MyDataTable data={LoyaltyPrograms} columns={columns} />
        </Portlet>
      )}
    </MainPage>
  )
}

export default observer(LoyaltyPrograms)
