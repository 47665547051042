import { action, makeObservable, observable, runInAction } from 'mobx'
import IGenericError from 'src/Models/Server/IGenericError'
import { ITransactionExtendedSTO } from 'src/Models/Server/Response/Transaction/ITransactionExtendedSTO'

import ReportController from 'src/Services/ToprCoreApi/Axios/Controllers/ReportController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export default class Store extends BaseStore {
  public Transactions: ITransactionExtendedSTO[] = []

  constructor() {
    super()
    this.BaseInitialize()

    makeObservable(this, {
      Transactions: observable,
      Initialize: action,
    })
  }

  public Initialize(merchantPaymentId: string) {
    this.IsLoading = true
    ReportController.GetMerchantPaymentTransactions(merchantPaymentId)
      .then((transactions) => {
        runInAction(() => {
          this.IsLoading = false
          this.Transactions = transactions
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsLoading = false))
        appUiStore.ShowError(error)
      })
  }
}
export const merchantPaymentDetails = new Store()
