const modules: IModule[] = [
  {
    ModuleId: 300,
    SystemName: 'POS_PAYMENT',
    NeedsCurrency: true,
  },
  {
    ModuleId: 301,
    SystemName: 'POS_TOPUP',
    NeedsCurrency: true,
  },
  {
    ModuleId: 302,
    SystemName: 'POS_DEDUCT',
    NeedsCurrency: true,
  },
  {
    ModuleId: 303,
    SystemName: 'LOYALTY_REDEEM_COUPON',
    NeedsCurrency: false,
  },
  {
    ModuleId: 304,
    SystemName: 'LOYALTY_PUSH_COUPON',
    NeedsCurrency: false,
  },
  {
    ModuleId: 305,
    SystemName: 'POS_PRODUCT_REGISTER',
    NeedsCurrency: true,
  },
  {
    ModuleId: 306,
    SystemName: 'USER_INFO',
    NeedsCurrency: false,
  },
  {
    ModuleId: 307,
    SystemName: 'LOYALTY_SAVINGCARDS',
    NeedsCurrency: false,
  },
  {
    ModuleId: 309,
    SystemName: 'MYB_TRIPS',
    NeedsCurrency: false,
  },
  {
    ModuleId: 311,
    SystemName: 'PSDPP_PAY_BALANCE',
    NeedsCurrency: true,
  },
  {
    ModuleId: 312,
    SystemName: 'PSDPP_BALANCE',
    NeedsCurrency: true,
  },
  {
    ModuleId: 314,
    SystemName: 'USER_ACCESS_CONTROL',
    NeedsCurrency: false,
  },
  {
    ModuleId: 315,
    SystemName: 'EVENT_USER_ACCESS_CONTROL',
    NeedsCurrency: false,
  },
  {
    ModuleId: 316,
    SystemName: 'SAVING_ARTICLE',
    NeedsCurrency: false,
  },
]

export default modules

export interface IModule {
  ModuleId: number
  SystemName: string
  NeedsCurrency: boolean
}
