import { action, makeObservable, observable, runInAction } from 'mobx'
import { createPageAlert } from 'src/Components/McPageAlert'
import { ISelectOption } from 'src/Models/Client'
import IGenericError from 'src/Models/Server/IGenericError'
import { ToSelectOptions } from 'src/Models/Server/INameIdVTO'
import IPosVTO from 'src/Models/Server/Response/Pos/IPosVTO'
import PosController from 'src/Services/ToprCoreApi/Axios/Controllers/PosController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export interface IFormValues extends IPosVTO {
  SelectedPosType: ISelectOption
}

export default class Store extends BaseStore<IFormValues> {
  public PosTypes: ISelectOption[] = []

  constructor() {
    super()

    makeObservable(this, {
      PosTypes: observable,
      Initialize: action,
      EditPos: action,
    })
  }

  public Initialize(editPos: IPosVTO) {
    const viewModel = { SelectedPosType: null, ...editPos }
    this.BaseInitialize(viewModel)

    PosController.GetPosTypes()
      .then((posTypes) => {
        runInAction(() => {
          this.PosTypes = ToSelectOptions(posTypes)

          const selectedPosType = this.PosTypes.find(
            (x) => x.value === editPos.PosTypeId.toString()
          )
          this.ViewModel = { ...editPos, SelectedPosType: selectedPosType }
          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsLoading = false))
        appUiStore.ShowError(error)
      })
  }

  public EditPos(data: IFormValues) {
    this.IsSubmitting = true
    data.PosTypeName = data.SelectedPosType.label
    data.PosTypeId = data.SelectedPosType.value

    return new Promise<void>((resolve, reject) => {
      PosController.Edit(data)
        .then(() => {
          runInAction(() => (this.IsSubmitting = false))
          resolve()
        })
        .catch((error: IGenericError) => {
          runInAction(() => {
            this.PageAlert = createPageAlert(error)
            this.IsSubmitting = false
          })
          reject()
        })
    })
  }
}
export const editPosStore = new Store()
