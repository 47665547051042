import React, { useEffect, useReducer } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useLocation, useParams } from 'react-router-dom'
import { LoaderIcon, McButton } from 'src/Components'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import {
  MainPage,
  MyCurrencyInput,
  MySelect,
  MyTextbox,
} from 'src/Components/My'
import { t } from 'src/I18n'
import { ISelectOption } from 'src/Models/Client'
import { IFormValues, initialState, reducer, reducerActions } from './Reducer'

const WalletWithdraw: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const actions = reducerActions(state, dispatch)
  const params = useParams<'currencyId'>()
  useEffect(() => {
    actions.initialize(params.currencyId)
  }, [null])

  const {
    isSubmitting,
    isLoading,
    viewModel,
    selectedCurrency,
    currencyOptions,
  } = state

  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>()
  const { handleSubmit, reset } = useFormProps

  const location = useLocation()

  const { ids } = location.state as { ids: number[] }

  useEffect(() => {
    reset(viewModel)
  }, [viewModel])

  const onSubmit = (data: IFormValues) => {
    actions.save(data, ids)
  }

  const renderContent = () => {
    if (viewModel === undefined) {
      return <LoaderIcon IsLoading={isLoading} />
    }

    return (
      <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
        <MySelect
          formObject={{} as IFormValues}
          useFormProps={useFormProps}
          label={t.common.currencies}
          name="selectedCurrencyOption"
          options={currencyOptions}
          onSelectionChange={(option: ISelectOption) => {
            actions.setSelectedCurrency(option.value)
          }}
        />

        <MyCurrencyInput
          formObject={{} as IFormValues}
          label={t.commonFields.Amount.label}
          currency={selectedCurrency}
          onChangeCents={(amountCents) => {
            actions.setAmountCents(amountCents)
          }}
          name="amount"
          useFormProps={useFormProps}
        />

        <MyTextbox
          formObject={{} as IFormValues}
          icon="fa-bars"
          label={t.walletWithdraw.fields.Description.label}
          name="description"
          useFormProps={useFormProps}
        />
        <McButton
          block
          bsStyle="primary"
          bsSize="large"
          type="submit"
          isLoading={isSubmitting}
        >
          {t.common.withdraw}
        </McButton>
      </form>
    )
  }

  return (
    <>
      <MainPage>
        <BreadCrumbs currentPageTitle={t.walletWithdraw.title} />
        <Portlet title={t.common.withdraw}>{renderContent()}</Portlet>
      </MainPage>
    </>
  )
}

export default WalletWithdraw
