import { AxiosError, AxiosResponse } from 'axios'
import { handleException } from 'src/Helpers/ResponseHelpers'
import { IExceptionError } from 'src/Models/Server/Response/Exception/ExceptionError'
import { ITagVTO } from 'src/Models/Server/Response/Tag/ITagVTO'
import { axiosAuth } from '../AuthAxios'

export default {
  GetTags() {
    return new Promise<ITagVTO[]>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get<ITagVTO[]>(`/api/v1/tags/merchant`)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  GetMerchantUserTags(userGuid: string) {
    return new Promise<ITagVTO[]>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/tags/merchant/user/${userGuid}`)
          .then((response: AxiosResponse<ITagVTO[]>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  TagInsert(name: string) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/tags/merchant/insert`, {
            Name: name,
          })
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  TagEdit(tagId: number, name: string) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/tags/merchant/edit`, {
            TagId: tagId,
            Name: name,
          })
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  UserTagInsert(userGuid: string, tagId: string) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/tags/merchant/user/${userGuid}/add/${tagId}`, null)
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  UserTagDelete(userGuid: string, tagId: number) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .delete(`/api/v1/user/${userGuid}/tag/${tagId}/delete`)
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
}
