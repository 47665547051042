import { AxiosError, AxiosResponse } from 'axios'
import { handleException } from 'src/Helpers/ResponseHelpers'
import { IExceptionError } from 'src/Models/Server/Response/Exception/ExceptionError'
import { IFieldValueEditVTO } from 'src/Models/Server/Response/Field/IFieldValueEditVTO'
import { axiosAuth } from '../AuthAxios'

export default {
  GetFieldValueEdit(fieldValueId: number) {
    return new Promise<IFieldValueEditVTO>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/field/value/edit/${fieldValueId}`)
          .then((response: AxiosResponse<IFieldValueEditVTO>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  SaveFieldValueEdit(fieldValueEdit: IFieldValueEditVTO) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/field/value/edit`, fieldValueEdit)
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  InsertSiedlerCompany(fieldValueEdit: IFieldValueEditVTO) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/siedler/company/insert`, fieldValueEdit)
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
}
