import React, { useEffect, useReducer } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { McButton } from 'src/Components'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import { InputMasks, MainPage, MyTextbox } from 'src/Components/My'
import { t } from 'src/I18n'
import { IFormValues, initialState, reducer, reducerActions } from './Reducer'

const AddConsumerByToken: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const actions = reducerActions(state, dispatch)

  const { isSubmitting, viewModel } = state

  // useEffect(() => {
  //   reset(viewModel)

  //   console.log('reset form')
  // }, [viewModel, viewModel.activationCode])

  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>()
  const { handleSubmit, setValue } = useFormProps

  const onSubmit = (data: IFormValues) => {
    actions.save(data)
  }

  const renderContent = () => {
    return (
      <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
        <Portlet title={t.merchant.addConsumerUser.title}>
          <Row>
            <Col md={6}>
              <MyTextbox
                useFormProps={useFormProps}
                formObject={viewModel}
                name="activationCode"
                label={t.merchant.addConsumerUser.fields.activationCode.label}
                maxLength={8}
                inputMask={InputMasks.Number}
                rules={{
                  required:
                    t.merchant.addConsumerUser.fields.activationCode.required,
                  minLength: {
                    value: 8,
                    message:
                      t.merchant.addConsumerUser.fields.activationCode
                        .minLength,
                  },
                }}
                actionButton={{
                  icon: 'fa-times',
                  bsStyle: 'default',
                  type: 'button',
                  clicked: (inputRef) => {
                    setValue('activationCode', '')
                    inputRef.current.focus()
                  },
                }}
              />
              <McButton
                block
                bsStyle="primary"
                bsSize="large"
                type="submit"
                isLoading={isSubmitting}
              >
                {t.common.save}
              </McButton>
            </Col>
          </Row>
        </Portlet>
      </form>
    )
  }

  return (
    <>
      <MainPage>
        <BreadCrumbs currentPageTitle={t.merchant.addConsumerUser.title} />
        {renderContent()}
      </MainPage>
    </>
  )
}

export default AddConsumerByToken
