import IGenericError from 'src/Models/Server/IGenericError'
import { IEventVTO } from 'src/Models/Server/Response/Event/IEventVTO'

import EventController from 'src/Services/ToprCoreApi/Axios/Controllers/EventController'
import { appUiStore } from 'src/Stores/AppUiStore'

export interface IState {
  isLoading: boolean
  events: IEventVTO[]
}

export const initialState: IState = {
  isLoading: true,
  events: [],
}

export type Actions = {
  type: 'initialize'
  events: IEventVTO[]
}

export const reducer = (state: IState, action: Actions): IState => {
  switch (action.type) {
    case 'initialize':
      return {
        ...state,
        isLoading: false,
        events: action.events,
      }
    default:
      throw new Error('Invalid Reducer Action')
  }
}

export const reducerActions = (
  state: IState,
  dispatch: (action: Actions) => void
) => {
  const initialize = () => {
    EventController.GetEvents()
      .then((events) => {
        dispatch({
          type: 'initialize',
          events,
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
      })
  }

  return {
    initialize,
  }
}
