import { createAction, createReducer } from '@reduxjs/toolkit'
import { Today } from 'src/Helpers/DateHelpers'
import { getItemTypeText, GetLocalText } from 'src/Helpers/TextHelpers'
import { ISelectOption } from 'src/Models/Client'
import { IConsumerSubscriptionItemSto } from 'src/Models/Server/Response/ConsumerSubscription/IConsumerSubscriptionItemSto'
import { IConsumerSubscriptionSto } from 'src/Models/Server/Response/ConsumerSubscription/IConsumerSubscriptionSto'
import { IUserSubscriptionVTO } from 'src/Models/Server/Response/ConsumerSubscription/IUserSubscriptionVTO'

export interface IState {
  consumerSubscriptionItems: IConsumerSubscriptionItemSto[]
  viewModel: IUserSubscriptionForm
}

export const initialState: IState = {
  consumerSubscriptionItems: [],
  viewModel: undefined,
}

export interface IUserSubscriptionForm {
  ConsumerSubscriptionItemOption: ISelectOption
  StartDate: Date
  EndDate?: Date //only used in edit
}

export const itemsToOptions = (
  consumerSubscriptionItems: IConsumerSubscriptionItemSto[]
) => {
  return consumerSubscriptionItems.map((csi) => ({
    value: csi.ConsumerSubscriptionItemId.toString(),
    label: `${GetLocalText(csi.Name)} (${getItemTypeText(csi)})`,
  }))
}

const initInsert = (consumerSubscription: IConsumerSubscriptionSto) => {
  const consumerSubscriptionItemOptions: ISelectOption[] = itemsToOptions(
    consumerSubscription.ConsumerSubscriptionItems
  )

  const viewModel: IUserSubscriptionForm = {
    ConsumerSubscriptionItemOption: consumerSubscriptionItemOptions[0],
    StartDate: Today(),
  }

  return {
    payload: {
      consumerSubscriptionItems: consumerSubscription.ConsumerSubscriptionItems,
      viewModel,
    },
  }
}

const initEdit = (
  consumerSubscription: IConsumerSubscriptionSto,
  userSubscription: IUserSubscriptionVTO
) => {
  const consumerSubscriptionItemOptions: ISelectOption[] = itemsToOptions(
    consumerSubscription.ConsumerSubscriptionItems
  )

  const itemOption = consumerSubscriptionItemOptions.find(
    (x) => x.value === userSubscription.ConsumerSubscriptionItemId.toString()
  )

  const viewModel: IUserSubscriptionForm = {
    ConsumerSubscriptionItemOption: itemOption,
    StartDate: new Date(userSubscription.ValidFrom),
    EndDate:
      userSubscription.ValidTill === null
        ? undefined
        : new Date(userSubscription.ValidTill),
  }

  return {
    payload: {
      consumerSubscriptionItems: consumerSubscription.ConsumerSubscriptionItems,
      viewModel,
    },
  }
}

export const initialize = createAction(
  'userSubscriptionForm/initialize',
  (
    consumerSubscription: IConsumerSubscriptionSto,
    userSubscription?: IUserSubscriptionVTO
  ) => {
    if (userSubscription === undefined) {
      //initInsert(consumerSubscriptionId)
      return initInsert(consumerSubscription)
    } else {
      //edit
      return initEdit(consumerSubscription, userSubscription)
    }
  }
)

//reducer
const reducer = createReducer<IState>(initialState, (builder) => {
  builder.addCase(initialize, (state, action) => {
    state.consumerSubscriptionItems = action.payload.consumerSubscriptionItems
    state.viewModel = action.payload.viewModel
  })
})

export default reducer
