import React from 'react'
import Portlet from 'src/Components/Authenticated/Portlet'
import { t } from 'src/I18n'
import { IMerchantConsumerUserTotalsDto } from 'src/Models/Server/Response/Dashobard/IMerchantConsumerUserTotalsDto'

interface IProps {
  userTotals: IMerchantConsumerUserTotalsDto
}

const UserTotals: React.FC<IProps> = ({ userTotals }) => {
  return (
    <Portlet title={t.common.users}>
      <table className="table keyvalue-table">
        <tbody>
          <tr>
            <td className="kv-key">
              <i className="fa fa-user kv-icon kv-icon-primary" />
              {t.dashboard.totalUsers}
            </td>
            <td className="kv-value">{userTotals.Total}</td>
          </tr>
          <tr>
            <td className="kv-key">
              <i className="fa fa-user-check kv-icon kv-icon-tertiary" />
              {t.dashboard.totalActivated}
            </td>
            <td className="kv-value">{userTotals.TotalActivated}</td>
          </tr>
          <tr>
            <td className="kv-key">
              <i className="fa fa-user-plus kv-icon kv-icon-tertiary" />
              {t.dashboard.newUsersThisWeek}
            </td>
            <td className="kv-value">{userTotals.NewUsers}</td>
          </tr>
          <tr>
            <td className="kv-key">
              <i className="fa fa-bolt kv-icon kv-icon-tertiary" />
              {t.dashboard.activeUsersThisWeek}
            </td>
            <td className="kv-value">{userTotals.ActiveUsers}</td>
          </tr>
        </tbody>
      </table>
    </Portlet>
  )
}

export default UserTotals
