import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { MainPage } from 'src/Components/My'
import { t } from 'src/I18n'
import UserCouponsPortlet from './UserCouponsPortlet/UserCouponsPortlet'
import { userLoyaltyStore } from './Store'
import { useParams } from 'react-router-dom'
import { LoaderIcon } from 'src/Components'
interface IProps {}

const LoyaltyTab: React.FC<IProps> = (props) => {
  const params = useParams<'id'>()
  useEffect(() => {
    userLoyaltyStore.Initialize(params.id)
  }, [null])

  const { IsLoading, UserCoupons, CouponTotalsUser } = userLoyaltyStore

  if (IsLoading) {
    return <LoaderIcon IsLoading />
  }
  return (
    <>
      <UserCouponsPortlet
        userCoupons={UserCoupons}
        couponTotalsUser={CouponTotalsUser}
      />
    </>
  )
}

export default observer(LoyaltyTab)
