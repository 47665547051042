import { action, makeObservable, observable, runInAction } from 'mobx'
import IGenericError from 'src/Models/Server/IGenericError'
import IPosVTO from 'src/Models/Server/Response/Pos/IPosVTO'
import PosController from 'src/Services/ToprCoreApi/Axios/Controllers/PosController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export default class Store extends BaseStore<IPosVTO[]> {
  public EditPosGuid: string = null

  constructor() {
    super()
    this.BaseInitialize([])
    makeObservable(this, {
      EditPosGuid: observable,
      GetPosByLocation: action,
      Activate: action,
      Deactivate: action,
    })
  }

  public GetPosByLocation(merchantGuid: string) {
    this.IsLoading = true
    PosController.GetPosByLocation(merchantGuid)
      .then((poses) => {
        runInAction(() => {
          this.ViewModel = poses
          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        runInAction(() => {
          this.IsLoading = false
        })
      })
  }

  public Activate(posGuid: string): void {
    this.IsSubmitting = true
    this.EditPosGuid = posGuid
    PosController.Activate(posGuid)
      .then((pos) => {
        runInAction(() => {
          this.EditPosGuid = null
          this.IsSubmitting = false
          this.UpdateElement(pos, (x) => x.PosGuid === posGuid)
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => {
          appUiStore.ShowError(error)
          this.EditPosGuid = null
          this.IsSubmitting = false
        })
      })
  }

  public Deactivate(posGuid: string): void {
    this.IsSubmitting = true
    this.EditPosGuid = posGuid
    PosController.Deactivate(posGuid)
      .then((pos) => {
        runInAction(() => {
          this.EditPosGuid = null
          this.IsSubmitting = false
          this.UpdateElement(pos, (x) => x.PosGuid === posGuid)
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        runInAction(() => {
          this.EditPosGuid = null
          this.IsSubmitting = false
        })
      })
  }
}

export const posGridStore = new Store()
