import React from 'react'
import { NavLink } from 'react-router-dom'
import { hasMatchedChildren } from 'src/Helpers/UrlHelpers'
import { t } from 'src/I18n'
import { AdditionalModules } from 'src/Models/Server/AdditionalModules'
import { IsAdmin } from 'src/Models/Server/Response/User/ManagerUserVTO'
import { persistedStore } from 'src/Stores/PersistedStore'
interface IProps {}

const FinanceMenu: React.FC<IProps> = () => {
  const { SelectedManagerUser } = persistedStore

  // const megaMenuSize =
  //   SelectedManagerUser.Reports.length > 1
  //     ? 'dropdown-menu mega-menu-2' //mega-menu-3 also possible
  //     : 'dropdown-menu'

  //   const navLinkStyles = (props: { isActive: boolean }): React.CSSProperties => {
  //     return {
  //       color: props.isActive ? Tenant.Colors.AccentColorDarkened : '#555',
  //     }
  //   }

  const hasActiveChildrenClassName = hasMatchedChildren(
    '/finance/merchantpayments',
    'merchantrefunds',
    '/finance/uploadstornos',
    '/finance/stornostatus',
    '/finance/sepafile/generate'
  )
    ? 'dropdown active'
    : 'dropdown'

  return (
    <li className={hasActiveChildrenClassName}>
      <a
        className="dropdown-toggle"
        data-toggle="dropdown"
        data-hover="dropdown"
      >
        {t.topMenu.finance.title}
        <i className="fa fa-chevron-down" />
      </a>

      <ul className="dropdown-menu" role="menu">
        <li>
          <NavLink to={'/finance/merchantpayments'}>
            <i className={`fa fa-table dropdown-icon`} />
            {t.topMenu.finance.merchantPayments}
          </NavLink>
        </li>
        {IsAdmin(SelectedManagerUser) && (
          <>
            <li>
              <NavLink to={'/merchantrefunds'}>
                <i className={`fa fa-download dropdown-icon`} />
                {t.merchantRefunds.title}
              </NavLink>
            </li>
            <li>
              <NavLink to={'/finance/uploadstornos'}>
                <i className={`fa fa-upload dropdown-icon`} />
                {t.stornos.title}
              </NavLink>
            </li>
          </>
        )}
        {(SelectedManagerUser.AdditionalModules.indexOf(
          AdditionalModules.RtoStornoStatus
        ) > -1 ||
          IsAdmin(SelectedManagerUser)) && (
          <li>
            <NavLink to={'/finance/stornostatus'}>
              <i className={`fa fa-table dropdown-icon`} />
              {t.stornoStatus.title}
            </NavLink>
          </li>
        )}
        {/* {SelectedManagerUser.MerchantCurrencies.some(
          (x) => x.AutomaticChargeAllowed
        ) && (
          <li>
            <NavLink to={'/finance/sepafile/generate'}>
              <i className={`fa fa-file dropdown-icon`} />
              {t.generateAutomaticCharge.title}
            </NavLink>
          </li>
        )} */}
      </ul>
    </li>
  )
}

export default FinanceMenu
