import React, { ReactNode } from 'react'

interface IProps {
  children?: ReactNode | undefined
  imageUrl: string
}
export type CardImageHeaderType = React.FC<IProps>

const CardImageHeader: CardImageHeaderType = ({ children, imageUrl }) => {
  return (
    <div
      className="header-bg"
      style={{
        backgroundImage: `url(${imageUrl})`,
      }}
    >
      {children}
    </div>
  )
}

export default CardImageHeader
