import { action, makeObservable, observable, runInAction } from 'mobx'
import { toast } from 'react-toastify'
import { t } from 'src/I18n'
import { IExceptionError } from 'src/Models/Server/Response/Exception/ExceptionError'
import { ILoyaltyProgramDetailsVTO } from 'src/Models/Server/Response/LoyaltyProgram/ILoyaltyProgramDetailsVTO'
import LoyaltyProgramController from 'src/Services/ToprCoreApi/Axios/Controllers/LoyaltyProgramController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'
import { IEditLoyaltyProgramRule } from './EditArticleModal/Reducer'
import { IEditLoyaltyReward } from './EditRewardModal/Reducer'

export default class Store extends BaseStore<ILoyaltyProgramDetailsVTO> {
  public EditLoyaltyProgramRule?: IEditLoyaltyProgramRule = undefined
  public EditLoyaltyReward: IEditLoyaltyReward = undefined
  public EditLoyaltyProgram: ILoyaltyProgramDetailsVTO = undefined

  constructor() {
    super()
    this.BaseInitialize()
    makeObservable(this, {
      EditLoyaltyProgramRule: observable,
      EditLoyaltyReward: observable,
      EditLoyaltyProgram: observable,
      Initialize: action,
      ToggleIsEditArticleRuleVisible: action,
      ToggleIsEditRewardVisible: action,
      ToggleIsEditLoyaltyProgramVisible: action,
    })
  }

  public Initialize(id: string) {
    this.IsLoading = true
    this.EditLoyaltyProgramRule = undefined
    this.EditLoyaltyReward = undefined

    LoyaltyProgramController.GetLoyaltyProgram(id)
      .then((loyaltyProgram) => {
        runInAction(() => {
          //console.log(loyaltyProgram)
          this.ViewModel = loyaltyProgram
          this.IsLoading = false
        })
      })
      .catch((error: IExceptionError) => {
        runInAction(() => (this.IsLoading = false))
        appUiStore.ShowError(error)
      })
  }

  public ToggleIsEditArticleRuleVisible(
    editLoyaltyProgramRule?: IEditLoyaltyProgramRule
  ) {
    this.EditLoyaltyProgramRule = editLoyaltyProgramRule
  }

  public ToggleIsEditRewardVisible(editLoyaltyReward?: IEditLoyaltyReward) {
    this.EditLoyaltyReward = editLoyaltyReward
  }

  public ToggleIsEditLoyaltyProgramVisible(
    editLoyaltyProgram?: ILoyaltyProgramDetailsVTO
  ) {
    this.EditLoyaltyProgram = editLoyaltyProgram
  }

  public DeleteLoyaltyProgram() {
    console.log('del')
    LoyaltyProgramController.DeleteLoyaltyProgram(
      this.ViewModel.LoyaltyProgramId
    )
      .then(() => {
        toast.success(t.loyaltyprogram.details.deleteSuccess)
        history.back()
      })
      .catch((error) => {
        appUiStore.ShowError(error)
      })
  }
}

export const loyaltyProgramDetailsStore = new Store()
