import React, { useEffect, useReducer } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { McButton } from 'src/Components'
import Portlet from 'src/Components/Authenticated/Portlet'
import {
  InputMasks,
  MyLocalizedStringTabs,
  MySelect,
  MySlim,
  MyTextbox,
} from 'src/Components/My'
import { t } from 'src/I18n'
import { ISelectOption } from 'src/Models/Client'
import { ConsumerSubscriptionItemType } from 'src/Models/Server/Enums'
import { IEditConsumerSubscriptionItemVTO } from 'src/Models/Server/Response/ConsumerSubscription/IEditConsumerSubscriptionItemVTO'
import Reducer, {
  initialState,
  ISubscriptionItemFormValues,
  initializeForm,
  onTypeSelect,
  typeOptions,
} from './Reducer'

interface IProps {
  title: string
  onSave: (data: ISubscriptionItemFormValues) => void
  isSubmitting: boolean
  editItem?: IEditConsumerSubscriptionItemVTO
}

const SubscriptionItemForm: React.FC<IProps> = ({
  title,
  onSave,
  isSubmitting,
  editItem,
}) => {
  //when using useReducer, the state is not shared, which is perfect for forms
  const [state, dispatch] = useReducer(Reducer, initialState)

  const { viewModel, amountLabel } = state

  useEffect(() => {
    dispatch(initializeForm(editItem))
  }, [editItem])

  useEffect(() => {
    reset(viewModel)
  }, [viewModel])

  const useFormProps: UseFormReturn<ISubscriptionItemFormValues> =
    useForm<ISubscriptionItemFormValues>()

  const { handleSubmit, reset } = useFormProps

  const onSubmit = (data: ISubscriptionItemFormValues) => {
    onSave(data)
  }

  const renderContent = () => {
    if (viewModel === undefined) {
      return null
    }

    const renderAmountTextBox = () => {
      if (
        viewModel.TypeOption.value ===
        ConsumerSubscriptionItemType.Unlimited.toString()
      ) {
        return null
      }

      return (
        <MyTextbox
          formObject={viewModel}
          useFormProps={useFormProps}
          name="Amount"
          type="number"
          inputMask={InputMasks.Number}
          label={amountLabel}
          rules={{
            required: t.subscription.insertItem.fields.amount.required,
          }}
        />
      )
    }

    return (
      <>
        <Row>
          <Col md={6}>
            <MySelect
              formObject={viewModel}
              useFormProps={useFormProps}
              name="TypeOption"
              label={t.common.type}
              options={typeOptions}
              onSelectionChange={(option: ISelectOption) => {
                dispatch(onTypeSelect(option))
              }}
            />
          </Col>
          <Col md={6}>{renderAmountTextBox()}</Col>
        </Row>
        <MyLocalizedStringTabs
          formObject={viewModel}
          useFormProps={useFormProps}
          name="Name"
          label={t.subscription.insertItem.fields.name.label}
          isRequired
        />
        <MyLocalizedStringTabs
          formObject={viewModel}
          useFormProps={useFormProps}
          name="Description"
          label={t.subscription.insertItem.fields.description.label}
          isRequired
        />
        <MySlim
          name="ImageFile"
          formObject={viewModel}
          useFormProps={useFormProps}
          label={t.savingcardInsert.fields.ImageFile.label}
          rules={{
            required: t.savingcardInsert.fields.ImageFile.errors.required,
          }}
          sizeSlim={{ width: 580, height: 380 }}
        />
        <McButton
          block
          bsStyle="primary"
          bsSize="large"
          type="submit"
          isLoading={isSubmitting}
        >
          {t.common.save}
        </McButton>
      </>
    )
  }

  return (
    <>
      <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
        <Portlet title={title}>{renderContent()}</Portlet>
      </form>
    </>
  )
}

export default SubscriptionItemForm
