import { convertTextToCent } from 'src/Helpers/CurrencyHelpers'
import IGenericError from 'src/Models/Server/IGenericError'
import { ICreateEventTicketRequest } from 'src/Models/Server/Request/EventTicket/ICreateEventTicketRequest'
import { IEventVTO } from 'src/Models/Server/Response/Event/IEventVTO'
import EventController from 'src/Services/ToprCoreApi/Axios/Controllers/EventController'
import { appUiStore } from 'src/Stores/AppUiStore'
import { IEventFormValues } from '../Form/Reducer'

export interface IState {
  event?: IEventVTO
  isSubmitting: boolean
}

export const initialState: IState = {
  isSubmitting: false,
  event: undefined,
}

export type Actions =
  | {
      type: 'setIsSubmitting'
      isSubmitting: boolean
    }
  | {
      type: 'initialized'
      event: IEventVTO
    }

export const reducer = (state: IState, action: Actions): IState => {
  switch (action.type) {
    case 'initialized':
      return {
        ...state,
        event: action.event,
      }
    case 'setIsSubmitting':
      return {
        ...state,
        isSubmitting: action.isSubmitting,
      }
    default:
      throw new Error('Invalid Reducer Action')
  }
}

export const reducerActions = (
  state: IState,
  dispatch: (action: Actions) => void
) => {
  return {
    getEvent: (eventId: string) => {
      EventController.GetEvent(eventId)
        .then((event) => {
          dispatch({ type: 'initialized', event })
        })
        .catch((error: IGenericError) => {
          appUiStore.ShowError(error)
        })
    },
    save: (eventId: string, data: IEventFormValues) => {
      const request: ICreateEventTicketRequest = {
        EventId: eventId,
        Name: data.Name,
        OnSale: data.OnSale,
        Price: convertTextToCent(data.Price.toString()),
      }

      //console.log('request', request)

      dispatch({ type: 'setIsSubmitting', isSubmitting: true })
      EventController.EventTicketInsert(request)
        .then(() => {
          history.back()
          dispatch({ type: 'setIsSubmitting', isSubmitting: false })
        })
        .catch((error: IGenericError) => {
          appUiStore.ShowError(error)
          dispatch({ type: 'setIsSubmitting', isSubmitting: false })
        })
    },
  }
}
