import { AxiosError, AxiosResponse } from 'axios'
import { handleException } from 'src/Helpers/ResponseHelpers'
import { ICreateEventRequest } from 'src/Models/Server/Request/Event/ICreateEventRequest'
import { IEventEditVTO } from 'src/Models/Server/Response/Event/IEventEditVTO'
import { IEventVTO } from 'src/Models/Server/Response/Event/IEventVTO'

import { IEditEventRequest } from 'src/Models/Server/Request/Event/IEditEventRequest'
import { ICreateEventTicketRequest } from 'src/Models/Server/Request/EventTicket/ICreateEventTicketRequest'
import { IEditEventTicketRequest } from 'src/Models/Server/Request/EventTicket/IEditEventTicketRequest'
import { IEventTicketEditVTO } from 'src/Models/Server/Response/Event/IEventTicketEditVTO'
import { IEventTicketVTO } from 'src/Models/Server/Response/Event/IEventTicketVTO'
import { IUserEventSto } from 'src/Models/Server/Response/Event/IUserEventSto'
import { IExceptionError } from 'src/Models/Server/Response/Exception/ExceptionError'
import { axiosAuth } from '../AuthAxios'

export default {
  EventInsert(request: ICreateEventRequest) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/event/insert`, request)
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  EventEdit(request: IEditEventRequest) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/event/edit`, request)
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  GetEvents() {
    return new Promise<IEventVTO[]>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/events`)
          .then((response: AxiosResponse<IEventVTO[]>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  GetEvent(eventId: string) {
    return new Promise<IEventVTO>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/event/${eventId}`)
          .then((response: AxiosResponse<IEventVTO>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  GetEventEdit(eventId: string) {
    return new Promise<IEventEditVTO>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/event/${eventId}/edit`)
          .then((response: AxiosResponse<IEventEditVTO>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  GetEventTicketEdit(eventTicketId: string) {
    return new Promise<IEventTicketEditVTO>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/eventticket/${eventTicketId}/edit`)
          .then((response: AxiosResponse<IEventTicketEditVTO>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  GetEventTickets(eventId: string) {
    return new Promise<IEventTicketVTO[]>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/event/${eventId}/tickets/`)
          .then((response: AxiosResponse<IEventTicketVTO[]>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  GetUserEventTickets(userGuid: string) {
    return new Promise<IUserEventSto[]>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/usereventtickets/${userGuid}`)
          .then((response: AxiosResponse<IUserEventSto[]>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  EventTicketInsert(request: ICreateEventTicketRequest) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/eventticket/insert`, request)
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  UserEventTicketInsert(
    userGuid: string,
    eventTicketId: string,
    amount: number
  ) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(
            `/api/v1/user/${userGuid}/eventticket/${eventTicketId}/insert/${amount}`,
            null
          )
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  //
  EventTicketEdit(request: IEditEventTicketRequest) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/eventticket/edit`, request)
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  DeleteUserEventTicket(userEventTicketId: number) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .delete(`/api/v1/usereventticket/${userEventTicketId}/delete`)
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
}
