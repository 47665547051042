import IBAN from 'iban'
import { observer } from 'mobx-react'
import React, { Fragment, useEffect } from 'react'
import { Col, FormGroup, Row } from 'react-bootstrap'

import { useForm, UseFormReturn } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import LoaderIcon from 'src/Components/LoaderIcon'
import McButton from 'src/Components/McButton'
//const  IBAN = require('iban');
import { MainPage, MyDropzone, MySelect, MyTextbox } from 'src/Components/My'
import { ReadFileContent } from 'src/Helpers/FileHelper'
import { IBankSelectOption } from 'src/Models/Server/Response/Bank/IBankDTO'
import { t } from '../../../../../I18n'
import { automaticChargeEditStore, IFormValues } from './Store'

const Edit: React.FC = () => {
  const { id, userGuid } = useParams<'id' | 'userGuid'>()
  useEffect(() => {
    automaticChargeEditStore.Initialize(id, userGuid)
  }, [null])

  const { IsLoading, ViewModel, IsSubmitting, User, Banks } =
    automaticChargeEditStore

  useEffect(() => {
    reset(ViewModel)
  }, [ViewModel])

  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({})
  const { handleSubmit, reset, getValues, setValue } = useFormProps

  const onSubmit = (data: IFormValues) => {
    automaticChargeEditStore.Edit(data)
  }

  return (
    <MainPage>
      <LoaderIcon IsLoading={IsLoading} />

      <br className="xs-10" />
      {!IsLoading && ViewModel !== undefined && User !== null && (
        <Fragment>
          <BreadCrumbs
            currentPageTitle={t.automaticChargeEdit.title}
            previousPages={[
              {
                to: '/users',
                title: t.helpdesk.title,
              },
              {
                to: `/user/details/${userGuid}`,
                title: User.Name,
              },
            ]}
          />
          <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
            <Portlet title={t.common.edit}>
              <Row>
                <Col md={6}>
                  <MySelect
                    formObject={ViewModel}
                    useFormProps={useFormProps}
                    name="SelectedBank"
                    label={t.automaticChargeInsert.fields.SelectedBank.label}
                    infoText={t.automaticChargeInsert.fields.SelectedBank.info}
                    rules={{
                      required:
                        t.automaticChargeInsert.fields.SelectedBank.errors
                          .required,
                      validate: (bank: IBankSelectOption) => {
                        const accountNr = getValues('AccountNumber')
                        //check when both are set
                        if (bank.BankId !== null && accountNr !== '') {
                          return (
                            accountNr.indexOf(bank.BankId) > -1 ||
                            t.automaticChargeInsert.fields.SelectedBank.errors
                              .bank
                          )
                        }
                        return true
                      },
                    }}
                    options={Banks}
                  />
                  <MyTextbox
                    formObject={ViewModel}
                    label={t.automaticChargeInsert.fields.AccountNumber.label}
                    name="AccountNumber"
                    useFormProps={useFormProps}
                    rules={{
                      required:
                        t.automaticChargeInsert.fields.AccountNumber.errors
                          .required,
                      validate: (value) => {
                        return (
                          IBAN.isValid(value) ||
                          t.automaticChargeInsert.fields.AccountNumber.errors
                            .iban
                        )
                      },
                    }}
                    maxLength={50}
                  />
                  <MyTextbox
                    formObject={ViewModel}
                    label={t.automaticChargeInsert.fields.AccountHolder.label}
                    name="AccountHolder"
                    useFormProps={useFormProps}
                    rules={{
                      required:
                        t.automaticChargeInsert.fields.AccountNumber.errors
                          .required,
                    }}
                    maxLength={70}
                  />

                  <Row>
                    <Col sm={6}>
                      <MyTextbox
                        formObject={ViewModel}
                        label={t.automaticChargeInsert.fields.StepAmount.label}
                        infoText={
                          t.automaticChargeInsert.fields.StepAmount.info
                        }
                        name="StepAmount"
                        useFormProps={useFormProps}
                        rules={{
                          required:
                            t.automaticChargeInsert.fields.StepAmount.errors
                              .required,
                        }}
                        type="number"
                        min={10}
                        max={150}
                      />
                    </Col>
                    <Col sm={6}>
                      <MyTextbox
                        formObject={ViewModel}
                        label={t.automaticChargeInsert.fields.StepLimit.label}
                        infoText={t.automaticChargeInsert.fields.StepLimit.info}
                        name="StepLimit"
                        useFormProps={useFormProps}
                        rules={{
                          required:
                            t.automaticChargeInsert.fields.StepLimit.errors
                              .required,
                        }}
                        type="number"
                        min={1}
                        max={10}
                      />
                    </Col>
                  </Row>
                  <FormGroup>
                    <MyDropzone
                      formObject={ViewModel}
                      useFormProps={useFormProps}
                      name="SepaFile"
                      label={t.automaticChargeInsert.fields.SepaFileData.label}
                      infoText={
                        t.automaticChargeInsert.fields.SepaFileData.info
                      }
                      dropFileText={
                        t.automaticChargeInsert.fields.SepaFileData.dropFile
                      }
                      rules={{
                        required:
                          t.automaticChargeInsert.fields.SepaFileData.errors
                            .required,
                      }}
                      accept={['image/jpeg', 'image/png', 'application/pdf']}
                      onChangeFile={(files: File[] | null) => {
                        if (files === null) {
                          //removed
                          setValue('FileData', '')
                          setValue('MimeType', '')
                        } else {
                          ReadFileContent(files, (base64, file) => {
                            setValue('FileData', base64)
                            setValue('MimeType', file.type)
                          })
                        }
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col mdOffset={8} md={4}>
                  <McButton
                    block
                    bsStyle="primary"
                    type="submit"
                    isLoading={IsSubmitting}
                  >
                    {t.common.save}
                  </McButton>
                </Col>
              </Row>
            </Portlet>
          </form>
        </Fragment>
      )}
    </MainPage>
  )
}

export default observer(Edit)
