import { observer } from 'mobx-react'
import React, { Fragment, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { McPageAlert } from 'src/Components'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import LoaderIcon from 'src/Components/LoaderIcon'
import McButton from 'src/Components/McButton'
import { MainPage, MySelect, MyTextbox } from 'src/Components/My'
import { t } from '../../../../I18n'
import { IFormValues, insertTerminalStore } from './Store'

const Insert: React.FC = () => {
  const params = useParams<'posGuid' | 'posName'>()
  const navigate = useNavigate()
  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({
    defaultValues: {
      Description: '',
    },
  })
  const { handleSubmit, reset } = useFormProps

  useEffect(() => {
    insertTerminalStore.Initialize(params.posGuid, params.posName)
  }, [null])

  const { ViewModel, IsLoading, IsSubmitting, Pos, TerminalTypes, PageAlert } =
    insertTerminalStore
  useEffect(() => {
    reset(ViewModel)
  }, [ViewModel])

  const onSubmit = (data: IFormValues) => {
    insertTerminalStore.Insert(
      params.posGuid,
      data.SelectedTerminalType.value,
      data.Description,
      navigate
    )
  }

  return (
    <MainPage>
      <LoaderIcon IsLoading={IsLoading} />

      <br className="xs-10" />
      {!IsLoading && ViewModel !== undefined && Pos !== undefined && (
        <Fragment>
          <BreadCrumbs
            currentPageTitle={t.terminal.insert.title}
            previousPages={[
              {
                to: '/merchants',
                title: t.common.merchants,
              },
              {
                to: `/merchant/details/${Pos.MerchantGuid}`,
                title: Pos.Name,
              },
              {
                to: `/location/details/${Pos.LocationGuid}`,
                title: Pos.LocationName,
              },
              {
                to: `/pos/details/${Pos.PosGuid}`,
                title: Pos.Name,
              },
            ]}
          />

          <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
            <Portlet title={t.common.terminal}>
              <Row>
                <Col md={6}>
                  <MyTextbox
                    formObject={{} as IFormValues}
                    label={t.terminal.insert.fields.Description.label}
                    name="Description"
                    useFormProps={useFormProps}
                    rules={{
                      required:
                        t.terminal.insert.fields.Description.errors.required,
                    }}
                    tabIndex={1}
                  />
                  <MySelect
                    formObject={{} as IFormValues}
                    useFormProps={useFormProps}
                    label={t.terminal.insert.fields.TerminalTypeID.label}
                    name="SelectedTerminalType"
                    rules={{
                      required:
                        t.terminal.insert.fields.TerminalTypeID.errors.required,
                    }}
                    options={TerminalTypes}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col mdOffset={8} md={4}>
                  <McButton
                    block
                    bsStyle="primary"
                    type="submit"
                    isLoading={IsSubmitting}
                  >
                    {t.common.save}
                  </McButton>
                </Col>
              </Row>
            </Portlet>
          </form>
        </Fragment>
      )}
      <McPageAlert pageAlert={PageAlert} />
    </MainPage>
  )
}

export default observer(Insert)
