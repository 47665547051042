import { action, makeObservable, observable, runInAction } from 'mobx'
import { IExceptionError } from 'src/Models/Server/Response/Exception/ExceptionError'
import { ILoyaltyProgramVTO } from 'src/Models/Server/Response/LoyaltyProgram/ILoyaltyProgramVTO'
import LoyaltyProgramController from 'src/Services/ToprCoreApi/Axios/Controllers/LoyaltyProgramController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export default class Store extends BaseStore {
  public LoyaltyPrograms: ILoyaltyProgramVTO[] = []

  constructor() {
    super()
    this.BaseInitialize()
    makeObservable(this, {
      LoyaltyPrograms: observable,
      Initialize: action,
      GetLoyaltyPrograms: action,
    })
  }

  public Initialize() {
    this.IsLoading = true
    this.GetLoyaltyPrograms()
  }

  public GetLoyaltyPrograms() {
    this.IsLoading = true
    LoyaltyProgramController.GetLoyaltyPrograms()
      .then((programs) => {
        runInAction(() => {
          this.LoyaltyPrograms = programs
          this.IsLoading = false
        })
      })
      .catch((error: IExceptionError) => {
        runInAction(() => (this.IsLoading = false))
        appUiStore.ShowError(error)
      })
  }
}

export const loyaltyProgramsStore = new Store()
