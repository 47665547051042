import React from 'react'
interface ILoaderIconProps {
  IsLoading: boolean
}

const LoaderIcon: React.FC<ILoaderIconProps> = ({ IsLoading }) => {
  if (IsLoading) {
    return <i className="fa fa-sync fa-spin" />
  }
  return null
}

export default LoaderIcon
