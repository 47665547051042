export const apikey = 'AIzaSyAHqdDaWvejwg-4Ko4c4aT7WG_kbXySBbE'
//https://maps.googleapis.com/maps/api/geocode/json?address=1600+Amphitheatre+Parkway,+Mountain+View,+CA&key=YOUR_API_KEY
//https://maps.googleapis.com/maps/api/geocode/json?key=YOUR_API_KEY&address=

const GeoCodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?key=${apikey}`

export const GetAddressComponent2Vm = (
  addressComponents: google.maps.GeocoderAddressComponent[],
  typeKey: string,
  viewModel: any,
  viewModelKey: string
) => {
  const comp = addressComponents.find((x) => x.types.indexOf(typeKey) > -1)

  if (comp) {
    viewModel[viewModelKey] = comp.long_name
  }
}

export const GetGeoCodeUrl = (
  city: string,
  streetName: string,
  streetNumber: string,
  zipcode: string
) => {
  let url = `${GeoCodeUrl}&address=`

  if (streetNumber) {
    url += streetNumber
  }

  if (streetName) {
    streetName = streetName.replace(' ', '+')
    url += `+${streetName}`
  }
  if (city) {
    city = city.replace(' ', '+')
    url += `,+${city}`
  }

  if (zipcode) {
    zipcode = zipcode.replace(' ', '')
    url += `,+${zipcode}`
  }

  return url
}

export const getCoords = (location: string): string[] => {
  return location.replace(new RegExp(',', 'g'), '.').split(':')
}
