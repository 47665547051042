import { observer } from 'mobx-react'
import React, { Fragment, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { McPageAlert } from 'src/Components'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import LoaderIcon from 'src/Components/LoaderIcon'
import McButton from 'src/Components/McButton'
import McMap from 'src/Components/McMap'
import { MainPage, MySlim, MyTextbox } from 'src/Components/My'
import IInsertLocationRequest from 'src/Models/Server/Request/Location/IInsertLocationRequest'
import { t } from '../../../../I18n'
import { locationInsertStore } from './Store'

const Insert: React.FC = () => {
  const params = useParams<'id'>()
  const navigate = useNavigate()
  useEffect(() => {
    locationInsertStore.Initialize(params.id)
  }, [null])

  const useFormProps: UseFormReturn<IInsertLocationRequest> =
    useForm<IInsertLocationRequest>({
      defaultValues: {
        Name: '',
        City: '',
        EmailAddress: '',
        POSOrderUrl: '',
        PhoneNumber: '',
        StreetName: '',
        StreetNumber: '',
        ZipCode: '',
      },
    })
  const { handleSubmit, getValues } = useFormProps

  const onSubmit = (data: IInsertLocationRequest) => {
    locationInsertStore.Insert(data, navigate)
  }

  const { IsLoading, IsSubmitting, Merchant, PageAlert, LatLong } =
    locationInsertStore

  const lat = LatLong === undefined ? '' : LatLong.lat.toString()
  const long = LatLong === undefined ? '' : LatLong.lng.toString()

  return (
    <MainPage>
      <LoaderIcon IsLoading={IsLoading} />

      <br className="xs-10" />
      {!IsLoading && Merchant !== null && (
        <Fragment>
          <BreadCrumbs
            currentPageTitle={t.location.insert.title}
            previousPages={[
              {
                to: '/merchants',
                title: t.common.merchants,
              },
              {
                to: `/merchant/details/${Merchant.MerchantGuid}`,
                title: Merchant.Name,
              },
            ]}
          />
          <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
            <Portlet title={t.common.location}>
              <Row>
                <Col md={6}>
                  <MyTextbox
                    formObject={{} as IInsertLocationRequest}
                    label={t.location.insert.fields.Name.label}
                    placeholder={t.location.insert.fields.Name.label}
                    name="Name"
                    useFormProps={useFormProps}
                    rules={{
                      required: t.location.insert.fields.Name.errors.required,
                    }}
                    tabIndex={1}
                  />
                  <MyTextbox
                    formObject={{} as IInsertLocationRequest}
                    label={t.location.insert.fields.Email.label}
                    placeholder={t.location.insert.fields.Email.label}
                    name="EmailAddress"
                    useFormProps={useFormProps}
                  />
                  <MyTextbox
                    formObject={{} as IInsertLocationRequest}
                    label={t.location.insert.fields.POSOrderUrl.label}
                    placeholder={t.location.insert.fields.POSOrderUrl.label}
                    name="POSOrderUrl"
                    useFormProps={useFormProps}
                  />
                  <Row>
                    <Col md={6}>
                      <MyTextbox
                        formObject={{} as IInsertLocationRequest}
                        label={t.location.insert.fields.ZipCode.label}
                        placeholder={t.location.insert.fields.ZipCode.label}
                        name="ZipCode"
                        useFormProps={useFormProps}
                      />
                    </Col>
                    <Col md={6}>
                      <MyTextbox
                        formObject={{} as IInsertLocationRequest}
                        label={t.location.insert.fields.StreetNumber.label}
                        placeholder={
                          t.location.insert.fields.StreetNumber.label
                        }
                        name="StreetNumber"
                        useFormProps={useFormProps}
                        onBlur={() =>
                          locationInsertStore.GeoCodeAddress(getValues())
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <MyTextbox
                        formObject={{} as IInsertLocationRequest}
                        label={t.location.insert.fields.StreetName.label}
                        placeholder={t.location.insert.fields.StreetName.label}
                        name="StreetName"
                        useFormProps={useFormProps}
                        onBlur={() =>
                          locationInsertStore.GeoCodeAddress(getValues())
                        }
                      />
                    </Col>
                    <Col md={6}>
                      <MyTextbox
                        formObject={{} as IInsertLocationRequest}
                        label={t.location.insert.fields.City.label}
                        placeholder={t.location.insert.fields.City.label}
                        name="City"
                        useFormProps={useFormProps}
                        onBlur={() =>
                          locationInsertStore.GeoCodeAddress(getValues())
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <MyTextbox
                        formObject={{} as IInsertLocationRequest}
                        label={t.location.insert.fields.Lat.label}
                        placeholder={t.location.insert.fields.Lat.label}
                        name="Lat"
                        value={lat}
                        useFormProps={useFormProps}
                        disabled={true}
                      />
                    </Col>
                    <Col md={6}>
                      <MyTextbox
                        formObject={{} as IInsertLocationRequest}
                        label={t.location.insert.fields.Long.label}
                        placeholder={t.location.insert.fields.Long.label}
                        name="Long"
                        value={long}
                        useFormProps={useFormProps}
                        disabled={true}
                      />
                    </Col>
                  </Row>
                  {LatLong !== undefined && (
                    <McMap lat={LatLong.lat} lng={LatLong.lng} zoom={16} />
                  )}
                </Col>
                <Col md={6}>
                  <MySlim
                    name="ImageFile"
                    formObject={{} as IInsertLocationRequest}
                    useFormProps={useFormProps}
                    label={t.savingcardInsert.fields.ImageFile.label}
                    sizeSlim={{ width: 600, height: 600 }}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col mdOffset={8} md={4}>
                  <McButton
                    block
                    bsStyle="primary"
                    type="submit"
                    isLoading={IsSubmitting}
                  >
                    {t.common.save}
                  </McButton>
                </Col>
              </Row>
            </Portlet>
          </form>
        </Fragment>
      )}
      <McPageAlert pageAlert={PageAlert} />
    </MainPage>
  )
}

export default observer(Insert)
