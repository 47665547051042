import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { ButtonToolbar } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { McPageAlert } from 'src/Components'
import McButton from 'src/Components/McButton'
import McModal from 'src/Components/Modals/McModal'
import { MyTextbox } from 'src/Components/My'
import { t } from 'src/I18n'
import { MyRegExps } from 'src/Models/Client'
import IEditAppUserRequest from 'src/Models/Server/Request/Merchant/IEditAppUserRequest'
import IAppUserVTO from 'src/Models/Server/Response/User/IAppUserVTO'
import { editAppUserStore } from './Store'

interface IProps {
  onClose: () => void

  appUser?: IAppUserVTO
}

const EditAppUserModal: React.FC<IProps> = ({
  onClose,

  appUser,
}) => {
  useEffect(() => {
    editAppUserStore.Initialize(appUser)
  }, [null])

  const { ViewModel, IsSubmitting, PageAlert } = editAppUserStore

  useEffect(() => {
    reset(ViewModel)
  }, [ViewModel])

  const useFormProps: UseFormReturn<IEditAppUserRequest> =
    useForm<IEditAppUserRequest>({})
  const { handleSubmit, reset } = useFormProps

  const onSubmit = (data: IEditAppUserRequest) => {
    editAppUserStore.EditAppUser(data).then(() => {
      onClose()
    })
  }

  if (ViewModel === undefined) {
    return null
  }

  return (
    <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
      <McModal
        title={t.merchant.details.appUsers.editUser}
        closeOnBackdrop={false}
        footer={
          <div>
            <McPageAlert pageAlert={PageAlert} />
            <ButtonToolbar className="pull-right">
              <McButton onClick={() => onClose()}>{t.common.cancel}</McButton>
              <McButton
                bsStyle="primary"
                type="submit"
                isLoading={IsSubmitting}
              >
                {t.common.save}
              </McButton>
            </ButtonToolbar>
          </div>
        }
        closeClicked={() => onClose()}
      >
        <MyTextbox
          formObject={ViewModel}
          label={t.addAppUserModal.fields.Name.label}
          name="Name"
          useFormProps={useFormProps}
          rules={{
            required: t.addAppUserModal.fields.Name.errors.required,
          }}
          maxLength={50}
          placeholder="Jan Jansen"
          tabIndex={1}
        />

        <MyTextbox
          formObject={ViewModel}
          label={t.addAppUserModal.fields.Email.label}
          name="Email"
          useFormProps={useFormProps}
          rules={{
            required: t.addAppUserModal.fields.Email.errors.required,
            pattern: {
              value: MyRegExps.EmailAdress,
              message: t.addAppUserModal.fields.Email.errors.regex,
            },
          }}
          maxLength={100}
          placeholder="jj@multicard.nl"
        />
        <MyTextbox
          formObject={ViewModel}
          label={t.addAppUserModal.fields.Password.label}
          name="Pincode"
          useFormProps={useFormProps}
          rules={{
            required: t.addAppUserModal.fields.Password.errors.required,
          }}
          maxLength={4}
          placeholder="0000"
        />
      </McModal>
    </form>
  )
}

export default observer(EditAppUserModal)
