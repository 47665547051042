import { action, makeObservable, observable, runInAction } from 'mobx'
import IGenericError from 'src/Models/Server/IGenericError'
import IAppUserVTO from 'src/Models/Server/Response/User/IAppUserVTO'
import AppUserController from 'src/Services/ToprCoreApi/Axios/Controllers/AppUserController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export default class Store extends BaseStore<IAppUserVTO[]> {
  public ShowAddUser: boolean = false

  public MerchantId: number = -1
  public EditAppUser: IAppUserVTO = null
  public AddPosModuleAppUser: IAppUserVTO = null

  constructor() {
    super()
    this.BaseInitialize([])
    makeObservable(this, {
      ShowAddUser: observable,
      EditAppUser: observable,
      MerchantId: observable,
      AddPosModuleAppUser: observable,
      GetAppUsers: action,
      ToggleAddUserModal: action,
      ToggleAddPosModuleModal: action,
      DeletePosModule: action,
      DeleteAppUser: action,
      ToggleEditUserModal: action,
    })
  }

  public GetAppUsers(merchantId: number) {
    this.MerchantId = merchantId
    this.IsLoading = true
    AppUserController.GetMerchantAppUsers(merchantId)
      .then((appusers) => {
        runInAction(() => {
          this.ViewModel = appusers
          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => {
          appUiStore.ShowError(error)
          this.IsLoading = null
        })
      })
  }

  public ToggleAddUserModal(showAddUserModal: boolean) {
    this.ShowAddUser = showAddUserModal
  }

  public ToggleAddPosModuleModal(addPosModuleAppUser: IAppUserVTO) {
    this.AddPosModuleAppUser = addPosModuleAppUser
  }

  public DeletePosModule(posModuleGuid: string, userGuid: string) {
    this.IsConfirming = true

    AppUserController.DeletePosModule(posModuleGuid)
      .then(() => {
        appUiStore.HideConfirm()
        this.GetAppUsers(this.MerchantId)
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        appUiStore.HideConfirm()
      })
  }

  public DeleteAppUser(userGuid: string) {
    this.IsConfirming = true

    AppUserController.Delete(userGuid)
      .then(() => {
        appUiStore.HideConfirm()
        runInAction(() => {
          const index = this.ViewModel.findIndex((x) => x.UserGuid === userGuid)
          if (index > -1) {
            this.ViewModel.splice(index, 1)
            this.ViewModel = [...this.ViewModel]
          }
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        appUiStore.HideConfirm()
      })
  }

  public ToggleEditUserModal(editAppUser?: IAppUserVTO) {
    this.EditAppUser = editAppUser
  }
}

export const appUsersGridStore = new Store()
