import { AxiosError, AxiosResponse } from 'axios'
import { handleException } from 'src/Helpers/ResponseHelpers'
import IEditLocationImageFileRequest from 'src/Models/Server/Request/Location/IEditLocationImageFileRequest'
import IEditLocationRequest from 'src/Models/Server/Request/Location/IEditLocationRequest'
import IInsertLocationRequest from 'src/Models/Server/Request/Location/IInsertLocationRequest'
import { IExceptionError } from 'src/Models/Server/Response/Exception/ExceptionError'
import ILocationVTO from 'src/Models/Server/Response/Location/ILocationVTO'
import { axiosAuth } from '../AuthAxios'

export default {
  GetMerchantLocations(guid: string) {
    return new Promise<ILocationVTO[]>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/locations/merchant/${guid}`)
          .then((response: AxiosResponse<ILocationVTO[]>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  GetLocation(guid: string) {
    return new Promise<ILocationVTO>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/location/${guid}`)
          .then((response: AxiosResponse<ILocationVTO>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  Insert(request: IInsertLocationRequest) {
    return new Promise<string>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/location/insert`, request)
          .then((response: AxiosResponse<string>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  Edit(request: IEditLocationRequest) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/location/edit`, request)
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  SetIsvisibleApp(locationGuid: string, isVisibleInApp: boolean) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/location/setisvisibleinapp`, {
            LocationGuid: locationGuid,
            IsVisibleInApp: isVisibleInApp,
          })
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  EditImage(request: IEditLocationImageFileRequest) {
    return new Promise<ILocationVTO>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/location/image/edit`, request)
          .then((response: AxiosResponse<ILocationVTO>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
}
