export const ReadFileContent = (
  files: File[],
  onLoad: (base64: string, file: File) => void
) => {
  const file = files[0]

  const reader = new FileReader()
  reader.onabort = () => console.log('file reading was aborted')
  reader.onerror = () => console.log('file reading has failed')
  reader.onload = () => {
    const dataUrl = reader.result as string
    const base64 = dataUrl.split(',')[1]

    onLoad(base64, file)
    //
  }

  reader.readAsDataURL(file)
}
