import { AxiosError, AxiosResponse } from 'axios'
import FileDownload from 'js-file-download'
import { FormatYearMonthDay } from 'src/Helpers/DateHelpers'
import { handleException } from 'src/Helpers/ResponseHelpers'
import IEditAutomaticChargeRequest from 'src/Models/Server/Request/AutomaticCharge/IEditAutomaticChargeRequest'
import IInsertAutomaticChargeRequest from 'src/Models/Server/Request/AutomaticCharge/IInsertAutomaticChargeRequest'
import { IAutomaticChargeCollectionVTO } from 'src/Models/Server/Response/AutomaticCharge/IAutomaticChargeCollectionVTO'
import { IAutomaticChargeVTO } from 'src/Models/Server/Response/AutomaticCharge/IAutomaticChargeVTO'
import { axiosAuth } from '../AuthAxios'
export default {
  AvailableAutomaticChargeCollections(merchantId: number) {
    return new Promise<number>((resolve, reject) => {
      axiosAuth
        .get(
          encodeURI(
            `/api/v1/automaticcharge/collections/available/${merchantId}`
          )
        )
        .then((response: AxiosResponse<number>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  GenerateAutomaticChargeCollectionFile(merchantId: number) {
    return new Promise<void>((resolve, reject) => {
      axiosAuth
        .post(`/api/v1/automaticcharge/generate/sepafile/${merchantId}`, null)
        .then(() => {
          resolve()
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  Last(id: string) {
    return new Promise<IAutomaticChargeVTO[]>((resolve, reject) => {
      axiosAuth
        .get(`/api/v1/automaticcharge/last/${id}`)
        .then((response: AxiosResponse<IAutomaticChargeVTO[]>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  GetAutomaticCharge(id: string) {
    return new Promise<IAutomaticChargeVTO>((resolve, reject) => {
      axiosAuth
        .get(`/api/v1/automaticcharge/${id}`)
        .then((response: AxiosResponse<IAutomaticChargeVTO>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  Insert(request: IInsertAutomaticChargeRequest) {
    return new Promise<void>((resolve, reject) => {
      axiosAuth
        .post(`/api/v1/automaticcharge/insert`, request)
        .then(() => {
          resolve()
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  Edit(request: IEditAutomaticChargeRequest) {
    return new Promise<void>((resolve, reject) => {
      axiosAuth
        .post(`/api/v1/automaticcharge/edit`, request)
        .then(() => {
          resolve()
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  TurnOff(userGuid: string, currencyGuid: string) {
    return new Promise<void>((resolve, reject) => {
      axiosAuth
        .post(`/api/v1/automaticcharge/turnoff`, {
          UserGuid: userGuid,
          CurrencyGuid: currencyGuid,
        })
        .then(() => {
          resolve()
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  GetStornosByCurrency(currencyGuid: string, statusId: string) {
    return new Promise<IAutomaticChargeCollectionVTO[]>((resolve, reject) => {
      axiosAuth
        .get(`/api/v1/automaticcharge/storno/${currencyGuid}/${statusId}`)
        .then((response: AxiosResponse<IAutomaticChargeCollectionVTO[]>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  GetBlockedStornosByUser(userGuid: string) {
    return new Promise<IAutomaticChargeCollectionVTO[]>((resolve, reject) => {
      axiosAuth
        .get(`/api/v1/automaticcharge/storno/blocked/user/${userGuid}`)

        .then((response: AxiosResponse<IAutomaticChargeCollectionVTO[]>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  SendStornoLetter(currencyGuid: string) {
    return new Promise<void>((resolve, reject) => {
      axiosAuth
        .post(`/api/v1/automaticcharge/storno/sendletter/${currencyGuid}`, null)
        .then((response: AxiosResponse<any>) => {
          FileDownload(
            response.data,
            `MailDataStornos_${FormatYearMonthDay(new Date())}.csv`
          )
          resolve()
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  GetMailData(currencyGuid: string, statusId: string) {
    return new Promise<void>((resolve, reject) => {
      axiosAuth
        .post(
          `/api/v1/automaticcharge/storno/maildata/${currencyGuid}/${statusId}`,
          null
        )
        .then((response: AxiosResponse<any>) => {
          FileDownload(
            response.data,
            `MailDataStornos_${FormatYearMonthDay(new Date())}.csv`
          )

          resolve()
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  ReadyForBlock(automaticChargeCollectionId: number) {
    return new Promise<void>((resolve, reject) => {
      axiosAuth
        .post(
          `/api/v1/automaticcharge/storno/readyforblock/${automaticChargeCollectionId}`,
          null
        )
        .then(() => {
          resolve()
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  BlockStorno(automaticChargeCollectionId: number) {
    return new Promise<void>((resolve, reject) => {
      axiosAuth
        .post(
          `/api/v1/automaticcharge/storno/block/${automaticChargeCollectionId}`,
          null
        )
        .then(() => {
          resolve()
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  UnblockStorno(automaticChargeCollectionId: number) {
    return new Promise<void>((resolve, reject) => {
      axiosAuth
        .post(
          `/api/v1/automaticcharge/storno/unblock/${automaticChargeCollectionId}`,
          null
        )
        .then(() => {
          resolve()
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
}
