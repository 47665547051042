import { observer } from 'mobx-react'
import React, { Fragment, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ActiveIcon from 'src/Components/Icons/ActiveIcon'
import LoaderIcon from 'src/Components/LoaderIcon'
import McButton from 'src/Components/McButton'
import McModal from 'src/Components/Modals/McModal'
import { MyDataTable } from 'src/Components/My'
import { t } from 'src/I18n'
import { GetImageFileUrl } from 'src/Images'
import ILocationVTO from 'src/Models/Server/Response/Location/ILocationVTO'
import { LocationRow, locationsGridStore } from './Store'

interface IProps {
  merchantGuid: string
}

const LocationsGrid: React.FC<IProps> = ({ merchantGuid }) => {
  useEffect(() => {
    locationsGridStore.GetLocations(merchantGuid)
  }, [null])
  const { ViewModel, IsLoading } = locationsGridStore

  const columns = [
    {
      dataField: 'Name',
      text: t.common.grid.name.label,
      //filter: textFilter({ placeholder: t.common.grid.name.placeholder }),
      formatter: (cell, data: ILocationVTO) => {
        const img =
          data.ImageFileGuid === null ||
          data.ImageFileGuid === undefined ? null : (
            <img
              src={GetImageFileUrl(data.ImageFileGuid)}
              className="thumb"
              onClick={() =>
                locationsGridStore.TogglePreview(data.ImageFileGuid)
              }
            />
          )

        return (
          <Fragment>
            <Link to={`/location/details/${data.LocationGuid}`}>
              <McButton bsSize="xsmall" bsStyle="primary" pullRight>
                {t.common.details}
              </McButton>
            </Link>
            <div>{data.Name}</div>
            {img}
          </Fragment>
        )
      },
    },
    {
      dataField: 'EmailAddress',
      text: t.common.grid.email.label,
      //  filter: textFilter({ placeholder: t.common.grid.email.placeholder }),
    },
    {
      dataField: 'IsVisibleApp',
      text: 'IsVisibleApp',
      formatter: (cell, data: LocationRow) => {
        const renderSetIsVisibleInAppButton = () => {
          if (data.IsVisibleInApp) {
            return (
              <McButton
                iconLeft="fa-ban"
                bsSize="xsmall"
                bsStyle="danger"
                isLoading={data.IsLoading}
                onClick={() => {
                  locationsGridStore.SetIsvisibleInApp(
                    data.LocationGuid,
                    !data.IsVisibleInApp
                  )
                }}
              >
                {t.location.setVisibleInActive}
              </McButton>
            )
          }

          return (
            <McButton
              iconLeft="fa-check"
              bsSize="xsmall"
              bsStyle="default"
              isLoading={data.IsLoading}
              onClick={() => {
                locationsGridStore.SetIsvisibleInApp(
                  data.LocationGuid,
                  !data.IsVisibleInApp
                )
              }}
            >
              {t.location.setVisibleActive}
            </McButton>
          )
        }

        return (
          <Fragment>
            <ActiveIcon active={data.IsVisibleInApp} />
            {renderSetIsVisibleInAppButton()}
          </Fragment>
        )
      },
    },
    // {
    //   dataField: 'actions',
    //   text: 'actions',
    //   isDummy: true,
    //   formatter: actionsCellFormatter
    // }
  ]

  const RenderPreview = () => {
    const { PreviewImage } = locationsGridStore

    if (PreviewImage === null) {
      return null
    }

    return (
      <McModal
        closeClicked={() => {
          locationsGridStore.TogglePreview(null)
        }}
        title={t.common.preview}
        size="lg"
      >
        <img src={GetImageFileUrl(PreviewImage)} className="preview" />
      </McModal>
    )
  }

  return (
    <Fragment>
      <Link to={`/location/insert/${merchantGuid}`}>
        <Button bsStyle="primary">{t.location.insert.title}</Button>
      </Link>
      <LoaderIcon IsLoading={IsLoading} />
      {!IsLoading && <MyDataTable data={ViewModel} columns={columns} />}
      {RenderPreview()}
    </Fragment>
  )
}

export default observer(LocationsGrid)
