import React from 'react'
import { NavLink } from 'react-router-dom'
import { hasMatchedChildren } from 'src/Helpers/UrlHelpers'
import { t } from 'src/I18n'
import { persistedStore } from 'src/Stores/PersistedStore'
import ReportGroupMenu from '../../ReportGroupMenu'
interface IProps {}

const ReportsMenu: React.FC<IProps> = () => {
  const { SelectedManagerUser } = persistedStore

  // SelectMany or flatmap
  const reportUrls = SelectedManagerUser.Reports.map((reportGroup) =>
    reportGroup.Reports.map((report) => report.Url)
  ).reduce((a, b) => {
    return a.concat(b)
  }, [])

  const hasActiveChildrenClassName = hasMatchedChildren(
    '/report/paymentreport',
    '/report/payment/day/merchant',
    '/report/user/balances',
    ...reportUrls
  )
    ? 'dropdown active'
    : 'dropdown'

  return (
    <li className={hasActiveChildrenClassName}>
      <a
        className="dropdown-toggle"
        data-toggle="dropdown"
        data-hover="dropdown"
      >
        {t.topMenu.reports.title}
        <i className="fa fa-chevron-down" />
      </a>

      <ul className={'dropdown-menu mega-menu-2'} role="menu">
        <li>
          <div className="mega-menu-col">
            <ul className="mega-menu-menu">
              <li className="mega-menu-header">
                {t.topMenu.reports.payments.groupName}
              </li>
              <li>
                <NavLink to="/report/paymentreport">
                  <i className={`fa fa-table dropdown-icon`} />
                  {t.topMenu.reports.payments.paymentsPeriod}
                </NavLink>
              </li>
              <li>
                <NavLink to={'/report/payment/day/merchant'}>
                  <i className={`fa fa-chart-bar dropdown-icon`} />
                  {t.topMenu.reports.payments.paymentsDay}
                </NavLink>
              </li>
            </ul>
            <ul className="mega-menu-menu">
              <li className="mega-menu-header">
                {t.topMenu.reports.wallets.groupName}
              </li>
              <li>
                <NavLink to={'/report/user/balances'}>
                  <i className={`fa fa-table dropdown-icon`} />
                  {t.userBalances.title}
                </NavLink>
              </li>
            </ul>
          </div>
          {SelectedManagerUser.Reports.map((reportGroup, index) => (
            <ReportGroupMenu key={index} reportGroup={reportGroup} />
          ))}
        </li>
      </ul>
    </li>
  )
}

export default ReportsMenu
