import { observer } from 'mobx-react'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { hasMatchedChildren } from 'src/Helpers/UrlHelpers'
import { t } from 'src/I18n'
import { persistedStore } from 'src/Stores/PersistedStore'
interface IProps {}

const SiedlerMenu: React.FC<IProps> = () => {
  const { SelectedManagerUser } = persistedStore

  if (SelectedManagerUser.Merchant && SelectedManagerUser.Merchant.Id !== 493) {
    return null
  }

  const hasActiveChildrenClassName = hasMatchedChildren('/siedler/companies')
    ? 'dropdown active'
    : 'dropdown'

  return (
    <li className={hasActiveChildrenClassName}>
      <a
        className="dropdown-toggle"
        data-toggle="dropdown"
        data-hover="dropdown"
      >
        Siedler
        <i className="fa fa-chevron-down" />
      </a>

      <ul className="dropdown-menu" role="menu">
        <li>
          <NavLink to={'/siedler/companies'}>
            <i className={`fa fa-table dropdown-icon`} />
            {t.common.company}
          </NavLink>
        </li>
      </ul>
    </li>
  )
}

export default SiedlerMenu
