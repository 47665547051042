import { action, makeObservable, observable, runInAction } from 'mobx'
import { IChartResponse } from 'src/Models/Server/Chart/IChartData'
import { IDashboardVTO } from 'src/Models/Server/Response/Dashobard/IDashboardVTO'
import { IExceptionError } from 'src/Models/Server/Response/Exception/ExceptionError'
import DashboardController from 'src/Services/ToprCoreApi/Axios/Controllers/DashboardController'
import ReportController from 'src/Services/ToprCoreApi/Axios/Controllers/ReportController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'
import Tenant from 'src/Tenants/Tenant'

export default class Store extends BaseStore {
  constructor() {
    super()

    makeObservable(this, {
      DashboardData: observable,
      ChartResponses: observable,
      Initialize: action,
    })
  }

  public get VersionText(): string {
    return `1.3.6 Copyright © ${Tenant.Name}`
  }

  public DashboardData: IDashboardVTO = null
  public ChartResponses: IChartResponse[] = []

  public Initialize() {
    this.IsLoading = true

    Promise.all([
      DashboardController.GetDashboard(),
      ReportController.GetPaymentsDashboard(),
    ])
      .then((values) => {
        runInAction(() => {
          this.IsLoading = false
          this.DashboardData = values[0]
          this.ChartResponses = values[1]
        })
      })
      .catch((error: IExceptionError) => {
        appUiStore.ShowError(error)
      })
  }
}

export const dashboardStore = new Store()
