import { action, makeObservable, runInAction } from 'mobx'
import IGenericError from 'src/Models/Server/IGenericError'
import { IFieldValueEditVTO } from 'src/Models/Server/Response/Field/IFieldValueEditVTO'
import {
  LocalizedStringDTO,
  TextBoxType,
} from 'src/Models/Server/Response/LocalizedString/LocalizedStringDTO'
import FieldController from 'src/Services/ToprCoreApi/Axios/Controllers/FieldController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'
import { persistedStore } from 'src/Stores/PersistedStore'

//export interface IFormValues {}

export class Store extends BaseStore<IFieldValueEditVTO> {
  constructor() {
    super()
    makeObservable(this, {
      Initialize: action,
      InsertFieldValue: action,
    })
  }

  public Initialize() {
    console.log('init')
    this.ViewModel = {
      Description: new LocalizedStringDTO(
        'FieldValue Description',
        TextBoxType.TextBox
      ),
    }
  }

  public InsertFieldValue(data: IFieldValueEditVTO, onClose: () => void) {
    this.IsSubmitting = true
    FieldController.InsertSiedlerCompany(data)
      .then(() => {
        onClose()
        runInAction(() => {
          this.IsSubmitting = false
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsSubmitting = false))
        appUiStore.ShowError(error)
      })
  }
}

export const siedlerCompanyAddStore = new Store()
