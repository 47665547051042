import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { FormGroup } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { McPageAlert } from 'src/Components'
import McButton from 'src/Components/McButton'
import { MainPage, MyTextbox } from 'src/Components/My'
import { t } from 'src/I18n'

import { IFormValues, resetPasswordStore } from './Store'

const ResetPassword: React.FC = () => {
  const navigate = useNavigate()
  useEffect(() => {
    resetPasswordStore.BaseInitialize()
  }, [null])

  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({
    defaultValues: {
      Password: '',
      PasswordConfirm: '',
    },
  })
  const { handleSubmit, getValues } = useFormProps

  const params = useParams<'id'>()
  const requestId = params.id

  const onSubmit = (data: IFormValues) => {
    resetPasswordStore.Submit(requestId, data.Password, navigate)
  }

  const { IsSubmitting, PageAlert } = resetPasswordStore
  return (
    <>
      <MainPage>
        <br className="xs-80" />
        <McPageAlert pageAlert={PageAlert} />
        <div className="account-wrapper">
          <div className="account-body">
            <h3>{t.resetPassword.header}</h3>
            {t.resetPassword.info}
            <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
              <MyTextbox
                formObject={{} as IFormValues}
                label={t.resetPassword.fields.password.label}
                name="Password"
                labelClassName="placeholder-hidden"
                useFormProps={useFormProps}
                rules={{
                  required: t.resetPassword.fields.password.errors.required,
                  minLength: {
                    value: 6,
                    message: t.resetPassword.fields.password.errors.min,
                  },
                }}
                placeholder={t.resetPassword.fields.password.label}
                tabIndex={1}
                type="password"
              />
              <MyTextbox
                formObject={{} as IFormValues}
                label={t.resetPassword.fields.passwordConfirm.label}
                name="PasswordConfirm"
                labelClassName="placeholder-hidden"
                useFormProps={useFormProps}
                rules={{
                  required:
                    t.resetPassword.fields.passwordConfirm.errors.required,
                  validate: (value) => {
                    return (
                      getValues('Password') === value ||
                      t.resetPassword.fields.passwordConfirm.errors.match
                    )
                  },
                }}
                placeholder={t.resetPassword.fields.passwordConfirm.label}
                tabIndex={2}
                type="password"
              />

              {/* validate: value => value === '1' */}
              {/*
              <McInput
                label={t.resetPassword.fields.password.label}
                name="Password"
                value={ViewModel.Password}
                onChange={(e) => this.Store.HandleInputChange(e)}
                validators={['required', 'minStringLength:6']}
                errorMessages={[
                  t.resetPassword.fields.password.errors.required,
                  t.resetPassword.fields.password.errors.min,
                ]}
                placeholder={t.resetPassword.fields.password.placeholder}
                type="password"
              />
              <McInput
                label={t.resetPassword.fields.passwordConfirm.label}
                name="PasswordConfirm"
                value={ViewModel.PasswordConfirm}
                onChange={(e) => this.Store.HandleInputChange(e)}
                validators={['required', 'isPasswordMatch']}
                errorMessages={[
                  t.resetPassword.fields.passwordConfirm.errors.required,
                  t.resetPassword.fields.passwordConfirm.errors.match,
                ]}
                type="password"
              />
*/}
              <FormGroup>
                <McButton
                  block
                  bsStyle="primary"
                  bsSize="large"
                  type="submit"
                  isLoading={IsSubmitting}
                  tabIndex={3}
                >
                  {t.resetPassword.submitText}
                </McButton>
              </FormGroup>
              <FormGroup>
                <Link to="/">
                  <i className="fa fa-angle-double-left" />
                  &nbsp;
                  {t.forgotPassword.login}
                </Link>
              </FormGroup>
            </form>
          </div>
        </div>
      </MainPage>
    </>
  )
}

export default observer(ResetPassword)
