import { action, makeObservable, runInAction } from 'mobx'
import { createPageAlert } from 'src/Components/McPageAlert'
import { t } from 'src/I18n'
import IGenericError from 'src/Models/Server/IGenericError'
import SamController from 'src/Services/ToprCoreApi/Axios/Controllers/SamController'
import BaseStore from 'src/Stores/BaseStore'

export interface IFormValues {
  SamId: string
}

export default class Store extends BaseStore<IFormValues> {
  constructor() {
    super()

    makeObservable(this, {
      Initialize: action,
      LinkSam: action,
    })
  }

  public Initialize() {
    this.BaseInitialize()
  }

  public LinkSam(samId: string, terminalId: string) {
    this.IsSubmitting = true
    return new Promise((resolve, reject) => {
      //validate sam, checked if it is already Linked
      SamController.GetSam(samId)
        .then((sam) => {
          if (sam.TerminalId !== null) {
            runInAction(() => {
              this.PageAlert = createPageAlert(
                {
                  Code: 'SAM_ALREADY_LINKED',
                  Message: t.formatString(
                    t.sam.link.samId.errors.alreadyLinked,
                    {
                      terminalId: sam.TerminalId,
                    }
                  ) as string,
                },
                ['SAM_ALREADY_LINKED']
              )
              this.IsSubmitting = false
            })
            reject()
          } else {
            //valid

            SamController.Link(samId, terminalId)
              .then(() => {
                runInAction(() => (this.IsSubmitting = false))
                resolve(null)
              })
              .catch((error: IGenericError) => {
                runInAction(() => {
                  this.IsSubmitting = false
                  this.PageAlert = createPageAlert(error)
                })
                reject()
              })
          }
        })
        .catch((error: IGenericError) => {
          runInAction(() => {
            this.IsSubmitting = false
            this.PageAlert = createPageAlert(error)
          })
          reject()
        })
    })
  }
}

export const linkSamStore = new Store()
