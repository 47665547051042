import React, { PropsWithChildren, useEffect } from 'react'

interface IPreviewModalProps {
  title: string
  footer?: React.ReactNode
  closeClicked: () => void
  size?: 'lg' | 'sm'
  className?: string
  closeOnBackdrop?: boolean
}

const Footer = (closeClicked: () => void, footer?: React.ReactNode) => {
  if (footer === undefined) {
    return (
      <button type="button" className="btn btn-primary" onClick={closeClicked}>
        Ok
      </button>
    )
  } else {
    return footer
  }
}

const McModal: React.FC<PropsWithChildren<IPreviewModalProps>> = ({
  title,
  closeClicked,
  children,
  footer,
  size,
  className,
  closeOnBackdrop,
}) => {
  // useEffect(() => {
  //   //close outside of modal
  //   const windowClick = () => {
  //     console.log('windowClick')
  //     closeClicked()
  //   }

  //   window.addEventListener('click', windowClick)

  //   return () => {
  //     window.removeEventListener('click', windowClick)
  //   }
  //   //}
  // }, [null])

  const modalSizeClass =
    size === undefined
      ? `modal-dialog ${className}`
      : `modal-dialog ${className} modal-${size}`

  return (
    // <div className="modal-backdrop" onClick={handleCloseClicked}>
    <div
      className="modal-backdrop"
      onClick={(e) => {
        if (closeOnBackdrop) {
          closeClicked()
        }
      }}
    >
      <div className="modal fade in">
        <div className={modalSizeClass} onClick={(e) => e.stopPropagation()}>
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                onClick={closeClicked}
                aria-hidden="true"
              >
                ×
              </button>
              <h3 className="modal-title">{title}</h3>
            </div>
            <div className="modal-body">{children}</div>
            <div className="modal-footer">{Footer(closeClicked, footer)}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

McModal.defaultProps = {
  closeOnBackdrop: true,
}

export default McModal
