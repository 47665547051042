import { action, makeObservable, observable, runInAction } from 'mobx'
import IGenericError from 'src/Models/Server/IGenericError'
import { IMerchantFileSTO } from 'src/Models/Server/Response/Merchant/IMerchantFile'
import AutomaticChargeController from 'src/Services/ToprCoreApi/Axios/Controllers/AutomaticChargeController'
import FileController from 'src/Services/ToprCoreApi/Axios/Controllers/FileController'
import MerchantController from 'src/Services/ToprCoreApi/Axios/Controllers/MerchantController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'
import { persistedStore } from 'src/Stores/PersistedStore'

export interface IFormValues {}

export class Store extends BaseStore<IFormValues> {
  public AvailableAutomaticChargeCollections: number = 0
  public MerchantFiles: IMerchantFileSTO[] = []

  public Page = 1
  public TotalRows = 1
  //  public PageSize = 10
  public PageSize = 2

  constructor() {
    super()
    makeObservable(this, {
      AvailableAutomaticChargeCollections: observable,
      MerchantFiles: observable,
      Page: observable,
      PageSize: observable,
      TotalRows: observable,
      Initialize: action,
      DownloadSepaFile: action,
      SetPagination: action,
      GetMerchantFiles: action,
    })
  }

  public Initialize() {
    this.IsLoading = true
    const merchantId = persistedStore.SelectedManagerUser.Merchant.Id

    Promise.all([
      AutomaticChargeController.AvailableAutomaticChargeCollections(merchantId),
      MerchantController.GetMerchantFiles(merchantId, this.Page, this.PageSize),
    ])
      .then((values) => {
        runInAction(() => {
          this.AvailableAutomaticChargeCollections = values[0]
          this.MerchantFiles = values[1].Rows
          this.TotalRows = values[1].TotalRows
          this.Page = 1
          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsLoading = false))
        appUiStore.ShowError(error)
      })
  }

  public SetPagination(page: number, pageSize) {
    //console.log('SetPagination', page, pageSize)

    this.Page = page
    this.PageSize = pageSize

    this.GetMerchantFiles()
  }

  public DownloadSepaFile(fileId: number) {
    FileController.DownloadFile(fileId)
      .then()
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsLoading = false))
        appUiStore.ShowError(error)
      })
  }

  public GetMerchantFiles() {
    const merchantId = persistedStore.SelectedManagerUser.Merchant.Id
    MerchantController.GetMerchantFiles(merchantId, this.Page, this.PageSize)
      .then((pagedReport) => {
        runInAction(() => {
          this.MerchantFiles = pagedReport.Rows
          this.TotalRows = pagedReport.TotalRows
          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsLoading = false))
        appUiStore.ShowError(error)
      })
  }

  public GenerateAutomaticChargeCollectionFile() {
    this.IsSubmitting = true
    const merchantId = persistedStore.SelectedManagerUser.Merchant.Id
    AutomaticChargeController.GenerateAutomaticChargeCollectionFile(merchantId)
      .then(() => {
        runInAction(() => {
          this.IsSubmitting = false

          this.Initialize()
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsSubmitting = false))
        appUiStore.ShowError(error)
      })
  }
}

export const sepaFileStore = new Store()
