import { ISelectOption } from 'src/Models/Client'
import INameIdVTO from './INameIdVTO'

export class INameGuidVTO {
  public Id: string
  public Name: string
}

export const SelectOptionsFromNameGuidVTO = (
  source: INameGuidVTO[]
): ISelectOption[] => {
  return source
    .sort((a, b) => {
      const nameA = a.Name.toLowerCase()
      const nameB = b.Name.toLowerCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })
    .map((nameValue) => {
      return { value: nameValue.Id, label: nameValue.Name }
    })
}

export const SelectOptionsFromNameIdVTO = (
  source: Array<INameIdVTO<number>>
): ISelectOption[] => {
  return source
    .sort((a, b) => {
      const nameA = a.Name.toLowerCase()
      const nameB = b.Name.toLowerCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })
    .map((nameValue) => {
      return { value: nameValue.Id.toString(), label: nameValue.Name }
    })
}

// export const SelectOptionsFromNameGuidVTOSingle = (
//   source: INameGuidVTO
// ): ISelectOption => {
//   return { value: source.Id, label: source.Name }
// }
