import { action, makeObservable, observable, runInAction } from 'mobx'
import IGenericError from 'src/Models/Server/IGenericError'
import IPosVTO from 'src/Models/Server/Response/Pos/IPosVTO'
import PosController from 'src/Services/ToprCoreApi/Axios/Controllers/PosController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export default class Store extends BaseStore<IPosVTO> {
  public ShowEditPos: boolean = false

  constructor() {
    super()

    makeObservable(this, {
      ShowEditPos: observable,

      Initialize: action,
      ToggleShowEditPos: action,
    })
  }
  public Initialize(posGuid: string) {
    this.IsLoading = true

    PosController.GetPos(posGuid)
      .then((response) => {
        runInAction(() => {
          this.ViewModel = response
          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        runInAction(() => (this.IsLoading = false))
      })
  }

  public ToggleShowEditPos(showEditLocation: boolean) {
    this.ShowEditPos = showEditLocation
  }
}

export const posDetailsStore = new Store()
