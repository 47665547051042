import { action, makeObservable, observable, runInAction } from 'mobx'
import { toast } from 'react-toastify'
import { createPageAlert } from 'src/Components/McPageAlert'
import { t } from 'src/I18n'
import IGenericError from 'src/Models/Server/IGenericError'
import IHelpDeskUserVTO from 'src/Models/Server/Response/User/IHelpDeskUserVTO'
import UserController from 'src/Services/ToprCoreApi/Axios/Controllers/UserController'
import BaseStore from 'src/Stores/BaseStore'

export interface IFormValues {
  Name: string
}
export default class Store extends BaseStore<IFormValues> {
  public Source: IHelpDeskUserVTO = null

  constructor() {
    super()
    makeObservable(this, {
      Source: observable,
      EditName: action,
      Initialize: action,
    })
  }

  public Initialize(user: IHelpDeskUserVTO) {
    this.BaseInitialize({
      Name: user.Name,
    })
    this.Source = user
  }

  public EditName(name: string) {
    this.IsSubmitting = true

    return new Promise<IHelpDeskUserVTO>((resolve, reject) => {
      UserController.EditName(this.Source.UserGuid, name)
        .then(() => {
          runInAction(() => {
            this.IsSubmitting = false
            this.Source.Name = name
          })
          toast.success(t.editName.success)
          resolve(this.Source)
        })
        .catch((error: IGenericError) => {
          runInAction(() => {
            this.PageAlert = createPageAlert(error)
            this.IsSubmitting = false
          })
          reject()
        })
    })
  }
}

export const editNameStore = new Store()
