import { observer } from 'mobx-react'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { hasMatchedChildren } from 'src/Helpers/UrlHelpers'
import { t } from 'src/I18n'
interface IProps {}

const SystemMenu: React.FC<IProps> = () => {
  const subscriptionActive = hasMatchedChildren('/subscriptions')
    ? 'dropdown active'
    : 'dropdown'

  return (
    <li className={subscriptionActive}>
      <a
        className="dropdown-toggle"
        data-toggle="dropdown"
        data-hover="dropdown"
      >
        System
        <i className="fa fa-chevron-down" />
      </a>
      <ul className="dropdown-menu" role="menu">
        <li>
          <NavLink to={'/subscriptions'}>
            <i className={`fa fa-newspaper dropdown-icon`} />
            {t.subscription.index.title}
          </NavLink>
        </li>
        <li>
          <NavLink to={'/events'}>
            <i className={`fa fa-calendar dropdown-icon`} />
            {t.event.index.title}
          </NavLink>
        </li>
        <li>
          <NavLink to={'/tags'}>
            <i className={`fa fa-tags dropdown-icon`} />
            {t.tags.index.title}
          </NavLink>
        </li>
        <li>
          <NavLink to={'/loyaltyprograms'}>
            <i className={`fa fa-piggy-bank dropdown-icon`} />
            {t.common.loyaltyPrograms}
          </NavLink>
        </li>
      </ul>
    </li>
  )

  //return <>{renderSubscriptions()}</>
}

export default observer(SystemMenu)
