import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { userPlanningStore } from './Store'
import UserBonusesPortlet from './Components/UserBonusesPortlet'
interface IProps {}

const PlanningTab: React.FC<IProps> = (props) => {
  const params = useParams<'id'>()
  useEffect(() => {
    userPlanningStore.Initialize(params.id)
  }, [null])
  return (
    <>
      <UserBonusesPortlet
        onTurnOff={(userScheduleBonusId) =>
          userPlanningStore.TurnOffUserScheduleBonus(
            userScheduleBonusId,
            params.id
          )
        }
      />
    </>
  )
}

export default observer(PlanningTab)
