import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { MainPage } from 'src/Components/My'
import { t } from 'src/I18n'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import { useParams } from 'react-router-dom'
import SubscriptionItemForm from '../Components/SubscriptionItemForm/SubscriptionItemForm'
import { useInsertConsumerSubscriptionItemMutation } from 'src/Services/ToprCoreApi/RTK/Slices/SubscriptionSlice'
import { IInsertConsumerSubscriptionItemRequest } from 'src/Models/Server/Request/ConsumerSubscription/IInsertConsumerSubscriptionItemRequest'
interface IProps {}

const InsertItem: React.FC<IProps> = (props) => {
  const params = useParams<'id'>()

  const [insertItem, { isLoading: isSubmitting }] =
    useInsertConsumerSubscriptionItemMutation()

  return (
    <>
      <MainPage>
        <BreadCrumbs
          currentPageTitle={t.subscription.insertItem.title}
          previousPages={[
            {
              to: `/subscriptions`,
              title: t.subscription.index.title,
            },
          ]}
        />
        <SubscriptionItemForm
          onSave={(data) => {
            const request: IInsertConsumerSubscriptionItemRequest = {
              ConsumerSubscriptionId: params.id,
              ConsumerSubscriptionItemTypeId: data.TypeOption.value,
              Amount: data.Amount,
              Description: data.Description,
              Name: data.Name,
              ImageFile: data.ImageFile,
            }
            insertItem(request)
              .then(() => history.back())
              .catch((rejected) => console.error(rejected))
          }}
          title={t.subscription.insertItem.title}
          isSubmitting={isSubmitting}
        />
      </MainPage>
    </>
  )
}

export default observer(InsertItem)
