import deDE from 'date-fns/locale/de'
import enGb from 'date-fns/locale/en-GB'
import nlNl from 'date-fns/locale/nl'
import { makeAutoObservable, runInAction } from 'mobx'
import { makePersistable, clearPersistedStore } from 'mobx-persist-store'

import { t } from 'src/I18n'
import IGenericError from 'src/Models/Server/IGenericError'
import { IExceptionError } from 'src/Models/Server/Response/Exception/ExceptionError'
import { AuthenticateUserResponse } from 'src/Models/Server/Response/User/AuthenticateUserResponse'
import ManagerUserVTO from 'src/Models/Server/Response/User/ManagerUserVTO'
import ManagerUserController from 'src/Services/ToprCoreApi/Axios/Controllers/ManagerUserController'

export class PersistedStore {
  public Language: string = 'nl' //initialize in nl

  public IsHydrated = false

  /**
   * Force users to login again
   * Last time required for: ManagerUser.ConsumerSubscriptionIds
   */
  public ResetLoginDate = new Date(2022, 6, 4)

  public LastLoginDate?: Date = undefined
  public PurgeDate?: Date = undefined

  public AuthenticateUserResponse?: AuthenticateUserResponse = null

  public SelectedManagerUser: ManagerUserVTO = null

  constructor() {
    makeAutoObservable(this)

    makePersistable(this, {
      name: 'PersistedStore',
      properties: [
        'AuthenticateUserResponse',
        'SelectedManagerUser',
        'Language',
        'LastLoginDate',
        'PurgeDate',
      ],
      storage: window.localStorage,
    }).then(() => {
      runInAction(() => {
        this.SetIsHydrated()

        //check ResetLogin
        if (
          this.ResetLoginDate !== undefined &&
          this.LastLoginDate !== undefined &&
          (this.PurgeDate === undefined ||
            new Date(this.PurgeDate) < this.ResetLoginDate) && //check purge date
          new Date(this.LastLoginDate) > this.ResetLoginDate
        ) {
          this.PurgePersistedStore()
        }

        if (this.SelectedManagerUser === null) {
          this.Logoff()
          return
        }

        this.SetLanguage(this.Language) //also set i18n.js
      })
    })
  }

  private PurgePersistedStore() {
    //Force New Login, when a user login gain, new data is loaded
    //this is only run once when this.PurgeDate === undefined || new Date(this.PurgeDate) < this.ResetLoginDate
    // to reset again set a new ResetLoginDate
    clearPersistedStore(this).then(() => {
      this.Logoff()
      runInAction(() => (this.PurgeDate = new Date()))
    })
  }

  get IsMainUser() {
    if (this.AuthenticateUserResponse && this.SelectedManagerUser) {
      return (
        this.AuthenticateUserResponse.AuthenticatedUserGuid ===
        this.SelectedManagerUser.UserGuid
      )
    }

    return false
  }

  get IsAuthenticated() {
    return this.AuthenticateUserResponse !== null
  }

  //used in ServiceHelper RefreshToken
  public SetAuthenticatedUserResponse(response: AuthenticateUserResponse) {
    this.AuthenticateUserResponse = response
    this.LastLoginDate = new Date()
  }

  public Logoff() {
    this.AuthenticateUserResponse = null
    this.SelectedManagerUser = null
  }

  private SetSelectedManagerUser(selectedManagerUser: ManagerUserVTO) {
    this.SelectedManagerUser = selectedManagerUser
  }

  //login and switch user
  public GetAuthenticatedMerchantUser() {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        ManagerUserController.GetAuthenticateMerchantUser()
          .then((managerUser) => {
            this.SetSelectedManagerUser(managerUser)
            //this.PurgeDate = new Date()
            resolve()
          })
          .catch((error: IExceptionError) => {
            reject(error)
          })
      }
    )
  }

  public SwitchSelectedManagerUser(userGuid: string) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        //1) switch
        ManagerUserController.SelectManagerUser(
          this.AuthenticateUserResponse.AuthenticatedUserGuid,
          userGuid
        )
          .then((response) => {
            // 2) Get merchantUser
            runInAction(() => (this.AuthenticateUserResponse = response))
            this.GetAuthenticatedMerchantUser()
              .then(() => {
                window.location.href = '/dashboard'
                resolve()
              })
              .catch((error: IExceptionError) => {
                reject(error)
              })
          })
          .catch((error: IGenericError) => {
            reject(error)
          })
      }
    )
  }

  public SetHasvalidSubscription(hasValidSubscription: boolean) {
    this.SelectedManagerUser.HasValidSubscription = hasValidSubscription
  }

  public SetIsHydrated() {
    this.IsHydrated = true
  }

  public SetLanguage(lang: string, reload: boolean = false) {
    this.Language = lang
    t.setLanguage(lang) //I18n

    //reload when switching languages
    if (reload) {
      // eslint-disable-next-line no-self-assign
      window.location = window.location
      // this.GetAuthenticatedMerchantUser().then(() => {
      //   window.location = window.location
      // })
    }
  }

  public GetCurrentDateLocale() {
    switch (this.Language) {
      case 'nl':
        return nlNl
      case 'de':
        return deDE
      case 'en':
      default:
        return enGb
    }
  }
}

export const persistedStore = new PersistedStore()
