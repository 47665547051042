import React from 'react'

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          {/* /.col */}
          <div className="col-sm-4">
            <div className="heading-block">
              <h4>Contact</h4>
            </div>
            {/* /.heading-block */}
            <ul className="icons-list">
              <li>
                <i className="icon-li fa fa-home" />
                Einsteinstraat 8 <br />
                3261 LP, Oud-Beijerland
              </li>
              <li>
                <i className="icon-li fa fa-phone" />
                (+31) (0)186 - 636 530
              </li>
              <li>
                <i className="icon-li fa fa-envelope" />
                <a href="mailto:sales@toprbs.nl">sales@toprbs.nl</a>
              </li>
              {/* <li>
                <i className="icon-li fa fa-map-marker" />
                <a>Map</a>
              </li> */}
            </ul>
          </div>
          {/* /.col */}
          <div className="col-sm-4">
            <div className="heading-block">
              <h4>Connect </h4>
            </div>
            {/* /.heading-block */}
            <ul className="icons-list">
              <li>
                <i className="icon-li fab fa-facebook" />
                <a>Facebook</a>
              </li>
              <li>
                <i className="icon-li fab fa-twitter" />
                <a>Twitter</a>
              </li>
            </ul>
          </div>
          {/* /.col */}
          <div className="col-sm-4">
            <div className="heading-block">
              <h4>Blijf op de hoogte</h4>
            </div>
            <form action="/" className="form">
              <div className="form-group">
                {/* <label>Email: <span class="required">*</span></label> */}
                <input
                  className="form-control"
                  id="newsletter_email"
                  name="newsletter_email"
                  type="text"
                  required
                  placeholder="Email Address"
                />
              </div>
              {/* /.form-group */}
              <div className="form-group">
                <button className="btn btn-transparent">Subscribe Me</button>
              </div>
              {/* /.form-group */}
            </form>
          </div>
          {/* /.col */}
        </div>
        <div className="row">
          <div className="col-sm-12">
            <p>
              Copyright © <a href="https://www.jijbenteentopr.nl">Topr</a>.
            </p>
          </div>
          {/* /.col */}
        </div>
      </div>
      {/* /.container */}
    </footer>
  )
}

export default Footer
