import { IEditConsumerSubscriptionRequest } from 'src/Models/Server/Request/ConsumerSubscription/EditConsumerSubscriptionRequest'
import { ICreateUserSubscriptionRequest } from 'src/Models/Server/Request/ConsumerSubscription/ICreateUserSubscriptionRequest'
import { IEditConsumerSubscriptionItemRequest } from 'src/Models/Server/Request/ConsumerSubscription/IEditConsumerSubscriptionItemRequest'
import { IEditUserSubscriptionRequest } from 'src/Models/Server/Request/ConsumerSubscription/IEditUserSubscriptionRequest'
import { IInsertConsumerSubscriptionItemRequest } from 'src/Models/Server/Request/ConsumerSubscription/IInsertConsumerSubscriptionItemRequest'
import { IInsertConsumerSubscriptionRequest } from 'src/Models/Server/Request/ConsumerSubscription/IInsertConsumerSubscriptionRequest'
import { IConsumerSubscriptionSto } from 'src/Models/Server/Response/ConsumerSubscription/IConsumerSubscriptionSto'
import { IEditConsumerSubscriptionItemVTO } from 'src/Models/Server/Response/ConsumerSubscription/IEditConsumerSubscriptionItemVTO'
import { IEditConsumerSubscriptionVTO } from 'src/Models/Server/Response/ConsumerSubscription/IEditConsumerSubscriptionVTO'
import { IUserSubscriptionSto } from 'src/Models/Server/Response/ConsumerSubscription/IUserSubscriptionSto'
import { IUserSubscriptionVTO } from 'src/Models/Server/Response/ConsumerSubscription/IUserSubscriptionVTO'
import { SetMissingLanguageValues } from 'src/Models/Server/Response/LocalizedString/LocalizedStringDTO'
import { toprCoreApi } from '../ToprCoreApi'

const subscriptionSlice = toprCoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getConsumerSubscriptions: builder.query<IConsumerSubscriptionSto[], void>({
      query: () => `/api/v1/subscriptions/consumer`,
      keepUnusedDataFor: 0,
    }),
    getUserSubscriptions: builder.query<IUserSubscriptionSto[], string>({
      query: (userGuid) => `/api/v1/subscriptions/user/${userGuid}`,
      keepUnusedDataFor: 0,
    }),
    getUserSubscription: builder.query<IUserSubscriptionVTO, string>({
      query: (userSubscriptionId) =>
        `/api/v1/subscription/user/${userSubscriptionId}`,
      keepUnusedDataFor: 0,
    }),
    getConsumerSubscription: builder.query<IConsumerSubscriptionSto, string>({
      query: (consumerSubscriptionId) =>
        `/api/v1/subscription/${consumerSubscriptionId}`,
      //keepUnusedDataFor: 10,
    }),
    getEditConsumerSubscriptionItem: builder.query<
      IEditConsumerSubscriptionItemVTO,
      string
    >({
      query: (consumerSubscriptionItemId) =>
        `/api/v1/subscription/item/${consumerSubscriptionItemId}/edit`,
      keepUnusedDataFor: 1,
      transformResponse: (response: IEditConsumerSubscriptionItemVTO) => {
        SetMissingLanguageValues(response.Name)
        SetMissingLanguageValues(response.Description)

        return response
      },
    }),
    getEditConsumerSubscription: builder.query<
      IEditConsumerSubscriptionVTO,
      string
    >({
      query: (consumerSubscriptionId) =>
        `/api/v1/subscription/${consumerSubscriptionId}/edit`,
      keepUnusedDataFor: 1,
      transformResponse: (response: IEditConsumerSubscriptionVTO) => {
        SetMissingLanguageValues(response.Name)
        return response
      },
    }),

    //mutations
    editConsumerSubscriptionItem: builder.mutation<
      void,
      IEditConsumerSubscriptionItemRequest
    >({
      query: (request) => ({
        url: `/api/v1/subscription/item/edit`,
        method: 'POST',
        body: request,
      }),
    }),
    insertConsumerSubscriptionItem: builder.mutation<
      void,
      IInsertConsumerSubscriptionItemRequest
    >({
      query: (request) => ({
        url: `/api/v1/subscription/item/insert`,
        method: 'POST',
        body: request,
      }),
    }),
    deleteConsumerSubscriptionItem: builder.mutation<void, number>({
      query: (consumerSubscriptionItemId: number) => ({
        url: `/api/v1/subscription/${consumerSubscriptionItemId}/item`,
        method: 'DELETE',
      }),
    }),
    insertConsumerSubscription: builder.mutation<
      void,
      IInsertConsumerSubscriptionRequest
    >({
      query: (request) => ({
        url: `/api/v1/subscription/insert`,
        method: 'POST',
        body: request,
      }),
    }),
    editConsumerSubscription: builder.mutation<
      void,
      IEditConsumerSubscriptionRequest
    >({
      query: (request) => ({
        url: `/api/v1/subscription/edit`,
        method: 'POST',
        body: request,
      }),
    }),
    deleteUserSubscription: builder.mutation<void, number>({
      query: (userSubscriptionId: number) => ({
        url: `/api/v1/subscription/user/${userSubscriptionId}/delete`,
        method: 'DELETE',
      }),
    }),
    insertUserSubscription: builder.mutation<
      void,
      ICreateUserSubscriptionRequest
    >({
      query: (request) => ({
        url: `/api/v1/subscription/user/insert`,
        method: 'POST',
        body: request,
      }),
    }),
    editUserSubscription: builder.mutation<void, IEditUserSubscriptionRequest>({
      query: (request) => ({
        url: `/api/v1/subscription/user/edit`,
        method: 'POST',
        body: request,
      }),
    }),
  }),
})

export const {
  useGetEditConsumerSubscriptionItemQuery,
  useGetConsumerSubscriptionsQuery,
  useGetEditConsumerSubscriptionQuery,
  useGetConsumerSubscriptionQuery,
  useGetUserSubscriptionQuery,
  useInsertConsumerSubscriptionMutation,
  useEditConsumerSubscriptionItemMutation,
  useInsertConsumerSubscriptionItemMutation,
  useDeleteConsumerSubscriptionItemMutation,
  useEditConsumerSubscriptionMutation,
  useGetUserSubscriptionsQuery,
  useDeleteUserSubscriptionMutation,
  useInsertUserSubscriptionMutation,
  useEditUserSubscriptionMutation,
} = subscriptionSlice
