import { observer } from 'mobx-react'
import React, { Fragment, useEffect } from 'react'
import { Col, FormGroup, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import LoaderIcon from 'src/Components/LoaderIcon'
import McButton from 'src/Components/McButton'
import McMap from 'src/Components/McMap'
import EditImage from 'src/Components/Modals/EditImage/EditImage'
import { MainPage, MyCheckbox, MyCheckboxLabel } from 'src/Components/My'
import { t } from 'src/I18n'
import { PosOrderDeliveryTypesDTO, TenantIds } from 'src/Models/Server/Enums'
import EditLocationModal from './EditLocationModal/EditLocationModal'
import PosGrid from './PosGrid/PosGrid'
import { locationDetailsStore } from './Store'

const Details: React.FC = () => {
  const params = useParams<'id'>()
  const id = params.id

  useEffect(() => {
    locationDetailsStore.Initialize(id)
  }, [null])

  const { ViewModel, IsLoading, IsSubmitting } = locationDetailsStore

  const renderMap = () => {
    if (ViewModel === undefined) {
      return null
    }

    return <McMap lat={ViewModel.Lat} lng={ViewModel.Long} zoom={16} />
  }

  const renderImage = () => {
    return (
      <Fragment>
        <LoaderIcon IsLoading={IsSubmitting} />
        {!IsSubmitting && (
          <EditImage
            fileGuid={ViewModel.ImageFileGuid}
            label={t.common.image}
            onSubmitSuccess={(imageFile) => {
              locationDetailsStore.EditLocationImage(imageFile)
            }}
          />
        )}
      </Fragment>
    )
  }

  const renderEditLocation = () => {
    const { ShowEditLocation, ViewModel } = locationDetailsStore

    if (!ShowEditLocation) {
      return null
    }

    return (
      <EditLocationModal
        onClose={() => locationDetailsStore.ToggleShowEditLocation(false)}
        onSubmitSuccess={(viewModel) => {
          locationDetailsStore.UpdateViewModel(viewModel)
        }}
        editLocation={{ ...ViewModel }}
      />
    )
  }

  return (
    <MainPage>
      <LoaderIcon IsLoading={IsLoading} />

      <br className="xs-10" />
      {!IsLoading && ViewModel !== undefined && (
        <Fragment>
          <BreadCrumbs
            currentPageTitle={ViewModel.Name}
            previousPages={[
              {
                to: '/merchants',
                title: t.common.merchants,
              },
              {
                to: `/merchant/details/${ViewModel.MerchantGuid}`,
                title: ViewModel.MerchantName,
              },
            ]}
          />

          <Portlet title={t.common.location}>
            <Row className="details">
              <Col md={6}>
                <FormGroup>
                  <label>Id</label>
                  <span>{ViewModel.LocationId}</span>
                </FormGroup>
                <FormGroup>
                  <label>
                    {t.location.insert.fields.Name.label}
                    <McButton
                      iconLeft="fa-edit"
                      bsStyle="primary"
                      bsSize="xsmall"
                      pullRight
                      onClick={() => {
                        locationDetailsStore.ToggleShowEditLocation(true)
                      }}
                    >
                      {t.common.edit}
                    </McButton>
                  </label>
                  <span>{ViewModel.Name}</span>
                </FormGroup>
                <FormGroup>
                  <label>{t.location.insert.fields.Email.label}</label>
                  <span>{ViewModel.EmailAddress}</span>
                </FormGroup>
                <FormGroup>
                  <label>Tenant</label>
                  <span>{TenantIds[ViewModel.TenantId]}</span>
                </FormGroup>
                <FormGroup>
                  <label>{t.location.insert.fields.POSOrderUrl.label}</label>
                  <span>{ViewModel.POSOrderUrl}</span>
                </FormGroup>
                <FormGroup>
                  <label>Delivery Type</label>
                  <span>
                    {PosOrderDeliveryTypesDTO[ViewModel.PosOrderDeliveryTypeID]}
                  </span>
                </FormGroup>
                <FormGroup>
                  <MyCheckboxLabel
                    checked={ViewModel.IsVisibleInApp}
                    label={t.location.insert.fields.IsVisibleInApp.label}
                  />
                </FormGroup>
                <FormGroup>
                  <label>{t.common.address}</label>
                  <span>
                    {ViewModel.StreetName} {ViewModel.StreetNumber}
                  </span>
                  <br />
                  <span>
                    {ViewModel.ZipCode} {ViewModel.City}
                  </span>
                </FormGroup>
                {renderMap()}
              </Col>
              <Col md={6}>
                <FormGroup>{renderImage()}</FormGroup>
              </Col>
            </Row>
          </Portlet>
          <Portlet title="Pos">
            <PosGrid
              locationGuid={ViewModel.LocationGuid}
              locationName={ViewModel.Name}
              merchantGuid={ViewModel.MerchantGuid}
            />
          </Portlet>
        </Fragment>
      )}
      {renderEditLocation()}
    </MainPage>
  )
}

export default observer(Details)
