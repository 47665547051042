import { observer } from 'mobx-react'
import React, { Fragment, useEffect } from 'react'
import { Button, ButtonToolbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ActiveIcon from 'src/Components/Icons/ActiveIcon'
import LoaderIcon from 'src/Components/LoaderIcon'
import McButton from 'src/Components/McButton'
import { MyDataTable } from 'src/Components/My'
import { t } from 'src/I18n'
import IPosVTO from 'src/Models/Server/Response/Pos/IPosVTO'
import { appUiStore } from 'src/Stores/AppUiStore'
import { posGridStore } from './Store'

interface IProps {
  locationGuid: string
  locationName: string
  merchantGuid: string
}

const PosGrid: React.FC<IProps> = ({
  locationGuid,
  locationName,
  merchantGuid,
}) => {
  useEffect(() => {
    posGridStore.GetPosByLocation(locationGuid)
  }, [null])

  const { ViewModel, IsLoading } = posGridStore

  const actionsCellFormatter = (cell, data: IPosVTO) => {
    const isSubmitting =
      posGridStore.EditPosGuid === data.PosGuid && posGridStore.IsSubmitting

    return (
      <ButtonToolbar>
        <Link to={`/pos/details/${data.PosGuid}`}>
          <McButton bsSize="small" bsStyle="primary">
            {t.common.details}
          </McButton>
        </Link>

        {!data.IsActive && (
          <McButton
            iconLeft="fa-check"
            bsSize="small"
            bsStyle="default"
            isLoading={isSubmitting}
            onClick={() => {
              appUiStore.ShowConfirm({
                message: t.formatString(t.pos.activate.confirmMessage, {
                  name: data.Name,
                }),
                confirmAction: () => posGridStore.Activate(data.PosGuid),
                confirmActionText: t.pos.activate.action,
              })
            }}
          >
            {t.pos.activate.action}
          </McButton>
        )}
        {data.IsActive && (
          <McButton
            iconLeft="fa-ban"
            bsSize="small"
            bsStyle="danger"
            isLoading={isSubmitting}
            onClick={() => {
              appUiStore.ShowConfirm({
                message: t.formatString(t.pos.deactivate.confirmMessage, {
                  name: data.Name,
                }),
                confirmAction: () => posGridStore.Deactivate(data.PosGuid),
                confirmActionText: t.pos.deactivate.action,
              })
            }}
          >
            {t.pos.deactivate.action}
          </McButton>
        )}
      </ButtonToolbar>
    )
  }

  const posCellFormatter = (cell, data: IPosVTO) => {
    return <ActiveIcon active={data.IsActive} text={data.Name} />
  }

  const columns = [
    {
      dataField: 'Name',
      text: t.common.grid.name.label,
      formatter: posCellFormatter,
      //filter: textFilter({ placeholder: t.common.grid.name.placeholder }),
    },
    {
      dataField: 'PosTypeName',
      text: t.pos.insert.fields.PosTypeID.label,
      // filter: textFilter({
      //   placeholder: t.pos.insert.fields.PosTypeID.label,
      // }),
    },
    {
      dataField: 'actions',
      csvExport: false,
      text: '',
      formatter: actionsCellFormatter,
    },
  ]

  return (
    <Fragment>
      <Link to={`/pos/insert/${locationGuid}/${merchantGuid}/${locationName}`}>
        <Button bsStyle="primary">{t.pos.insert.title}</Button>
      </Link>
      <LoaderIcon IsLoading={IsLoading} />
      {!IsLoading && <MyDataTable data={ViewModel} columns={columns} />}
    </Fragment>
  )
}

export default observer(PosGrid)
