import { ISelectOption } from 'src/Models/Client'

export interface IBankDTO {
  BankId: string
  Bic: string
  Name: string
}

//convert to bankOption
export interface IBankSelectOption extends IBankDTO, ISelectOption {}
export const SelectOptionsFromBank = (
  source: IBankDTO[]
): IBankSelectOption[] => {
  return source
    .sort((a, b) => {
      const nameA = a.Name.toLowerCase()
      const nameB = b.Name.toLowerCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })
    .map((bank) => {
      return {
        value: bank.Bic,
        label: `${bank.Name} (${bank.BankId})`,
        ...bank,
      }
    })
}
