import React, { CSSProperties, ReactNode, PropsWithChildren } from 'react'

interface IProps {
  title?: string
  subTitle?: string
  subTitleBadge?: ReactNode
  headerContent?: ReactNode
  backgroundUrl?: string
  className?: string
}

const Portlet: React.FC<PropsWithChildren<IProps>> = ({
  title,
  subTitle,
  subTitleBadge,
  backgroundUrl,
  className,
  children,
  headerContent,
}) => {
  const style: CSSProperties = backgroundUrl
    ? {
        backgroundImage: `url(
          ${backgroundUrl}
        )`,
      }
    : null

  const renderTitle = () => {
    if (title) {
      return <h4 className="portlet-title">{title}</h4>
    }

    return null
  }

  const renderSubTitle = () => {
    if (subTitle) {
      return (
        <h5 className="portlet-title">
          {subTitle}
          {subTitleBadge}
        </h5>
      )
    }

    return null
  }

  const renderHeaderContent = () => {
    if (headerContent) {
      return headerContent
    }
    return null
  }

  const renderHeader = () => {
    if (title || subTitle || headerContent) {
      return (
        <div className="portlet-header">
          {renderTitle()}
          {renderSubTitle()}
          {renderHeaderContent()}
        </div>
      )
    }

    return null
  }
  return (
    <div className={`portlet portlet-boxed ${className}`}>
      {renderHeader()}
      <div className="portlet-body" style={style}>
        {children}
      </div>
    </div>
  )
}

export default Portlet
