import { AxiosError, AxiosResponse } from 'axios'
import { handleException } from 'src/Helpers/ResponseHelpers'
import { INameGuidVTO } from 'src/Models/Server/INameGuidVTO'
import CurrencyVTO from 'src/Models/Server/Response/Currency/CurrencyVTO'
import { IExceptionError } from 'src/Models/Server/Response/Exception/ExceptionError'
import { axiosAuth } from '../AuthAxios'

export default {
  GetUserCurrencies(userGuid: string) {
    return new Promise<INameGuidVTO[]>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/currencies/user/merchant/${userGuid}`)
          .then((response: AxiosResponse<INameGuidVTO[]>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  GetMyMerchantCurrencies() {
    return new Promise<CurrencyVTO[]>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/currencies/merchant/my`)
          .then((response: AxiosResponse<CurrencyVTO[]>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  GetMerchantCurrencies(merchantId: string) {
    return new Promise<CurrencyVTO[]>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/currencies/merchant/${merchantId}`)
          .then((response: AxiosResponse<CurrencyVTO[]>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  GetMerchantCurrenciesManagerCharge() {
    return new Promise<CurrencyVTO[]>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/currencies/merchant/managercharge`)
          .then((response: AxiosResponse<CurrencyVTO[]>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
}
