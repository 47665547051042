import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { NavigateFunction } from 'react-router-dom'
import { toast } from 'react-toastify'
import { createPageAlert } from 'src/Components/McPageAlert'
import { convertTextToCent } from 'src/Helpers/CurrencyHelpers'
import { GetToday } from 'src/Helpers/DateHelpers'
import { t } from 'src/I18n'
import { ISelectOption } from 'src/Models/Client'
import { CouponTypesDTO, VoucherTypes } from 'src/Models/Server/Enums'
import IGenericError from 'src/Models/Server/IGenericError'
import ISavingCardEditVTO from 'src/Models/Server/Request/SavingCard/ISavingCardEditVTO'
import CurrencyVTO from 'src/Models/Server/Response/Currency/CurrencyVTO'
import {
  GetValue,
  LocalizedStringDTO,
  TextBoxType,
} from 'src/Models/Server/Response/LocalizedString/LocalizedStringDTO'
import { SavingCardRewardTypes } from 'src/Models/Server/Response/SavingCard/SavingCardRewardTypes'
import CurrencyController from 'src/Services/ToprCoreApi/Axios/Controllers/CurrencyController'
import SavingCardController from 'src/Services/ToprCoreApi/Axios/Controllers/SavingCardController'
import BaseStore from 'src/Stores/BaseStore'
import { persistedStore } from 'src/Stores/PersistedStore'

export interface IFormValues extends ISavingCardEditVTO {
  SelectedCurrencyOption: ISelectOption
  SelectedRewardOption: ISelectOption
  SelectedVoucherTypeOption: ISelectOption

  DisplayDiscountAmount: string
  DisplayRewardAmount: string
}

export class SavingStore extends BaseStore<IFormValues> {
  public SelectedCurrency: CurrencyVTO = undefined
  public Currencies: CurrencyVTO[] = []

  public get CurrencyOptions(): ISelectOption[] {
    return this.Currencies.map((x) => ({
      value: x.CurrencyGuid,
      label: x.Name,
    }))
  }

  public RewardOptions: ISelectOption[] = []
  public VoucherTypeOptions: ISelectOption[] = []

  constructor() {
    super()

    makeObservable(this, {
      InitializeInsert: action,
      SelectedCurrency: observable,
      Currencies: observable,
      CurrencyOptions: computed,
      RewardOptions: observable,
      VoucherTypeOptions: observable,
      SetSelectedCurrency: action,
      InsertSavingCard: action,
    })
  }

  public GetMerchantCurrencies() {
    return new Promise<void>(
      (resolve, reject: (error: IGenericError) => void) => {
        this.IsLoading = true
        CurrencyController.GetMyMerchantCurrencies()
          .then((result: CurrencyVTO[]) => {
            runInAction(() => {
              this.Currencies = result
              this.SelectedCurrency = result[0]

              this.RewardOptions = [
                {
                  value: SavingCardRewardTypes.Coupon.toString(),
                  label: t.common.coupon,
                },
                {
                  value: SavingCardRewardTypes.Currency.toString(),
                  label: t.common.currency,
                },
              ]

              this.VoucherTypeOptions = [
                {
                  value: VoucherTypes.FreeProduct.toString(),
                  label: t.savingcardInsert.VoucherTypes.FreeProduct,
                },
                {
                  value: VoucherTypes.PercentageDiscount.toString(),
                  label: t.savingcardInsert.VoucherTypes.PercentageDiscount,
                },
                {
                  value: VoucherTypes.AmountDiscount.toString(),
                  label: t.savingcardInsert.VoucherTypes.AmountDiscount,
                },
              ]
              this.IsLoading = false
              resolve()
            })
          })
          .catch((error: IGenericError) => {
            reject(error)
            runInAction(() => (this.IsLoading = false))
          })
      }
    )
  }

  public InitializeInsert() {
    this.IsLoading = true
    this.GetMerchantCurrencies()
      .then(() => {
        runInAction(() => {
          this.BaseInitialize({
            SavingCardGuid: '', // insert
            RewardType: SavingCardRewardTypes.Coupon,
            RewardAmount: 0,
            DisplayRewardAmount: '',
            Title: new LocalizedStringDTO('SavingCard Name'),
            StampCount: 5,
            AutoRefresh: true,
            Description: new LocalizedStringDTO(
              'SavingCard Description',
              TextBoxType.TextArea
            ),
            CouponDescription: new LocalizedStringDTO(
              'Coupon Description',
              TextBoxType.TextArea
            ),
            ImageFile: null,
            CouponTypeId: CouponTypesDTO.Percentage,
            DiscountAmount: 10000,
            SelectedVoucherTypeOption: this.VoucherTypeOptions.find(
              (x) => x.value === VoucherTypes.FreeProduct.toString()
            ),
            DisplayDiscountAmount: '',
            SelectedRewardOption: this.RewardOptions[0],
            SelectedCurrencyOption: this.CurrencyOptions[0],
            //ValidFrom: moment().startOf('day').toDate(),
            ValidFrom: GetToday(),
          })

          // //Test data
          // this.ViewModel.Title.LocalizedStringValues[0].Value =
          //   'Gratis kopje koffie en een stukje cake'
          // this.ViewModel.Description.LocalizedStringValues[0].Value =
          //   'Bij elk bezoek ontvang je 1 stempel. Bij 4 stempels ontvang je een coupon voor een gratis kopje koffie.'

          // this.ViewModel.CouponDescription.LocalizedStringValues[0].Value =
          //   'Tegen inlevering van deze voucher ontvang je een heerlijk kopje koffie'
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => {
          this.PageAlert = createPageAlert(error)
          this.IsLoading = false
        })
      })
  }

  public SetSelectedCurrency(selectedCurrencyOption: ISelectOption) {
    this.SelectedCurrency = this.Currencies.find(
      (x) => x.CurrencyGuid === selectedCurrencyOption.value
    )
  }

  public OnRewardTypeChange(newRewardType: ISelectOption, data: IFormValues) {
    //switch to coupon
    if (newRewardType.value === SavingCardRewardTypes.Coupon.toString()) {
      if (
        data.CouponDescription === null ||
        data.CouponDescription === undefined
      ) {
        data.CouponDescription = new LocalizedStringDTO(
          'Coupon Description',
          TextBoxType.TextArea
        )
        data.SelectedRewardOption = newRewardType
        this.BaseInitialize(data)
      }
    } else {
      //to currency
      runInAction(() => (this.SelectedCurrency = this.Currencies[0]))
    }
  }

  protected SetRewardFormValues(data: IFormValues) {
    if (
      data.SelectedRewardOption.value ===
      SavingCardRewardTypes.Coupon.toString()
    ) {
      data.RewardType = SavingCardRewardTypes.Coupon
      switch (data.SelectedVoucherTypeOption.value) {
        case VoucherTypes.AmountDiscount.toString():
          data.DiscountAmount = convertTextToCent(data.DisplayDiscountAmount)
          data.CouponTypeId = CouponTypesDTO.Euro
          break
        case VoucherTypes.PercentageDiscount.toString():
          data.DiscountAmount = convertTextToCent(data.DisplayDiscountAmount)
          data.CouponTypeId = CouponTypesDTO.Percentage
          break

        case VoucherTypes.FreeProduct.toString():
        default:
          data.DiscountAmount = 10000
          data.CouponTypeId = CouponTypesDTO.Percentage
          break
      }
    } else {
      //currency
      data.RewardType = SavingCardRewardTypes.Currency
      data.CurrencyGuid = data.SelectedCurrencyOption.value
    }
  }

  public InsertSavingCard(data: IFormValues, navigate: NavigateFunction) {
    this.SetRewardFormValues(data)

    this.IsSubmitting = true
    SavingCardController.Insert(data)
      .then(() => {
        navigate('/savingcards')
        toast.success(
          t.formatString(
            t.savingCardCreated.contentIntro,
            GetValue(this.ViewModel.Title)
          )
        )
        this.InitializeInsert()
      })
      .catch((error: IGenericError) => {
        this.PageAlert = createPageAlert(error)
        this.IsSubmitting = false
      })
  }
}
export const savingCardInsertStore = new SavingStore()
