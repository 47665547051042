import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { LoaderIcon } from 'src/Components'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import {
  MainPage,
  MyDataTable,
  MyMonthPeriodSelect,
  MySelect,
} from 'src/Components/My'
import { t } from 'src/I18n'
import { ISelectOption } from 'src/Models/Client'
//import Layout from '../../../_Layout'
import { cardPaymentTotalsStore, IFormValues } from './Store'

const CardPaymentTotals: React.FC = () => {
  useEffect(() => {
    cardPaymentTotalsStore.Initialize()
  }, [null])
  const {
    IsLoading,
    ViewModel,
    TokenTypes,
    Currencies,
    Transactions,
    Page,
    PageSize,
    TotalRows,
  } = cardPaymentTotalsStore

  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({})
  const { reset, setValue, handleSubmit } = useFormProps

  useEffect(() => {
    reset(ViewModel)
  }, [ViewModel])

  //const data = getValues()

  const renderTransactions = () => {
    const columns = [
      {
        dataField: 'ActivationCode',
        text: t.common.activationCode,
        sort: true,
      },
      {
        dataField: 'Transactions',
        text: t.common.amount,
        sort: true,
      },
      {
        dataField: 'TotalAmountFormatted',
        text: t.common.amountText,
        sort: true,
      },
    ]

    if (IsLoading) {
      return <LoaderIcon IsLoading={IsLoading} />
    }

    return (
      <MyDataTable
        data={Transactions}
        columns={columns}
        serverPaging={{
          page: Page,
          pageSize: PageSize,
          totalRows: TotalRows,
          onPagination: (newPage, newPageSize) => {
            cardPaymentTotalsStore.SetPagination(newPage, newPageSize)

            handleSubmit((data) =>
              cardPaymentTotalsStore.GetTransactionsPaged(data, false)
            )()
          },
        }}
      />
    )
  }

  return (
    <MainPage>
      <BreadCrumbs currentPageTitle={t.paymentTransactions.title} />

      <LoaderIcon IsLoading={IsLoading} />
      {!IsLoading && ViewModel !== undefined && (
        <Portlet title={t.paymentTransactions.title}>
          <form className="parsley-form">
            <Row>
              <Col md={6}>
                <MyMonthPeriodSelect
                  onChange={(startDate, endDate) => {
                    setValue('StartDate', startDate)
                    setValue('EndDate', endDate)
                    handleSubmit((data) =>
                      cardPaymentTotalsStore.GetTransactionsPaged(data)
                    )()
                  }}
                />
              </Col>
              <Col md={6}>
                <MySelect
                  formObject={{} as IFormValues}
                  useFormProps={useFormProps}
                  name="SelectedTokenType"
                  label={t.common.tokenTypes}
                  options={TokenTypes}
                  onSelectionChange={(options: ISelectOption) => {
                    setValue('SelectedTokenType', options)
                    handleSubmit((data) =>
                      cardPaymentTotalsStore.GetTransactionsPaged(data)
                    )()
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <MySelect
                  formObject={{} as IFormValues}
                  useFormProps={useFormProps}
                  name="SelectedCurrency"
                  label={t.common.currency}
                  options={Currencies}
                  onSelectionChange={(options: ISelectOption) => {
                    setValue('SelectedCurrency', options)
                    handleSubmit((data) =>
                      cardPaymentTotalsStore.GetTransactionsPaged(data)
                    )()
                  }}
                />
              </Col>
            </Row>
            {renderTransactions()}
          </form>
        </Portlet>
      )}
    </MainPage>
  )
}

export default observer(CardPaymentTotals)
