import accounting from 'accounting'
import { observer } from 'mobx-react'
import React, { Fragment, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import CircleStatRow from 'src/Components/Authenticated/CircleStatRow'
import CouponDetailsReport from 'src/Screens/Authenticated/SavingCard/Details/Components/CouponReportDetailsReport'
import Portlet from 'src/Components/Authenticated/Portlet'
import SavingCardDetailsReport from 'src/Components/Authenticated/SavingCardDetailsReport'
import LoaderIcon from 'src/Components/LoaderIcon'
import { MainPage } from 'src/Components/My'
import { t } from 'src/I18n'
import { savingCardDetailsStore } from './Store'
import CurrencRewardPortlet from './Components/CurrencRewardPortlet'

interface IProps {}

const Details: React.FC<IProps> = () => {
  const params = useParams<'id'>()
  useEffect(() => {
    savingCardDetailsStore.Initialize(params.id)
  }, [null])

  const { ViewModel, IsLoading, CurrencyRewards } = savingCardDetailsStore
  const renderPage = () => {
    if (ViewModel !== undefined && !IsLoading) {
      const renderCoupons = () => {
        if (ViewModel.Coupons.length === 0) {
          return null
        }

        return (
          <>
            <h5>
              {t.common.coupon} {t.common.reward}
            </h5>
            {ViewModel.Coupons.length > 0 &&
              ViewModel.Coupons.map((couponDetailsStats, key) => {
                return (
                  <CouponDetailsReport
                    key={key}
                    couponDetailsStats={couponDetailsStats}
                  />
                )
              })}
          </>
        )
      }

      const renderCurrencyReward = () => {
        if (CurrencyRewards.length === 0) {
          return null
        }

        return (
          <>
            <h5>
              {t.common.currency} {t.common.reward}
            </h5>
            {CurrencyRewards.map((curReward, key) => (
              <CurrencRewardPortlet savingCardReward={curReward} key={key} />
            ))}
            {/* {CurrencyRewards.length > 0 &&
              ViewModel.Coupons.map((couponDetailsStats, key) => {
                return (
                  <CouponDetailsReport
                    key={key}
                    couponDetailsStats={couponDetailsStats}
                  />
                )
              })} */}
          </>
        )
      }

      return (
        <Fragment>
          <BreadCrumbs
            currentPageTitle={ViewModel.SavingCard.Title}
            previousPages={{ to: '/savingcards', title: t.common.savingcards }}
          />

          <Portlet title={t.common.savingcard}>
            <Row>
              <Col md={6}>
                <SavingCardDetailsReport savingcardDetails={ViewModel} />
              </Col>
              <Col md={6} className="circle-stats even">
                <CircleStatRow
                  stat={ViewModel.TotalSavingCards}
                  text={t.savingcardDetails.savingcardsIssued}
                  iconText="#"
                />
                <CircleStatRow
                  stat={ViewModel.TotalSavingCardsProcessed}
                  text={t.savingcardDetails.savingcardsCompleted}
                  icon="fas fa-check"
                />
                <CircleStatRow
                  stat={accounting.formatNumber(
                    ViewModel.SavingCardsAverageStamps
                  )}
                  text={t.savingcardDetails.savingcardsAverage}
                  icon="fas fa-stamp"
                />
              </Col>
            </Row>
          </Portlet>

          {renderCoupons()}
          {renderCurrencyReward()}
        </Fragment>
      )
    }

    return null
  }

  return (
    <>
      <MainPage>
        <LoaderIcon IsLoading={IsLoading} />
        {renderPage()}
      </MainPage>
    </>
  )
}

export default observer(Details)
