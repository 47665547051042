import React, { Fragment } from 'react'
import { IExceptionError } from 'src/Models/Server/Response/Exception/ExceptionError'

interface IPageAlertProps {
  pageAlert: IPageAlert
}

export interface IPageAlert {
  type: AlertType
  message: string
}

export enum AlertType {
  Success,
  Info,
  Warning,
  Danger,
}

//default type AlerType.Danger
export const createPageAlert = (
  error: IExceptionError,
  warningCodes?: string[],
  succesCodes?: string[]
): IPageAlert => {
  let type: AlertType = AlertType.Danger
  if (warningCodes && warningCodes.indexOf(error.Code) > -1) {
    type = AlertType.Warning
  }

  if (succesCodes && succesCodes.indexOf(error.Code) > -1) {
    type = AlertType.Success
  }

  return { message: error.Message, type }
}

const getIcon = (type: AlertType) => {
  switch (type) {
    case AlertType.Danger:
      return 'fa fa-exclamation-circle'
    case AlertType.Warning:
      return 'fa fa-exclamation-triangle'
    case AlertType.Success:
    default:
      return 'fa fa-check'
  }
}

const McPageAlert: React.FC<IPageAlertProps> = ({ pageAlert }) => {
  if (pageAlert === undefined || pageAlert === null) {
    return null
  }

  const alertClass = AlertType[pageAlert.type].toLocaleLowerCase()

  return (
    <Fragment>
      <div className={`alert alert-${alertClass}`}>
        <i className={getIcon(pageAlert.type)} />
        <span dangerouslySetInnerHTML={{ __html: pageAlert.message }} />
      </div>
    </Fragment>
  )
}

export default McPageAlert
