import React, { PropsWithChildren } from 'react'
import { FormGroup } from 'react-bootstrap'
import { Path, useController, UseFormReturn } from 'react-hook-form'
import { ValidateRequiredLocalizedString } from 'src/Helpers/ValidationHelpers'
import {
  LocalizedStringDTO,
  TextBoxType,
} from 'src/Models/Server/Response/LocalizedString/LocalizedStringDTO'
import { LocalizedStringValueDTO } from 'src/Models/Server/Response/LocalizedString/LocalizedStringValueDTO'
import { persistedStore } from 'src/Stores/PersistedStore'
import { IMyTab, MyLabel, MyTabs } from '../index'

interface IProps<T> {
  name: Path<T>
  formObject: T
  useFormProps: UseFormReturn<any>
  isRequired?: boolean
  label: string
  infoText?: string
}

const MyLocalizedStringTabs = <ObjectType,>({
  name,
  useFormProps,
  //rules,
  isRequired,
  label,
  infoText,
}: PropsWithChildren<IProps<ObjectType>>) => {
  const {
    control,
    formState: { errors },
  } = useFormProps
  const { SelectedManagerUser } = persistedStore

  const rules =
    isRequired === undefined || !isRequired
      ? undefined
      : {
          validate: (value: LocalizedStringDTO) => {
            return ValidateRequiredLocalizedString(
              value,
              SelectedManagerUser.DefaultLanguage,
              label
            )
          },
        }

  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
    rules,
  })

  const errorMessage =
    errors !== undefined && errors[name.toString()] !== undefined
      ? errors[name.toString()].message
      : undefined
  //const required = rules !== undefined && rules.required !== undefined
  const hasError = errorMessage !== undefined

  const localizedString = value as LocalizedStringDTO

  if (localizedString === null || localizedString === undefined) {
    return null
  }

  //  console.log(' lls', value)

  const getTabs = (): IMyTab[] => {
    const { SelectedManagerUser } = persistedStore

    return SelectedManagerUser.MerchantLanguages.map((lang) => {
      return {
        //key: `${name}_${lang}`,
        key: lang,
        title: lang,
        content: renderTextControls(lang),
      }
    })
  }

  const handleTextControlChange = <
    T extends HTMLInputElement | HTMLTextAreaElement
  >(
    event: React.FormEvent<T>,
    llsvalue: LocalizedStringValueDTO
  ) => {
    const target = event.currentTarget
    const value = target.value
    llsvalue.Value = value
    onChange(localizedString)
  }

  const renderTextArea = (llsvalue: LocalizedStringValueDTO) => {
    return (
      <textarea
        value={llsvalue.Value}
        onChange={(e) => handleTextControlChange(e, llsvalue)}
        className="form-control"
        rows={5}
      />
    )
  }

  const renderTextBox = (llsvalue: LocalizedStringValueDTO) => {
    return (
      <input
        type="text"
        value={llsvalue.Value}
        onChange={(e) => handleTextControlChange(e, llsvalue)}
        className="form-control"
      />
    )
  }

  const renderTextControls = (lang: string) => {
    const llsvalue = localizedString.LocalizedStringValues.find(
      (x) => x.LanguageId === lang
    )

    if (llsvalue === undefined) {
      return null
    }

    switch (localizedString.TextBoxTypeId) {
      case TextBoxType.TextBox:
        return renderTextBox(llsvalue)
      case TextBoxType.TextArea:
        return renderTextArea(llsvalue)
      case TextBoxType.TextAreaHtml:
      //return this.renderTextAreaHtml(llsvalue)
    }

    return null
  }

  const errorText = () => {
    if (!hasError) {
      return null
    }

    return (
      <ul className="parsley-error-list filled">
        <li className="parsley-required">{errorMessage.toString()}</li>
      </ul>
    )
  }

  return (
    <FormGroup>
      <MyLabel infoText={infoText} isRequired={isRequired}>
        {label}
      </MyLabel>
      <MyTabs tabs={getTabs()} />
      {errorText()}
    </FormGroup>
  )
}

export default MyLocalizedStringTabs
