import IGenericError from 'src/Models/Server/IGenericError'
import { ICreateEventRequest } from 'src/Models/Server/Request/Event/ICreateEventRequest'
import EventController from 'src/Services/ToprCoreApi/Axios/Controllers/EventController'
import { appUiStore } from 'src/Stores/AppUiStore'
import { IEventFormValues } from '../Components/Reducer'

export interface IState {
  isSubmitting: boolean
}

export const initialState: IState = {
  isSubmitting: false,
}

export type Actions = {
  type: 'setIsSubmitting'
  isSubmitting: boolean
}

export const reducer = (state: IState, action: Actions): IState => {
  switch (action.type) {
    case 'setIsSubmitting':
      return {
        ...state,
        isSubmitting: action.isSubmitting,
      }
    default:
      throw new Error('Invalid Reducer Action')
  }
}

export const reducerActions = (
  state: IState,
  dispatch: (action: Actions) => void
) => {
  return {
    save: (data: IEventFormValues) => {
      const request: ICreateEventRequest = {
        Description: data.Description,
        Name: data.Name,
        ImageFile: {
          Data: data.ImageFile.Data,
          MimeType: data.ImageFile.MimeType,
          Name: data.ImageFile.Name,
        },
        ConsumerSubscriptionId: undefined,
        End: data.End,
        Start: data.Start,
      }

      dispatch({ type: 'setIsSubmitting', isSubmitting: true })
      EventController.EventInsert(request)
        .then(() => {
          history.back()
          dispatch({ type: 'setIsSubmitting', isSubmitting: false })
        })
        .catch((error: IGenericError) => {
          appUiStore.ShowError(error)
          dispatch({ type: 'setIsSubmitting', isSubmitting: false })
        })
    },
  }
}
