import { action, makeObservable, observable, runInAction } from 'mobx'
import IGenericError from 'src/Models/Server/IGenericError'
import { IUserScheduleBonusSTO } from 'src/Models/Server/Response/IUserScheduleBonusSTO'
import UserScheduleBonusController from 'src/Services/ToprCoreApi/Axios/Controllers/UserScheduleBonusController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export interface IFormValues {}

export class Store extends BaseStore<IFormValues> {
  public UserBonuses: IUserScheduleBonusSTO[] = []

  constructor() {
    super()
    makeObservable(this, {
      UserBonuses: observable,
      Initialize: action,
      TurnOffUserScheduleBonus: action,
    })
  }

  public Initialize(userGuid: string) {
    this.IsLoading = true

    UserScheduleBonusController.GetUserScheduleBonuses(userGuid)
      .then((result) => {
        runInAction(() => {
          this.UserBonuses = result
          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        runInAction(() => {
          this.IsLoading = false
        })
      })
  }

  public TurnOffUserScheduleBonus(
    userScheduleBonusId: number,
    userGuid: string
  ) {
    this.IsSubmitting = true
    UserScheduleBonusController.TurnOff(userScheduleBonusId)
      .then(() => {
        this.Initialize(userGuid)
        runInAction(() => {
          this.IsSubmitting = false
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        runInAction(() => {
          this.IsSubmitting = false
        })
      })
  }
}

export const userPlanningStore = new Store()
