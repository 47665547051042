import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import LoaderIcon from 'src/Components/LoaderIcon'
import McButton from 'src/Components/McButton'
import McPageAlert from 'src/Components/McPageAlert'
import { MainPage, MyDropzone, MySelect } from 'src/Components/My'
import { ReadFileContent } from 'src/Helpers/FileHelper'
import { t } from 'src/I18n'
import { IFormValues, uploadStornoStore } from './Store'

interface IProps {}

const UploadStornos: React.FC<IProps> = () => {
  useEffect(() => {
    uploadStornoStore.Initialize()
  }, [null])
  const { IsLoading, ViewModel, IsSubmitting, PageAlert } = uploadStornoStore
  useEffect(() => {
    reset(ViewModel)
  }, [ViewModel])

  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({})
  const { handleSubmit, reset, setValue } = useFormProps

  const onSubmit = (data: IFormValues) => {
    //console.log('data', data)
    uploadStornoStore.UploadStorno(data)
  }
  const renderContent = () => {
    if (IsLoading || ViewModel === undefined) {
      return <LoaderIcon IsLoading={IsLoading} />
    }

    return (
      <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={6}>
            <MySelect
              formObject={{} as IFormValues}
              useFormProps={useFormProps}
              name="SelectedCurrency"
              label={t.common.currency}
              options={ViewModel.Currencies}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <MyDropzone
              formObject={{} as IFormValues}
              useFormProps={useFormProps}
              name="Storno"
              label={t.stornos.form.stornoField.label}
              dropFileText={t.stornos.form.stornoField.dropfile}
              rules={{
                required: t.stornos.form.stornoField.error.required,
              }}
              accept={['text/csv', '.csv']}
              onChangeFile={(files: File[] | null) => {
                if (files === null) {
                  //removed
                  setValue('StornoFile', '')
                } else {
                  ReadFileContent(files, (base64) => {
                    setValue('StornoFile', base64)
                  })
                }
              }}
            />
          </Col>
        </Row>
        <McPageAlert pageAlert={PageAlert} />
        <hr />
        <Row>
          <Col mdOffset={8} md={4}>
            <McButton
              block
              bsStyle="primary"
              type="submit"
              icon="fa-user"
              isLoading={IsSubmitting}
            >
              {t.common.save}
            </McButton>
          </Col>
        </Row>
      </form>
    )
  }

  return (
    <MainPage>
      <BreadCrumbs currentPageTitle={t.stornos.title} />
      <Portlet title={t.stornos.title}>{renderContent()}</Portlet>
    </MainPage>
  )
}

export default observer(UploadStornos)
