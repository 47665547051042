import { observer } from 'mobx-react'
import React from 'react'
import AdminMenu from './Components/AdminMenu'
import CustomerSearchMenu from './Components/CustomerSearchMenu'
import FinanceMenu from './Components/FinanceMenu'
import ReportsMenu from './Components/ReportsMenu'
import SiedlerMenu from './Components/SiedlerMenu'
import SystemMenu from './Components/SystemMenu'

const MerchantMenu: React.FC = () => {
  return (
    <>
      <div className="mainnav">
        <div className="container">
          <a
            className="mainnav-toggle"
            data-toggle="collapse"
            data-target=".mainnav-collapse"
          >
            <span className="sr-only">Toggle navigation</span>
            <i className="fa fa-bars" />
          </a>
          <nav className="collapse mainnav-collapse" role="navigation">
            <ul className="mainnav-menu">
              <AdminMenu />
              <CustomerSearchMenu />
              <SystemMenu />
              {/* <WalletsMenu /> */}
              <ReportsMenu />
              <FinanceMenu />
              <SiedlerMenu />
            </ul>
          </nav>
        </div>
      </div>
    </>
  )

  /*
  const renderLoyaltyMenu = () => {
    const hasActiveChildrenClassName = IsActiveMenu(
      SavingCards.Path,
      LoyaltyPrograms.Path
    )
      ? 'active dropwDown'
      : ' dropDown'
    return (
      <li className={hasActiveChildrenClassName}>
        <a

          className="dropdown-toggle"
          data-toggle="dropdown"
          data-hover="dropdown"
        >
          {t.topMenu.loyalty.title}
          <i className="fa fa-chevron-down" />
        </a>
        <ul className="dropdown-menu" role="menu">
          {renderAdminLoyaltyMenu()}
          <li>
            <NavLink to={SavingCards.GetUrl()} activeClassName="active">
              <i className={`fa fa-piggy-bank dropdown-icon`} />
              {t.common.savingcards}
            </NavLink>
          </li>
          <li>
            <NavLink to={Coupons.GetUrl()} activeClassName="active">
              <i className={`fa fa-ticket-alt dropdown-icon`} />
              {t.common.coupons}
            </NavLink>
          </li>
        </ul>
      </li>
    )
  }
*/
}

export default observer(MerchantMenu)
