import { action, computed, makeObservable, runInAction } from 'mobx'
import IGenericError from 'src/Models/Server/IGenericError'
import ISavingCardDetailsDTO from 'src/Models/Server/Response/SavingCard/ISavingCardDetailsDTO'
import { ISavingCardRewardDTO } from 'src/Models/Server/Response/SavingCard/ISavingCardRewardDTO'
import SavingCardController from 'src/Services/ToprCoreApi/Axios/Controllers/SavingCardController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export default class Store extends BaseStore<ISavingCardDetailsDTO> {
  public get CurrencyRewards(): ISavingCardRewardDTO[] {
    if (this.ViewModel) {
      return this.ViewModel.SavingCardRewards.filter(
        (x) => x.CurrencyId !== null
      )
    }

    return []
  }

  constructor() {
    super()

    makeObservable(this, {
      Initialize: action,
      CurrencyRewards: computed,
    })
  }

  public Initialize(savingCardGuid: string) {
    this.IsLoading = true
    SavingCardController.GetSavingCardDetails(savingCardGuid)
      .then((savingCardDetails) => {
        runInAction(() => {
          console.log('sd', savingCardDetails)
          this.ViewModel = savingCardDetails
          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        runInAction(() => (this.IsLoading = false))
      })
  }
}

export const savingCardDetailsStore = new Store()
