import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { LoaderIcon } from 'src/Components'
import { MainPage, MyChart } from 'src/Components/My'
import UserTotals from './Components/UserTotals'
import WeekTotal from './Components/WeekTotal'
import { dashboardStore } from './Store'

interface IProps {}

const Dashboard: React.FC<IProps> = () => {
  useEffect(() => {
    dashboardStore.Initialize()
  }, [null])

  const renderContent = () => {
    const { DashboardData, ChartResponses } = dashboardStore

    if (DashboardData === null) {
      return <LoaderIcon IsLoading={true} />
    }

    const renderTransactionTotals = () => {
      return DashboardData.TransactionWeekTotals.map((weekTotal, key) => {
        return (
          <Col md={4} key={key}>
            <WeekTotal weekTotal={weekTotal} />
          </Col>
        )
      })
    }
    const renderUserTotals = () => {
      return (
        <Col md={8}>
          <UserTotals userTotals={DashboardData.UserTotals} />
        </Col>
      )
    }

    const renderPaymentCharts = () => {
      return (
        <Col md={12}>
          {ChartResponses.map((chartResponse, key) => (
            <MyChart key={key} chartResponse={chartResponse} />
            // <Charts chartResponse={chartResponse} key={key} />
          ))}
        </Col>
      )
    }

    return (
      <>
        {renderTransactionTotals()}
        {renderUserTotals()}
        {renderPaymentCharts()}
      </>
    )
  }

  return (
    <MainPage>
      <Row>{renderContent()}</Row>
    </MainPage>
  )
}

export default observer(Dashboard)
