import React, { useEffect, useReducer } from 'react'
import {
  InputMasks,
  MyCurrencyInput,
  MyDatePicker,
  MySelect,
  MyTextbox,
} from 'src/Components/My'
import { t } from 'src/I18n'

import { Col, Row } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { LoaderIcon, McButton } from 'src/Components'
import Portlet from 'src/Components/Authenticated/Portlet'
import { LoyaltyRewardTypes, LoyaltyRuleTypes } from 'src/Models/Server/Enums'

import { ISelectOption } from 'src/Models/Client'
import {
  IFormValues,
  initialState,
  reducer,
  reducerActions,
  rewardTypeOptions,
  typeOptions,
} from './Reducer'
import { getCurrencyOptions } from '../../UserScheduleBonus/Components/Reducer'

interface IProps {
  title: string
}

const FormComponent: React.FC<IProps> = ({ title }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const actions = reducerActions(state, dispatch)

  useEffect(() => {
    actions.initialize()
  }, [null])

  const { currencies, couponOptions, viewModel, isLoading, isSubmitting } =
    state

  const currencyOptions = getCurrencyOptions(currencies)

  useEffect(() => {
    reset(viewModel)
  }, [viewModel])

  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>()
  const { handleSubmit, reset, setValue, getValues, watch } = useFormProps
  const isEdit = false //userScheduleBonusId !== undefined //todo

  const typeOption = watch('TypeOption')
  const rewardTypeOption = watch('RewardTypeOption')
  const rewardCurrencyOption = watch('RewardCurrencyOption')

  const onSubmit = (data: IFormValues) => {
    actions.save(data)
    // onSave(data)
  }

  const renderRewardCoupon = () => {
    if (
      rewardTypeOption === undefined ||
      rewardTypeOption.value !== LoyaltyRewardTypes.CouponReward.toString()
    ) {
      return null
    }

    return (
      <>
        <Col md={4}>
          <MySelect
            formObject={viewModel}
            useFormProps={useFormProps}
            name="RewardCouponOption"
            label={t.common.coupon}
            options={couponOptions}
            isDisabled={isEdit}
          />
        </Col>
        <Col md={4}>
          <MyTextbox
            formObject={viewModel}
            label={t.common.amountTypes.default.title}
            rules={{
              required: t.common.amountTypes.default.required,
            }}
            name="AmountCents"
            inputMask={InputMasks.Number}
            maxLength={2}
            useFormProps={useFormProps}
            formGroupStyle={{ marginBottom: 5 }}
          />
        </Col>
      </>
    )
  }

  const renderRewardCurrency = () => {
    if (
      rewardCurrencyOption === undefined ||
      rewardTypeOption === undefined ||
      (rewardTypeOption.value !==
        LoyaltyRewardTypes.CurrencyStaticReward.toString() &&
        rewardTypeOption.value !==
          LoyaltyRewardTypes.CurrencyOrderPercentageReward.toString())
    ) {
      return null
    }

    const currency = currencies.find(
      (x) => x.CurrencyId.toString() === rewardCurrencyOption.value
    )

    const renderCurrencyOrPercentage = () => {
      if (
        rewardTypeOption.value ===
        LoyaltyRewardTypes.CurrencyStaticReward.toString()
      ) {
        return (
          <MyCurrencyInput
            formObject={viewModel}
            label={t.common.amountTypes.currency.title}
            currency={currency}
            onChangeCents={(amountCents) => {
              setValue('AmountCents', amountCents)
            }}
            name="DisplayAmount"
            useFormProps={useFormProps}
          />
        )
      }

      if (
        rewardTypeOption.value ===
        LoyaltyRewardTypes.CurrencyOrderPercentageReward.toString()
      ) {
        return (
          <MyTextbox
            formObject={viewModel}
            label={t.common.amountTypes.percentage.title}
            rules={{
              required: t.common.amountTypes.percentage.required,
            }}
            icon="fa-percent"
            name="AmountCents"
            inputMask={InputMasks.Number}
            maxLength={3}
            useFormProps={useFormProps}
          />
        )
      }

      return null
    }

    return (
      <>
        <Col md={4}>
          <MySelect
            formObject={viewModel}
            useFormProps={useFormProps}
            name="RewardCurrencyOption"
            label={t.common.currency}
            options={currencyOptions}
            isDisabled={isEdit}
          />
        </Col>
        <Col md={4}>{renderCurrencyOrPercentage()}</Col>
      </>
    )
  }

  const renderArticleCodeOrGroup = () => {
    if (typeOption === undefined) {
      return null
    }

    switch (typeOption.value) {
      case LoyaltyRuleTypes.Article.toString():
        return (
          <MyTextbox
            formObject={viewModel}
            useFormProps={useFormProps}
            name="ArticleCode"
            label={t.loyaltyprogram.insert.fields.ArticleCode.label}
            rules={{
              required:
                t.loyaltyprogram.insert.fields.ArticleCode.errors.required,
            }}
          />
        )
      case LoyaltyRuleTypes.ArticleGroup.toString():
        return (
          <MyTextbox
            formObject={viewModel}
            useFormProps={useFormProps}
            name="ArticleGroup"
            label={t.loyaltyprogram.insert.fields.ArticleGroup.label}
            rules={{
              required:
                t.loyaltyprogram.insert.fields.ArticleGroup.errors.required,
            }}
          />
        )
      default:
        return null
    }
  }

  const renderContent = () => {
    if (isLoading || viewModel === undefined) {
      return <LoaderIcon IsLoading={isLoading} />
    }

    return (
      <>
        <MySelect
          formObject={viewModel}
          useFormProps={useFormProps}
          name="TypeOption"
          label={t.common.type}
          options={typeOptions}
          isDisabled={isEdit}
        />
        <Row>
          <Col md={4}>
            <MyTextbox
              formObject={viewModel}
              useFormProps={useFormProps}
              name="Name"
              label={t.common.name}
              rules={{
                required: t.loyaltyprogram.insert.fields.Name.errors.required,
              }}
            />
          </Col>
          <Col md={4}>{renderArticleCodeOrGroup()}</Col>
          <Col md={4}>
            <MySelect
              formObject={viewModel}
              useFormProps={useFormProps}
              name="RunOnCurrencyOption"
              label={t.common.currency}
              rules={{
                required:
                  t.loyaltyprogram.insert.fields.CurrencyId.errors.required,
              }}
              options={currencyOptions}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <MyDatePicker
              formObject={viewModel}
              useFormProps={useFormProps}
              name="StartDate"
              label={t.userScheduleBonus.insert.fields.StartDate.label}
              rules={{
                required: t.userScheduleBonus.insert.fields.StartDate.required,
              }}
            />
          </Col>
          <Col md={6}>
            <MyDatePicker
              formObject={viewModel}
              useFormProps={useFormProps}
              name="EndDate"
              label={t.userScheduleBonus.insert.fields.EndDate.label}
              rules={{
                validate: (endDate: Date) => {
                  if (endDate !== undefined && endDate !== null) {
                    const startDate = getValues('StartDate')
                    if (startDate > endDate) {
                      return t.userScheduleBonus.insert.fields.EndDate.errors
                        .greaterThan
                    }
                  }

                  return true
                },
              }}
            />
          </Col>
        </Row>
        <h5>{t.common.reward}</h5>
        <Row>
          <Col md={4}>
            <MySelect
              formObject={viewModel}
              useFormProps={useFormProps}
              name="RewardTypeOption"
              label={t.common.type}
              options={rewardTypeOptions}
              onSelectionChange={(option: ISelectOption) => {
                switch (option.value) {
                  case LoyaltyRewardTypes.CurrencyOrderPercentageReward.toString():
                    setValue('AmountCents', 1)
                    break
                  case LoyaltyRewardTypes.CouponReward.toString():
                    setValue('AmountCents', 1)
                    break
                  case LoyaltyRewardTypes.CurrencyStaticReward.toString():
                    {
                      const currency = currencies.find(
                        (x) =>
                          x.CurrencyId.toString() === rewardCurrencyOption.value
                      )

                      setValue('AmountCents', currency.IsDecimal ? 100 : 1)
                      setValue('DisplayAmount', '1')
                    }
                    break
                }
              }}
            />
          </Col>
          {renderRewardCoupon()}
          {renderRewardCurrency()}
        </Row>
        <McButton
          block
          bsStyle="primary"
          bsSize="large"
          type="submit"
          isLoading={isSubmitting}
        >
          {t.common.save}
        </McButton>
      </>
    )
  }
  return (
    <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
      <Portlet title={title}>{renderContent()}</Portlet>
    </form>
  )
}

export default FormComponent
