import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Col, Panel, Row, Tab, Tabs } from 'react-bootstrap'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import LoadContent from 'src/Components/LoadContent'
import { MainPage } from 'src/Components/My'
import CouponDetailsReport from 'src/Screens/Authenticated/SavingCard/Details/Components/CouponReportDetailsReport'
import { t } from '../../../../I18n'
import { couponsStore } from './Store'

interface IProps {}

const Coupons: React.FC<IProps> = () => {
  useEffect(() => {
    couponsStore.Initialize()
  }, [null])

  const { ViewModel, IsLoading } = couponsStore

  return (
    <MainPage>
      <BreadCrumbs currentPageTitle={t.common.savingcards} />
      <br className="xs-10" />
      <Portlet>
        <Tabs id="tabs">
          <Tab
            eventKey={1}
            title={[
              t.common.active,
              // <Badge className="badge-primary" key="0">
              //   1
              // </Badge>
            ]}
          >
            <LoadContent IsLoading={IsLoading}>
              {ViewModel !== undefined &&
                ViewModel.map((x, index) => (
                  <CouponDetailsReport
                    key={index}
                    couponDetailsStats={x}
                    footer={
                      <Panel.Footer className="flexEndRow cursor">
                        <h4>
                          {t.common.details}
                          <i className="fa fa-chevron-right" />
                        </h4>
                      </Panel.Footer>
                    }
                  />
                ))}
            </LoadContent>
          </Tab>
          <Tab eventKey={2} title={t.common.inActive}>
            <LoadContent IsLoading={IsLoading && ViewModel !== undefined}>
              <Row>
                {ViewModel !== undefined &&
                  ViewModel.map((x, index) => (
                    <Col md={6} key={index}>
                      todo: active coupons
                    </Col>
                  ))}
              </Row>
            </LoadContent>
          </Tab>
        </Tabs>
      </Portlet>
    </MainPage>
  )
}

export default observer(Coupons)
