import React, { PropsWithChildren, useEffect, useState } from 'react'
import {
  Path,
  RegisterOptions,
  useController,
  UseFormReturn,
} from 'react-hook-form'
import { convertTextToCent } from 'src/Helpers/CurrencyHelpers'
import { t } from 'src/I18n'
import CurrencyVTO from 'src/Models/Server/Response/Currency/CurrencyVTO'
import { MyLabel } from '..'
import { InputMasks } from './MyTextbox'

interface IProps<T> {
  name: Path<T>
  formObject: T
  useFormProps: UseFormReturn<T>
  //input
  label: string
  currency: CurrencyVTO
  onChangeCents: (amountCents: number) => void
  formGroupStyle?: React.CSSProperties
}

const MyCurrencyInput = <ObjectType,>({
  name,
  useFormProps,
  label,
  currency,
  onChangeCents,
  formGroupStyle,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  formObject, //remove from otherProps
  ...otherProps
}: PropsWithChildren<IProps<ObjectType>>) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormProps

  if (currency === undefined) {
    return null
  }
  const [isInitialized, setIsInitialized] = useState(false)

  useEffect(() => {
    //update cents when changing currency
    if (value && currency && isInitialized) {
      //console.log('useEffect')
      setValue(name, '0' as any)
      onChangeCents(0)
    }
  }, [currency])

  useEffect(() => {
    setIsInitialized(true)
  }, [null])

  const errorMessage =
    errors !== undefined && errors[name.toString()] !== undefined
      ? errors[name.toString()].message
      : undefined
  const minErrorMessage = currency.IsDecimal
    ? t.commonFields.Amount.errors.minDecimal
    : t.commonFields.Amount.errors.minNumber

  const placeholder = currency.IsDecimal
    ? t.commonFields.Amount.placeholder.decimal
    : t.commonFields.Amount.placeholder.number

  const rules: RegisterOptions = {
    required: t.commonFields.Amount.errors.required,
    validate: (value) => {
      const amountCents = currency.IsDecimal
        ? convertTextToCent(value)
        : parseInt(value, 10)

      return amountCents > 0 || minErrorMessage
    },
  }
  const required = true
  const hasError = errorMessage !== undefined

  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
    rules,
  })

  //console.log('value', value, currency.Name)

  const getCents = (value: string) => {
    const amountCents = currency.IsDecimal
      ? convertTextToCent(value)
      : parseInt(value, 10)

    return amountCents
  }

  const errorText = () => {
    if (!hasError) {
      return null
    }

    return (
      <ul className="parsley-error-list filled">
        <li className="parsley-required">{errorMessage}</li>
      </ul>
    )
  }

  const validateInputMask = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const inputMask = currency.IsDecimal
      ? InputMasks.Currency
      : InputMasks.Number

    const valueAndKey = e.currentTarget.value + e.key

    if (e.key !== 'Enter' && !inputMask.test(valueAndKey)) {
      e.preventDefault()
    }
  }
  const maxLength = currency.IsDecimal ? 6 : 3

  const myValue = value === undefined ? '' : value

  return (
    <div className="form-group" style={formGroupStyle}>
      <>
        <MyLabel htmlFor={name} isRequired={required}>
          {label}
        </MyLabel>
        <div className="input-group">
          <div className="input-group-addon">
            <i className={`fa fa-euro-sign`} />
          </div>

          <input
            type="text"
            id={name}
            name={name}
            onKeyPress={(e) => {
              validateInputMask(e)
            }}
            onChange={(e) => {
              const value = e.currentTarget.value
              onChangeCents(getCents(value)) //cent amount 123
              onChange(e) //react-form-hook text amount 1,23 isDecimal:true / 123 isdecimal: false
            }}
            value={myValue as any}
            placeholder={placeholder}
            className="form-control"
            maxLength={maxLength}
            {...otherProps}
          />
        </div>
      </>
      {errorText()}
    </div>
  )
}

export default MyCurrencyInput
