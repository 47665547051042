import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Col, Row, Tab, Tabs } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { LoaderIcon, McButton, McPageAlert } from 'src/Components'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import { MainPage, MySelect } from 'src/Components/My'
import { t } from 'src/I18n'
import { IFormValues, walletsBulkChangeStore } from './Store'

interface IProps {}

const BulkChange: React.FC<IProps> = () => {
  useEffect(() => {
    walletsBulkChangeStore.Initialize()
  }, [null])

  const {
    IsLoading,
    CurrencyOptions,
    ViewModel,
    PageAlert,
    IsSubmitting,
    ActiveTab,
  } = walletsBulkChangeStore

  useEffect(() => {
    reset(ViewModel)
  }, [ViewModel])

  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({})
  const { handleSubmit, getValues, reset } = useFormProps

  const renderContent = () => {
    if (IsLoading || ViewModel === undefined) {
      return <LoaderIcon IsLoading={true} />
    }

    const onSubmit = (data: IFormValues) => {
      walletsBulkChangeStore.GenerateTemplateFile(data)
    }

    const data = getValues()

    const currencyName = data.SelectedCurrencyOption.label
    const currencyId = data.SelectedCurrencyOption.value

    return (
      <>
        <BreadCrumbs currentPageTitle={t.walletBulkChange.title} />
        <Portlet>
          <Tabs
            id="tabs"
            activeKey={ActiveTab}
            onSelect={(key) => {
              walletsBulkChangeStore.SetActiveTab(key)
            }}
          >
            <Tab
              eventKey={1}
              title={[t.walletBulkChange.tabGenerateFile.title]}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: t.walletBulkChange.tabGenerateFile.info,
                }}
              ></div>
              <Row>
                <Col md={6}>
                  <form
                    className="parsley-form"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    {/* <McSelect
                      name="SelectedCurrencies"
                      label={t.common.currencies}
                      defaultValue={SelectedCurrencyOption}
                      onChange={(newSelectedOption: ISelectOption) => {
                        walletsBulkChangeStore.SetSelectedCurrency(
                          newSelectedOption.value
                        )
                      }}
                      options={CurrencyOptions}
                    /> */}
                    <MySelect
                      formObject={ViewModel}
                      useFormProps={useFormProps}
                      name="SelectedCurrencyOption"
                      label={t.common.currency}
                      options={CurrencyOptions}
                    />
                    <McButton
                      block
                      bsStyle="primary"
                      bsSize="large"
                      type="submit"
                      isLoading={IsSubmitting}
                    >
                      {t.walletBulkChange.tabGenerateFile.title}
                    </McButton>
                  </form>
                </Col>
              </Row>
            </Tab>
            <Tab
              eventKey={2}
              title={[t.walletBulkChange.tabUploadChanges.title]}
            >
              <div>dropzone</div>
              <div
                className="info-panel"
                dangerouslySetInnerHTML={{
                  __html: t.walletBulkChange.tabUploadChanges.info,
                }}
              ></div>
              <h4>{t.common.example}:</h4>
              <Row>
                <Col md={6}>
                  <div className="code">
                    UserId;Name;CurrencyId;Modifier;Amount
                    <br />
                    3;Jan;{currencyId};+;740
                    <br />
                    1;Jim;{currencyId};0;0
                    <br />
                    2;Jenna;{currencyId};=;1500
                    <br />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="code-explained">
                    <ul>
                      {}
                      <li>
                        Het <b>{currencyName}</b> saldo van Jan wordt
                        opgewaardeerd met <i>7,40</i>
                      </li>
                      <li>
                        Het <b>{currencyName}</b> saldo van Jim wordt gereset
                        naar
                        <i>0,00</i>
                      </li>
                      <li>
                        Het <b>{currencyName}</b> saldo van Jenna wordt
                        opgewaardeerd tot en met <i>15,00</i>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Tab>
          </Tabs>
          <McPageAlert pageAlert={PageAlert} />
        </Portlet>
      </>
    )
  }
  return <MainPage>{renderContent()}</MainPage>
}

export default observer(BulkChange)
