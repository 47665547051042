import React, { useEffect, useReducer } from 'react'
import { ButtonToolbar } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { LoaderIcon } from 'src/Components'
import McButton from 'src/Components/McButton'
import McModal from 'src/Components/Modals/McModal'
import { InputMasks, MySelect, MyTextbox } from 'src/Components/My'
import { t } from 'src/I18n'
import {
  IAddTicketForm,
  initialState,
  reducer,
  reducerActions,
} from './Reducer'

interface IProps {
  eventId?: number
  onClose: () => void
}

const AddTicketModal: React.FC<IProps> = ({ eventId, onClose }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const actions = reducerActions(state, dispatch)
  const { isLoading, eventTicketOptions, viewModel } = state

  const params = useParams<'id'>()
  const userGuid = params.id
  useEffect(() => {
    if (eventId !== undefined) {
      actions.initialize(eventId)
    }
  }, [eventId])

  useEffect(() => {
    reset(viewModel)
  }, [viewModel])

  const useFormProps: UseFormReturn<IAddTicketForm> = useForm<IAddTicketForm>(
    {}
  )
  const { handleSubmit, reset } = useFormProps

  const onSubmit = (data: IAddTicketForm) => {
    actions.save(userGuid, data, () => {
      onClose()
    })
  }

  if (eventId === undefined) {
    return null
  }

  const renderContent = () => {
    if (isLoading) {
      return <LoaderIcon IsLoading={isLoading} />
    }

    return (
      <>
        <MySelect
          formObject={viewModel}
          useFormProps={useFormProps}
          label={t.addTicket.fields.ticket.title}
          name="eventTicketOption"
          options={eventTicketOptions}
        />
        <MyTextbox
          formObject={viewModel}
          label={t.addTicket.fields.amount.title}
          name="amount"
          useFormProps={useFormProps}
          inputMask={InputMasks.Number}
          icon="fa-hashtag"
          placeholder="0"
          rules={{ required: t.addTicket.fields.amount.required }}
        />
      </>
    )
  }

  return (
    <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
      <McModal
        closeClicked={onClose}
        title={t.addTicket.title}
        footer={
          <ButtonToolbar className="pull-right">
            <McButton onClick={onClose}>{t.common.cancel}</McButton>
            <McButton bsStyle="primary" type="submit">
              {t.addTicket.title}
            </McButton>
          </ButtonToolbar>
        }
      >
        {renderContent()}
      </McModal>
    </form>
  )
}

export default AddTicketModal
