import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'
import { IMyTab } from './MyPageTabs'

interface IProps {
  tabs: IMyTab[]
}

const MyTabs: React.FC<IProps> = ({ tabs }) => {
  const [activeTabKey, setActiveTabKey] = useState(tabs[0].key)

  const renderTabs = () => {
    return (
      <ul className="nav nav-tabs">
        {tabs.map((tab, key) => {
          const className = activeTabKey === tab.key ? 'active' : ''
          return (
            <li className={className} key={key}>
              <a
                onClick={() => {
                  setActiveTabKey(tab.key)
                }}
              >
                {tab.title}
              </a>
            </li>
          )
        })}
      </ul>
    )
  }

  const renderContent = () => {
    const activeTab = tabs.find((x) => x.key === activeTabKey)
    if (activeTab === undefined) {
      return null
    }

    return activeTab.content
  }

  return (
    <>
      {renderTabs()}
      {renderContent()}
    </>
  )
}

export default MyTabs
