import React, { useEffect, useReducer } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { McButton } from 'src/Components'
import Portlet from 'src/Components/Authenticated/Portlet'
import { MyDatePicker, MyLocalizedStringTabs, MySlim } from 'src/Components/My'
import { t } from 'src/I18n'
import { IEventEditVTO } from 'src/Models/Server/Response/Event/IEventEditVTO'

import {
  IEventFormValues,
  initialState,
  reducer,
  reducerActions,
} from './Reducer'

interface IProps {
  title: string
  onSave: (data: IEventFormValues) => void
  isSubmitting: boolean
  eventEdit?: IEventEditVTO
}

const SubscriptionItemForm: React.FC<IProps> = ({
  title,
  onSave,
  isSubmitting,
  eventEdit,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const actions = reducerActions(state, dispatch)

  useEffect(() => {
    actions.initialize(eventEdit)
  }, [eventEdit])

  const { viewModel } = state

  useEffect(() => {
    reset(viewModel)
  }, [viewModel])

  const useFormProps: UseFormReturn<IEventFormValues> =
    useForm<IEventFormValues>()

  const { handleSubmit, reset, getValues, setValue } = useFormProps

  const onSubmit = (data: IEventFormValues) => {
    onSave(data)
  }

  const renderContent = () => {
    if (viewModel === undefined) {
      return null
    }

    return (
      <>
        <Row>
          <Col md={6}>
            <MyDatePicker
              name="Start"
              label={t.event.insert.fields.start.title}
              formObject={viewModel}
              useFormProps={useFormProps}
              showTimeSelect={true}
              rules={{
                required: t.event.insert.fields.start.required,
              }}
              onDateChange={(newDate) => {
                setValue('Start', newDate)
              }}
            />
          </Col>
          <Col md={6}>
            <MyDatePicker
              formObject={viewModel}
              useFormProps={useFormProps}
              name="End"
              label={t.event.insert.fields.end.title}
              showTimeSelect={true}
              onDateChange={(newDate) => {
                setValue('End', newDate)
              }}
              rules={{
                required: t.event.insert.fields.end.required,
                validate: (validTill: Date) => {
                  let result = true
                  const validFrom = getValues('Start') as Date
                  if (validFrom && validTill) {
                    // validTill must be greater than validfrom when set
                    result = validFrom < validTill
                  }

                  return result || t.event.insert.fields.end.greaterThan
                },
              }}
            />
          </Col>
        </Row>
        <MyLocalizedStringTabs
          formObject={viewModel}
          useFormProps={useFormProps}
          name="Name"
          label={t.event.insert.fields.name.label}
          isRequired
        />
        <MyLocalizedStringTabs
          formObject={viewModel}
          useFormProps={useFormProps}
          name="Description"
          label={t.event.insert.fields.description.label}
          isRequired
        />

        <MySlim
          name="ImageFile"
          formObject={viewModel}
          useFormProps={useFormProps}
          label={t.savingcardInsert.fields.ImageFile.label}
          rules={{
            required: t.savingcardInsert.fields.ImageFile.errors.required,
          }}
          sizeSlim={{ width: 580, height: 380 }}
        />

        <McButton
          block
          bsStyle="primary"
          bsSize="large"
          type="submit"
          isLoading={isSubmitting}
        >
          {t.common.save}
        </McButton>
      </>
    )
  }

  return (
    <>
      <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
        <Portlet title={title}>{renderContent()}</Portlet>
      </form>
    </>
  )
}

export default SubscriptionItemForm
