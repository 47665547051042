import { action, makeObservable, runInAction } from 'mobx'
import { NavigateFunction } from 'react-router-dom'
import { toast } from 'react-toastify'
import { t } from 'src/I18n'
import IGenericError from 'src/Models/Server/IGenericError'
import { IEditFileRequest } from 'src/Models/Server/Request/File/IEditFileRequest'
import FileController from 'src/Services/ToprCoreApi/Axios/Controllers/FileController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export interface IFormValues extends IEditFileRequest {
  File: File
}

export default class Store extends BaseStore<IEditFileRequest> {
  constructor() {
    super()

    makeObservable(this, {
      Initialize: action,
      Edit: action,
    })
  }

  public Initialize(fileId: string) {
    this.BaseInitialize({
      FileId: parseInt(fileId, 10),
      Name: '',
      MimeType: '',
      Data: '',
    })
  }

  public Edit(data: IFormValues, navigate: NavigateFunction) {
    if (this.ViewModel) {
      this.IsSubmitting = true

      FileController.Edit(data)
        .then(() => {
          runInAction(() => (this.IsSubmitting = false))
          toast.success(t.fileEdit.form.success)
          navigate(`/file/details/${data.FileId}`)
        })
        .catch((error: IGenericError) => {
          runInAction(() => (this.IsSubmitting = false))
          appUiStore.ShowError(error)
        })
    }
  }
}

export const fileEditStore = new Store()
