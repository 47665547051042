import { AxiosError, AxiosResponse } from 'axios'
import { handleException } from 'src/Helpers/ResponseHelpers'
import INameIdVTO from 'src/Models/Server/INameIdVTO'
import { IInsertPosRequest } from 'src/Models/Server/Request/Pos/IInsertPosRequest'
import { IExceptionError } from 'src/Models/Server/Response/Exception/ExceptionError'
import IPosVTO from 'src/Models/Server/Response/Pos/IPosVTO'
import { axiosAuth } from '../AuthAxios'

export default {
  GetPosTypes() {
    return new Promise<Array<INameIdVTO<number>>>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/pos/types`)
          .then((response: AxiosResponse<Array<INameIdVTO<number>>>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },

  Insert(request: IInsertPosRequest) {
    return new Promise<string>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/pos/insert`, request)
          .then((response: AxiosResponse<string>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  Edit(request: IPosVTO) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/pos/edit`, request)
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  Activate(guid: string) {
    return new Promise<IPosVTO>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/pos/activate/${guid}`, null)
          .then((response: AxiosResponse<IPosVTO>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  Deactivate(guid: string) {
    return new Promise<IPosVTO>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/pos/deactivate/${guid}`, null)
          .then((response: AxiosResponse<IPosVTO>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  GetPos(guid: string) {
    return new Promise<IPosVTO>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/pos/${guid}`)
          .then((response: AxiosResponse<IPosVTO>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  GetPosByLocation(guid: string) {
    return new Promise<IPosVTO[]>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/pos/location/${guid}`)
          .then((response: AxiosResponse<IPosVTO[]>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
}
