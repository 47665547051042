import { action, makeObservable, runInAction } from 'mobx'
import { createPageAlert } from 'src/Components/McPageAlert'
import IGenericError from 'src/Models/Server/IGenericError'
import IEditAppUserRequest from 'src/Models/Server/Request/Merchant/IEditAppUserRequest'
import IAppUserVTO from 'src/Models/Server/Response/User/IAppUserVTO'
import AppUserController from 'src/Services/ToprCoreApi/Axios/Controllers/AppUserController'
import BaseStore from 'src/Stores/BaseStore'

export default class Store extends BaseStore<IEditAppUserRequest> {
  constructor() {
    super()
    this.BaseInitialize()
    makeObservable(this, {
      Initialize: action,
      EditAppUser: action,
    })
  }

  public Initialize(appUser: IAppUserVTO) {
    this.BaseInitialize({
      Id: appUser.UserGuid,
      Name: appUser.Name,
      Email: appUser.UniqueEmailAddress,
      Pincode: appUser.TempPassword,
    })
  }

  public EditAppUser(data: IEditAppUserRequest) {
    this.IsSubmitting = true

    return new Promise<IAppUserVTO>((resolve, reject) => {
      AppUserController.Edit(data)
        .then((appUser) => {
          runInAction(() => (this.IsSubmitting = false))
          resolve(appUser)
        })
        .catch((error: IGenericError) => {
          runInAction(() => {
            this.PageAlert = createPageAlert(error)
            this.IsSubmitting = false
            reject()
          })
        })
    })
  }
}

export const editAppUserStore = new Store()
