import { action, makeObservable, runInAction } from 'mobx'
import { createPageAlert } from 'src/Components/McPageAlert'
import ErrorCodes from 'src/Models/Server/ErrorCodes'
import { IExceptionError } from 'src/Models/Server/Response/Exception/ExceptionError'
import AuthenticationController from 'src/Services/ToprCoreApi/Axios/Controllers/AuthenticationController'
import BaseStore from 'src/Stores/BaseStore'
import { persistedStore } from 'src/Stores/PersistedStore'

export interface ILoginViewModel {
  EmailAddress: string
  Password: string
}

export default class Store extends BaseStore {
  //public IsSubmitting = false
  //public PageAlert: IPageAlert = undefined

  constructor() {
    super()

    makeObservable(this, {
      Login: action,
    })
  }

  public Login(email: string, password: string) {
    //test
    // this.PageAlert = createPageAlert(
    //   {
    //     Code: ErrorCodes.USER_PASSWORD_INVALID,
    //     Message: 'invalid pasword jonguh',
    //   },
    //   [
    //     ErrorCodes.USER_PASSWORD_INVALID,
    //     ErrorCodes.USER_LOCKED_LONG,
    //     ErrorCodes.USER_LOCKED_SHORT,
    //     'USER_NOT_FOUND',
    //   ]
    // )

    this.IsSubmitting = true

    //1 ) atuhenticate
    AuthenticationController.AuthenticateMerchantUser(email, password)
      .then((response) => {
        // 2) Get merchantUser

        persistedStore.SetAuthenticatedUserResponse(response)
        persistedStore
          .GetAuthenticatedMerchantUser()
          .then(() => {
            runInAction(() => {
              this.IsSubmitting = false
            })
          })
          .catch((error: IExceptionError) => {
            runInAction(() => {
              this.IsSubmitting = false
              this.PageAlert = createPageAlert(error)
            })
          })
      })
      .catch((error: any) => {
        runInAction(() => {
          this.IsSubmitting = false
          this.PageAlert = createPageAlert(error, [
            ErrorCodes.USER_PASSWORD_INVALID,
            ErrorCodes.USER_LOCKED_LONG,
            ErrorCodes.USER_LOCKED_SHORT,
            'USER_NOT_FOUND',
          ])
        })
      })
  }
}

export const loginStore = new Store()
