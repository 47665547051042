import { action, makeObservable, runInAction } from 'mobx'
import { NavigateFunction } from 'react-router-dom'
import { toast } from 'react-toastify'
import { t } from 'src/I18n'
import IGenericError from 'src/Models/Server/IGenericError'
import { IInsertFileRequest } from 'src/Models/Server/Request/File/IInsertFileRequest'
import FileController from 'src/Services/ToprCoreApi/Axios/Controllers/FileController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export interface IFormValues extends IInsertFileRequest {
  File: File
}

export default class Store extends BaseStore<IFormValues> {
  constructor() {
    super()
    makeObservable(this, {
      Initialize: action,
      Insert: action,
    })
  }

  public Initialize() {
    this.BaseInitialize({
      Name: '',
      MimeType: '',
      Data: '',
      File: null,
    })

    this.IsLoading = false
  }

  public Insert(data: IFormValues, navigate: NavigateFunction) {
    this.IsSubmitting = true

    FileController.Insert(data)
      .then((fileId) => {
        runInAction(() => (this.IsSubmitting = false))
        toast.success(t.fileInsert.form.success)
        navigate(`/file/details/${fileId}`)
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsSubmitting = false))
        appUiStore.ShowError(error)
      })
  }
}

export const fileInsertStore = new Store()
