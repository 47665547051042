import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { LoaderIcon } from 'src/Components'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import { MainPage, MyMonthPicker, MySelect, MyChart } from 'src/Components/My'
import { t } from 'src/I18n'
import { ISelectOption } from 'src/Models/Client'
import { IFormValues, paymentsPerDayMerchantsStore } from './Store'

interface IProps {}

const PaymentsPerDayMerchants: React.FC<IProps> = () => {
  const navigate = useNavigate()
  // useEffect(() => {
  //   paymentsPerDayMerchants.Initialize()
  // }, [window.location.href])

  useEffect(() => {
    paymentsPerDayMerchantsStore.Initialize()
  }, [null])
  const { ViewModel, IsLoading, ChartResponse, Currencies, Merchants } =
    paymentsPerDayMerchantsStore

  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({})
  const { handleSubmit, reset, setValue, getValues } = useFormProps

  useEffect(() => {
    reset(ViewModel)
  }, [ViewModel])

  const renderFilter = () => {
    if (IsLoading || ViewModel === undefined) {
      return <LoaderIcon IsLoading />
    }

    return (
      <Row>
        <Col md={6}>
          <MyMonthPicker
            formObject={{} as IFormValues}
            useFormProps={useFormProps}
            name="SelectedMonth"
            label={t.common.month}
            onSelectionChange={(option: ISelectOption) => {
              setValue('SelectedMonth', option)
              handleSubmit((data) =>
                paymentsPerDayMerchantsStore.GetPaymentsPerDayMerchant(data)
              )()
            }}
          />
          <MySelect
            formObject={{} as IFormValues}
            useFormProps={useFormProps}
            name="SelectedCurrency"
            label={t.common.currencies}
            options={Currencies}
            onSelectionChange={(options: ISelectOption) => {
              setValue('SelectedCurrency', options)
              handleSubmit((data) =>
                paymentsPerDayMerchantsStore.GetPaymentsPerDayMerchant(data)
              )()
            }}
          />
        </Col>
        <Col md={6}>
          <MySelect
            formObject={{} as IFormValues}
            useFormProps={useFormProps}
            name="SelectedMerchants"
            label={t.common.merchants}
            options={Merchants}
            isMulti
            onSelectionChange={(options: ISelectOption[]) => {
              setValue('SelectedMerchants', options)
              handleSubmit((data) =>
                paymentsPerDayMerchantsStore.GetPaymentsPerDayMerchant(data)
              )()
            }}
          />
        </Col>
      </Row>
    )
  }

  const data = getValues()

  return (
    <MainPage>
      <BreadCrumbs currentPageTitle={t.paymentsPerDay.title} />
      <Portlet title={t.common.filter}>
        <form className="parsley-form">{renderFilter()}</form>
      </Portlet>
      <MyChart
        chartResponse={ChartResponse}
        onDetailsClick={(id) => {
          navigate(
            `/report/payment/day/merchant/${id}/${data.SelectedCurrency.value}/${data.SelectedMonth.value}`
          )
        }}
      />
    </MainPage>
  )
}

export default observer(PaymentsPerDayMerchants)
