import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { LoaderIcon } from 'src/Components'
import { persistedStore } from 'src/Stores/PersistedStore'
import { userInfoStore } from '../InfoTab/Store'
import AutomaticChargesPortlet from './AutomaticChargesPortlet/AutomaticChargesPortlet'
import BlockedStornosPortlet from './BlockedStornos/BlockedStornosPortlet'
import RefundsPortlet from './RefundsPortlet/RefundsPortlet'
import { userFinanceStore } from './Store'
interface IProps {}

const FinanceTab: React.FC<IProps> = (props) => {
  const params = useParams<'id'>()
  useEffect(() => {
    userFinanceStore.Initialize(params.id)
  }, [null])

  const { IsLoading, AutomaticCharges, Refunds, BlockedStornos } =
    userFinanceStore
  const { User } = userInfoStore
  const email = User !== null ? User.UniqueEmailAddress : ''

  if (IsLoading) {
    return <LoaderIcon IsLoading />
  }

  return (
    <>
      <AutomaticChargesPortlet
        userGuid={params.id}
        automaticCharges={AutomaticCharges}
        email={email}
        selectedManagerUser={persistedStore.SelectedManagerUser}
      />
      <BlockedStornosPortlet blockedStornos={BlockedStornos} />
      <RefundsPortlet refunds={Refunds} />
    </>
  )
}

export default observer(FinanceTab)
