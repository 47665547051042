import { ISelectOption } from 'src/Models/Client'

export default class INameIdVTO<T> {
  public Name: string
  public Id: T
}

export const Sort = <T>(source: Array<INameIdVTO<T>>) => {
  return source.sort((a, b) => {
    const nameA = a.Name.toLowerCase()
    const nameB = b.Name.toLowerCase()
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  })
}

export const ToSelectOptions = <T>(
  source: Array<INameIdVTO<T>>
): ISelectOption[] => {
  return Sort(source).map((nameValue) => {
    return { value: nameValue.Id.toString(), label: nameValue.Name }
  })
  /*
  return source
    .sort((a, b) => {
      const nameA = a.Name.toLowerCase()
      const nameB = b.Name.toLowerCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })
    .map((nameValue) => {
      return { value: nameValue.Id.toString(), label: nameValue.Name }
    })
    */
}
