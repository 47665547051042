import React from 'react'
interface IProps {
  label: string
  onButtonClick: () => void
  icon?: string
  buttonStyle?: 'primary' | 'success' | 'info' | 'warning' | 'danger'
}

const MyLabelButton: React.FC<IProps> = ({
  label,
  icon,
  onButtonClick,
  buttonStyle,
}) => {
  return (
    <>
      <div className="btn-group btn-group-xs tag">
        <button className="btn btn-default">{label}</button>
        <button
          type="button"
          className={`btn btn-${buttonStyle}`}
          onClick={onButtonClick}
        >
          <i className={`fa ${icon}`} />
        </button>
      </div>
    </>
  )
}

export default MyLabelButton

MyLabelButton.defaultProps = {
  //default delete
  icon: 'fa-times',
  buttonStyle: 'danger',
}
