import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { LoaderIcon, McButton, McPageAlert } from 'src/Components'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import { MainPage, MyDataTable } from 'src/Components/My'
import { FormatDateTimeNL } from 'src/Helpers/DateHelpers'
import { sortDate } from 'src/Helpers/TableHelpers'
import { t } from 'src/I18n'
import { IMerchantRefundFileDto } from 'src/Models/Server/Response/Refund/IMerchantRefundFileDto'
import { merchantRefundsStore } from './Store'

interface IProps {}

const MerchantRefunds: React.FC<IProps> = () => {
  useEffect(() => {
    merchantRefundsStore.Initialize()
  }, [null])

  const { PageAlert, MerchantRefundFiles, MerchantFileIdLoading } =
    merchantRefundsStore

  const columns = [
    {
      dataField: 'details',
      isDummyField: true,
      text: t.common.details,
      sort: false,
      formatter: (cell, data: IMerchantRefundFileDto) => {
        if (data.MerchantRefundFileId === MerchantFileIdLoading) {
          return <LoaderIcon IsLoading={true} />
        }

        return (
          <Link to={`/merchantrefunds/details/${data.MerchantRefundFileId}`}>
            {t.common.details}
          </Link>
        )
      },
    },
    {
      dataField: 'download',
      isDummyField: true,
      text: t.common.download,
      sort: false,
      formatter: (cell, data: IMerchantRefundFileDto) => {
        if (data.MerchantRefundFileId === MerchantFileIdLoading) {
          return <LoaderIcon IsLoading={true} />
        }

        return (
          <a
            onClick={() =>
              merchantRefundsStore.Download(data.MerchantRefundFileId)
            }
          >
            {t.common.download}
          </a>
        )
      },
    },
    {
      dataField: 'RefundsCount',
      text: t.merchantRefunds.amount,
    },
    {
      dataField: 'Downloaded',
      text: t.merchantRefunds.downloaded,
      sort: true,
      sortFunc: (order, dataField, rowA, rowB) => {
        return sortDate(order, rowA, rowB, 'Downloaded')
      },
      formatter: (cell, data: IMerchantRefundFileDto) => {
        //return data.Downloaded
        return FormatDateTimeNL(data.Downloaded)
      },
    },
  ]

  return (
    <MainPage>
      <BreadCrumbs currentPageTitle={t.merchantRefunds.title} />
      <McButton
        iconLeft="fa-cogs"
        bsStyle="primary"
        className="mb-10"
        isLoading={merchantRefundsStore.IsSubmitting}
        onClick={() => merchantRefundsStore.CreateMerchantRefundFile()}
      >
        {t.merchantRefunds.generateFile}
      </McButton>
      <McPageAlert pageAlert={PageAlert} />
      <Portlet title={t.merchantRefunds.title}>
        <MyDataTable data={MerchantRefundFiles} columns={columns} />
      </Portlet>
    </MainPage>
  )
}

export default observer(MerchantRefunds)
