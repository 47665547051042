import React from 'react'
import { Badge } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Card } from 'src/Components'
import { FormatDateLocalized } from 'src/Helpers/DateHelpers'
import { GetLocalText } from 'src/Helpers/TextHelpers'
import { t } from 'src/I18n'
import { GetFileUrl } from 'src/Images'
import { ConsumerSubscriptionItemType } from 'src/Models/Server/Enums'
import { IUserSubscriptionSto } from 'src/Models/Server/Response/ConsumerSubscription/IUserSubscriptionSto'
interface IProps {
  userSubscriptionItem: IUserSubscriptionSto
  userGuid: string
  onDelete: (userSubscriptionId: number, name: string) => void
}

const UserSubscriptionItemCard: React.FC<IProps> = ({
  userSubscriptionItem,
  userGuid,
  onDelete,
}) => {
  //const subText = getItemTypeText(userSubscriptionItem.SubscriptionItem)

  const renderDates = () => {
    const renderTill = () => {
      if (userSubscriptionItem.ValidTill === null) {
        return null
      }
      return (
        <div className="key-value">
          <label>{t.common.till}</label>
          <span>{FormatDateLocalized(userSubscriptionItem.ValidTill)}</span>
        </div>
      )
    }

    //    return <>{text}</>
    return (
      <>
        <div className="key-value">
          <label>{t.common.from}</label>
          <span>{FormatDateLocalized(userSubscriptionItem.ValidFrom)}</span>
        </div>
        {renderTill()}
      </>
    )
  }

  const renderDeleteLink = () => {
    if (userSubscriptionItem.UsedAmount > 0) {
      return null
    }

    return (
      <a
        className="danger"
        onClick={() => {
          onDelete(
            userSubscriptionItem.UserSubscriptionId,
            GetLocalText(userSubscriptionItem.SubscriptionItem.Name)
          )
        }}
      >
        {t.common.delete}
      </a>
    )
  }

  const renderTripsBadge = () => {
    if (
      userSubscriptionItem.SubscriptionItem.ConsumerSubscriptionItemTypeId !==
      ConsumerSubscriptionItemType.Trips
    ) {
      return null
    }

    return (
      <h5>
        <Badge>
          {userSubscriptionItem.UsedAmount}/{userSubscriptionItem.Amount}
        </Badge>{' '}
        Ritten
      </h5>
    )
  }

  return (
    <Card>
      <Card.CardImageHeader
        imageUrl={GetFileUrl(
          userSubscriptionItem.SubscriptionItem.ImageFileGuid
        )}
      />
      <Card.FooterTitle>
        <h4>{GetLocalText(userSubscriptionItem.SubscriptionItem.Name)}</h4>
        {renderTripsBadge()}
      </Card.FooterTitle>
      <div className="footer-text">{renderDates()}</div>
      {/* {userSubscriptionItem.SubscriptionItem.COnsu} */}
      <Card.FooterActions>
        <Link
          to={`/subscription/user/${userGuid}/edit/${userSubscriptionItem.SubscriptionItem.ConsumerSubscriptionId}/${userSubscriptionItem.UserSubscriptionId}`}
        >
          {t.common.edit}
        </Link>
        {renderDeleteLink()}
      </Card.FooterActions>
    </Card>
  )
}

export default UserSubscriptionItemCard
