import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { McButton } from 'src/Components'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import {
  MainPage,
  MyDateRangePicker,
  MySelect,
  MyTextbox,
} from 'src/Components/My'
import { FormatDateLocalized } from 'src/Helpers/DateHelpers'
import { t } from 'src/I18n'
import { IFormValues, siedlerChargeGuthabenStore } from './Store'

export const Path = '/siedler-report/invoice-coupons'
export const GetUrl = () => {
  return Path
}
interface IProps {}

const ChargeGuthaben: React.FC<IProps> = () => {
  useEffect(() => {
    siedlerChargeGuthabenStore.Initialize()
  }, [null])

  const { ViewModel, IsSubmitting, Companies } = siedlerChargeGuthabenStore

  useEffect(() => {
    reset(ViewModel)
  }, [ViewModel])

  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({})
  const { handleSubmit, reset, setValue } = useFormProps

  const onSubmit = (data: IFormValues) => {
    siedlerChargeGuthabenStore.GetSiedlerChargeGuthaben(data)
  }
  const renderForm = () => {
    return (
      <>
        <Portlet>
          <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={6}>
                <MyDateRangePicker
                  formObject={ViewModel}
                  name="DateRange"
                  useFormProps={useFormProps}
                  onDateRangeChange={(newDateRange) => {
                    setValue(
                      'CustomTitle',
                      `${FormatDateLocalized(
                        newDateRange.StartDate
                      )} - ${FormatDateLocalized(newDateRange.EndDate)}`
                    )
                  }}
                />
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={6}>
                    <MySelect
                      formObject={ViewModel}
                      useFormProps={useFormProps}
                      label={t.common.company}
                      name="SelectedCompany"
                      options={Companies}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <MyTextbox
                  formObject={ViewModel}
                  useFormProps={useFormProps}
                  label={t.siedlerCompanyCoupons.customerTitle}
                  name="CustomTitle"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <McButton
                  block
                  bsStyle="primary"
                  bsSize="large"
                  type="submit"
                  isLoading={IsSubmitting}
                >
                  {t.siedlerChargeGuthaben.actionText}
                </McButton>
              </Col>
            </Row>
          </form>
        </Portlet>
      </>
    )
  }

  return (
    <>
      <MainPage>
        <BreadCrumbs currentPageTitle={t.siedlerChargeGuthaben.title} />
        {renderForm()}
      </MainPage>
    </>
  )
}

export default observer(ChargeGuthaben)
