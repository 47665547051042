import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { Link } from 'react-router-dom'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import LoadContent from 'src/Components/LoadContent'
import LoaderIcon from 'src/Components/LoaderIcon'
import {
  MainPage,
  MySelect,
  MyMonthPeriodSelect,
  MyDataTable,
} from 'src/Components/My'

import { ISelectOption } from 'src/Models/Client'
import { sortDate, sortNumber } from 'src/Helpers/TableHelpers'
import { t } from 'src/I18n'
import { IMerchantPaymentVTO } from 'src/Models/Server/Response/Report/IMerchantPaymentVTO'
import { IFormValues, merchantPaymentsStore } from './Store'

interface IProps {}

const MerchantPayments: React.FC<IProps> = () => {
  useEffect(() => {
    merchantPaymentsStore.Initialize()
  }, [null])

  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({})
  const { handleSubmit, reset, setValue } = useFormProps

  const { IsLoading, ViewModel, LoadingMerchantPayments, MerchantPayments } =
    merchantPaymentsStore

  useEffect(() => {
    reset(ViewModel)
  }, [ViewModel])

  const renderMerchantPayments = () => {
    const transactionsFormatter = (cell, data: IMerchantPaymentVTO) => {
      return (
        <Link to={`/merchantpayment/${data.MerchantPaymentID}`}>
          {data.Transactions}
        </Link>
      )
    }

    const columns = [
      {
        dataField: 'Merchant.Name',
        text: t.common.merchant,
        sort: true,
      },
      {
        dataField: 'DateFromFormatted',
        text: t.common.from,
        sort: true,
        sortFunc: (order, dataField, rowA, rowB) => {
          return sortDate(order, rowA, rowB, 'DateFrom')
        },
      },
      {
        dataField: 'DateToFormatted',
        text: t.common.untill,
        sort: true,
        sortFunc: (order, dataField, rowA, rowB) => {
          return sortDate(order, rowA, rowB, 'DateFrom')
        },
      },
      {
        dataField: 'Transactions',
        text: t.common.transactions,
        sort: true,
        formatter: transactionsFormatter,
      },
      {
        dataField: 'AmountFormatted',
        text: t.common.amount,
        sort: true,
        sortFunc: (order, dataField, rowA, rowB) => {
          return sortNumber(order, rowA, rowB, 'Amount')
        },
      },
    ]

    return (
      <LoadContent IsLoading={LoadingMerchantPayments}>
        <MyDataTable data={MerchantPayments} columns={columns} />
      </LoadContent>
    )
  }

  return (
    <MainPage>
      <BreadCrumbs currentPageTitle={t.merchantPayments.title} />
      <LoaderIcon IsLoading={IsLoading} />
      {!IsLoading && ViewModel !== undefined && (
        <Portlet title={t.merchantPayments.title}>
          <form className="parsley-form">
            <Row>
              <Col md={6}>
                <MyMonthPeriodSelect
                  onChange={(startDate, endDate) => {
                    setValue('StartDate', startDate)
                    setValue('EndDate', endDate)

                    handleSubmit((data) =>
                      merchantPaymentsStore.GetMerchantPayments(data)
                    )()
                  }}
                />
              </Col>
              <Col md={6}>
                <MySelect
                  formObject={{} as IFormValues}
                  useFormProps={useFormProps}
                  name="SelectedMerchants"
                  label={t.common.merchants}
                  options={ViewModel.Merchants}
                  isMulti
                  onSelectionChange={(options: ISelectOption[]) => {
                    setValue('SelectedMerchants', options)
                    handleSubmit((data) =>
                      merchantPaymentsStore.GetMerchantPayments(data)
                    )()
                  }}
                />
              </Col>
            </Row>
            {renderMerchantPayments()}
          </form>
        </Portlet>
      )}
    </MainPage>
  )
}

export default observer(MerchantPayments)
