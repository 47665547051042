import { toast } from 'react-toastify'
import { t } from 'src/I18n'
import { ISelectOption } from 'src/Models/Client'
import IGenericError from 'src/Models/Server/IGenericError'
import CouponController from 'src/Services/ToprCoreApi/Axios/Controllers/CouponController'
import { appUiStore } from 'src/Stores/AppUiStore'

export interface IFormValues {
  amount: number
  couponOption?: ISelectOption
}

export interface IState {
  isLoading: boolean
  isSubmitting: boolean
  coupons: ISelectOption[]
  viewModel?: IFormValues
}

export const initialState: IState = {
  isSubmitting: false,
  isLoading: true,
  coupons: [],
}

export type Actions =
  | {
      type: 'setIsSubmitting'
      isSubmitting: boolean
    }
  | {
      type: 'initialize'
      coupons: ISelectOption[]
      viewModel?: IFormValues
    }

export const reducer = (state: IState, action: Actions): IState => {
  switch (action.type) {
    case 'initialize':
      return {
        ...state,
        isLoading: false,
        coupons: action.coupons,
        viewModel: action.viewModel,
      }
    case 'setIsSubmitting':
      return {
        ...state,
        isSubmitting: action.isSubmitting,
      }
    default:
      throw new Error('Invalid Reducer Action')
  }
}

export const reducerActions = (
  state: IState,
  dispatch: (action: Actions) => void
) => {
  return {
    initialize: () => {
      CouponController.GetAcquirableCoupons()
        .then((coupons) => {
          const couponOptions: ISelectOption[] = coupons.map((c) => ({
            label: c.FullTitle,
            value: c.CouponId.toString(),
          }))

          dispatch({
            type: 'initialize',
            coupons: couponOptions,
            viewModel: {
              amount: 1,
            },
          })
        })
        .catch((error: IGenericError) => {
          appUiStore.ShowError(error)
        })
    },
    save: (data: IFormValues, ids: number[]) => {
      dispatch({ type: 'setIsSubmitting', isSubmitting: true })
      CouponController.AddUserCoupon(data.amount, data.couponOption.value, ids)
        .then(() => {
          history.back()
          toast.success(
            t.formatString(t.coupons.userCouponAdd.successMessage, {
              amount: data.amount,
              couponTitle: data.couponOption.label,
              count: ids.length,
            })
          )
          dispatch({ type: 'setIsSubmitting', isSubmitting: false })
        })
        .catch((error: IGenericError) => {
          appUiStore.ShowError(error)
          dispatch({ type: 'setIsSubmitting', isSubmitting: false })
        })
    },
  }
}
