import { createPageAlert } from 'src/Components/McPageAlert'
import IGenericError from 'src/Models/Server/IGenericError'
import { IMerchantRefundFileDto } from 'src/Models/Server/Response/Refund/IMerchantRefundFileDto'
import MerchantRefundController from 'src/Services/ToprCoreApi/Axios/Controllers/MerchantRefundController'
import BaseStore from 'src/Stores/BaseStore'
import { persistedStore } from 'src/Stores/PersistedStore'
import { action, makeObservable, observable, runInAction } from 'mobx'

export class Store extends BaseStore {
  public MerchantFileIdLoading: number = -1

  public MerchantRefundFiles: IMerchantRefundFileDto[] = []

  public Page = 1
  public PageSize = 50

  constructor() {
    super()
    this.BaseInitialize()

    makeObservable(this, {
      MerchantFileIdLoading: observable,
      MerchantRefundFiles: observable,
      Page: observable,
      PageSize: observable,
      Initialize: action,
      GetMerchantFiles: action,
      CreateMerchantRefundFile: action,
      Download: action,
    })
  }

  public Initialize() {
    this.Page = 1

    this.GetMerchantFiles()
  }

  public GetMerchantFiles() {
    this.IsLoading = true
    const merchantId = persistedStore.SelectedManagerUser.Merchant.Id
    MerchantRefundController.GetMerchantRefundFiles(
      merchantId,
      this.Page,
      this.PageSize
    )
      .then((result) => {
        runInAction(() => {
          this.IsLoading = false
          this.MerchantRefundFiles = result
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => {
          this.PageAlert = createPageAlert(error)
          this.IsLoading = false
        })
      })
  }

  public CreateMerchantRefundFile() {
    this.IsSubmitting = true
    MerchantRefundController.CreateMerchantRefundFile()
      .then((merchantRefundFileId) => {
        runInAction(() => {
          this.IsSubmitting = false
          this.MerchantFileIdLoading = merchantRefundFileId
          this.GetMerchantFiles()
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => {
          this.PageAlert = createPageAlert(error, [
            'MERCHANTREFUND_CREATE_NO_REFUNDS_FOUND',
          ])

          this.IsSubmitting = false
        })
      })
  }

  public Download(merchantFileId: number) {
    this.MerchantFileIdLoading = merchantFileId

    MerchantRefundController.Download(merchantFileId)
      .then(() => {
        runInAction(() => {
          this.MerchantFileIdLoading = -1
          this.GetMerchantFiles()
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => {
          this.PageAlert = createPageAlert(error)
          this.MerchantFileIdLoading = -1
        })
      })
  }
}

export const merchantRefundsStore = new Store()
