import { action, makeObservable, observable, runInAction } from 'mobx'
import { createPageAlert } from 'src/Components/McPageAlert'
import { ISelectOption } from 'src/Models/Client'
import IGenericError from 'src/Models/Server/IGenericError'
import CurrencyVTO from 'src/Models/Server/Response/Currency/CurrencyVTO'
import CurrencyController from 'src/Services/ToprCoreApi/Axios/Controllers/CurrencyController'
import WalletController from 'src/Services/ToprCoreApi/Axios/Controllers/WalletController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export interface IFormValues {
  SelectedCurrencyOption: ISelectOption
}
export class Store extends BaseStore<IFormValues> {
  public CurrencyOptions: ISelectOption[] = []

  public ActiveTab = 1

  constructor() {
    super()

    makeObservable(this, {
      CurrencyOptions: observable,
      ActiveTab: observable,
      Initialize: action,
      SetActiveTab: action,
      GenerateTemplateFile: action,
    })
  }

  public Initialize() {
    this.IsLoading = true

    CurrencyController.GetMerchantCurrenciesManagerCharge()
      .then((result: CurrencyVTO[]) => {
        this.CurrencyOptions = result
          .filter((x) => x.ManagerCharge)
          .map((x) => ({
            label: x.Name,
            value: x.CurrencyId.toString(),
          }))

        if (this.CurrencyOptions.length > 0) {
          this.BaseInitialize({
            SelectedCurrencyOption: this.CurrencyOptions[0],
          })
        }

        runInAction(() => (this.IsLoading = false))
      })
      .catch((error: IGenericError) => {
        runInAction(() => {
          this.PageAlert = createPageAlert(error)
          this.IsLoading = false
        })
      })
  }

  public SetActiveTab(activeTab: number) {
    this.ActiveTab = activeTab
  }

  public GenerateTemplateFile(data: IFormValues) {
    this.IsSubmitting = true
    WalletController.GenerateTemplateFile(data.SelectedCurrencyOption.value)
      .then(() => {
        //console.log(result)
        this.SetActiveTab(2)

        runInAction(() => (this.IsSubmitting = false))
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        runInAction(() => (this.IsSubmitting = false))
      })
  }
}

export const walletsBulkChangeStore = new Store()
