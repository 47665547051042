import { action, makeObservable, observable, runInAction } from 'mobx'
import { ISelectOption } from 'src/Models/Client'
import IGenericError from 'src/Models/Server/IGenericError'
import UserController from 'src/Services/ToprCoreApi/Axios/Controllers/UserController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export interface IFormValues {
  SelectedTenantDomain: ISelectOption
}
export default class Store extends BaseStore<IFormValues> {
  public TenantDomains: ISelectOption[] = []

  public ShowTenantSelect: boolean = false

  public LoginUserGuid: string = null

  constructor() {
    super()

    this.BaseInitialize()

    makeObservable(this, {
      Initialize: action,
      OpenTenantSelect: action,
      CloseTenantSelect: action,
      LoginAsUser: action,
      LoginUserGuid: observable,
      ShowTenantSelect: observable,
      TenantDomains: observable,
    })
  }

  public Initialize() {
    const tenantDomains = [
      'www.toprwallet.nl',
      'www.mybility.nl',
      'www.mycashless.nl',
      'www.meinesiedlerkarte.de',
    ]
    this.TenantDomains = tenantDomains.map((tenantDomain) => ({
      value: tenantDomain,
      label: tenantDomain,
    }))

    this.BaseInitialize({
      SelectedTenantDomain: this.TenantDomains[0],
    })
  }

  public OpenTenantSelect(userGuid: string) {
    this.ShowTenantSelect = true
    this.LoginUserGuid = userGuid
  }

  public CloseTenantSelect() {
    this.ShowTenantSelect = false
    this.LoginUserGuid = null
  }

  public LoginAsUser(selectedTenantDomain: string) {
    this.IsSubmitting = true
    UserController.CreateAdminLogin(this.LoginUserGuid, selectedTenantDomain)
      .then((url) => {
        runInAction(() => {
          window.open(url)
          this.CloseTenantSelect()
          this.IsSubmitting = false
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        runInAction(() => {
          this.IsSubmitting = false
        })
        this.CloseTenantSelect()
      })
  }
}

export const selectTenantStore = new Store()
