import React from 'react'
import { useParams } from 'react-router-dom'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import { MainPage } from 'src/Components/My'
import { t } from 'src/I18n'
import UserSubscriptionForm from '../Components/UserSubscriptionForm/UserSubscriptionForm'
interface IProps {}

const InsertUserSubscription: React.FC<IProps> = (props) => {
  const params = useParams<'userGuid' | 'consumerSubscriptionId'>()
  return (
    <MainPage>
      <BreadCrumbs
        currentPageTitle={t.subscription.insertUserSubscription.title}
        previousPages={[
          {
            to: `/user/details/${params.userGuid}/subscriptions`,
            title: t.common.user,
          },
        ]}
      />
      <UserSubscriptionForm
        consumerSubscriptionId={params.consumerSubscriptionId}
        userGuid={params.userGuid}
        title={t.subscription.insertUserSubscription.title}
      />
    </MainPage>
  )
}

export default InsertUserSubscription
