export interface IState {
  top?: number
  left?: number
  isVisible: boolean
}

export const initialState: IState = {
  top: undefined,
  left: undefined,
  isVisible: false,
}

export type Actions =
  | { type: 'toggleTooltip'; top: number; left: number; isVisible: boolean }
  | { type: 'closeTooltip' }

export function reducer(state: IState, action: Actions): IState {
  switch (action.type) {
    case 'toggleTooltip':
      return {
        top: action.top,
        left: action.left,
        isVisible: action.isVisible,
      }

    case 'closeTooltip':
      return initialState

    default:
      throw new Error('Invalid Reducer Action')
  }
}
