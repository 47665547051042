import { action, makeObservable, observable, runInAction } from 'mobx'
import IGenericError from 'src/Models/Server/IGenericError'
import ILocationVTO from 'src/Models/Server/Response/Location/ILocationVTO'

import BaseStore from 'src/Stores/BaseStore'
import LocationController from 'src/Services/ToprCoreApi/Axios/Controllers/LocationController'
import { IRow } from 'src/Models/Server/IRow'
import { appUiStore } from 'src/Stores/AppUiStore'

export type LocationRow = ILocationVTO & IRow

class Store extends BaseStore<LocationRow[]> {
  public MerchantGuid: string = ''

  public PreviewImage: string = null

  constructor() {
    super()
    this.BaseInitialize([])

    makeObservable(this, {
      MerchantGuid: observable,
      PreviewImage: observable,
      GetLocations: action,
      TogglePreview: action,
      SetIsvisibleInApp: action,
    })
  }

  public GetLocations(merchantGuid: string) {
    this.MerchantGuid = merchantGuid
    this.IsLoading = true
    LocationController.GetMerchantLocations(merchantGuid)
      .then((locations) => {
        runInAction(() => {
          this.ViewModel = locations.map((loc) => ({
            ...loc,
            IsLoading: false,
          }))
          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        runInAction(() => {
          this.IsLoading = false
        })
      })
  }

  public TogglePreview(previewImage?: string) {
    this.PreviewImage = previewImage
  }

  private SetRow(index: number, row: LocationRow) {
    this.ViewModel[index] = row
    this.ViewModel = [...this.ViewModel]
  }

  public SetIsvisibleInApp(locationGuid: string, isVisibleInApp: boolean) {
    const index = this.ViewModel.findIndex(
      (x) => x.LocationGuid === locationGuid
    )
    const row = { ...this.ViewModel[index] }
    row.IsLoading = true
    this.SetRow(index, row)

    LocationController.SetIsvisibleApp(locationGuid, isVisibleInApp)
      .then(() => {
        runInAction(() => {
          row.IsLoading = false
          row.IsVisibleInApp = isVisibleInApp

          this.SetRow(index, row)
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        runInAction(() => {
          row.IsLoading = false
          this.SetRow(index, row)
        })
      })
  }
}

export const locationsGridStore = new Store()
