import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { ButtonToolbar } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import McButton from 'src/Components/McButton'
import McModal from 'src/Components/Modals/McModal'
import { MySelect } from 'src/Components/My'
import { t } from 'src/I18n'
import { IFormValues, selectTenantStore } from './Store'

interface IProps {}

const SelectTenantModal: React.FC<IProps> = () => {
  useEffect(() => {
    selectTenantStore.Initialize()
  }, [null])

  const { TenantDomains, ShowTenantSelect, ViewModel } = selectTenantStore
  useEffect(() => {
    reset(ViewModel)
  }, [ViewModel])

  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({})
  const { handleSubmit, reset } = useFormProps

  const onSubmit = (data: IFormValues) => {
    selectTenantStore.LoginAsUser(data.SelectedTenantDomain.value)
  }

  if (!ShowTenantSelect) {
    return null
  }

  return (
    <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
      <McModal
        closeClicked={() => selectTenantStore.CloseTenantSelect()}
        title={t.helpdesk.selectTenantModal.header}
        footer={
          <ButtonToolbar className="pull-right">
            <McButton onClick={() => selectTenantStore.CloseTenantSelect()}>
              {t.common.cancel}
            </McButton>
            <McButton bsStyle="primary" type="submit">
              {t.helpdesk.selectTenantModal.loginActionText}
            </McButton>
          </ButtonToolbar>
        }
      >
        <MySelect
          formObject={{} as IFormValues}
          useFormProps={useFormProps}
          label={t.common.tenants}
          name="SelectedTenantDomain"
          options={TenantDomains}
        />
      </McModal>
    </form>
  )
}

export default observer(SelectTenantModal)
