import { action, makeObservable, runInAction } from 'mobx'
import { AlertType, createPageAlert } from 'src/Components/McPageAlert'
import { t } from 'src/I18n'
import ErrorCodes from 'src/Models/Server/ErrorCodes'
import IGenericError from 'src/Models/Server/IGenericError'
import UserController from 'src/Services/ToprCoreApi/Axios/Controllers/UserController'
import BaseStore from 'src/Stores/BaseStore'

export interface IFormValues {
  EmailAddress: string
}

export default class Store extends BaseStore {
  constructor() {
    super()

    makeObservable(this, {
      Submit: action,
    })
  }

  public Submit(email: string) {
    this.IsSubmitting = true

    UserController.ForgotPassword(email)
      .then(() => {
        //show success message
        runInAction(() => {
          this.IsSubmitting = false
          this.PageAlert = {
            message: t.forgotPassword.success,
            type: AlertType.Success,
          }
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => {
          this.IsSubmitting = false
          this.PageAlert = createPageAlert(error, [
            ErrorCodes.USER_LOGIN_EMAIL_NOT_FOUND,
          ])
        })
      })
  }
}

export const forgotPasswordStore = new Store()
