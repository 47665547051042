import { createAction, createReducer } from '@reduxjs/toolkit'
import { enumToText } from 'src/Helpers/TextHelpers'
import { t } from 'src/I18n'
import { ISelectOption } from 'src/Models/Client'
import { ConsumerSubscriptionItemType } from 'src/Models/Server/Enums'
import FileDTO from 'src/Models/Server/File/FileDTO'
import { IEditConsumerSubscriptionItemVTO } from 'src/Models/Server/Response/ConsumerSubscription/IEditConsumerSubscriptionItemVTO'
import {
  LocalizedStringDTO,
  TextBoxType,
} from 'src/Models/Server/Response/LocalizedString/LocalizedStringDTO'

export interface ISubscriptionItemFormValues {
  TypeOption: ISelectOption
  Amount: number
  Name: LocalizedStringDTO
  Description: LocalizedStringDTO
  ImageFile?: FileDTO
}

export interface IState {
  viewModel: ISubscriptionItemFormValues
  amountLabel: string
}

export const initialState: IState = {
  viewModel: undefined,

  amountLabel: '',
}

export const typeOptions = [
  {
    value: ConsumerSubscriptionItemType.Day.toString(),
    label: t.common.day,
  },
  {
    value: ConsumerSubscriptionItemType.Week.toString(),
    label: t.common.week,
  },
  {
    value: ConsumerSubscriptionItemType.Month.toString(),
    label: t.common.month,
  },
  {
    value: ConsumerSubscriptionItemType.Year.toString(),
    label: t.common.year,
  },
  {
    value: ConsumerSubscriptionItemType.Trips.toString(),
    label: t.common.trip,
  },
  {
    value: ConsumerSubscriptionItemType.Unlimited.toString(),
    label: t.common.unlimited,
  },
]

const getAmountLabel = (option: ISelectOption) => {
  return enumToText(
    parseInt(option.value, 10),
    ConsumerSubscriptionItemType,
    t.subscription.insertItem.fields.amount.labelEnum
  )
}

//actions
export const initializeForm = createAction(
  'subscriptionForm/initialize',
  (editItem: IEditConsumerSubscriptionItemVTO) => {
    //prepare

    if (editItem) {
      //edit
      const typeOption = typeOptions.find(
        (x) => x.value === editItem.ConsumerSubscriptionItemTypeId.toString()
      )

      const viewModel: ISubscriptionItemFormValues = {
        TypeOption: typeOption,
        Amount: editItem.Amount,
        Name: editItem.Name,
        Description: editItem.Description,
        ImageFile: editItem.ImageFile,
      }
      return {
        payload: viewModel,
      }
    } else {
      //insert
      const viewModel: ISubscriptionItemFormValues = {
        TypeOption: typeOptions[0],
        Amount: 1,
        Name: new LocalizedStringDTO(
          'SavingCard Description',
          TextBoxType.TextBox
        ),
        Description: new LocalizedStringDTO(
          'SavingCard Description',
          TextBoxType.TextArea
        ),
      }
      return {
        payload: viewModel,
      }
    }
  }
)

export const onTypeSelect = createAction(
  'subscriptionForm/onTypeSelect',
  (selectOption: ISelectOption) => {
    return { payload: selectOption }
  }
)

//reducer
const reducer = createReducer<IState>(initialState, (builder) => {
  builder.addCase(initializeForm, (state, action) => {
    state.viewModel = action.payload
    state.amountLabel = getAmountLabel(action.payload.TypeOption)
  })
  builder.addCase(onTypeSelect, (state, action) => {
    state.viewModel = { ...state.viewModel, TypeOption: action.payload }
    state.amountLabel = getAmountLabel(action.payload)
  })
})

export default reducer
