import React, { Fragment } from 'react'
import Portlet from 'src/Components/Authenticated/Portlet'
import { MyDataTable } from 'src/Components/My'

import { FormatDateTimeNL } from 'src/Helpers/DateHelpers'
import { sortDate } from 'src/Helpers/TableHelpers'
import { t } from 'src/I18n'
import { IRefundVTO } from 'src/Models/Server/Response/Refund/IRefundVTO'

interface IProps {
  refunds: IRefundVTO[]
}

interface IProps {}

const RefundsPortlet: React.FC<IProps> = ({ refunds }) => {
  if (refunds.length === 0) {
    return null
  }

  const renderRefunds = () => {
    const columns = [
      {
        dataField: 'Created',
        formatter: (cell, data: IRefundVTO) => {
          return (
            <Fragment>
              <div>
                <label>{t.common.created}: </label>
                {FormatDateTimeNL(data.Created)}
              </div>
              {data.Modified && (
                <div>
                  <label>{t.common.modified}: </label>
                  {FormatDateTimeNL(data.Modified)}
                </div>
              )}
            </Fragment>
          )
        },
        text: 'Data',
        sort: true,
        sortFunc: (order, dataField, rowA, rowB) => {
          return sortDate(order, rowA, rowB, 'Created')
        },
      },
      {
        dataField: 'Amount',
        text: t.common.amount,
        sort: true,
      },
      {
        dataField: 'RefundStatusType',
        text: t.common.type,
        sort: true,
      },
      {
        dataField: 'infoTab',
        text: t.common.info,
        isDummyField: true,
        formatter: (cell, data: IRefundVTO) => {
          return (
            <Fragment>
              <div>
                <label>{t.common.name}: </label>
                {data.AccountName}
              </div>
              <div>
                <label>{t.common.accountNumber}: </label>
                {data.AccountNumber}
              </div>
              <div>
                <label>{t.common.email}: </label>
                {data.EmailAddress}
              </div>
            </Fragment>
          )
        },
      },
    ]

    return <MyDataTable data={refunds} columns={columns} />
  }

  return <Portlet title="Refunds">{renderRefunds()}</Portlet>
}

export default RefundsPortlet
