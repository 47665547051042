export const GetBaseUrl = () => {
  //development

  //return ''
  if (window.location.port === '3000' || window.location.port === '3001') {
    return 'https://localhost:44382'
  } else {
    return ''
  }
}
