import { observer } from 'mobx-react'
import React from 'react'
import Portlet from 'src/Components/Authenticated/Portlet'
import { MyDataTable } from 'src/Components/My'

import { formatCentsToEuro } from 'src/Helpers/CurrencyHelpers'
import { FormatDateTimeNL } from 'src/Helpers/DateHelpers'
import { sortDate } from 'src/Helpers/TableHelpers'
import { enumToText } from 'src/Helpers/TextHelpers'
import { t } from 'src/I18n'
import { AutomaticChargeCollectionStatusTypesDTO } from 'src/Models/Server/Enums'
import { IAutomaticChargeCollectionVTO } from 'src/Models/Server/Response/AutomaticCharge/IAutomaticChargeCollectionVTO'

interface IProps {
  blockedStornos: IAutomaticChargeCollectionVTO[]
}

interface IProps {}

const BlockedStornosPortlet: React.FC<IProps> = ({ blockedStornos }) => {
  if (blockedStornos.length === 0) {
    return null
  }

  const renderBlockedStornos = () => {
    const dateTimeFormatter = (
      cell,
      data: IAutomaticChargeCollectionVTO,
      key: string
    ) => {
      return FormatDateTimeNL(data[key])
    }

    const columns = [
      {
        dataField: 'AutomaticChargeCollectionStatusId',
        formatter: (cell, data: IAutomaticChargeCollectionVTO) => {
          return (
            <>
              {enumToText(
                data.AutomaticChargeCollectionStatusId,
                AutomaticChargeCollectionStatusTypesDTO,
                t.stornoStatus.status
              )}
            </>
          )
        },
        text: 'Status',
        sort: true,
      },
      {
        dataField: 'Amount',
        text: 'Bedrag',
        formatter: (cell, data: IAutomaticChargeCollectionVTO) => {
          return formatCentsToEuro(data.Amount)
        },
        sort: true,
      },
      {
        dataField: 'CurrencyDescription',
        text: 'Betaalmiddel',
      },

      {
        dataField: 'AccountNumber',
        text: 'Rekening',
        formatter: (cell, data: IAutomaticChargeCollectionVTO) => {
          return (
            <>
              <div>{data.AccountHolder}</div>
              <div>{data.AccountNumber}</div>
            </>
          )
        },
      },
      {
        dataField: 'Description',
        text: 'Omschrijving',
        sort: true,
      },
      {
        dataField: 'Modified',
        text: t.common.modified,
        formatter: (cell, data: IAutomaticChargeCollectionVTO) => {
          return dateTimeFormatter(cell, data, 'Modified')
        },
        csvFormatter: (cell, data: IAutomaticChargeCollectionVTO) => {
          return dateTimeFormatter(cell, data, 'Modified')
        },
        sort: true,
        sortFunc: (order, dataField, rowA, rowB) => {
          return sortDate(order, rowA, rowB, 'Modified')
        },
      },
    ]

    return <MyDataTable data={blockedStornos} columns={columns} />
  }

  return (
    <Portlet title={t.userDetails.blockedStornos}>
      {renderBlockedStornos()}
    </Portlet>
  )
}

export default observer(BlockedStornosPortlet)
