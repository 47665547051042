import { action, makeObservable, runInAction } from 'mobx'
import { INameGuidVTO } from 'src/Models/Server/INameGuidVTO'
import { IExceptionError } from 'src/Models/Server/Response/Exception/ExceptionError'
import MerchantController from 'src/Services/ToprCoreApi/Axios/Controllers/MerchantController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

class Store extends BaseStore<INameGuidVTO[]> {
  constructor() {
    super()
    this.ViewModel = []

    makeObservable(this, {
      Initialize: action,
    })
  }

  public Initialize() {
    this.IsLoading = true
    MerchantController.GetActiveMerchants()
      .then((response) => {
        runInAction(() => {
          this.ViewModel = response
          this.IsLoading = false
        })
      })
      .catch((error: IExceptionError) => {
        appUiStore.ShowError(error)
        runInAction(() => {
          this.IsLoading = false
        })
      })
  }
}

export const merchantsStore = new Store()
