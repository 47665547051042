import { observer } from 'mobx-react'
import React, { Fragment } from 'react'
import { ButtonToolbar, Col, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { McButton } from 'src/Components'
import Portlet from 'src/Components/Authenticated/Portlet'
import { MyDataTable } from 'src/Components/My'
import { FormatDateTimeLocalized } from 'src/Helpers/DateHelpers'
import { sortCurrencyAmount, sortDate } from 'src/Helpers/TableHelpers'
import { t } from 'src/I18n'
import CurrencyVTO from 'src/Models/Server/Response/Currency/CurrencyVTO'
import IWalletVTO from 'src/Models/Server/Response/Wallet/IWalletVTO'
import { persistedStore } from 'src/Stores/PersistedStore'
import { userInfoStore } from '../../InfoTab/Store'

interface IProps {
  userGuid: string
  wallets: IWalletVTO[]
  merchantCurrencies: CurrencyVTO[]
}

const WalletsPortlet: React.FC<IProps> = ({
  userGuid,
  wallets,
  merchantCurrencies,
}) => {
  const navigate = useNavigate()
  const { User } = userInfoStore

  const renderButtons = () => {
    const currencyWithManagerCharge = merchantCurrencies.find(
      (x) => x.ManagerCharge
    )

    const currencyWithManagerWithdraw = merchantCurrencies.find(
      (x) => x.ManagerWithdraw
    )

    const renderManagerChargeButton = () => {
      if (currencyWithManagerCharge === undefined) {
        return null
      }

      return (
        <McButton
          iconLeft="fa-plus"
          bsSize="xs"
          bsStyle="primary"
          title={t.common.charge}
          onClick={() => {
            navigate('/wallet/charge', {
              state: { ids: [User.UserId] },
            })
          }}
        >
          {t.common.charge}
        </McButton>
      )
    }

    const renderManagerWithdrawButton = () => {
      if (currencyWithManagerWithdraw === undefined) {
        return null
      }

      return (
        <McButton
          iconLeft="fa-minus"
          bsSize="xs"
          title={t.common.charge}
          onClick={() => {
            navigate('/wallet/withdraw', {
              state: { ids: [User.UserId] },
            })
          }}
        >
          {t.common.withdraw}
        </McButton>
      )
    }

    if (
      User === null ||
      (currencyWithManagerCharge === undefined &&
        currencyWithManagerWithdraw === undefined)
    ) {
      return null
    }

    return (
      <ButtonToolbar className="pull-right">
        {renderManagerChargeButton()} {renderManagerWithdrawButton()}
      </ButtonToolbar>
    )
  }

  const renderWallets = () => {
    const dateTimeFormatter = (cell, data: IWalletVTO) => {
      // return FormatDateTimeNL(data.Modified)
      return FormatDateTimeLocalized(data.Modified)
    }

    const mc = persistedStore.SelectedManagerUser.MerchantCurrencies

    const columns = [
      {
        dataField: 'IsHidden',
        text: t.common.status,
        formatter: (cell, data: IWalletVTO) => {
          const icon = data.IsBlocked
            ? 'fa-ban red'
            : data.IsHidden
            ? 'fa-eye '
            : 'fa-check-circle green'
          const text = data.IsBlocked
            ? t.common.blocked
            : data.IsHidden
            ? t.common.hidden
            : t.common.active
          return (
            <>
              <i className={`fas ${icon}`} /> {text}
            </>
          )
        },
        sort: true,
      },
      {
        dataField: 'BalanceFormatted',
        text: t.common.amount,
        sort: true,
        sortFunc: (order, dataField, rowA, rowB) => {
          return sortCurrencyAmount(order, dataField, rowA, rowB)
        },
        formatter: (cell, data: IWalletVTO) => {
          if (
            mc.find((x) => x.Id === data.Currency.CurrencyId) &&
            (data.Currency.ManagerCharge || data.Currency.ManagerWithdraw)
          ) {
            return (
              <Row>
                <Col xs={6}>{data.BalanceFormatted}</Col>
                <Col xs={6}>
                  <ButtonToolbar className="pull-right">
                    {data.Currency.ManagerCharge && (
                      <McButton
                        icon="fa-plus"
                        bsSize="xs"
                        bsStyle="primary"
                        title={t.common.charge}
                        onClick={() => {
                          navigate(
                            `/wallet/charge/${data.Currency.CurrencyId}`,
                            {
                              state: { ids: [User.UserId] },
                            }
                          )
                        }}
                      />
                    )}
                    {data.Currency.ManagerWithdraw && (
                      <McButton
                        icon="fa-minus"
                        bsSize="xs"
                        title={t.common.withdraw}
                        onClick={() => {
                          navigate(
                            `/wallet/withdraw/${data.Currency.CurrencyId}`,
                            {
                              state: { ids: [User.UserId] },
                            }
                          )
                        }}
                      />
                    )}
                  </ButtonToolbar>
                </Col>
              </Row>
            )
          }
          return data.BalanceFormatted
        },
      },
      {
        dataField: 'Currency.Name',
        text: t.common.currency,
        sort: true,
      },
      {
        dataField: 'Modified',
        text: t.common.modified,
        formatter: dateTimeFormatter,
        sort: true,
        sortFunc: (order, dataField, rowA, rowB) => {
          return sortDate(order, rowA, rowB, 'Modified')
        },
      },
    ]

    // const defaultSorted = [
    //   {
    //     dataField: 'Modified',
    //     order: 'desc', // desc or asc
    //   },
    //]

    return (
      <Fragment>
        <MyDataTable data={wallets} columns={columns} />
      </Fragment>
    )
  }

  return (
    <Portlet title={t.common.wallets} headerContent={renderButtons()}>
      {renderWallets()}
    </Portlet>
  )
}

export default observer(WalletsPortlet)
