export interface ITokenSTO {
  TokenGUID: string
  Description: string
  ActivationCode: string
  IsBlocked: boolean
  Activated: string | null
  ValidThru: string
}

export enum TokenStatus {
  activated,
  notActivated,
  blocked,
  expired,
}

export const getTokenStatus = (token: ITokenSTO) => {
  let status: TokenStatus = TokenStatus.activated

  const today = new Date()
  const validThru = new Date(token.ValidThru)

  if (today > validThru) {
    status = TokenStatus.expired
  } else if (token.IsBlocked) {
    status = TokenStatus.blocked
  } else if (token.Activated === null) {
    status = TokenStatus.notActivated
  }

  return status
  //return TokenStatus[status].toString()
}
