import { configureStore, combineReducers } from '@reduxjs/toolkit'
import EditSubscriptionitemReducer from 'src/Screens/Authenticated/Subscription/EditItem/SliceDemo'
import { toprCoreApi } from 'src/Services/ToprCoreApi/RTK/ToprCoreApi'
import { ToprErrorMiddleware } from './Middleware/ToprErrorMiddleware'

const reducers = combineReducers({
  EditSubscriptionitemReducer,
  [toprCoreApi.reducerPath]: toprCoreApi.reducer,
})

export const rootStore = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(toprCoreApi.middleware)
      .concat(ToprErrorMiddleware),
  /*middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // serializableCheck: {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
    })      
      .concat(
        //any middleware
      ),
      */
})

export type RootState = ReturnType<typeof rootStore.getState>
