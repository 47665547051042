import { action, makeObservable, observable, runInAction } from 'mobx'
import { NavigateFunction } from 'react-router-dom'
import { LatLng } from 'src/Models/Client/LatLng'
import IGenericError from 'src/Models/Server/IGenericError'
import IInsertLocationRequest from 'src/Models/Server/Request/Location/IInsertLocationRequest'
import IMerchantVTO from 'src/Models/Server/Response/Merchant/IMerchantVTO'
import LocationController from 'src/Services/ToprCoreApi/Axios/Controllers/LocationController'
import MapsService from 'src/Services/ToprCoreApi/Axios/Controllers/MapsService'
import MerchantController from 'src/Services/ToprCoreApi/Axios/Controllers/MerchantController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

class Store extends BaseStore<IInsertLocationRequest> {
  public Merchant: IMerchantVTO = null

  public LatLong?: LatLng = undefined

  constructor() {
    super()

    makeObservable(this, {
      Merchant: observable,

      Initialize: action,

      GeoCodeAddress: action,
      Insert: action,
      LatLong: observable,
    })
  }

  public Initialize(id: string) {
    this.BaseInitialize()
    this.Merchant = null
    this.IsLoading = true

    MerchantController.GetMerchant(id)
      .then((merchant) => {
        runInAction(() => {
          this.Merchant = merchant
          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => {
          this.IsLoading = false
        })
        appUiStore.ShowError(error)
      })
  }

  public GeoCodeAddress(data: IInsertLocationRequest) {
    if (data.City === undefined) {
      return
    }

    MapsService.GeoCodeAddress(
      data.City,
      data.StreetName,
      data.StreetNumber,
      data.ZipCode
    )
      .then((result) => {
        if (result.results.length > 0) {
          const location = result.results[0].geometry
            .location as unknown as LatLng

          runInAction(() => {
            this.LatLong = location
          })
        } else {
          runInAction(() => {
            this.LatLong = undefined
          })
        }
      })
      .catch((error) => {
        console.log(error)
        runInAction(() => {
          this.LatLong = undefined
        })
      })
  }

  public Insert(data: IInsertLocationRequest, navigate: NavigateFunction) {
    this.IsSubmitting = true
    data.Lat = this.LatLong !== undefined ? this.LatLong.lat : undefined
    data.Long = this.LatLong !== undefined ? this.LatLong.lng : undefined
    data.MerchantGuid = this.Merchant.MerchantGuid
    LocationController.Insert(data)
      .then((locationGuid) => {
        runInAction(() => {
          this.IsSubmitting = false
        })
        navigate(`/location/details/${locationGuid}`)
      })
      .catch((error: IGenericError) => {
        runInAction(() => {
          this.IsSubmitting = false
        })
        appUiStore.ShowError(error)
      })
  }
}

export const locationInsertStore = new Store()
