export default class FileDTO {
  public FileGuid?: string
  public FileId?: number
  public Name: string
  public MimeType: string
  public Data: string
}

export const GetImageDataUrl = (file: FileDTO) => {
  return file !== null ? `data:image/png;base64,${file.Data}` : ''
}
