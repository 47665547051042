import { toast } from 'react-toastify'
import { formatCurrency } from 'src/Helpers/CurrencyHelpers'
import { t } from 'src/I18n'
import { ISelectOption } from 'src/Models/Client'
import IGenericError from 'src/Models/Server/IGenericError'
import CurrencyVTO from 'src/Models/Server/Response/Currency/CurrencyVTO'
import CouponController from 'src/Services/ToprCoreApi/Axios/Controllers/CouponController'
import CurrencyController from 'src/Services/ToprCoreApi/Axios/Controllers/CurrencyController'
import WalletController from 'src/Services/ToprCoreApi/Axios/Controllers/WalletController'
import { appUiStore } from 'src/Stores/AppUiStore'

export interface IFormValues {
  amount: string
  selectedCurrencyOption?: ISelectOption
  description: string
}

export interface IState {
  isLoading: boolean
  isSubmitting: boolean
  currencyOptions: ISelectOption[]
  currencies: CurrencyVTO[]
  selectedCurrency?: CurrencyVTO
  viewModel?: IFormValues
  amountCents: number
}

export const initialState: IState = {
  isSubmitting: false,
  isLoading: true,
  currencyOptions: [],
  currencies: [],
  amountCents: 0,
}

export type Actions =
  | {
      type: 'setIsSubmitting'
      isSubmitting: boolean
    }
  | {
      type: 'initialize'

      currencyOptions: ISelectOption[]
      currencies: CurrencyVTO[]
      selectedCurrency?: CurrencyVTO
      viewModel: IFormValues
    }
  | {
      type: 'setSelectedCurrency'
      selectedCurrency: CurrencyVTO
    }
  | {
      type: 'setAmountCents'
      amountCents: number
    }

export const reducer = (state: IState, action: Actions): IState => {
  switch (action.type) {
    case 'initialize':
      return {
        ...state,
        isLoading: false,
        currencies: action.currencies,
        currencyOptions: action.currencyOptions,
        selectedCurrency: action.selectedCurrency,
        viewModel: action.viewModel,
      }
    case 'setIsSubmitting':
      return {
        ...state,
        isSubmitting: action.isSubmitting,
      }
    case 'setSelectedCurrency':
      return {
        ...state,
        selectedCurrency: action.selectedCurrency,
      }
    case 'setAmountCents':
      return { ...state, amountCents: action.amountCents }
    default:
      throw new Error('Invalid Reducer Action')
  }
}

export const reducerActions = (
  state: IState,
  dispatch: (action: Actions) => void
) => {
  return {
    initialize: (currencyId: string) => {
      CurrencyController.GetMyMerchantCurrencies()
        .then((result: CurrencyVTO[]) => {
          const currencies = result.filter((x) => x.ManagerWithdraw)

          if (currencies.length === 0) {
            appUiStore.ShowError({ Code: 'NO_CURRENCIES' })
            return
          }

          const selectedCurrency =
            currencyId === undefined
              ? currencies[0]
              : currencies.find((x) => x.CurrencyId.toString() === currencyId)

          const currencyOptions = currencies.map((x) => ({
            label: x.Name,
            value: x.CurrencyId.toString(),
          }))

          const viewModel: IFormValues = {
            amount: '',
            description: '',
            selectedCurrencyOption:
              currencyId === undefined
                ? currencyOptions[0]
                : currencyOptions.find((x) => x.value === currencyId),
          }

          dispatch({
            type: 'initialize',
            currencies,
            currencyOptions,
            selectedCurrency,
            viewModel,
          })
        })
        .catch((error: IGenericError) => {
          appUiStore.ShowError(error)
        })
    },
    setSelectedCurrency: (currencyId: string) => {
      //
      const selectedCurrency = state.currencies.find(
        (x) => x.CurrencyId.toString() == currencyId
      )
      dispatch({ type: 'setSelectedCurrency', selectedCurrency })
    },
    setAmountCents: (amountCents: number) => {
      dispatch({ type: 'setAmountCents', amountCents })
    },
    save: (data: IFormValues, ids: number[]) => {
      const { selectedCurrency, amountCents } = state

      if (selectedCurrency) {
        //dispatch({ type: 'setIsSubmitting', isSubmitting: true })

        WalletController.WithdrawManager(
          selectedCurrency.CurrencyId,
          ids,
          amountCents,
          data.description
        )
          .then((result) => {
            if (result.Success > 0) {
              toast.success(
                t.formatString(t.walletWithdraw.successAlert.message, {
                  amountFormatted: formatCurrency(
                    amountCents,
                    selectedCurrency.IsDecimal,
                    selectedCurrency.StringFormat
                  ),
                  currencyName: selectedCurrency.Name,
                  count: result.Success,
                })
              )
            }
            if (result.Failed > 0) {
              toast.error(
                t.formatString(t.walletWithdraw.failedAlert.message, {
                  count: result.Failed,
                })
              )
            }
            history.back()
            dispatch({ type: 'setIsSubmitting', isSubmitting: false })
          })
          .catch((error: IGenericError) => {
            appUiStore.ShowError(error)
            dispatch({ type: 'setIsSubmitting', isSubmitting: false })
          })
      }
    },
  }
}
