import { makeObservable, observable, action, runInAction } from 'mobx'
import { createPageAlert } from 'src/Components/McPageAlert'
import { ISelectOption } from 'src/Models/Client'
import { t } from 'src/I18n'
import IGenericError from 'src/Models/Server/IGenericError'
import Modules from 'src/Models/Server/Modules'
import CurrencyVTO from 'src/Models/Server/Response/Currency/CurrencyVTO'
import IAppUserVTO from 'src/Models/Server/Response/User/IAppUserVTO'
import AppUserController from 'src/Services/ToprCoreApi/Axios/Controllers/AppUserController'
import CurrencyController from 'src/Services/ToprCoreApi/Axios/Controllers/CurrencyController'
import BaseStore from 'src/Stores/BaseStore'

export interface IFormValues {
  SelectedCurrencyOption: ISelectOption
  SelectedModuleOption: ISelectOption
  ModuleId: string
  CurrencyCode?: string
}

export default class Store extends BaseStore<IFormValues> {
  public NeedsCurrency: boolean = true // default module payment

  public AllCurrencies: CurrencyVTO[] = []
  public CurrencyOptions: ISelectOption[] = []
  public ModuleOptions: ISelectOption[] = []
  public AppUser: IAppUserVTO = null

  constructor() {
    super()
    this.BaseInitialize()
    makeObservable(this, {
      Initialize: action,
      SetSelectedModule: action,
      NeedsCurrency: observable,
      AllCurrencies: observable,
      CurrencyOptions: observable,
      ModuleOptions: observable,
      AppUser: observable,
      AddPosModule: action,
    })
  }

  public Initialize(merchantId: number, appUser: IAppUserVTO) {
    this.IsLoading = true
    this.AppUser = appUser

    const viewModel: IFormValues = {
      ModuleId: '-1',
      SelectedCurrencyOption: undefined,
      SelectedModuleOption: undefined,
      CurrencyCode: '',
    }

    CurrencyController.GetMerchantCurrencies(merchantId.toString())
      .then((result: CurrencyVTO[]) => {
        runInAction(() => {
          this.AllCurrencies = result
          this.ModuleOptions = this.GetAvailableModuleOptions()

          //this.GetAvailableModuleOptions()

          if (this.ModuleOptions.length > 0) {
            //default module, first module
            viewModel.SelectedModuleOption = this.ModuleOptions[0]
            this.SetSelectedModule(this.ModuleOptions[0])
          }

          if (result.length > 0) {
            viewModel.SelectedCurrencyOption = {
              value: this.AllCurrencies[0].Code,
              label: this.AllCurrencies[0].Name,
            }
          }
          this.BaseInitialize(viewModel)
          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => {
          this.PageAlert = createPageAlert(error)
          this.IsLoading = false
        })
      })
  }

  private GetAvailableModuleOptions() {
    const moduleOptions: ISelectOption[] = []

    for (const module of Modules) {
      if (module.NeedsCurrency) {
        //check wether if this module exists for  AllCurrencies
        const foundModules = this.AppUser.PosModules.filter(
          (x) => x.ModuleId === module.ModuleId
        )
        if (foundModules.length < this.AllCurrencies.length) {
          //not all currencies yet, show module
          moduleOptions.push({
            value: module.ModuleId.toString(),
            label: t.modules[module.SystemName],
          })
        }
      } else {
        //check if module !exist in posmodule
        const foundModule = this.AppUser.PosModules.find(
          (x) => x.ModuleId === module.ModuleId
        )
        if (foundModule === undefined) {
          moduleOptions.push({
            value: module.ModuleId.toString(),
            label: t.modules[module.SystemName],
          })
        }
      }
    }

    return moduleOptions
  }

  public SetSelectedModule(selectedModule: ISelectOption) {
    const module = Modules.find(
      (x) => x.ModuleId.toString() === selectedModule.value
    )

    this.NeedsCurrency = module.NeedsCurrency

    if (module.NeedsCurrency) {
      const foundPosModules = this.AppUser.PosModules.filter(
        (x) => x.ModuleId === module.ModuleId
      )

      //has existing posModules of this type, check currencies
      if (foundPosModules.length > 0) {
        const availableCurrenciesForThisModule: ISelectOption[] = []
        for (const currency of this.AllCurrencies) {
          const foundCurrency = foundPosModules.find(
            (x) => x.CurrencyCode === currency.Code
          )
          if (foundCurrency === undefined) {
            availableCurrenciesForThisModule.push({
              value: currency.Code,
              label: currency.Name,
            })
          }
        }
        this.CurrencyOptions = availableCurrenciesForThisModule
      } else {
        //pos posModules yet, show all currencies
        this.CurrencyOptions = this.AllCurrencies.map((currency) => {
          return { value: currency.Code, label: currency.Name }
        })
      }

      if (this.CurrencyOptions.length > 0) {
        this.ViewModel = {
          ...this.ViewModel,
          SelectedModuleOption: selectedModule,
        }
      }
    } else {
      this.CurrencyOptions = []
    }
  }

  public AddPosModule(data: IFormValues) {
    this.IsSubmitting = true

    return new Promise<void>((resolve, reject) => {
      const module = Modules.find(
        (x) => x.ModuleId.toString() === data.SelectedModuleOption.value
      )

      AppUserController.InsertPosModule({
        ModuleId: data.SelectedModuleOption.value,
        PosId: this.AppUser.PosId,
        UserGuid: this.AppUser.UserGuid,
        CurrencyCode:
          module.NeedsCurrency && data.SelectedCurrencyOption !== undefined
            ? data.SelectedCurrencyOption.value
            : undefined,
      })
        .then(() => {
          runInAction(() => (this.IsSubmitting = false))
          resolve()
        })
        .catch((error: IGenericError) => {
          runInAction(() => {
            this.PageAlert = createPageAlert(error)
            this.IsSubmitting = false
          })
          reject()
        })
    })
  }
}

export const addPosModuleStore = new Store()
