import React, { Fragment } from 'react'

interface IProps {
  active: boolean
  text?: string
}

const ActiveIcon: React.FC<IProps> = ({ active, text }) => {
  const icon = active ? 'fa-check green' : 'fa-ban red'
  return (
    <Fragment>
      <i className={`fa ${icon}`} /> {text}
    </Fragment>
  )
}

export default ActiveIcon
