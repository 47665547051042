import React, { ReactNode } from 'react'

interface IProps {
  children?: ReactNode | undefined
}

export type CardFooterTitleType = React.FC<IProps>

const CardFooterTitle: CardFooterTitleType = ({ children }) => {
  return <div className="footer-title">{children}</div>
}

export default CardFooterTitle
