import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { LoaderIcon } from 'src/Components'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import { MainPage, MyMonthPicker, MySelect, MyChart } from 'src/Components/My'
import { ISelectOption } from 'src/Models/Client'
import { t } from 'src/I18n'
import { IFormValues, paymentsPerDayMerchantsDetailsStore } from './Store'

interface IProps {}

const PaymentsPerDayDetails: React.FC<IProps> = () => {
  const params = useParams<'merchantId' | 'currencyId' | 'month'>()
  useEffect(() => {
    const { merchantId, currencyId, month } = params
    paymentsPerDayMerchantsDetailsStore.Initialize(
      merchantId,
      currencyId,
      month
    )
  }, [null])

  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({})
  const { handleSubmit, reset, setValue } = useFormProps

  const { ViewModel, IsLoading, ChartResponse, Currencies, Poses } =
    paymentsPerDayMerchantsDetailsStore

  useEffect(() => {
    reset(ViewModel)
  }, [ViewModel])

  const renderContent = () => {
    if (IsLoading || ViewModel === undefined) {
      return <LoaderIcon IsLoading />
    }

    return (
      <>
        <BreadCrumbs
          currentPageTitle={`${t.paymentsPerDay.title} (${ViewModel.Merchant})`}
        />
        <Portlet title={t.common.filter}>
          <form className="parsley-form">
            <Row>
              <Col md={6}>
                <MyMonthPicker
                  formObject={{} as IFormValues}
                  useFormProps={useFormProps}
                  name="SelectedMonth"
                  label={t.common.month}
                  onSelectionChange={(option: ISelectOption) => {
                    setValue('SelectedMonth', option)
                    handleSubmit((data) =>
                      paymentsPerDayMerchantsDetailsStore.GetPaymentsPerDayMerchantDetails(
                        data
                      )
                    )()
                  }}
                />
                <MySelect
                  formObject={{} as IFormValues}
                  useFormProps={useFormProps}
                  name="SelectedCurrency"
                  label={t.common.currencies}
                  options={Currencies}
                  onSelectionChange={(option: ISelectOption) => {
                    setValue('SelectedCurrency', option)
                    handleSubmit((data) =>
                      paymentsPerDayMerchantsDetailsStore.GetPaymentsPerDayMerchantDetails(
                        data
                      )
                    )()
                  }}
                />
              </Col>
              <Col md={6}>
                <MySelect
                  formObject={{} as IFormValues}
                  useFormProps={useFormProps}
                  name="SelectedPoses"
                  label={t.common.pos}
                  options={Poses}
                  isMulti
                  onSelectionChange={(options: ISelectOption[]) => {
                    setValue('SelectedPoses', options)
                    handleSubmit((data) =>
                      paymentsPerDayMerchantsDetailsStore.GetPaymentsPerDayMerchantDetails(
                        data
                      )
                    )()
                  }}
                />
              </Col>
            </Row>
          </form>
        </Portlet>
      </>
    )
  }

  return (
    <MainPage>
      {renderContent()}
      <MyChart chartResponse={ChartResponse} />
    </MainPage>
  )
}

export default observer(PaymentsPerDayDetails)
