import { observer } from 'mobx-react'
import React, { Component } from 'react'
import TopHeader from 'src/Components/Landing/TopHeader'
import { MainPage } from 'src/Components/My'
import { t } from 'src/I18n'
import { persistedStore } from 'src/Stores/PersistedStore'
//import AutenticatedLayout from './Authenticated/_Layout'
import LandingLayout from './Landing/_LandingLayout'

class NoMatch extends Component {
  public render() {
    const { IsAuthenticated } = persistedStore

    if (IsAuthenticated) {
      return this.renderAuthenticated404()
    } else {
      return this.renderLanding404()
    }
  }

  private renderAuthenticated404() {
    return (
      <MainPage>
        <br className="sm-50 md-100" />
        <div className="error-container">
          <div className="error-code">404</div>
          <div className="error-details">{t.noMatch.text}</div>
        </div>
      </MainPage>
    )
  }

  private renderLanding404() {
    return (
      <LandingLayout>
        <TopHeader text={t.noMatch.header} />
        <div className="content">
          <div className="container">
            <div>{t.noMatch.text}</div>
          </div>
        </div>
      </LandingLayout>
    )
  }
}

export default observer(NoMatch)
