import React from 'react'
import { Badge } from 'react-bootstrap'
import { GetLoyaltyDateRangeStatus } from 'src/Helpers/TextHelpers'
import { t } from 'src/I18n'
import { LoyaltyDateRangeStatus } from 'src/Models/Server/Enums'
interface IProps {
  start: string
  end: string | null
}

const UserBonusStatusBadge: React.FC<IProps> = ({ start, end }) => {
  const status = GetLoyaltyDateRangeStatus(start, end)
  switch (status) {
    case LoyaltyDateRangeStatus.Active:
      return (
        <Badge className="badge-primary">
          {t.userScheduleBonus.status.Active}
        </Badge>
      )

    case LoyaltyDateRangeStatus.NotYetActive:
      return (
        <Badge className="badge-not-active">
          {t.userScheduleBonus.status.NotYetActive}
        </Badge>
      )
    case LoyaltyDateRangeStatus.Expired:
      return (
        <Badge className="badge-warning">
          {t.userScheduleBonus.status.Expired}
        </Badge>
      )
    case undefined:
    default:
      return <>invalid status</>
  }
}

export default UserBonusStatusBadge
