import FileDTO from 'src/Models/Server/File/FileDTO'
import { IEditConsumerSubscriptionItemVTO } from 'src/Models/Server/Response/ConsumerSubscription/IEditConsumerSubscriptionItemVTO'
import { IEventEditVTO } from 'src/Models/Server/Response/Event/IEventEditVTO'
import {
  LocalizedStringDTO,
  SetMissingLanguageValues,
  TextBoxType,
} from 'src/Models/Server/Response/LocalizedString/LocalizedStringDTO'
import { persistedStore } from 'src/Stores/PersistedStore'

export interface IEventFormValues {
  Name: LocalizedStringDTO
  Description: LocalizedStringDTO
  ImageFile?: FileDTO
  Start?: any
  End?: any
}

export interface IState {
  viewModel: IEventFormValues
}

export const initialState: IState = {
  viewModel: undefined,
}

export type Actions = {
  type: 'init'
  viewModel: IEventFormValues
}

export const reducer = (state: IState, action: Actions): IState => {
  switch (action.type) {
    case 'init': {
      return {
        ...state,
        viewModel: action.viewModel,
      }
    }

    default:
      throw new Error('Invalid Reducer Action')
  }
}

export const reducerActions = (
  state: IState,
  dispatch: (action: Actions) => void
) => {
  return {
    initialize: (eventEdit: IEventEditVTO) => {
      if (eventEdit) {
        //edit
        const viewModel: IEventFormValues = {
          Name: SetMissingLanguageValues(eventEdit.Name),
          Description: SetMissingLanguageValues(eventEdit.Description),
          ImageFile: eventEdit.ImageFile,
          Start: eventEdit.Start,
          End: eventEdit.End,
        }

        dispatch({ type: 'init', viewModel })
      } else {
        //insert

        const viewModel: IEventFormValues = {
          Name: new LocalizedStringDTO(
            'SavingCard Description',
            TextBoxType.TextBox
          ),
          Description: new LocalizedStringDTO(
            'SavingCard Description',
            TextBoxType.TextArea
          ),
        }

        dispatch({ type: 'init', viewModel })
      }
    },
  }
}
