import { AxiosError, AxiosResponse } from 'axios'
import { handleException } from 'src/Helpers/ResponseHelpers'
import IAddAppUserRequest from 'src/Models/Server/Request/Merchant/IAddAppUserRequest'
import IEditAppUserRequest from 'src/Models/Server/Request/Merchant/IEditAppUserRequest'
import { IExceptionError } from 'src/Models/Server/Response/Exception/ExceptionError'
import { IPosModuleVTO } from 'src/Models/Server/Response/PosModule/IPosModuleVTO'
import IAppUserVTO from 'src/Models/Server/Response/User/IAppUserVTO'
import { axiosAuth } from '../AuthAxios'

export default {
  GetMerchantAppUsers(merchantId: number) {
    return new Promise<IAppUserVTO[]>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/appusers/merchant/${merchantId}`)
          .then((response: AxiosResponse<IAppUserVTO[]>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  GetAppUser(userGuid: string) {
    return new Promise<IAppUserVTO>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(encodeURI(`/api/v1/appuser/${userGuid}`))
          .then((response: AxiosResponse<IAppUserVTO>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  Insert(request: IAddAppUserRequest) {
    return new Promise<IAppUserVTO>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/appuser/insert`, request)
          .then((response: AxiosResponse<IAppUserVTO>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  Edit(request: IEditAppUserRequest) {
    return new Promise<IAppUserVTO>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/appuser/edit`, request)
          .then((response: AxiosResponse<IAppUserVTO>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  Delete(guid: string) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .delete(`/api/v1/appuser/delete/${guid}`)
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  InsertPosModule(request: {
    UserGuid: string
    ModuleId: string
    PosId: number
    CurrencyCode?: string
  }) {
    return new Promise<IPosModuleVTO>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/posmodule/insert`, request)
          .then((response: AxiosResponse<IPosModuleVTO>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  DeletePosModule(guid: string) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .delete(`/api/v1/posmodule/delete/${guid}`)
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
}
