import { action, makeObservable, observable, runInAction, toJS } from 'mobx'
import IGenericError from 'src/Models/Server/IGenericError'
import {
  CreateDataTable,
  IDataTable,
} from 'src/Models/Server/Report/IDataTable'
import ReportController from 'src/Services/ToprCoreApi/Axios/Controllers/ReportController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export interface IFormValues {
  TillDate: any
}

class Store extends BaseStore<IFormValues> {
  public DataTable?: IDataTable = undefined

  public Page = 1
  public TotalRows = 1
  public PageSize = 50

  constructor() {
    super()

    makeObservable(this, {
      DataTable: observable,
      Page: observable,
      PageSize: observable,
      TotalRows: observable,
      Initialize: action,
      GetUserBalances: action,
      SetPagination: action,
    })
  }

  public Initialize() {
    this.ViewModel = {
      TillDate: new Date(),
    }
    this.GetUserBalances(this.ViewModel)
  }

  public SetPagination(data: IFormValues, page: number, pageSize) {
    //console.log('SetPagination', page, pageSize)

    this.Page = page
    this.PageSize = pageSize

    this.GetUserBalances(data)
  }

  public GetUserBalances(data: IFormValues) {
    this.IsLoading = true
    ReportController.GetUserBalances(data.TillDate, this.Page, this.PageSize)
      .then((response) => {
        runInAction(() => {
          this.DataTable = CreateDataTable(response.DataTable, 'UserId', false)
          this.IsLoading = false
          this.TotalRows = response.TotalRows
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsLoading = false))
        appUiStore.ShowError(error)
      })
  }
}

export const userBalancesStore = new Store()
