import { action, makeObservable, observable, runInAction } from 'mobx'
import { toast } from 'react-toastify'
import { createPageAlert } from 'src/Components/McPageAlert'
import { t } from 'src/I18n'
import IGenericError from 'src/Models/Server/IGenericError'
import IHelpDeskUserVTO from 'src/Models/Server/Response/User/IHelpDeskUserVTO'
import UserController from 'src/Services/ToprCoreApi/Axios/Controllers/UserController'
import BaseStore from 'src/Stores/BaseStore'

export interface IFormValues {
  Email: string
}

export default class Store extends BaseStore<IFormValues> {
  public Source: IHelpDeskUserVTO = null

  constructor() {
    super()

    makeObservable(this, {
      Source: observable,
      Initialize: action,
      EditEmail: action,
    })
  }

  public Initialize(user: IHelpDeskUserVTO) {
    this.BaseInitialize({
      Email: user.UniqueEmailAddress,
    })
    this.Source = user
  }

  public EditEmail(email: string) {
    this.IsSubmitting = true

    return new Promise<IHelpDeskUserVTO>((resolve, reject) => {
      UserController.EditEmail(this.Source.UserGuid, email)
        .then(() => {
          runInAction(() => {
            this.IsSubmitting = false
            this.Source.UniqueEmailAddress = email
          })
          toast.success(t.editEmail.success)
          resolve(this.Source)
        })
        .catch((error: IGenericError) => {
          runInAction(() => {
            this.PageAlert = createPageAlert(error)
            this.IsSubmitting = false
          })
          reject()
        })
    })
  }
}

export const editEmailStore = new Store()
