import { AxiosError, AxiosResponse } from 'axios'
import { handleException } from 'src/Helpers/ResponseHelpers'
import ICouponDetailsStatsDTO from 'src/Models/Server/Response/Coupon/ICouponDetailStatsDTO'
import ICouponDTO from 'src/Models/Server/Response/Coupon/ICouponDTO'
import { ICouponTotalsForUserVTO } from 'src/Models/Server/Response/Coupon/ICouponTotalsForUserVTO'
import { IUserCouponVTO } from 'src/Models/Server/Response/Coupon/IUserCouponVTO'
import { IExceptionError } from 'src/Models/Server/Response/Exception/ExceptionError'
import { axiosAuth } from '../AuthAxios'

export default {
  GetCouponStatsMerchant() {
    return new Promise<ICouponDetailsStatsDTO[]>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/coupons/merchant/stats`)
          .then((response: AxiosResponse<ICouponDetailsStatsDTO[]>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  GetCouponsMerchant() {
    return new Promise<ICouponDTO[]>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/coupons/merchant`)
          .then((response: AxiosResponse<ICouponDTO[]>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  GetAcquirableCoupons() {
    return new Promise<ICouponDTO[]>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/coupons/merchant/acquirable`)
          .then((response: AxiosResponse<ICouponDTO[]>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  GetUserCoupons(userGuid: string) {
    return new Promise<IUserCouponVTO[]>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/coupons/user/${userGuid}`)
          .then((response: AxiosResponse<IUserCouponVTO[]>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  GetCouponTotalsForUser(userGuid: string) {
    return new Promise<ICouponTotalsForUserVTO[]>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/coupon/totals/${userGuid}`)
          .then((response: AxiosResponse<ICouponTotalsForUserVTO[]>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  DeleteUserCoupon(userCouponId: number) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .delete(`/api/v1/coupon/user/delete/${userCouponId}`)
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  AddUserCoupon(Amount: number, CouponId: string, UserIds: number[]) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/coupon/user/add`, { Amount, UserIds, CouponId })
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
}
