import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { ButtonToolbar } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { McPageAlert } from 'src/Components'
import McButton from 'src/Components/McButton'
import McModal from 'src/Components/Modals/McModal'
import { MySlim } from 'src/Components/My'
import { t } from 'src/I18n'
import { GetImageFileUrl } from 'src/Images'
import FileDTO from 'src/Models/Server/File/FileDTO'
import IEditImageFileRequest from 'src/Models/Server/Request/File/IEditImageFileRequest'
import { editImageModalStore } from './Store'

interface IProps {
  onSubmitSuccess: (file: FileDTO) => void
  fileGuid?: string
  label: string
}

const EditImage: React.FC<IProps> = (props) => {
  const useFormProps: UseFormReturn<IEditImageFileRequest> =
    useForm<IEditImageFileRequest>({})
  const { handleSubmit } = useFormProps

  const { label, fileGuid, onSubmitSuccess } = props
  useEffect(() => {
    editImageModalStore.Initialize(fileGuid)
  }, [null])

  const onSubmit = (data: IEditImageFileRequest) => {
    close()
    onSubmitSuccess(data.ImageFile)
  }

  const close = () => {
    if (!editImageModalStore.IsSubmitting) {
      editImageModalStore.ToggleShowEditModal(false)
    }
  }

  const renderImage = () => {
    if (fileGuid === null) {
      return null
    }

    return (
      <img
        src={GetImageFileUrl(fileGuid)}
        className="inline cursor"
        onClick={() => {
          editImageModalStore.ToggleShowEditModal(true)
        }}
      />
    )
  }

  const renderEditModal = () => {
    const { IsSubmitting, ShowEditModal, PageAlert } = editImageModalStore

    if (ShowEditModal) {
      return (
        <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
          <McModal
            title={t.common.edit}
            footer={
              <div>
                <McPageAlert pageAlert={PageAlert} />
                <ButtonToolbar className="pull-right">
                  <McButton onClick={() => close()}>{t.common.cancel}</McButton>
                  <McButton
                    bsStyle="primary"
                    type="submit"
                    isLoading={IsSubmitting}
                  >
                    {t.common.save}
                  </McButton>
                </ButtonToolbar>
              </div>
            }
            closeClicked={() => close()}
          >
            <MySlim
              name="ImageFile"
              formObject={{} as IEditImageFileRequest}
              useFormProps={useFormProps}
              rules={{
                required: t.savingcardInsert.fields.ImageFile.errors.required,
              }}
              label={t.savingcardInsert.fields.ImageFile.label}
              // onChange={(file: FileDTO) => {
              //   editImageModalStore.HandleImageChange('ImageFile', file)
              // }}
              sizeSlim={{ width: 600, height: 600 }}
            />
          </McModal>
        </form>
      )
    }

    return null
  }

  return (
    <>
      <label>
        {label}

        <McButton
          iconLeft="fa-edit"
          bsStyle="primary"
          bsSize="xsmall"
          pullRight
          onClick={() => {
            editImageModalStore.ToggleShowEditModal(true)
          }}
        >
          {t.common.edit}
        </McButton>
      </label>
      {renderImage()}
      {renderEditModal()}
    </>
  )
}

export default observer(EditImage)
