import { action, makeObservable, observable, runInAction } from 'mobx'
import {
  FormatDateLocalized,
  GetCurrentMonthOption,
  GetCurrentYear,
  GetFirstDayOfThisMonth,
  GetToday,
} from 'src/Helpers/DateHelpers'
import { t } from 'src/I18n'
import { IDateRange, IMonthYear, ISelectOption } from 'src/Models/Client'
import IGenericError from 'src/Models/Server/IGenericError'
import { SelectOptionsFromNameIdVTO } from 'src/Models/Server/INameGuidVTO'
import ReportController from 'src/Services/ToprCoreApi/Axios/Controllers/ReportController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export interface IFormValues {
  SelectedCompany: ISelectOption
  DateRange: IDateRange
  CustomTitle: string
}

export default class Store extends BaseStore<IFormValues> {
  public CompanyOptions: ISelectOption[] = []

  constructor() {
    super()
    makeObservable(this, {
      CompanyOptions: observable,
      Initialize: action,
      GetFilter: action,
      GetSiedlerCompanyCoupons: action,
    })
  }

  public Initialize() {
    // this.ViewModel = {
    //   SelectedMonthYear: {
    //     Month: GetCurrentMonthOption().value,
    //     Year: GetCurrentYear().toString(),
    //   },
    // }

    this.ViewModel = undefined
    this.CompanyOptions = []

    this.GetFilter()
  }

  public GetFilter() {
    this.IsLoading = true

    ReportController.SiedlerGetCompanies()
      .then((companies) => {
        runInAction(() => {
          const companyOptions = SelectOptionsFromNameIdVTO(companies)
          this.CompanyOptions = [
            { label: t.common.all, value: '-1' },
            ...companyOptions,
          ]

          const dateRange = {
            StartDate: GetFirstDayOfThisMonth(),
            EndDate: GetToday(),
          }

          this.ViewModel = {
            DateRange: dateRange,
            CustomTitle: `${FormatDateLocalized(
              dateRange.StartDate
            )} - ${FormatDateLocalized(dateRange.EndDate)}`,
            SelectedCompany:
              this.CompanyOptions.length > 0
                ? this.CompanyOptions[0]
                : undefined,
          }

          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsLoading = false))
        appUiStore.ShowError(error)
      })
  }

  public GetSiedlerCompanyCoupons(data: IFormValues) {
    //console.log(data)
    this.IsSubmitting = true
    ReportController.GetSiedlerCompanyCoupons(
      data.CustomTitle,
      data.DateRange,
      data.SelectedCompany
    )
      .then(() => {
        runInAction(() => (this.IsSubmitting = false))
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsSubmitting = false))
        appUiStore.ShowError(error)
      })
  }
}

export const siedlerCompanyCouponsStore = new Store()
