import React, { useEffect, useReducer } from 'react'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import { MainPage } from 'src/Components/My'
import { t } from 'src/I18n'
import EventForm from '../Components/EventForm'
import { initialState, reducer, reducerActions } from './Reducer'

const InsertItem: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const actions = reducerActions(state, dispatch)

  useEffect(() => {
    //actions.getEditConsumerSubscriptionItem(params.consumerSubscriptionItemId)
  }, [null])

  const { isSubmitting } = state

  return (
    <>
      <MainPage>
        <BreadCrumbs
          currentPageTitle={t.event.insert.title}
          previousPages={[
            {
              to: `/events`,
              title: t.event.index.title,
            },
          ]}
        />
        <EventForm
          onSave={(data) => {
            actions.save(data)
          }}
          title={t.event.insert.title}
          isSubmitting={isSubmitting}
        />
      </MainPage>
    </>
  )
}

export default InsertItem
