import React, { PropsWithChildren, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import {
  Path,
  RegisterOptions,
  useController,
  UseFormReturn,
} from 'react-hook-form'
import {
  GetAllMonthsOptions,
  GetLast5YearOptions,
} from 'src/Helpers/DateHelpers'
import { t } from 'src/I18n'
import { IMonthYear, ISelectOption } from 'src/Models/Client'
import MySelect from './MySelect'

interface IProps<T> {
  name: Path<T>
  useFormProps: UseFormReturn<any>
  rules?: RegisterOptions
  //select

  onSelectionChange?: (monthYear: IMonthYear) => void
}

const MyMonthYearPicker = <ObjectType,>({
  name,
  useFormProps,
  rules,

  onSelectionChange,
}: PropsWithChildren<IProps<ObjectType>>) => {
  const monthOptions = GetAllMonthsOptions()
  const yearOptions = GetLast5YearOptions()

  const { control, setValue } = useFormProps

  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
    rules,
  })

  const currentValue = value as IMonthYear
  useEffect(() => {
    if (currentValue !== undefined) {
      //set month
      const selectedMonthOption = monthOptions.find(
        (x) => x.value === currentValue.Month
      )
      setValue('SelectedMonth', selectedMonthOption)

      //set year
      const selectedYearOption = yearOptions.find(
        (x) => x.value === currentValue.Year
      )
      setValue('SelectedYear', selectedYearOption)
    }
  }, [currentValue])

  return (
    <>
      <Row>
        <Col md={6}>
          <MySelect
            formObject={{} as any}
            useFormProps={useFormProps}
            label={t.common.month}
            name={'SelectedMonth'}
            rules={rules}
            options={monthOptions}
            onSelectionChange={(option: ISelectOption) => {
              const newMonthYear: IMonthYear = {
                Month: option.value,
                Year: currentValue.Year,
              }
              onChange(newMonthYear)
              if (onSelectionChange) {
                onSelectionChange(newMonthYear)
              }
            }}
          />
        </Col>
        <Col md={6}>
          <MySelect
            formObject={{} as any}
            useFormProps={useFormProps}
            label={t.common.year}
            rules={rules}
            name={'SelectedYear'}
            options={yearOptions}
            onSelectionChange={(option: ISelectOption) => {
              const newMonthYear: IMonthYear = {
                Month: currentValue.Month,
                Year: option.value,
              }
              onChange(newMonthYear)
              if (onSelectionChange) {
                onSelectionChange(newMonthYear)
              }
            }}
          />
        </Col>
      </Row>
    </>
  )
}

export default MyMonthYearPicker
