import React from 'react'
import { ButtonToolbar, Col, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { LoaderIcon, McButton } from 'src/Components'
import Portlet from 'src/Components/Authenticated/Portlet'
import { GetLocalText } from 'src/Helpers/TextHelpers'
import { t } from 'src/I18n'
import {
  useDeleteUserSubscriptionMutation,
  useGetConsumerSubscriptionsQuery,
  useGetUserSubscriptionsQuery,
} from 'src/Services/ToprCoreApi/RTK/Slices/SubscriptionSlice'
import { appUiStore } from 'src/Stores/AppUiStore'
import UserSubscriptionItemCard from './Components/UserSubscriptionItemCard'

const SubscriptionTab: React.FC = () => {
  const params = useParams<'id'>()
  const navigate = useNavigate()
  const userGuid = params.id
  const { data: userSubscriptions, refetch: refetchUserSubscriptions } =
    useGetUserSubscriptionsQuery(userGuid)

  const { data: subscriptions, refetch } = useGetConsumerSubscriptionsQuery()

  const [deleteUserSubscription] = useDeleteUserSubscriptionMutation()

  if (subscriptions === undefined || userSubscriptions === undefined) {
    return <LoaderIcon IsLoading={true} />
  }

  const renderButtons = (consumerSubscriptionId: number) => {
    return (
      <ButtonToolbar className="pull-right">
        <McButton
          iconLeft="fa-plus"
          bsStyle="primary"
          bsSize="xs"
          title={t.common.add}
          onClick={() =>
            navigate(
              `/subscription/user/${userGuid}/insert/${consumerSubscriptionId}`
            )
          }
        >
          {t.common.add}
        </McButton>
      </ButtonToolbar>
    )
  }

  const renderUserSubscriptionItemCards = (consumerSubscriptionId: number) => {
    if (userSubscriptions === undefined) {
      return null
    }

    const items = userSubscriptions.filter(
      (x) =>
        x.SubscriptionItem.ConsumerSubscriptionId === consumerSubscriptionId
    )

    if (items.length === 0) {
      return <Col md={6}>{t.common.noData}</Col>
    }

    return items.map((usi, key) => (
      <Col md={4} key={key}>
        <UserSubscriptionItemCard
          userSubscriptionItem={usi}
          userGuid={userGuid}
          onDelete={(userSubscriptionId: number, name: string) => {
            //

            appUiStore.ShowConfirm({
              message: t.formatString(t.subscription.index.confirmDelete, {
                name,
              }),
              confirmActionText: t.common.delete,
              confirmAction: () => {
                deleteUserSubscription(userSubscriptionId)
                  .unwrap()
                  .then(() => {
                    refetch()
                    refetchUserSubscriptions()
                  })
                  .catch()
                //actions.delete(userSubscriptionId, userGuid)
                //reload subscriptions
              },
            })
          }}
        />
      </Col>
    ))
  }

  const renderSubscriptions = () => {
    return subscriptions.map((subscription, key) => (
      <Portlet
        key={key}
        title={GetLocalText(subscription.Name)}
        headerContent={renderButtons(subscription.ConsumerSubscriptionId)}
      >
        <Row>
          {renderUserSubscriptionItemCards(subscription.ConsumerSubscriptionId)}
        </Row>
      </Portlet>
    ))
  }

  return <>{renderSubscriptions()}</>
}

export default SubscriptionTab
