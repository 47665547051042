export enum CouponTypesDTO {
  Euro = 1,
  Percentage = 2,
  Bulk = 3,
}

export enum TransactionSearchType {
  Reference = 0,
  Guid = 1,
}

export enum VoucherTypes {
  AmountDiscount = 1,
  PercentageDiscount = 2,
  FreeProduct = 22,
}

export enum LoyaltyStatusDTO {
  Active = 0,
  NotYetActive = 1,
  Expired = 2,
  Deactivated = 3,
}

export enum RolesDTO {
  ToprWebManager = 300,
}

export enum AutomaticChargeCollectionStatusTypesDTO {
  //ToCollect = 0,
  Collected = 1,
  Canceled = 2, //CanceledEmailSent = 2,
  CanceledMailMerged = 3, //CanceledFirstReminder = 3,
  ReadyForBlock = 4, //CanceledSecondReminder = 4,
  Blocked = 5, //ContactConsumer = 5,
  //AppointmentConsumer = 6,
  //ErrorOnCreate = 7
}

export enum LoyaltyProgramTypes {
  CompleteRuleSetOnce = 1,
  CompleteRuleSetMax = 2,
  IndividualRuleOnce = 3,
  IndividualRuleMax = 4,
  BalanceRuleSet = 5,
  RedeemCoupon = 6,
}

export enum LoyaltyRewardTypes {
  CouponReward = 1,
  CurrencyStaticReward = 2,
  CurrencyOrderPercentageReward = 3,
}

export enum LoyaltyRuleTypes {
  Article = 1,
  ArticleGroup = 2,
  Balance = 3,
  TransactionAmountPositive = 4, //opwaardering
  TransactionAmountNegative = 5, //betaling
  RedeemCoupon = 6,
}

export enum LoyaltyTypes {
  Currency = 1,
  Coupon = 2,
}

export enum UserScheduleType {
  Daily = 1,
  //Weekly = 2,
  Monthly = 3,
}

export enum LoyaltyDateRangeStatus {
  Active = 1,
  NotYetActive = 2,
  Expired = 3,
}

export enum ConsumerSubscriptionItemType {
  Day = 1,
  Week = 2,
  Month = 3,
  Year = 4,
  Trips = 5,
  Unlimited = 6,
}

export enum TenantIds {
  Topr = 9,
  Siedler = 10,
}

export enum PosOrderDeliveryTypesDTO {
  Pickup = 0,
  Delivery = 1,
  PickupAndDelivery = 2,
}

export enum UserCouponStatus {
  Active = 1,
  NotYetActive = 2,
  Expired = 3,
  Redeemed = 4,
}
