import { toast } from 'react-toastify'
import { t } from 'src/I18n'
import { ISelectOption } from 'src/Models/Client'
import IGenericError from 'src/Models/Server/IGenericError'
import CouponController from 'src/Services/ToprCoreApi/Axios/Controllers/CouponController'
import MerchantController from 'src/Services/ToprCoreApi/Axios/Controllers/MerchantController'
import { appUiStore } from 'src/Stores/AppUiStore'

export interface IFormValues {
  activationCode: string
}

export interface IState {
  isSubmitting: boolean
  viewModel: IFormValues
}

export const initialState: IState = {
  isSubmitting: false,
  viewModel: { activationCode: '' },
}

export type Actions =
  | {
      type: 'setIsSubmitting'
      isSubmitting: boolean
    }
  | { type: 'reset' }

export const reducer = (state: IState, action: Actions): IState => {
  switch (action.type) {
    case 'setIsSubmitting':
      return {
        ...state,
        isSubmitting: action.isSubmitting,
      }
    case 'reset':
      return initialState
    default:
      throw new Error('Invalid Reducer Action')
  }
}

export const reducerActions = (
  state: IState,
  dispatch: (action: Actions) => void
) => {
  return {
    save: (data: IFormValues) => {
      dispatch({ type: 'setIsSubmitting', isSubmitting: true })
      MerchantController.AddConsumerUser(data.activationCode)
        .then(() => {
          toast.success(
            t.formatString(t.merchant.addConsumerUser.successMessage, {
              activationCode: data.activationCode,
            })
          )
          dispatch({ type: 'setIsSubmitting', isSubmitting: false })
        })
        .catch((error: IGenericError) => {
          dispatch({ type: 'reset' })
          appUiStore.ShowError(error)
        })
    },
  }
}
