import { action, makeObservable, runInAction } from 'mobx'
import IGenericError from 'src/Models/Server/IGenericError'
import IGetSavingCardSummariesResponse from 'src/Models/Server/Response/IGetSavingCardSummariesResponse'
import SavingCardController from 'src/Services/ToprCoreApi/Axios/Controllers/SavingCardController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

class Store extends BaseStore<IGetSavingCardSummariesResponse> {
  constructor() {
    super()

    makeObservable(this, {
      Initialize: action,
      DeleteSavingCard: action,
      SetIsActive: action,
    })
  }

  public Initialize() {
    this.IsLoading = true
    SavingCardController.GetSavingCardSummaries()
      .then((response) => {
        runInAction(() => {
          this.ViewModel = response
          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsLoading = false))
        appUiStore.ShowError(error)
      })
  }

  public SetIsActive(savingCardGuid: string, isActive: boolean) {
    SavingCardController.SetActive(savingCardGuid, isActive)
      .then(() => {
        this.Initialize()
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsLoading = false))
        appUiStore.ShowError(error)
      })
  }

  public DeleteSavingCard(savingCardGuid: string) {
    this.IsConfirming = true
    SavingCardController.Delete(savingCardGuid)
      .then(() => {
        this.Initialize()
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsConfirming = false))
        appUiStore.ShowError(error)
      })
  }
}

export const savingCardsStore = new Store()
