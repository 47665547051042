import React, { useEffect, useReducer } from 'react'
import { ButtonToolbar } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import McButton from 'src/Components/McButton'
import McModal from 'src/Components/Modals/McModal'
import { MySelect } from 'src/Components/My'
import { t } from 'src/I18n'
import { ITagVTO } from 'src/Models/Server/Response/Tag/ITagVTO'
import { userInfoStore } from '../../Store'
import {
  IAddUserTagForm,
  initialState,
  reducer,
  reducerActions,
} from './Reducer'

interface IProps {
  onClose: () => void
  userTags: ITagVTO[]
}

const AddUserTagModal: React.FC<IProps> = ({ onClose, userTags }) => {
  const params = useParams<'id'>() //userguid
  useEffect(() => {
    actions.initialize(userTags)
  }, [userTags])

  const [state, dispatch] = useReducer(reducer, initialState)
  const actions = reducerActions(state, dispatch)
  const { isSubmitting, viewModel, tagOptions } = state

  useEffect(() => {
    reset(viewModel)
  }, [viewModel])

  const useFormProps: UseFormReturn<IAddUserTagForm> = useForm<IAddUserTagForm>(
    {}
  )
  const { handleSubmit, reset } = useFormProps

  const onSubmit = (data: IAddUserTagForm) => {
    actions.save(params.id, data, () => {
      onClose()
      userInfoStore.GetUserTags(params.id)
    })
  }

  const renderContent = () => {
    return (
      <>
        <MySelect
          formObject={viewModel}
          label={t.tags.insertUserTag.fields.tag.title}
          name="tagOption"
          options={tagOptions}
          useFormProps={useFormProps}
          rules={{ required: t.tags.insertUserTag.fields.tag.required }}
        />
      </>
    )
  }

  return (
    <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
      <McModal
        closeClicked={onClose}
        title={t.tags.insert.title}
        footer={
          <ButtonToolbar className="pull-right">
            <McButton onClick={onClose}>{t.common.cancel}</McButton>
            <McButton bsStyle="primary" type="submit" isLoading={isSubmitting}>
              {t.tags.insert.title}
            </McButton>
          </ButtonToolbar>
        }
      >
        {renderContent()}
      </McModal>
    </form>
  )
}

export default AddUserTagModal
