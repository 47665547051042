import { action, makeObservable, runInAction } from 'mobx'
import { createPageAlert } from 'src/Components/McPageAlert'
import IGenericError from 'src/Models/Server/IGenericError'
import IAddManagerUserRequest from 'src/Models/Server/Request/Merchant/IAddManagerUserRequest'
import IManagerUserGridVTO from 'src/Models/Server/Response/User/IManagerUserGridVTO'
import ManagerUserController from 'src/Services/ToprCoreApi/Axios/Controllers/ManagerUserController'
import BaseStore from 'src/Stores/BaseStore'

export default class Store extends BaseStore<IAddManagerUserRequest> {
  constructor() {
    super()
    makeObservable(this, {
      Initialize: action,
      AddManagerUser: action,
    })
  }

  public Initialize(merchantGuid: string) {
    this.BaseInitialize({
      MerchantGuid: merchantGuid,
      CreatePassword: this.generatePincode(),
      Name: '',
      Email: '',
    })
  }

  public AddManagerUser(data: IAddManagerUserRequest) {
    this.IsSubmitting = true

    return new Promise<IManagerUserGridVTO>((resolve, reject) => {
      ManagerUserController.Insert(data)
        .then((managerUser) => {
          runInAction(() => (this.IsSubmitting = false))
          resolve(managerUser)
        })
        .catch((error: IGenericError) => {
          runInAction(() => {
            this.PageAlert = createPageAlert(error, ['USER_EMAIL_NOT_UNIQUE'])
            this.IsSubmitting = false
          })
          reject()
        })
    })
  }

  private generatePincode() {
    return Math.random().toString(36).slice(-6)
  }
}

export const addManagerUserStore = new Store()
