import { observer } from 'mobx-react'
import React, { PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'
import { LoadContent } from 'src/Components'
import MerchantMenu from 'src/Components/Authenticated/MerchantMenu/MerchantMenu'
import FlagsNavItems from 'src/Components/FlagsNavItems'
import { mainPageStore } from 'src/Components/My/Layout/Store'
import { t } from 'src/I18n'
import Images from 'src/Images'
import { dashboardStore } from 'src/Screens/Authenticated/Dashboard/Store'
import { persistedStore } from 'src/Stores/PersistedStore'
import SelectManagerUserModal from './Components/SelectManagerUserModal'

const MainPage: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const {
    IsAuthenticated,
    SelectedManagerUser,
    AuthenticateUserResponse,
    IsMainUser,
  } = persistedStore

  const { VersionText } = dashboardStore

  const ManagerUserName = SelectedManagerUser ? SelectedManagerUser.Name : ''

  const renderMerchantMenu = () => {
    const { IsAuthenticated, SelectedManagerUser } = persistedStore

    if (!IsAuthenticated) {
      return null
    }
    //const { IsLoading } = this.DashboardStore
    const IsLoading = false

    if (
      //!IsLoading &&
      SelectedManagerUser &&
      SelectedManagerUser.HasValidSubscription
    ) {
      return (
        <LoadContent IsLoading={IsLoading}>
          <MerchantMenu />
        </LoadContent>
      )
    }

    return null
  }

  return (
    <>
      <div id="wrapper">
        <header className="navbar" role="banner">
          <div className="container">
            <div className="navbar-header">
              <button
                className="navbar-toggle"
                type="button"
                data-toggle="collapse"
                data-target=".navbar-collapse"
              >
                <span className="sr-only">Toggle navigation</span>
                <i className="fa fa-cog" />
              </button>
              <Link to="/" className="navbar-brand navbar-brand-img">
                <img src={Images.Logo} alt="MVP Ready" />
              </Link>
            </div>
            {/* /.navbar-header */}
            <nav className="collapse navbar-collapse" role="navigation">
              <ul className="nav navbar-nav navbar-right">
                <FlagsNavItems />
                {IsAuthenticated && SelectedManagerUser !== null && (
                  <>
                    <li className="dropdown navbar-profile">
                      <a className="dropdown-toggle" data-toggle="dropdown">
                        {ManagerUserName}
                        &nbsp;
                        <i className="fa fa-caret-down" />
                      </a>
                      <ul className="dropdown-menu" role="menu">
                        <li>
                          {SelectedManagerUser.HasSubMerchants && (
                            <a
                              onClick={() =>
                                mainPageStore.ToggleManagerUserModal(true)
                              }
                            >
                              <i className="fa fa-user-secret" />
                              &nbsp;&nbsp;{t.topMenu.changeMerchant.label}
                            </a>
                          )}
                          {!IsMainUser && (
                            <a
                              onClick={async () => {
                                persistedStore
                                  .SwitchSelectedManagerUser(
                                    AuthenticateUserResponse.AuthenticatedUserGuid
                                  )
                                  .then(() => {
                                    // setTimeout(() => {
                                    //   window.location.href = '/dashboard'
                                    // }, 300)
                                  })
                              }}
                            >
                              <i className="fa fa-chevron-left" />
                              &nbsp;&nbsp;{t.common.backTo}{' '}
                              {t.common.authenticatedUser}
                            </a>
                          )}
                        </li>
                        <li className="divider" />
                        <li>
                          <a
                            onClick={() => {
                              persistedStore.Logoff()
                            }}
                          >
                            <i className="fa fa-sign-out" />
                            &nbsp;&nbsp;{t.common.logoff}
                          </a>
                        </li>
                      </ul>
                    </li>
                  </>
                )}
              </ul>
            </nav>
          </div>
          {/* /.container */}
        </header>
        {renderMerchantMenu()}
        <div className="content">
          <div className="container">{children}</div>
        </div>
      </div>
      <SelectManagerUserModal />
      <footer className="footer">
        <div className="container">
          <p className="pull-left">{VersionText}</p>
        </div>
      </footer>
    </>
  )
}

export default observer(MainPage)
