import ITenant from '../ITenant'

const tenant: ITenant = {
  SystemName: 'Topr',
  Name: 'Topr',
  TenantId: 9,
  Colors: {
    AccentColor: '#b8d433',
    AccentColorDarkened: '#97af25',
    SecondaryColor: '#2b3d4c',
  },
}
export default tenant
