import React, { useEffect, useReducer } from 'react'
import { useParams } from 'react-router-dom'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import { MainPage } from 'src/Components/My'
import { t } from 'src/I18n'
import EventForm from '../Components/EventForm'
import { initialState, reducer, reducerActions } from './Reducer'

const EventEdit: React.FC = () => {
  const params = useParams<'id'>()
  const [state, dispatch] = useReducer(reducer, initialState)
  const actions = reducerActions(state, dispatch)

  useEffect(() => {
    actions.getEventEdit(params.id)
  }, [null])

  const { isSubmitting, eventEdit } = state

  return (
    <>
      <MainPage>
        <BreadCrumbs
          currentPageTitle={t.event.edit.title}
          previousPages={[
            {
              to: `/events`,
              title: t.event.index.title,
            },
          ]}
        />
        <EventForm
          onSave={(data) => {
            actions.save(params.id, data)
          }}
          title={t.event.edit.title}
          isSubmitting={isSubmitting}
          eventEdit={eventEdit}
        />
      </MainPage>
    </>
  )
}

export default EventEdit
