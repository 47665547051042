import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import LoaderIcon from 'src/Components/LoaderIcon'
import { MainPage } from 'src/Components/My'
import { bootStore } from './Store'

interface IProps {}

const Boot: React.FC<IProps> = () => {
  useEffect(() => {
    bootStore.Boot()
  }, [null])
  const { IsLoading, MachineName } = bootStore
  return (
    <>
      <MainPage>
        <LoaderIcon IsLoading={IsLoading} />
        {!IsLoading && <h1>{MachineName}</h1>}
      </MainPage>
    </>
  )
}

export default observer(Boot)
