import axios from 'axios'
import { GetBaseUrl } from 'src/Helpers/ServiceHelpers'
import { getFullLanguage } from 'src/I18n'
import { persistedStore } from 'src/Stores/PersistedStore'
import AuthenticationController from './Controllers/AuthenticationController'
import { Mutex } from 'async-mutex'
// create a new mutex
export const mutex = new Mutex()

export const axiosAuth = axios.create({
  baseURL: GetBaseUrl(),
  headers: {
    'Content-Type': 'application/json',
    'accept-language': getFullLanguage(),
  },
})

axiosAuth.interceptors.request.use(
  async (config) => {
    await mutex.waitForUnlock()
    const { AuthenticateUserResponse } = persistedStore
    if (AuthenticateUserResponse && AuthenticateUserResponse.AccessToken) {
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${AuthenticateUserResponse.AccessToken}`,
      }
    }

    return config
  },
  (error) => Promise.reject(error)
)

axiosAuth.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config

    if (error?.response?.status === 401 && !config?.sent) {
      config.sent = true

      if (!mutex.isLocked()) {
        const release = await mutex.acquire()

        try {
          const {
            AuthenticateUserResponse: { RefreshToken },
          } = persistedStore

          const response = await AuthenticationController.RefreshToken(
            RefreshToken
          )
          console.log('*** token refreshed v2*** :)')
          persistedStore.SetAuthenticatedUserResponse(response)

          if (response.AccessToken) {
            //updated with new acces token
            config.headers = {
              ...config.headers,
              authorization: `Bearer ${response.AccessToken}`,
            }
          }

          return axios(config)
        } finally {
          release()
        }
      } else {
        // wait until the mutex is available without locking it
        //console.log('wait for refresh 2 ')
        await mutex.waitForUnlock()

        //updated accestoken
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${persistedStore.AuthenticateUserResponse.AccessToken}`,
        }
        return axios(config)
      }
    }

    return Promise.reject(error)
  }
)
