import React, { PropsWithChildren } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {
  Path,
  RegisterOptions,
  useController,
  UseFormReturn,
} from 'react-hook-form'
import { t } from 'src/I18n'
import { MyLabel } from '..'

interface IProps<T> {
  name: Path<T>
  formObject: T
  useFormProps: UseFormReturn<T>
  rules?: RegisterOptions
  //date picker
  label: string
  infoText?: string
  onDateChange?: (daterange: Date) => void
  showTimeSelect?: boolean
}

const MyDatePicker = <ObjectType,>({
  name,
  useFormProps,
  infoText,
  label,
  rules,
  onDateChange,
  showTimeSelect,
}: PropsWithChildren<IProps<ObjectType>>) => {
  const {
    control,
    formState: { errors },
  } = useFormProps

  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
    rules,
  })

  const errorMessage =
    errors !== undefined && errors[name.toString()] !== undefined
      ? errors[name.toString()].message
      : undefined
  const required = rules !== undefined && rules.required !== undefined
  const hasError = errorMessage !== undefined

  const errorText = () => {
    if (!hasError) {
      return null
    }

    return (
      <ul className="parsley-error-list filled">
        <li className="parsley-required">{errorMessage}</li>
      </ul>
    )
  }

  //dates return from .net are ISO formated dates, vonvert them to JS dates
  const myValue =
    value !== null && typeof value === 'string' ? new Date(value) : value

  return (
    <div className="form-group">
      <MyLabel htmlFor={name} isRequired={required} infoText={infoText}>
        {label}
      </MyLabel>
      <DatePicker
        selectsStart
        selected={myValue}
        locale={t.getLanguage()}
        className="form-control"
        //dateFormat="dd-MM-yyyy HH:mm"
        dateFormat={showTimeSelect ? 'Pp' : 'dd-MM-yyyy'}
        autoComplete="off"
        showTimeSelect={showTimeSelect}
        timeCaption={t.common.time}
        onChange={(e) => {
          const newValue = e !== null ? new Date(e) : null

          onChange(newValue) //react-form-hook state
          if (onDateChange) {
            onDateChange(newValue) //component change
          }
        }}
      />
      {errorText()}
    </div>
  )
}

export default MyDatePicker
