import React, { useEffect, useReducer } from 'react'
import { ButtonToolbar } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { LoaderIcon } from 'src/Components'
import McButton from 'src/Components/McButton'
import McModal from 'src/Components/Modals/McModal'
import { InputMasks, MySelect, MyTextbox } from 'src/Components/My'
import { t } from 'src/I18n'
import {
  IAddTicketForm,
  initialState,
  reducer,
  reducerActions,
} from './Reducer'

interface IProps {
  onClose: () => void
  onSave: () => void
}

const AddTagModal: React.FC<IProps> = ({ onClose, onSave }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const actions = reducerActions(state, dispatch)
  const { isSubmitting, viewModel } = state

  useEffect(() => {
    reset(viewModel)
  }, [viewModel])

  const useFormProps: UseFormReturn<IAddTicketForm> = useForm<IAddTicketForm>(
    {}
  )
  const { handleSubmit, reset } = useFormProps

  const onSubmit = (data: IAddTicketForm) => {
    actions.save(data, () => {
      onSave()
    })
  }

  const renderContent = () => {
    return (
      <>
        <MyTextbox
          formObject={viewModel}
          label={t.tags.insert.fields.name.title}
          name="name"
          useFormProps={useFormProps}
          icon="fa-tag"
          placeholder="My tag"
          rules={{ required: t.tags.insert.fields.name.required }}
        />
      </>
    )
  }

  return (
    <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
      <McModal
        closeClicked={onClose}
        title={t.tags.insert.title}
        footer={
          <ButtonToolbar className="pull-right">
            <McButton onClick={onClose}>{t.common.cancel}</McButton>
            <McButton bsStyle="primary" type="submit" isLoading={isSubmitting}>
              {t.tags.insert.title}
            </McButton>
          </ButtonToolbar>
        }
      >
        {renderContent()}
      </McModal>
    </form>
  )
}

export default AddTagModal
