import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import LoaderIcon from 'src/Components/LoaderIcon'

import {
  MainPage,
  MyDataTable,
  MyMonthPeriodSelect,
  MySelect,
} from 'src/Components/My'
import { ISelectOption } from 'src/Models/Client'
import { t } from 'src/I18n'

import { IFormValues, monthlyTransactionsStore } from './Store'

const MontlyTransactionTotals: React.FC = () => {
  useEffect(() => {
    monthlyTransactionsStore.Initialize()
  }, [null])

  const {
    IsLoading,
    ViewModel,
    MonthlyTransactionTotals: monthlyTransactionTotals,
  } = monthlyTransactionsStore

  useEffect(() => {
    reset(ViewModel)
  }, [ViewModel])
  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({})
  const { handleSubmit, reset, setValue } = useFormProps

  const renderTotals = () => {
    const columns = [
      {
        dataField: 'MerchantName',
        text: t.common.merchant,
        sort: true,
      },
      {
        dataField: 'CurrencyName',
        text: t.common.currency,
        sort: true,
      },
      {
        dataField: 'Amount',
        text: t.common.amount,
        sort: true,
      },
      {
        dataField: 'AmountTotal',
        text: t.montlyTransactionTotals.amountTotal,
        sort: true,
      },
      {
        dataField: 'AmountTotalValue',
        text: t.montlyTransactionTotals.amountValue,
        sort: true,
      },
      {
        dataField: 'AmountNoValue',
        text: t.montlyTransactionTotals.amountNoValue,
        sort: true,
      },
    ]

    if (IsLoading) {
      return <LoaderIcon IsLoading={IsLoading} />
    }

    return <MyDataTable data={monthlyTransactionTotals} columns={columns} />
  }

  return (
    <MainPage>
      <BreadCrumbs currentPageTitle={t.paymentTransactions.title} />

      <LoaderIcon IsLoading={IsLoading} />
      {!IsLoading && ViewModel !== undefined && (
        <Portlet title={t.paymentTransactions.title}>
          <form className="parsley-form">
            <Row>
              <Col md={6}>
                <MyMonthPeriodSelect
                  onChange={(startDate, endDate) => {
                    setValue('StartDate', startDate)
                    setValue('EndDate', endDate)
                    handleSubmit((data) =>
                      monthlyTransactionsStore.GetMonthlyTransactionTotals(data)
                    )()
                  }}
                />
              </Col>
              <Col md={6}>
                <MySelect
                  formObject={{} as IFormValues}
                  useFormProps={useFormProps}
                  name="SelectedMerchants"
                  label={t.common.merchants}
                  options={ViewModel.Merchants}
                  isMulti
                  onSelectionChange={(options: ISelectOption[]) => {
                    setValue('SelectedMerchants', options)
                    handleSubmit((data) =>
                      monthlyTransactionsStore.GetMonthlyTransactionTotals(data)
                    )()
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <MySelect
                  formObject={{} as IFormValues}
                  useFormProps={useFormProps}
                  name="SelectedCurrencies"
                  label={t.common.currencies}
                  options={ViewModel.SelectedCurrencies}
                  isMulti
                  onSelectionChange={(options: ISelectOption[]) => {
                    setValue('SelectedCurrencies', options)
                    handleSubmit((data) =>
                      monthlyTransactionsStore.GetMonthlyTransactionTotals(data)
                    )()
                  }}
                />
              </Col>
            </Row>
            {renderTotals()}
          </form>
        </Portlet>
      )}
    </MainPage>
  )
}

export default observer(MontlyTransactionTotals)
