import { action, makeObservable, observable, runInAction } from 'mobx'
import { createPageAlert } from 'src/Components/McPageAlert'
import { ISelectOption } from 'src/Models/Client'
import ErrorCodes from 'src/Models/Server/ErrorCodes'
import IGenericError from 'src/Models/Server/IGenericError'
import IAddAppUserRequest from 'src/Models/Server/Request/Merchant/IAddAppUserRequest'
import CurrencyVTO from 'src/Models/Server/Response/Currency/CurrencyVTO'
import IAppUserVTO from 'src/Models/Server/Response/User/IAppUserVTO'
import { IBaseUserVTO } from 'src/Models/Server/Response/User/IBaseUserVTO'
import AppUserController from 'src/Services/ToprCoreApi/Axios/Controllers/AppUserController'
import CurrencyController from 'src/Services/ToprCoreApi/Axios/Controllers/CurrencyController'
import UserController from 'src/Services/ToprCoreApi/Axios/Controllers/UserController'
import BaseStore from 'src/Stores/BaseStore'
import { appUsersGridStore } from '../Store'

export interface IFormValues extends IAddAppUserRequest {}

export default class Store extends BaseStore<IFormValues> {
  public CheckEmail: boolean = true
  public HasExistingUser: boolean = false

  constructor() {
    super()

    makeObservable(this, {
      CheckEmail: observable,
      HasExistingUser: observable,
      Initialize: action,
      GetUserByEmail: action,
      AddAppUser: action,
    })
  }

  public Initialize(merchantId: number) {
    this.BaseInitialize()
    this.HasExistingUser = false
    this.CheckEmail = true

    this.ViewModel = {
      Email: '',
      MerchantId: merchantId,
      Name: '',
      Pincode: this.generatePincode(),
    }
  }

  public GetUserByEmail(data: IFormValues) {
    this.IsSubmitting = true

    UserController.GetUserByEmail(data.Email)
      .then((user) => {
        runInAction(() => {
          const hasExistingUser = user !== ''
          this.HasExistingUser = hasExistingUser
          this.CheckEmail = false
          this.IsSubmitting = false

          if (hasExistingUser) {
            this.ViewModel = {
              ...this.ViewModel,
              Name: (user as IBaseUserVTO).Name,
            }
          }
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => {
          this.PageAlert = createPageAlert(error)
          this.IsSubmitting = false
        })
      })
  }

  public AddAppUser(data: IFormValues) {
    this.IsSubmitting = true

    return new Promise<IAppUserVTO>((resolve, reject) => {
      AppUserController.Insert(data)
        .then((appUser) => {
          resolve(appUser)
          this.Initialize(data.MerchantId)
          appUsersGridStore.GetAppUsers(data.MerchantId)
        })
        .catch((error: IGenericError) => {
          runInAction(() => {
            this.PageAlert = createPageAlert(error)
            this.IsSubmitting = false
          })
          reject()
        })
    })
  }

  private generatePincode() {
    return Math.floor(1000 + Math.random() * 9000).toString()
  }
}

export const addAppUserStore = new Store()
