import IGenericError from 'src/Models/Server/IGenericError'
import TagController from 'src/Services/ToprCoreApi/Axios/Controllers/TagController'
import { appUiStore } from 'src/Stores/AppUiStore'

export interface IState {
  isSubmitting: boolean
  viewModel: IAddTicketForm
}

export const initialState: IState = {
  isSubmitting: false,
  viewModel: undefined,
}

export interface IAddTicketForm {
  name: string
}

export type Actions = {
  type: 'setIsSubmitting'
  isSubmitting: boolean
}

export const reducer = (state: IState, action: Actions): IState => {
  switch (action.type) {
    case 'setIsSubmitting':
      return { ...state, isSubmitting: action.isSubmitting }

    default:
      throw new Error('Invalid Reducer Action')
  }
}

export const reducerActions = (
  state: IState,
  dispatch: (action: Actions) => void
) => {
  return {
    save: (data: IAddTicketForm, onComplete: () => void) => {
      dispatch({ type: 'setIsSubmitting', isSubmitting: true })

      TagController.TagInsert(data.name)
        .then(() => {
          onComplete()
          dispatch({ type: 'setIsSubmitting', isSubmitting: false })
        })
        .catch((error: IGenericError) => {
          appUiStore.ShowError(error)
          dispatch({ type: 'setIsSubmitting', isSubmitting: false })
        })
    },
  }
}
