import { AxiosError, AxiosResponse } from 'axios'
import FileDownload from 'js-file-download'
import { FormatYearMonthDayHourMinutes } from 'src/Helpers/DateHelpers'
import { handleException } from 'src/Helpers/ResponseHelpers'
import { IModifyWalletResultVTO } from 'src/Models/Server/Response/Wallet/IModifyWalletResultVTO'
import IWalletVTO from 'src/Models/Server/Response/Wallet/IWalletVTO'
import { axiosAuth } from '../AuthAxios'

export default {
  GetWalletsUser(userGuid: string) {
    return new Promise<IWalletVTO[]>((resolve, reject) => {
      axiosAuth
        .get(`/api/v1/wallets/user/${userGuid}`)
        .then((response: AxiosResponse<IWalletVTO[]>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  ChargeManager(
    CurrencyId: number,
    UserIds: number[],
    Amount: number,
    Description: string
  ) {
    return new Promise<IModifyWalletResultVTO>((resolve, reject) => {
      axiosAuth
        .post(`/api/v1/wallet/manager/charge`, {
          CurrencyId,
          UserIds,
          Amount,
          Description,
        })
        .then((response: AxiosResponse<IModifyWalletResultVTO>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  WithdrawManager(
    CurrencyId: number,
    UserIds: number[],
    Amount: number,
    Description: string
  ) {
    return new Promise<IModifyWalletResultVTO>((resolve, reject) => {
      axiosAuth
        .post(`/api/v1/wallet/manager/withdraw`, {
          CurrencyId,
          UserIds,
          Amount,
          Description,
        })
        .then((response: AxiosResponse<IModifyWalletResultVTO>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  GenerateTemplateFile(currencyId: string) {
    return new Promise<void>((resolve, reject) => {
      axiosAuth
        .post(`/api/v1/wallets/generatetemplatefile/${currencyId}`, null)
        .then((response: AxiosResponse<any>) => {
          resolve()

          FileDownload(
            response.data,
            `ImportWallets_${FormatYearMonthDayHourMinutes(new Date())}.csv`
          )
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
}
