import { observer } from 'mobx-react'
import React, { Fragment, useEffect } from 'react'
import { ButtonToolbar, Col, FormGroup, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { McButton } from 'src/Components'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import { MainPage } from 'src/Components/My'
import { Base64ImageUrl, GetFileUrl } from 'src/Images'
import { t } from '../../../../I18n'
import { fileDetailsStore } from './Store'

interface IProps {}

const Details: React.FC<IProps> = () => {
  const params = useParams<'id'>()
  const navigate = useNavigate()
  useEffect(() => {
    fileDetailsStore.Initialize(params.id)
  }, [null])

  const { File, IsLoading } = fileDetailsStore

  const renderImageOrLink = () => {
    if (File == undefined) {
      return null
    }

    if (File.MimeType.toLowerCase().indexOf('image') > -1) {
      return <img src={Base64ImageUrl(File)} />
    }
    return (
      <a download href={GetFileUrl(File.FileGuid)} title={File.Name}>
        Download
      </a>
    )
  }

  return (
    <MainPage>
      <br className="xs-10" />

      {!IsLoading && File !== undefined && (
        <Fragment>
          <BreadCrumbs currentPageTitle={t.common.file} />
          <div className="details">
            <Portlet
              title={t.common.details}
              headerContent={
                <ButtonToolbar className="pull-right">
                  <McButton
                    iconLeft="fa-edit"
                    bsSize="xs"
                    bsStyle="primary"
                    title={t.common.edit}
                    onClick={() => navigate(`/file/edit/${File.FileId}`)}
                  >
                    {t.common.edit}
                  </McButton>
                </ButtonToolbar>
              }
            >
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <label>{t.common.name}</label>
                    <span>{File.Name}</span>
                  </FormGroup>
                  <FormGroup>
                    <label>{t.common.type}</label>
                    <span>{File.MimeType}</span>
                  </FormGroup>
                  {renderImageOrLink()}
                </Col>
              </Row>
            </Portlet>
          </div>
        </Fragment>
      )}
    </MainPage>
  )

  return (
    <>
      <MainPage>Details</MainPage>
    </>
  )
}

export default observer(Details)
