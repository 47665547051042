import { CentsToCurrencyAmount } from 'src/Helpers/CurrencyHelpers'
import { Today } from 'src/Helpers/DateHelpers'
import { enumToText } from 'src/Helpers/TextHelpers'
import { t } from 'src/I18n'
import { ISelectOption } from 'src/Models/Client'
import { LoyaltyTypes, UserScheduleType } from 'src/Models/Server/Enums'
import IGenericError from 'src/Models/Server/IGenericError'
import { ICreateUserScheduleBonusRequest } from 'src/Models/Server/Request/UserScheduleBonus/ICreateUserScheduleBonusRequest'
import { IEditUserScheduleBonusRequest } from 'src/Models/Server/Request/UserScheduleBonus/IEditUserScheduleBonusRequest'
import CurrencyVTO from 'src/Models/Server/Response/Currency/CurrencyVTO'
import { IUserScheduleBonusSTO } from 'src/Models/Server/Response/IUserScheduleBonusSTO'
import CouponController from 'src/Services/ToprCoreApi/Axios/Controllers/CouponController'
import CurrencyController from 'src/Services/ToprCoreApi/Axios/Controllers/CurrencyController'
import UserScheduleBonusController from 'src/Services/ToprCoreApi/Axios/Controllers/UserScheduleBonusController'
import { appUiStore } from 'src/Stores/AppUiStore'

export const typeOptions = [
  {
    value: LoyaltyTypes.Currency.toString(),
    label: t.common.currency,
  },
  {
    value: LoyaltyTypes.Coupon.toString(),
    label: t.common.coupon,
  },
]

export const scheduleTypeOptions = [
  {
    value: UserScheduleType.Daily.toString(),
    label: enumToText(
      UserScheduleType.Daily,
      UserScheduleType,
      t.userScheduleBonus.insert.fields.ScheduleType.enum
    ),
  },
  //   {
  //     value: UserScheduleType.Weekly.toString(),
  //     label: enumToText(
  //       UserScheduleType.Weekly,
  //       UserScheduleType,
  //       t.userScheduleBonus.insert.fields.ScheduleType.enum
  //     ),
  //   },
  {
    value: UserScheduleType.Monthly.toString(),
    label: enumToText(
      UserScheduleType.Monthly,
      UserScheduleType,
      t.userScheduleBonus.insert.fields.ScheduleType.enum
    ),
  },
]

export const getCurrencyOptions = (
  currencies: CurrencyVTO[]
): ISelectOption[] => {
  return currencies.map((cur) => {
    return {
      label: cur.Name,
      value: cur.CurrencyId.toString(),
    }
  })
}

export interface IUSerScheduleBonusFormValues {
  TypeOption: ISelectOption
  CurrencyOption: ISelectOption
  CouponOption: ISelectOption
  ScheduleTypeOption: ISelectOption
  DisplayAmount: string
  AmountCents: number
  Replenish: boolean
  StartDate: Date
  EndDate?: Date

  Monday: boolean
  Tuesday: boolean
  Wednesday: boolean
  Thursday: boolean
  Friday: boolean
  Saturday: boolean
  Sunday: boolean
}

export interface IState {
  isLoading: boolean
  isSubmitting: boolean
  currencies: CurrencyVTO[]
  couponOptions: ISelectOption[]
  viewModel: IUSerScheduleBonusFormValues
}

export const initialState: IState = {
  isLoading: true,
  isSubmitting: false,
  currencies: [],
  couponOptions: [],
  viewModel: undefined,
}

export type Actions =
  | {
      type: 'initialize'
      viewModel: IUSerScheduleBonusFormValues
      currencies: CurrencyVTO[]
      couponOptions: ISelectOption[]
    }
  | {
      type: 'setIsSubmitting'
      isSubmitting: boolean
    }
  | {
      type: 'initializeEdit'
      viewModel: IUSerScheduleBonusFormValues
      currencies: CurrencyVTO[]
    }

export const reducer = (state: IState, action: Actions): IState => {
  switch (action.type) {
    case 'initialize':
      return {
        ...state,
        viewModel: action.viewModel,
        currencies: action.currencies,
        couponOptions: action.couponOptions,
        isLoading: false,
      }
    case 'initializeEdit':
      return {
        ...state,
        viewModel: action.viewModel,
        currencies: action.currencies,
        isLoading: false,
      }
    case 'setIsSubmitting':
      return { ...state, isSubmitting: action.isSubmitting }

    default:
      throw new Error('Invalid Reducer Action')
  }
}

export const reducerActions = (
  state: IState,
  dispatch: (action: Actions) => void
) => {
  const initInsert = (userGuid: string) => {
    Promise.all([
      CurrencyController.GetMyMerchantCurrencies(),
      CouponController.GetCouponsMerchant(),
      UserScheduleBonusController.GetUserScheduleBonuses(userGuid),
    ])
      .then((values) => {
        const existingUserBonuses = values[2]
        //filter: don't show currencies from existingUserBonuses
        const currencies = values[0].filter(
          (cur) =>
            existingUserBonuses.findIndex(
              (usb) => usb.CurrencyId === cur.CurrencyId
            ) === -1
        )

        //filter: don't show coupons from existingUserBonuses
        const couponOptions = values[1]
          .filter(
            (coupon) =>
              existingUserBonuses.findIndex(
                (usb) => usb.CouponId === coupon.CouponId
              ) === -1
          )
          .map((coupon) => {
            return {
              label: coupon.FullTitle,
              value: coupon.CouponId.toString(),
            }
          })

        const viewModel: IUSerScheduleBonusFormValues = {
          TypeOption: typeOptions[0],
          CurrencyOption: getCurrencyOptions(currencies)[0],
          CouponOption: couponOptions[0],
          DisplayAmount: '1',
          AmountCents: 1,
          Replenish: false,
          ScheduleTypeOption: scheduleTypeOptions[0],
          StartDate: Today(),
          Monday: true,
          Tuesday: true,
          Wednesday: true,
          Thursday: true,
          Friday: true,
          Saturday: true,
          Sunday: true,
        }

        dispatch({
          type: 'initialize',
          viewModel,
          currencies,
          couponOptions,
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
      })
  }
  const saveInsert = (userGuid: string, data: IUSerScheduleBonusFormValues) => {
    const request: ICreateUserScheduleBonusRequest = {
      UserGuid: userGuid,
      Amount: data.AmountCents,
      ScheduleTypeId: data.ScheduleTypeOption.value,
      CurrencyId:
        data.TypeOption.value === LoyaltyTypes.Currency.toString()
          ? data.CurrencyOption.value
          : undefined,
      CouponId:
        data.TypeOption.value === LoyaltyTypes.Coupon.toString()
          ? data.CouponOption.value
          : undefined,
      ...data,
    }

    dispatch({ type: 'setIsSubmitting', isSubmitting: true })
    UserScheduleBonusController.Insert(request)
      .then(() => {
        history.back()
        dispatch({ type: 'setIsSubmitting', isSubmitting: false })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        dispatch({ type: 'setIsSubmitting', isSubmitting: false })
      })
  }

  const initEdit = (userScheduleBonusId: string) => {
    Promise.all([
      CurrencyController.GetMyMerchantCurrencies(),
      CouponController.GetCouponsMerchant(),
      UserScheduleBonusController.GetUserScheduleBonus(userScheduleBonusId),
    ])
      .then((values) => {
        const editUserScheduleBonus = values[2]
        const hasCurrency = editUserScheduleBonus.CurrencyId !== null

        const currencies = values[0]
        const currency = currencies.find(
          (x) => x.CurrencyId === editUserScheduleBonus.CurrencyId
        )

        const currencyOptions = getCurrencyOptions(currencies)
        const currencyOptionIndex = hasCurrency
          ? currencyOptions.findIndex(
              (x) => x.value === editUserScheduleBonus.CurrencyId.toString()
            )
          : 0

        const couponOptions = values[1].map((coupon) => {
          return {
            label: coupon.FullTitle,
            value: coupon.CouponId.toString(),
          }
        })
        const couponOptionIndex = hasCurrency
          ? 0
          : couponOptions.findIndex(
              (x) => x.value === editUserScheduleBonus.CouponId.toString()
            )

        const typeIndex = hasCurrency ? 0 : 1
        const scheduleTypeIndex = scheduleTypeOptions.findIndex(
          (x) => x.value === editUserScheduleBonus.ScheduleTypeId.toString()
        )
        const scheduleTypeOption = scheduleTypeOptions[scheduleTypeIndex]

        const viewModel: IUSerScheduleBonusFormValues = {
          TypeOption: typeOptions[typeIndex],
          CurrencyOption: currencyOptions[currencyOptionIndex],
          CouponOption: couponOptions[couponOptionIndex],
          DisplayAmount: hasCurrency
            ? CentsToCurrencyAmount(
                editUserScheduleBonus.Amount,
                currency.IsDecimal,
                currency.StringFormat
              )
            : '1',
          AmountCents: editUserScheduleBonus.Amount,
          Replenish: editUserScheduleBonus.Replenish,
          ScheduleTypeOption: scheduleTypeOption,

          StartDate: new Date(editUserScheduleBonus.StartDate),
          EndDate:
            editUserScheduleBonus.EndDate === null
              ? undefined
              : new Date(editUserScheduleBonus.EndDate),
          Monday: editUserScheduleBonus.Monday,
          Tuesday: editUserScheduleBonus.Tuesday,
          Wednesday: editUserScheduleBonus.Wednesday,
          Thursday: editUserScheduleBonus.Thursday,
          Friday: editUserScheduleBonus.Friday,
          Saturday: editUserScheduleBonus.Saturday,
          Sunday: editUserScheduleBonus.Sunday,
        }

        dispatch({
          type: 'initializeEdit',
          viewModel,
          currencies,
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
      })
  }

  const saveEdit = (
    userScheduleBonusId: string,
    data: IUSerScheduleBonusFormValues
  ) => {
    //

    const request: IEditUserScheduleBonusRequest = {
      UserScheduleBonusId: userScheduleBonusId,
      Amount: data.AmountCents,
      ScheduleTypeId: data.ScheduleTypeOption.value,
      ...data,
    }

    dispatch({ type: 'setIsSubmitting', isSubmitting: true })
    UserScheduleBonusController.Edit(request)
      .then(() => {
        history.back()
        dispatch({ type: 'setIsSubmitting', isSubmitting: false })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        dispatch({ type: 'setIsSubmitting', isSubmitting: false })
      })
  }

  return {
    initialize: (userGuid: string, userScheduleBonusId: string) => {
      if (userScheduleBonusId !== undefined) {
        initEdit(userScheduleBonusId)
      } else {
        initInsert(userGuid)
      }
    },
    save: (
      userGuid: string,
      userSchedulerBonusId: string,
      data: IUSerScheduleBonusFormValues
    ) => {
      //
      if (userSchedulerBonusId !== undefined) {
        //update
        saveEdit(userSchedulerBonusId, data)
      } else {
        //insert
        saveInsert(userGuid, data)
      }
    },
  }
}
