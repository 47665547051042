import { IEditConsumerSubscriptionVTO } from 'src/Models/Server/Response/ConsumerSubscription/IEditConsumerSubscriptionVTO'
import {
  LocalizedStringDTO,
  SetMissingLanguageValues,
  TextBoxType,
} from 'src/Models/Server/Response/LocalizedString/LocalizedStringDTO'
import { persistedStore } from 'src/Stores/PersistedStore'

export interface ISubscriptionFormValues {
  Name: LocalizedStringDTO
  SystemName: string
}

export interface IState {
  viewModel: ISubscriptionFormValues
}

export const initialState: IState = {
  viewModel: undefined,
}

export type Actions = {
  type: 'init'
  viewModel: ISubscriptionFormValues
}

export const reducer = (state: IState, action: Actions): IState => {
  switch (action.type) {
    case 'init': {
      return { ...state, viewModel: action.viewModel }
    }
    default:
      throw new Error('Invalid Reducer Action')
  }
}

export const reducerActions = (
  state: IState,
  dispatch: (action: Actions) => void
) => {
  return {
    initialize: (editItem: IEditConsumerSubscriptionVTO) => {
      if (editItem === undefined) {
        //insert
        const viewModel: ISubscriptionFormValues = {
          Name: new LocalizedStringDTO('Subscription', TextBoxType.TextBox),
          SystemName: '',
        }
        dispatch({ type: 'init', viewModel })
      } else {
        //edit
        const viewModel: ISubscriptionFormValues = {
          Name: SetMissingLanguageValues(editItem.Name),
          SystemName: editItem.SystemName,
        }
        dispatch({ type: 'init', viewModel })
      }
    },
  }
}
