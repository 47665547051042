import { toast } from 'react-toastify'
import { t } from 'src/I18n'
import IGenericError from 'src/Models/Server/IGenericError'
import IEditAutomaticChargeRequest from 'src/Models/Server/Request/AutomaticCharge/IEditAutomaticChargeRequest'
import {
  IBankSelectOption,
  SelectOptionsFromBank,
} from 'src/Models/Server/Response/Bank/IBankDTO'
import IHelpDeskUserVTO from 'src/Models/Server/Response/User/IHelpDeskUserVTO'
import AutomaticChargeController from 'src/Services/ToprCoreApi/Axios/Controllers/AutomaticChargeController'
import BankController from 'src/Services/ToprCoreApi/Axios/Controllers/BankController'
import UserController from 'src/Services/ToprCoreApi/Axios/Controllers/UserController'
import { action, makeObservable, observable, runInAction } from 'mobx'
import BaseStore from 'src/Stores/BaseStore'
import { appUiStore } from 'src/Stores/AppUiStore'

export interface IFormValues extends IEditAutomaticChargeRequest {
  /**
   * To select an option in the react-select wen need a ISelectOption.
   * But we don't want to send the option to the server, that's why we have a
   * separate property besides CurrencyGuid
   */
  SelectedBank: IBankSelectOption
  SepaFile: File
}

export default class Store extends BaseStore<IFormValues> {
  public User: IHelpDeskUserVTO = null

  public Banks: IBankSelectOption[] = []

  constructor() {
    super()

    makeObservable(this, {
      User: observable,
      Banks: observable,
      Initialize: action,
      GetBanks: action,
      GetHelpdeskUser: action,
      Edit: action,
    })
  }

  public Initialize(id: string, userGuid: string) {
    this.IsLoading = true
    AutomaticChargeController.GetAutomaticCharge(id)
      .then((result) => {
        this.BaseInitialize({
          FileData: '',
          MimeType: '',
          AutomaticChargeId: id,
          Bic: result.Bic,
          SelectedBank: null,
          AccountNumber: result.AccountNumber,
          AccountHolder: result.AccountHolder,
          StepAmount: result.StepAmount / 100,
          StepLimit: result.StepLimit,
          SepaFile: null,
        })

        this.GetBanks()
        runInAction(() => (this.IsLoading = false))
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        runInAction(() => (this.IsLoading = false))
      })
    this.GetHelpdeskUser(userGuid)
  }

  public GetHelpdeskUser(userGuid: string) {
    this.IsLoading = true
    UserController.GetHelpdeskUser(userGuid)
      .then((user) => {
        runInAction(() => {
          this.IsLoading = false
          this.User = user
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        runInAction(() => (this.IsLoading = false))
      })
  }

  public GetBanks() {
    this.IsLoading = true

    BankController.GetAllBanks()
      .then((banks) => {
        runInAction(() => {
          this.Banks = SelectOptionsFromBank(banks)
          const selectedBank = this.Banks.find(
            (x) => x.Bic === this.ViewModel.Bic
          )
          if (selectedBank !== undefined) {
            this.ViewModel = { ...this.ViewModel, SelectedBank: selectedBank }
          }

          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        runInAction(() => (this.IsLoading = false))
      })
  }

  public Edit(data: IFormValues) {
    this.IsSubmitting = true
    data.Bic = data.SelectedBank.Bic

    AutomaticChargeController.Edit(data)
      .then(() => {
        runInAction(() => (this.IsSubmitting = false))
        window.history.back()
        toast.success(t.automaticChargeEdit.success)
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsSubmitting = false))
        appUiStore.ShowError(error)
      })
  }
}

export const automaticChargeEditStore = new Store()
