import React from 'react'

interface IProps {
  leftSlide: React.ReactNode
  rightSlide: React.ReactNode
  isSlideLeft: boolean
}

const ClickSlider: React.FC<IProps> = ({
  leftSlide,
  rightSlide,
  isSlideLeft,
}) => {
  const leftCss = isSlideLeft ? 'left' : ''
  return (
    <div className="horizontal-scroll">
      <div className={`h-slide ${leftCss}`}>{leftSlide}</div>
      <div className={`h-slide`}>{rightSlide}</div>
    </div>
  )
}

export default ClickSlider
