import { observer } from 'mobx-react'
import React, { Fragment, useEffect } from 'react'
import { ButtonToolbar } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { McPageAlert } from 'src/Components'
import LoaderIcon from 'src/Components/LoaderIcon'
import McButton from 'src/Components/McButton'
import McModal from 'src/Components/Modals/McModal'
import { MySelect } from 'src/Components/My'
import { t } from 'src/I18n'
import { ISelectOption } from 'src/Models/Client'
import IAppUserVTO from 'src/Models/Server/Response/User/IAppUserVTO'
import { appUsersGridStore } from '../Store'
import { addPosModuleStore, IFormValues } from './Store'

interface IProps {
  onClose: () => void

  appUser: IAppUserVTO
  merchantId: number
}

const AddPosModuleModal: React.FC<IProps> = ({
  onClose,
  appUser,
  merchantId,
}) => {
  useEffect(() => {
    addPosModuleStore.Initialize(merchantId, appUser)
  }, [null])
  const {
    IsLoading,
    ViewModel,
    IsSubmitting,
    NeedsCurrency,
    CurrencyOptions,
    ModuleOptions,
    PageAlert,
  } = addPosModuleStore

  useEffect(() => {
    reset(ViewModel)
  }, [ViewModel])

  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({})
  const { handleSubmit, reset } = useFormProps

  if (ViewModel === undefined) {
    return null
  }

  const onSubmit = (data: IFormValues) => {
    addPosModuleStore.AddPosModule(data).then(() => {
      onClose()
    })
  }

  const renderCurrencySelect = () => {
    if (NeedsCurrency) {
      return (
        <MySelect
          formObject={ViewModel}
          useFormProps={useFormProps}
          name="SelectedCurrencyOption"
          label={t.common.currency}
          // rules={{
          //   required:
          //     t.loyaltyprogram.insert.fields.LoyaltyProgramTypeId.errors
          //       .required,
          // }}
          options={CurrencyOptions}
        />
      )
    }

    return null
  }
  return (
    <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
      <McModal
        title={t.merchant.details.appUsers.addPosModule}
        closeOnBackdrop={false}
        footer={
          <div>
            <McPageAlert pageAlert={PageAlert} />
            {!IsLoading && (
              <ButtonToolbar className="pull-right">
                <McButton onClick={() => onClose()}>{t.common.cancel}</McButton>
                <McButton
                  bsStyle="primary"
                  type="submit"
                  isLoading={IsSubmitting}
                >
                  {t.common.save}
                </McButton>
              </ButtonToolbar>
            )}
          </div>
        }
        closeClicked={() => onClose()}
      >
        <LoaderIcon IsLoading={IsLoading} />
        {!IsLoading && (
          <Fragment>
            <MySelect
              formObject={ViewModel}
              useFormProps={useFormProps}
              name="SelectedModuleOption"
              label={t.common.module}
              // rules={{
              //   required:
              //     t.loyaltyprogram.insert.fields.LoyaltyProgramTypeId.errors
              //       .required,
              // }}
              onSelectionChange={(value: ISelectOption) => {
                addPosModuleStore.SetSelectedModule(value)
              }}
              options={ModuleOptions}
            />
            {renderCurrencySelect()}
          </Fragment>
        )}
      </McModal>
    </form>
  )
}

export default observer(AddPosModuleModal)
