import { observer } from 'mobx-react'
import React, { Fragment, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import LoaderIcon from 'src/Components/LoaderIcon'
import McButton from 'src/Components/McButton'
import { MyDataTable } from 'src/Components/My'
import { t } from 'src/I18n'
import ITerminalVTO from 'src/Models/Server/Response/Terminal/ITerminalVTO'
import { terminalsGrid } from './Store'

interface IProps {
  posGuid: string
  posName: string
}

const TerminalsGrid: React.FC<IProps> = ({ posGuid, posName }) => {
  useEffect(() => {
    terminalsGrid.GetTerminalsByPos(posGuid)
  }, [null])
  const { ViewModel, IsLoading } = terminalsGrid
  const actionsCellFormatter = (cell, data: ITerminalVTO) => {
    return (
      <Link to={`/terminal/details/${data.TerminalId}`}>
        <McButton bsSize="small" bsStyle="primary">
          {t.common.details}
        </McButton>
      </Link>
    )
  }

  const columns = [
    {
      dataField: 'Description',
      text: t.common.description,
      //filter: textFilter({ placeholder: t.common.description }),
    },
    {
      dataField: 'TerminalTypeName',
      text: t.terminal.insert.fields.TerminalTypeID.label,
      // filter: textFilter({
      //   placeholder: t.terminal.insert.fields.TerminalTypeID.label,
      // }),
    },
    {
      dataField: 'actions',
      csvExport: false,
      text: '',
      formatter: actionsCellFormatter,
    },
  ]

  return (
    <Fragment>
      <Link to={`/terminal/insert/${posGuid}/${posName}`}>
        <Button bsStyle="primary">{t.terminal.insert.title}</Button>
      </Link>
      <LoaderIcon IsLoading={IsLoading} />
      {!IsLoading && <MyDataTable data={ViewModel} columns={columns} />}
    </Fragment>
  )
}

export default observer(TerminalsGrid)
