import { observer } from 'mobx-react'
import React from 'react'
import { MainPage, RequireAuthentication } from 'src/Components/My'
import { persistedStore } from 'src/Stores/PersistedStore'
interface IProps {}

const DashboardTest: React.FC<IProps> = () => {
  console.log('DashboardTest', persistedStore.IsAuthenticated)
  return (
    <RequireAuthentication>
      <MainPage>
        DashboardTest
        <button onClick={() => persistedStore.Logoff()}>logoff</button>
      </MainPage>
    </RequireAuthentication>
  )
}

export default observer(DashboardTest)
