import React from 'react'
import { NavLink } from 'react-router-dom'
import { hasMatchedChildren } from 'src/Helpers/UrlHelpers'
import { t } from 'src/I18n'
interface IProps {}

const CustomerSearchMenu: React.FC<IProps> = () => {
  const hasActiveChildrenClassName = hasMatchedChildren(
    '/helpdesk/users',
    '/transaction/search'
  )
    ? 'dropdown active'
    : 'dropdown'

  return (
    <li className={hasActiveChildrenClassName}>
      <a
        className="dropdown-toggle"
        data-toggle="dropdown"
        data-hover="dropdown"
      >
        {t.common.consumers}
        <i className="fa fa-chevron-down" />
      </a>

      <ul className="dropdown-menu" role="menu">
        <li>
          <NavLink to={'/helpdesk/users'}>
            <i className={`fa fa-search dropdown-icon`} />
            {t.topMenu.consumers.helpdesk}
          </NavLink>
        </li>
        <li>
          <NavLink to={'/transaction/search'}>
            <i className={`fa fa-search dropdown-icon`} />
            {t.transaction.title}
          </NavLink>
        </li>
        <li>
          <NavLink to={'/merchant/consumer/add'}>
            <i className={`fa fa-user-plus dropdown-icon`} />
            {t.merchant.addConsumerUser.title}
          </NavLink>
        </li>
      </ul>
    </li>
  )
}

export default CustomerSearchMenu
