import { map } from 'jquery'

export interface IDataTable {
  Columns: {
    dataField: string
    text: string
    sort: boolean
    sortFunc?: (a: string, b: string, order: string) => number
  }[]
  Data: any[]
}

/**
 *
 * @param result c# DataTale Json array
 * @param keyfield Keyfield for the grid, will be removed from columns
 * @returns
 */
export const CreateDataTable = (
  result: any[],
  keyfield?: string,
  sort: boolean = true
): IDataTable => {
  if (result && result.length > 0) {
    return {
      Columns: map(
        Object.keys(result[0]).filter((x) => x !== keyfield), //columns from first item
        (column) => ({
          dataField: column,
          text: column,
          sort: sort,
          // sortFunc: (a: string, b: string, order) => {
          //   //if decimal, remove , sort as cents
          //   a = a.replace(',', '')
          //   b = b.replace(',', '')

          //   if (order === 'asc') {
          //     return parseInt(a) - parseInt(b)
          //   } else {
          //     return parseInt(b) - parseInt(a)
          //   }
          // },
        })
      ),
      Data: result,
    }
  }

  return undefined
}
