import { action, makeObservable } from 'mobx'

import { toast } from 'react-toastify'
import { t } from 'src/I18n'
import IGenericError from 'src/Models/Server/IGenericError'
import TokenController from 'src/Services/ToprCoreApi/Axios/Controllers/TokenController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

import { userInfoStore } from '../Store'

export default class Store extends BaseStore {
  constructor() {
    super()
    this.BaseInitialize([])
    makeObservable(this, {
      BlockToken: action,
      UnblockToken: action,
    })
  }

  public BlockToken(tokenGuid: string, userGuid: string) {
    this.IsConfirming = true
    TokenController.Block(tokenGuid, userGuid)
      .then(() => {
        toast.success(t.tokens.blockToken.success)
        //userInfoStore.GetTokensUser()
        userInfoStore.SetTokenBlocked(tokenGuid, true)
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
      })
  }

  public UnblockToken(tokenGuid: string, userGuid: string) {
    this.IsConfirming = true
    TokenController.Unblock(tokenGuid, userGuid)
      .then(() => {
        toast.success(t.tokens.unblockToken.success)
        //userInfoStore.GetTokensUser()
        userInfoStore.SetTokenBlocked(tokenGuid, false)
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
      })
  }
}

export const tokensStore = new Store()
