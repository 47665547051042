import { t } from 'src/I18n'
import { LocalizedStringDTO } from 'src/Models/Server/Response/LocalizedString/LocalizedStringDTO'

// export const ValidateRequiredLocalizedString = (
//   localizedString: LocalizedString,
//   defaultLanguage: string
// ): boolean => {
//   const llsValue = localizedString.LocalizedStringValues.find(
//     x => x.LanguageId === defaultLanguage
//   )
//   if (llsValue && (llsValue.Value !== null && llsValue.Value !== '')) {
//     return true
//   }

//   return false
// }

export const ValidateRequiredLocalizedString = (
  localizedString: LocalizedStringDTO,
  defaultLanguage: string,
  label: string
): boolean | string => {
  const llsValue = localizedString.LocalizedStringValues.find(
    (x) => x.LanguageId === defaultLanguage
  )
  if (llsValue && llsValue.Value !== null && llsValue.Value !== '') {
    return true
  }

  //return error message // 'Voer een {0} ({1}) in.',
  return t
    .formatString(
      t.commonErrors.localizedStringRequired,
      label,
      defaultLanguage
    )
    .toString()
}

export const ValidateEmail = (value) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(value).toLowerCase())
}
