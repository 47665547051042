import LocalizedStrings from 'react-localization'
import nl from './locales/nl'
import en from './locales/en'
import de from './locales/de'

//datePicker resources
import { registerLocale } from 'react-datepicker'
import enGb from 'date-fns/locale/en-GB'
import nlNl from 'date-fns/locale/nl'
import deDE from 'date-fns/locale/de'

registerLocale('en', enGb)
registerLocale('nl', nlNl)
registerLocale('de', deDE)

export const t = new LocalizedStrings({
  nl,
  en,
  de,
})

//t.setLanguage('de')

export const getFullLanguage = () => {
  const lang = t.getLanguage()

  switch (lang) {
    case 'nl':
      return 'nl-NL'
    case 'de':
      return 'de-DE'
    case 'en':
    default:
      return 'en-GB'
  }
}
