import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { ButtonToolbar } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { McPageAlert } from 'src/Components'
import McButton from 'src/Components/McButton'
import McModal from 'src/Components/Modals/McModal'
import { MySelect, MyTextbox } from 'src/Components/My'
import { t } from 'src/I18n'
import IPosVTO from 'src/Models/Server/Response/Pos/IPosVTO'
import { editPosStore, IFormValues } from './Store'

interface IProps {
  onClose: () => void
  onSubmitSuccess: (editPos: IPosVTO) => void
  editPos: IPosVTO
}

interface IProps {}

const EditPosModal: React.FC<IProps> = ({
  onClose,
  onSubmitSuccess,
  editPos,
}) => {
  const { ViewModel, IsSubmitting, PosTypes, PageAlert } = editPosStore
  useEffect(() => {
    editPosStore.Initialize(editPos)
  }, [null])

  useEffect(() => {
    reset(ViewModel)
  }, [ViewModel])

  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({})
  const { handleSubmit, reset } = useFormProps

  const onSubmit = (data: IFormValues) => {
    editPosStore.EditPos(data).then(() => {
      onSubmitSuccess(data)
      close()
    })
  }

  const close = () => {
    if (!editPosStore.IsSubmitting) {
      onClose()
    }
  }

  if (ViewModel === undefined) {
    return null
  }

  return (
    <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
      <McModal
        title={t.common.edit}
        footer={
          <div>
            <McPageAlert pageAlert={PageAlert} />
            <ButtonToolbar className="pull-right">
              <McButton onClick={() => close()}>{t.common.cancel}</McButton>
              <McButton
                bsStyle="primary"
                type="submit"
                isLoading={IsSubmitting}
              >
                {t.common.save}
              </McButton>
            </ButtonToolbar>
          </div>
        }
        closeClicked={() => close()}
      >
        <MyTextbox
          formObject={{} as IFormValues}
          label={t.location.insert.fields.Name.label}
          name="Name"
          useFormProps={useFormProps}
          rules={{
            required: t.location.insert.fields.Name.errors.required,
          }}
          tabIndex={1}
        />
        <MySelect
          formObject={{} as IFormValues}
          useFormProps={useFormProps}
          label={t.pos.insert.fields.PosTypeID.label}
          name="SelectedPosType"
          rules={{
            required: t.pos.insert.fields.PosTypeID.errors.required,
          }}
          options={PosTypes}
        />
      </McModal>
    </form>
  )
}

export default observer(EditPosModal)
