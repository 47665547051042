import { observer } from 'mobx-react'
import React, { useEffect, useReducer } from 'react'
import {
  InputMasks,
  MyCheckbox,
  MyCurrencyInput,
  MyDatePicker,
  MySelect,
  MyTextbox,
} from 'src/Components/My'
import { t } from 'src/I18n'

import { Col, Row } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { LoaderIcon, McButton } from 'src/Components'
import Portlet from 'src/Components/Authenticated/Portlet'
import { LoyaltyTypes, UserScheduleType } from 'src/Models/Server/Enums'
//import { IFormValues, userScheduleBonusFormStore } from './Store'
import { GetWeekDays } from 'src/Helpers/DateHelpers'
import {
  getCurrencyOptions,
  initialState,
  IUSerScheduleBonusFormValues,
  reducer,
  reducerActions,
  scheduleTypeOptions,
  typeOptions,
} from './Reducer'

interface IProps {
  title: string
  userScheduleBonusId?: string
  userGuid: string
}

const UserScheduleBonusForm: React.FC<IProps> = ({
  title,
  userScheduleBonusId,
  userGuid,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const actions = reducerActions(state, dispatch)

  useEffect(() => {
    actions.initialize(userGuid, userScheduleBonusId)
  }, [null])

  const { currencies, couponOptions, viewModel, isLoading, isSubmitting } =
    state

  const currencyOptions = getCurrencyOptions(currencies)

  useEffect(() => {
    reset(viewModel)
  }, [viewModel])

  const useFormProps: UseFormReturn<IUSerScheduleBonusFormValues> =
    useForm<IUSerScheduleBonusFormValues>()
  const { handleSubmit, reset, setValue, getValues, watch } = useFormProps
  const isEdit = userScheduleBonusId !== undefined

  const typeOption = watch('TypeOption')
  const currencyOption = watch('CurrencyOption')
  const scheduleTypeOption = watch('ScheduleTypeOption')

  const onSubmit = (data: IUSerScheduleBonusFormValues) => {
    actions.save(userGuid, userScheduleBonusId, data)
    // onSave(data)
  }

  const renderTypeSelect = () => {
    return (
      <MySelect
        formObject={viewModel}
        useFormProps={useFormProps}
        name="TypeOption"
        label={t.common.type}
        options={typeOptions}
        isDisabled={isEdit}

        // onSelectionChange={(option: ISelectOption) => {
        //   //setValue('AmountCents', 1)
        // }}
      />
    )
  }

  const renderCoupon = () => {
    if (
      typeOption === undefined ||
      typeOption.value !== LoyaltyTypes.Coupon.toString()
    ) {
      return null
    }

    return (
      <Row>
        <Col md={6}>{renderTypeSelect()}</Col>
        <Col md={6}>
          <MySelect
            formObject={viewModel}
            useFormProps={useFormProps}
            name="CouponOption"
            label={t.common.coupon}
            options={couponOptions}
            isDisabled={isEdit}
          />
        </Col>
        <Col md={6}>
          <MyTextbox
            formObject={viewModel}
            label={t.common.amount}
            name="AmountCents"
            inputMask={InputMasks.Number}
            maxLength={2}
            useFormProps={useFormProps}
            formGroupStyle={{ marginBottom: 5 }}
          />
        </Col>
      </Row>
    )
  }

  const renderCurrency = () => {
    if (
      typeOption === undefined ||
      typeOption.value !== LoyaltyTypes.Currency.toString()
    ) {
      return null
    }

    const currency = currencies.find(
      (x) => x.CurrencyId.toString() === currencyOption.value
    )

    return (
      <Row>
        <Col md={6}>{renderTypeSelect()}</Col>
        <Col md={6}>
          <MySelect
            formObject={viewModel}
            useFormProps={useFormProps}
            name="CurrencyOption"
            label={t.common.currency}
            options={currencyOptions}
            isDisabled={isEdit}
            // onSelectionChange={(option: ISelectOption) => {
            //   actions.setCurrency(option)
            // }}
          />
        </Col>
        <Col md={6}>
          <MyCurrencyInput
            formObject={viewModel}
            label={t.savingcardInsert.fields.RewardAmount.label}
            currency={currency}
            onChangeCents={(amountCents) => {
              setValue('AmountCents', amountCents)
            }}
            name="DisplayAmount"
            useFormProps={useFormProps}
            formGroupStyle={{ marginBottom: 5 }}
          />
        </Col>
      </Row>
    )
  }

  const renderDaysCheckboxes = () => {
    //weekly and monthy both start on day 1
    if (
      scheduleTypeOption !== undefined &&
      scheduleTypeOption.value === UserScheduleType.Daily.toString()
    ) {
      const translatedDays = GetWeekDays()
      const getWeekDay = (value: string) => {
        return translatedDays.find((x) => x.value === value)?.label
      }

      //show checkbox for each day
      return (
        <div className="inline-check">
          {translatedDays.map((day, key) => (
            <MyCheckbox
              key={key}
              formObject={viewModel}
              useFormProps={useFormProps}
              name={day.value as any}
              label={getWeekDay(day.value)}
            />
          ))}
        </div>
      )
    }
    return null
  }

  const renderContent = () => {
    if (isLoading || viewModel === undefined) {
      return <LoaderIcon IsLoading={isLoading} />
    }

    const replenishLabel =
      viewModel.TypeOption.value == LoyaltyTypes.Currency.toString()
        ? t.userScheduleBonus.insert.fields.ReplenishCurrency.label
        : t.userScheduleBonus.insert.fields.ReplenishCoupon.label

    const replenishTooltip =
      viewModel.TypeOption.value == LoyaltyTypes.Currency.toString()
        ? t.userScheduleBonus.insert.fields.ReplenishCurrency.tooltip
        : t.userScheduleBonus.insert.fields.ReplenishCoupon.tooltip

    return (
      <>
        {renderCoupon()}
        {renderCurrency()}
        <MyCheckbox
          formObject={viewModel}
          useFormProps={useFormProps}
          label={replenishLabel}
          name="Replenish"
          title={replenishTooltip}
        />
        <Row>
          <Col md={6}>
            <MySelect
              formObject={viewModel}
              useFormProps={useFormProps}
              name="ScheduleTypeOption"
              label={t.userScheduleBonus.insert.fields.ScheduleType.label}
              options={scheduleTypeOptions}
            />
          </Col>
          <Col md={6}>{renderDaysCheckboxes()}</Col>
        </Row>
        <Row>
          <Col md={6}>
            <MyDatePicker
              formObject={viewModel}
              useFormProps={useFormProps}
              name="StartDate"
              label={t.userScheduleBonus.insert.fields.StartDate.label}
              rules={{
                required: t.userScheduleBonus.insert.fields.StartDate.required,
              }}
            />
          </Col>
          <Col md={6}>
            <MyDatePicker
              formObject={viewModel}
              useFormProps={useFormProps}
              name="EndDate"
              label={t.userScheduleBonus.insert.fields.EndDate.label}
              rules={{
                validate: (endDate: Date) => {
                  if (endDate !== undefined && endDate !== null) {
                    const startDate = getValues('StartDate')
                    if (startDate > endDate) {
                      return t.userScheduleBonus.insert.fields.EndDate.errors
                        .greaterThan
                    }
                  }

                  return true
                },
              }}
            />
          </Col>
        </Row>
        <McButton
          block
          bsStyle="primary"
          bsSize="large"
          type="submit"
          isLoading={isSubmitting}
        >
          {t.common.save}
        </McButton>
      </>
    )
  }
  return (
    <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
      <Portlet title={title}>{renderContent()}</Portlet>
    </form>
  )
}

export default UserScheduleBonusForm
