import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { ButtonToolbar } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { McPageAlert } from 'src/Components'
import McButton from 'src/Components/McButton'
import McModal from 'src/Components/Modals/McModal'
import { MySelect, MyTextbox } from 'src/Components/My'
import { t } from 'src/I18n'
import ITerminalVTO from 'src/Models/Server/Response/Terminal/ITerminalVTO'
import { editTerminalStore, IEditTerminalView } from './Store'

interface IProps {
  onClose: () => void
  onSubmitSuccess: (editTerminal: ITerminalVTO) => void
  editTerminal: ITerminalVTO
}

interface IProps {}

const EditTerminalModal: React.FC<IProps> = ({
  editTerminal,
  onClose,
  onSubmitSuccess,
}) => {
  const { ViewModel, IsSubmitting, TerminalTypes, PageAlert } =
    editTerminalStore

  useEffect(() => editTerminalStore.Initialize(editTerminal), [null])

  useEffect(() => {
    reset(ViewModel)
  }, [ViewModel])

  const useFormProps: UseFormReturn<IEditTerminalView> =
    useForm<IEditTerminalView>({})
  const { handleSubmit, reset } = useFormProps

  const close = () => {
    if (!editTerminalStore.IsSubmitting) {
      onClose()
    }
  }

  const onSubmit = (data: IEditTerminalView) => {
    editTerminalStore.EditType(data).then(() => {
      editTerminal.TerminalTypeId = data.SelectedTerminalType.value
      editTerminal.Description = data.Description
      onSubmitSuccess(editTerminal)
      close()
    })
  }

  if (ViewModel === undefined) {
    return null
  }

  return (
    <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
      <McModal
        title={t.common.edit}
        footer={
          <div>
            <McPageAlert pageAlert={PageAlert} />
            <ButtonToolbar className="pull-right">
              <McButton onClick={() => close()}>{t.common.cancel}</McButton>
              <McButton
                bsStyle="primary"
                type="submit"
                isLoading={IsSubmitting}
              >
                {t.common.save}
              </McButton>
            </ButtonToolbar>
          </div>
        }
        closeClicked={() => close()}
      >
        <MyTextbox
          formObject={{} as IEditTerminalView}
          label={t.terminal.insert.fields.Description.label}
          name="Description"
          useFormProps={useFormProps}
          rules={{
            required: t.terminal.insert.fields.Description.errors.required,
          }}
          tabIndex={1}
        />
        <MySelect
          formObject={{} as IEditTerminalView}
          useFormProps={useFormProps}
          label={t.terminal.insert.fields.TerminalTypeID.label}
          name="SelectedTerminalType"
          rules={{
            required: t.terminal.insert.fields.TerminalTypeID.errors.required,
          }}
          options={TerminalTypes}
        />
      </McModal>
    </form>
  )
}

export default observer(EditTerminalModal)
