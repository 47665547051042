import { toast } from 'react-toastify'
import { ISelectOption } from 'src/Models/Client'
import { t } from 'src/I18n'
import IGenericError from 'src/Models/Server/IGenericError'
import { SelectOptionsFromNameGuidVTO } from 'src/Models/Server/INameGuidVTO'
import IInsertAutomaticChargeRequest from 'src/Models/Server/Request/AutomaticCharge/IInsertAutomaticChargeRequest'
import {
  IBankSelectOption,
  SelectOptionsFromBank,
} from 'src/Models/Server/Response/Bank/IBankDTO'
import IHelpDeskUserVTO from 'src/Models/Server/Response/User/IHelpDeskUserVTO'
import AutomaticChargeController from 'src/Services/ToprCoreApi/Axios/Controllers/AutomaticChargeController'
import BankController from 'src/Services/ToprCoreApi/Axios/Controllers/BankController'
import CurrencyController from 'src/Services/ToprCoreApi/Axios/Controllers/CurrencyController'
import UserController from 'src/Services/ToprCoreApi/Axios/Controllers/UserController'
import { action, makeObservable, observable, runInAction } from 'mobx'
import BaseStore from 'src/Stores/BaseStore'
import { appUiStore } from 'src/Stores/AppUiStore'

export interface IFormValues extends IInsertAutomaticChargeRequest {
  SelectedCurrency: ISelectOption
  SelectedBank: IBankSelectOption
  SepaFile: File
}

export default class Store extends BaseStore<IFormValues> {
  public User: IHelpDeskUserVTO = null

  public Currencies: ISelectOption[] = []

  public Banks: IBankSelectOption[] = []

  private Id: string = ''

  constructor() {
    super()

    makeObservable(this, {
      User: observable,
      Currencies: observable,
      Banks: observable,
      Initialize: action,
      GetBanks: action,
      GetHelpdeskUser: action,
      GetMerchantCurrencies: action,
      Insert: action,
    })
  }

  public Initialize(id: string, email: string) {
    this.Id = id
    this.BaseInitialize({
      AccountNumber: '',
      AccountHolder: '',
      Bic: '',
      SelectedBank: null,
      City: '',
      CurrencyGuid: '',
      SelectedCurrency: null,
      EmailAddress: email === null ? '' : email,
      LastName: '',
      PhoneNumber: '',
      SepaFileData: '',
      StepAmount: 10,
      StepLimit: 1,
      StreetName: '',
      StreetNumber: '',
      UserGuid: id,
      Zipcode: '',
      MimeType: '',
      SepaFile: null,
    })
    //test
    // this.BaseInitialize({
    //   AccountNumber: 'NL76INGB0003346704',
    //   AccountHolder: 'de Ruitjer',
    //   Bic: '123',
    //   SelectedBank: null,
    //   City: 'sd',
    //   CurrencyGuid: 'guid',
    //   SelectedCurrency: null,
    //   EmailAddress: email === null ? '' : email,
    //   LastName: 'last',
    //   PhoneNumber: '456',
    //   StepAmount: 10,
    //   StepLimit: 1,
    //   StreetName: 'street',
    //   StreetNumber: 'str numb',
    //   UserGuid: id,
    //   Zipcode: '1212sd',
    //   MimeType: '',
    //   SepaFileData: '',
    //   SepaFile: null,
    // })

    this.GetHelpdeskUser(id)
    this.GetMerchantCurrencies(id)
    this.GetBanks()
  }

  public GetMerchantCurrencies(id: string) {
    this.IsLoading = true
    CurrencyController.GetUserCurrencies(id)
      .then((currencies) => {
        runInAction(() => {
          this.Currencies = SelectOptionsFromNameGuidVTO(currencies)
          this.IsLoading = false

          this.ViewModel = {
            ...this.ViewModel,
            SelectedCurrency: this.Currencies[0],
          }
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        runInAction(() => (this.IsLoading = false))
      })
  }

  public GetBanks() {
    this.IsLoading = true
    BankController.GetAllBanks()
      .then((banks) => {
        runInAction(() => {
          this.Banks = SelectOptionsFromBank(banks)
          this.ViewModel = {
            ...this.ViewModel,
            SelectedBank: this.Banks[0],
          }
          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        runInAction(() => (this.IsLoading = false))
      })
  }

  public GetHelpdeskUser(id: string) {
    this.IsLoading = true
    UserController.GetHelpdeskUser(id)
      .then((user) => {
        runInAction(() => {
          this.IsLoading = false
          this.User = user
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        runInAction(() => (this.IsLoading = false))
      })
  }

  public Insert(data: IFormValues) {
    this.IsSubmitting = true
    data.CurrencyGuid = data.SelectedCurrency.value
    data.Bic = data.SelectedBank.value

    AutomaticChargeController.Insert(data)
      .then(() => {
        runInAction(() => (this.IsSubmitting = false))
        window.history.back()
        toast.success(
          t.formatString(t.automaticChargeInsert.success, {
            currencyName: this.ViewModel.SelectedCurrency.label,
          }),
          { type: toast.TYPE.SUCCESS }
        )
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsSubmitting = false))
        appUiStore.ShowError(error)
      })
  }
}

export const automaticChargeInsertStore = new Store()
