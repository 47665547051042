import { AxiosError, AxiosResponse } from 'axios'
import { handleException } from 'src/Helpers/ResponseHelpers'
import INameIdVTO from 'src/Models/Server/INameIdVTO'
import { IExceptionError } from 'src/Models/Server/Response/Exception/ExceptionError'
import ISamVTO from 'src/Models/Server/Response/Sam/ISamVTO'
import { axiosAuth } from '../AuthAxios'

export default {
  GetSamTypes() {
    return new Promise<Array<INameIdVTO<number>>>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/sam/types`)
          .then((response: AxiosResponse<Array<INameIdVTO<number>>>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },

  Insert(terminalId: string) {
    return new Promise<number>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/sam/insert`, { TerminalId: terminalId })
          .then((response: AxiosResponse<number>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  Edit(request: ISamVTO) {
    return new Promise<ISamVTO>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/sam/edit`, request)
          .then((response: AxiosResponse<ISamVTO>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  Link(samId: string, terminalId: string) {
    return new Promise<ISamVTO>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/sam/link/${samId}/${terminalId}`, null)
          .then((response: AxiosResponse<ISamVTO>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  Unlink(samId: number) {
    return new Promise<ISamVTO>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/sam/unlink/${samId}`, null)
          .then((response: AxiosResponse<ISamVTO>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  Block(samId: number) {
    return new Promise<ISamVTO>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/sam/block/${samId}`, null)
          .then((response: AxiosResponse<ISamVTO>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  Unblock(samId: number) {
    return new Promise<ISamVTO>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/sam/unblock/${samId}`, null)
          .then((response: AxiosResponse<ISamVTO>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  GetSam(id: string) {
    return new Promise<ISamVTO>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(encodeURI(`/api/v1/sam/${id}`))
          .then((response: AxiosResponse<ISamVTO>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  GetSamsByTerminal(id: string) {
    return new Promise<ISamVTO[]>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/sams/terminal/${id}`)
          .then((response: AxiosResponse<ISamVTO[]>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
}
