import React from 'react'
import { Badge } from 'react-bootstrap'
import { GetUserCouponStatus } from 'src/Helpers/DateHelpers'
import { t } from 'src/I18n'
import { UserCouponStatus } from 'src/Models/Server/Enums'
interface IProps {
  validFrom: string | null
  validTill: string | null
  redeemed: string | null
}

const UserCouponStatusBadge: React.FC<IProps> = ({
  validFrom,
  validTill,
  redeemed,
}) => {
  const status = GetUserCouponStatus(validFrom, validTill, redeemed)

  switch (status) {
    case UserCouponStatus.Active:
      return (
        <Badge className="badge-primary">
          {t.enums.savingCardStatusDTO.Active}
        </Badge>
      )
    case UserCouponStatus.NotYetActive:
      return (
        <Badge className="badge-secondary">
          {t.enums.savingCardStatusDTO.NotYetActive}
        </Badge>
      )
    case UserCouponStatus.Expired:
      return (
        <Badge className="badge-secondary">
          {t.enums.savingCardStatusDTO.Expired}
        </Badge>
      )
    case UserCouponStatus.Redeemed:
      return <Badge className="badge-secondary">{t.common.redeemed}</Badge>
  }
}

export default UserCouponStatusBadge
