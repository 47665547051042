import React, { PropsWithChildren } from 'react'
import { Sizes } from 'react-bootstrap'
import { Link } from 'react-router-dom'

interface IProps {
  to: string
  style?: 'buttonPrimary' | 'buttonDefault' | 'link'
  buttonSize?: Sizes
  icon?: string
}

const McLink: React.FC<PropsWithChildren<IProps>> = ({
  children,
  to,
  style,
  buttonSize,
  icon,
}) => {
  const getStyleClassName = () => {
    switch (style) {
      case 'buttonPrimary':
        return 'btn btn-primary'
      case 'buttonDefault':
        return 'btn btn-default'
      default:
        return ''
    }
  }

  const getSizeClassName = () => {
    switch (buttonSize) {
      case 'large':
      case 'lg':
        return 'btn-lg'
      case 'small':
      case 'sm':
        return 'btn-sm'
      case 'xs':
      case 'xsmall':
        return 'btn-xs'
      default:
        return ''
    }
  }

  const renderIcon = () => {
    if (icon === undefined) {
      return null
    }

    return <i className={`fa ${icon}`} />
  }

  const className = `${getStyleClassName()} ${getSizeClassName()}`
  return (
    <>
      <Link to={to} className={className}>
        {children} {renderIcon()}
      </Link>
    </>
  )
}

export default McLink

McLink.defaultProps = {
  style: 'link',
}
