import React, { useEffect, useReducer } from 'react'
import { ButtonToolbar, Col, Row } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import McButton from 'src/Components/McButton'
import McModal from 'src/Components/Modals/McModal'
import { MyDatePicker, MyTextbox } from 'src/Components/My'
import { t } from 'src/I18n'
import { LoyaltyRuleTypes } from 'src/Models/Server/Enums'
import { ILoyaltyProgramDetailsVTO } from 'src/Models/Server/Response/LoyaltyProgram/ILoyaltyProgramDetailsVTO'
import { loyaltyProgramDetailsStore } from '../Store'
import { IFormValues, initialState, reducer, reducerActions } from './Reducer'

interface IProps {
  editLoyaltyProgram: ILoyaltyProgramDetailsVTO
  onClose: () => void
}

const EditLoyaltyProgramModal: React.FC<IProps> = ({
  editLoyaltyProgram,
  onClose,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const actions = reducerActions(state, dispatch)
  const { viewModel, isSubmitting } = state

  useEffect(() => {
    if (editLoyaltyProgram !== undefined) {
      actions.initialize(editLoyaltyProgram)
    }
  }, [editLoyaltyProgram])

  useEffect(() => {
    reset(viewModel)
  }, [viewModel])

  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({})
  const { handleSubmit, reset, getValues } = useFormProps

  const onSubmit = (data: IFormValues) => {
    actions.save(editLoyaltyProgram, data, () => {
      onClose()
      loyaltyProgramDetailsStore.Initialize(
        editLoyaltyProgram.LoyaltyProgramId.toString()
      )
    })
  }

  if (editLoyaltyProgram === undefined) {
    return null
  }

  const renderContent = () => {
    return (
      <>
        <Row>
          <Col md={6}>
            <MyDatePicker
              formObject={viewModel}
              useFormProps={useFormProps}
              name="StartDate"
              label={t.userScheduleBonus.insert.fields.StartDate.label}
              rules={{
                required: t.userScheduleBonus.insert.fields.StartDate.required,
              }}
            />
          </Col>
          <Col md={6}>
            <MyDatePicker
              formObject={viewModel}
              useFormProps={useFormProps}
              name="EndDate"
              label={t.userScheduleBonus.insert.fields.EndDate.label}
              rules={{
                validate: (endDate: Date) => {
                  if (endDate !== undefined && endDate !== null) {
                    const startDate = getValues('StartDate')
                    if (startDate > endDate) {
                      return t.userScheduleBonus.insert.fields.EndDate.errors
                        .greaterThan
                    }
                  }

                  return true
                },
              }}
            />
          </Col>
        </Row>
      </>
    )
  }

  return (
    <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
      <McModal
        closeClicked={onClose}
        closeOnBackdrop={false}
        title={t.common.edit}
        footer={
          <ButtonToolbar className="pull-right">
            <McButton onClick={onClose}>{t.common.cancel}</McButton>
            <McButton bsStyle="primary" type="submit" isLoading={isSubmitting}>
              {t.common.edit}
            </McButton>
          </ButtonToolbar>
        }
      >
        {renderContent()}
      </McModal>
    </form>
  )
}

export default EditLoyaltyProgramModal
