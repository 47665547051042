import React from 'react'
import CircleStat from './CircleStat'
interface ICircleStatProps {
  stat: number | string
  text: string
  icon?: string
  iconText?: string
}

const CircleStatRow: React.FC<ICircleStatProps> = ({
  stat,
  text,
  icon,
  iconText,
}) => {
  return (
    <div className="circle-stat-row">
      <CircleStat stat={stat} icon={icon} iconText={iconText} />
      <span>{text}</span>
    </div>
  )
}

export default CircleStatRow
