import React, { CSSProperties, useEffect } from 'react'
import { observer } from 'mobx-react'
import { MainPage, MyTextbox } from 'src/Components/My'
import { IFormValues, Store } from './Store'
import { useForm, UseFormReturn } from 'react-hook-form'
import { McButton } from 'src/Components'

export const store = new Store()

const TestSignalR: React.FC = () => {
  useEffect(() => {
    // events((merchantId, message) =>
    //   console.log('ReceiveMerchantMessage', message)
    // )

    store.Initialize()
  }, [null])

  const { Logs } = store

  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({
    defaultValues: {
      message: '',
    },
  })

  const { handleSubmit, reset } = useFormProps

  const onSubmit = (data: IFormValues) => {
    store.SendMessage(data.message)
    reset()
  }

  const renderLogs = () => {
    return Logs.map((log, key) => {
      return (
        <div
          key={key}
          style={{
            color: log.received ? 'green' : '#000',
          }}
        >{`[${log.date}][${log.source}] - ${log.message}`}</div>
      )
    })
  }

  return (
    <>
      <MainPage>
        <div style={logContainer}>{renderLogs()}</div>
        <form
          className="parsley-form  account-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <hr />
          <MyTextbox
            formObject={{} as IFormValues}
            label="Message"
            name="message"
            useFormProps={useFormProps}
          />

          <McButton
            block
            bsStyle="primary"
            bsSize="large"
            type="submit"
            tabIndex={2}
          >
            Stuur bericht
          </McButton>
        </form>
      </MainPage>
    </>
  )
}

const logContainer: CSSProperties = {
  height: 200,
  overflow: 'scroll',
  border: 'solid 1px #000',
  backgroundColor: '#fff',
}

export default observer(TestSignalR)
