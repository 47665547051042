import React, { PropsWithChildren } from 'react'
interface ILoaderIconProps {
  IsLoading: boolean
  ShowLoader?: boolean
}

const LoadContent: React.FC<PropsWithChildren<ILoaderIconProps>> = ({
  IsLoading,
  ShowLoader,
  children,
}) => {
  if (IsLoading && ShowLoader) {
    return (
      <div className="center-xy min-y-50">
        <i className="fa fa-sync fa-spin" />
      </div>
    )
  }

  if (!IsLoading) {
    return <>{children}</>
  }

  return null
}

export default LoadContent

LoadContent.defaultProps = {
  ShowLoader: true,
}
