import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'

import { useForm, UseFormReturn } from 'react-hook-form'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import LoaderIcon from 'src/Components/LoaderIcon'
import McButton from 'src/Components/McButton'
//import MerchantPaymentDetails from 'Details/MerchantPaymentDetails'
import { MainPage, MyDataTable, MySelect } from 'src/Components/My'
import { ISelectOption } from 'src/Models/Client'
import { formatCentsToEuro } from 'src/Helpers/CurrencyHelpers'
import { FormatDateTimeNL } from 'src/Helpers/DateHelpers'
import { sortDate } from 'src/Helpers/TableHelpers'
import { enumToText } from 'src/Helpers/TextHelpers'
import { t } from 'src/I18n'
import { AutomaticChargeCollectionStatusTypesDTO } from 'src/Models/Server/Enums'
import { IAutomaticChargeCollectionVTO } from 'src/Models/Server/Response/AutomaticCharge/IAutomaticChargeCollectionVTO'
import { IFormValues, stornoStatusStore } from './Store'

const StornoStatus: React.FC = () => {
  useEffect(() => {
    stornoStatusStore.Initialize()
  }, [null])

  const { ViewModel, IsLoading, IsAdmin, Stornos, IsSubmitting } =
    stornoStatusStore

  useEffect(() => {
    reset(ViewModel)
  }, [ViewModel])

  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({})
  const { handleSubmit, reset, getValues, setValue } = useFormProps

  const data = getValues()
  const renderMailDataButton = () => {
    if (
      IsAdmin &&
      data !== undefined &&
      data.SelectedStatus &&
      (data.SelectedStatus.value ===
        AutomaticChargeCollectionStatusTypesDTO.CanceledMailMerged.toString() ||
        data.SelectedStatus.value ===
          AutomaticChargeCollectionStatusTypesDTO.ReadyForBlock.toString())
    ) {
      return (
        <McButton
          block
          bsStyle="primary"
          type="button"
          icon="fa-envelope"
          isLoading={IsSubmitting}
          onClick={() => {
            stornoStatusStore.GetMailData(data)
          }}
        >
          Genereer Mail data
        </McButton>
      )
    }

    return null
  }

  const renderSendLetterButton = () => {
    if (
      IsAdmin &&
      data !== undefined &&
      data.SelectedStatus &&
      data.SelectedStatus.value ===
        AutomaticChargeCollectionStatusTypesDTO.Canceled.toString()
    ) {
      return (
        <McButton
          block
          bsStyle="primary"
          type="button"
          icon="fa-envelope"
          isLoading={IsSubmitting}
          onClick={() => {
            stornoStatusStore.SendStornoLetter(data)
          }}
        >
          Stuur brief
        </McButton>
      )
    }

    return null
  }

  const renderContent = () => {
    if (IsLoading || ViewModel === undefined) {
      return <LoaderIcon IsLoading={IsLoading} />
    }

    return (
      <form className="parsley-form">
        <Row>
          <Col md={6}>
            <MySelect
              formObject={{} as IFormValues}
              useFormProps={useFormProps}
              name="SelectedStatus"
              label={t.common.status}
              options={ViewModel.Statuses}
              onSelectionChange={(options: ISelectOption) => {
                setValue('SelectedStatus', options)
                handleSubmit((data) =>
                  stornoStatusStore.GetStornosByCurrency(data)
                )()
              }}
            />
          </Col>

          <Col md={6}>
            <MySelect
              formObject={{} as IFormValues}
              useFormProps={useFormProps}
              name="SelectedCurrency"
              label={t.common.currency}
              options={ViewModel.Currencies}
              onSelectionChange={(options: ISelectOption) => {
                setValue('SelectedCurrency', options)
                handleSubmit((data) =>
                  stornoStatusStore.GetStornosByCurrency(data)
                )()
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6} mdOffset={6}>
            {renderSendLetterButton()}
            {renderMailDataButton()}
          </Col>
        </Row>
        <hr />
        {renderStornoGrid()}
      </form>
    )
  }

  const renderStornoGrid = () => {
    const dateTimeFormatter = (
      cell,
      data: IAutomaticChargeCollectionVTO,
      key: string
    ) => {
      return FormatDateTimeNL(data[key])
    }

    const renderBlockButton = (data: IAutomaticChargeCollectionVTO) => {
      if (
        IsAdmin &&
        data.AutomaticChargeCollectionStatusId ===
          AutomaticChargeCollectionStatusTypesDTO.ReadyForBlock
      ) {
        return (
          <McButton
            block
            bsSize="small"
            bsStyle="danger"
            type="button"
            icon="fa-ban"
            onClick={() => {
              stornoStatusStore.BlockStorno(data.AutomaticChargeCollectionId)
            }}
          >
            Blokkeren
          </McButton>
        )
      }
      return null
    }

    const renderReadyForBlockButton = (data: IAutomaticChargeCollectionVTO) => {
      if (
        IsAdmin &&
        data.AutomaticChargeCollectionStatusId ===
          AutomaticChargeCollectionStatusTypesDTO.CanceledMailMerged
      ) {
        return (
          <McButton
            block
            bsSize="small"
            bsStyle="warning"
            type="button"
            onClick={() => {
              stornoStatusStore.ReadyForBlock(data.AutomaticChargeCollectionId)
            }}
          >
            Zet gereed voor blokkeren
          </McButton>
        )
      }
      return null
    }

    const renderUnblockButton = (data: IAutomaticChargeCollectionVTO) => {
      if (IsAdmin) {
        const buttonText =
          data.AutomaticChargeCollectionStatusId ===
          AutomaticChargeCollectionStatusTypesDTO.Blocked
            ? 'De-blokkeren'
            : 'Zet betaald'

        return (
          <McButton
            block
            bsSize="small"
            bsStyle="success"
            type="button"
            onClick={() => {
              stornoStatusStore.UnblockStorno(data.AutomaticChargeCollectionId)
            }}
          >
            {buttonText}
          </McButton>
        )
      }
      return null
    }

    const columns = [
      {
        dataField: 'AutomaticChargeCollectionStatusId',
        formatter: (cell, data: IAutomaticChargeCollectionVTO) => {
          return (
            <>
              {enumToText(
                data.AutomaticChargeCollectionStatusId,
                AutomaticChargeCollectionStatusTypesDTO,
                t.stornoStatus.status
              )}
              {renderBlockButton(data)}
              {renderUnblockButton(data)}
              {renderReadyForBlockButton(data)}
            </>
          )
        },
        text: 'Status',
        sort: true,
      },
      {
        dataField: 'Amount',
        text: 'Bedrag',
        formatter: (cell, data: IAutomaticChargeCollectionVTO) => {
          return formatCentsToEuro(data.Amount)
        },
        sort: true,
      },
      {
        dataField: 'AccountHolder',
        text: 'Rekeninghouder',
        // filter: textFilter({
        //   placeholder: 'Zoek',
        // }),
        sort: true,
      },

      {
        dataField: 'AccountNumber',
        text: 'Rekeningnummer',
        // filter: textFilter({
        //   placeholder: 'Zoek',
        // }),
        sort: true,
      },
      {
        dataField: 'Description',
        text: 'Omschrijving',
        // filter: textFilter({
        //   placeholder: 'Zoek',
        // }),
        sort: true,
      },
      {
        dataField: 'CardNumber',
        text: 'Kaartnummer',
        // filter: textFilter({
        //   placeholder: 'Zoek',
        // }),
        sort: true,
      },

      // {
      //   dataField: 'IssueDate',
      //   text: 'Uitgevoerd',
      //   formatter: (cell, data: IAutomaticChargeCollectionVTO) => {
      //     return dateTimeFormatter(cell, data, 'IssueDate')
      //   },
      //   csvFormatter: (cell, data: IAutomaticChargeCollectionVTO) => {
      //     return dateTimeFormatter(cell, data, 'IssueDate')
      //   },
      //   sort: true,
      //   sortFunc: ( order, dataField, rowA, rowB) => {
      //     return sortDate(order, rowA, rowB, 'IssueDate')
      //   }
      // },
      {
        dataField: 'Modified',
        text: t.common.modified,
        formatter: (cell, data: IAutomaticChargeCollectionVTO) => {
          return dateTimeFormatter(cell, data, 'Modified')
        },
        csvFormatter: (cell, data: IAutomaticChargeCollectionVTO) => {
          return dateTimeFormatter(cell, data, 'Modified')
        },
        sort: true,
        sortFunc: (order, dataField, rowA, rowB) => {
          return sortDate(order, rowA, rowB, 'Modified')
        },
      },
    ]

    return <MyDataTable data={Stornos} columns={columns} />
  }

  return (
    <MainPage>
      <BreadCrumbs currentPageTitle={t.stornoStatus.title} />
      <Portlet title={t.stornoStatus.title}>{renderContent()}</Portlet>
    </MainPage>
  )
}

export default observer(StornoStatus)
