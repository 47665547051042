import { action, makeObservable, observable, runInAction } from 'mobx'
import IGenericError from 'src/Models/Server/IGenericError'
import { ICouponTotalsForUserVTO } from 'src/Models/Server/Response/Coupon/ICouponTotalsForUserVTO'
import { IUserCouponVTO } from 'src/Models/Server/Response/Coupon/IUserCouponVTO'
import CouponController from 'src/Services/ToprCoreApi/Axios/Controllers/CouponController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export interface IFormValues {}

export class Store extends BaseStore<IFormValues> {
  public UserCoupons: IUserCouponVTO[] = []
  public CouponTotalsUser: ICouponTotalsForUserVTO[] = []
  private UserGuid: string
  public DeletingUserCouponId: number = -1

  constructor() {
    super()
    makeObservable(this, {
      UserCoupons: observable,
      CouponTotalsUser: observable,
      DeletingUserCouponId: observable,
      Initialize: action,
      DeletUserCoupon: action,
    })
  }

  public Initialize(userGuid: string) {
    this.UserCoupons = []
    this.CouponTotalsUser = []
    this.IsLoading = true
    this.UserGuid = userGuid

    Promise.all([
      CouponController.GetUserCoupons(userGuid),
      CouponController.GetCouponTotalsForUser(userGuid),
    ])
      .then((values) => {
        runInAction(() => {
          this.UserCoupons = values[0]
          this.CouponTotalsUser = values[1]
          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        runInAction(() => {
          this.IsLoading = false
        })
      })
  }

  public DeletUserCoupon(userCouponId: number) {
    this.DeletingUserCouponId = userCouponId
    CouponController.DeleteUserCoupon(userCouponId)
      .then(() => {
        this.Initialize(this.UserGuid)
        runInAction(() => {
          this.DeletingUserCouponId = -1
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        runInAction(() => {
          this.DeletingUserCouponId = -1
        })
      })
  }
}

export const userLoyaltyStore = new Store()
