import React, { useEffect, useReducer } from 'react'
import { ButtonToolbar, Col, Row } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { LoaderIcon } from 'src/Components'
import McButton from 'src/Components/McButton'
import McModal from 'src/Components/Modals/McModal'
import {
  InputMasks,
  MyCurrencyInput,
  MySelect,
  MyTextbox,
} from 'src/Components/My'
import { getCurrencyOptions } from 'src/Helpers/SelectHelpers'
import { t } from 'src/I18n'
import { ISelectOption } from 'src/Models/Client'
import { LoyaltyRewardTypes, LoyaltyRuleTypes } from 'src/Models/Server/Enums'
import { loyaltyProgramDetailsStore } from '../Store'
import {
  IEditLoyaltyReward,
  IFormValues,
  initialState,
  reducer,
  reducerActions,
  rewardTypeOptions,
} from './Reducer'

interface IProps {
  editLoyaltyReward?: IEditLoyaltyReward
  onClose: () => void
}

const EditRewardModal: React.FC<IProps> = ({ editLoyaltyReward, onClose }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const actions = reducerActions(state, dispatch)
  const { currencies, couponOptions, viewModel, isSubmitting, isLoading } =
    state

  const currencyOptions = getCurrencyOptions(currencies)

  useEffect(() => {
    if (editLoyaltyReward !== undefined) {
      actions.initialize(editLoyaltyReward)
    }
  }, [editLoyaltyReward])

  useEffect(() => {
    reset(viewModel)
  }, [viewModel])

  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({})
  const { handleSubmit, reset, setValue, getValues, watch } = useFormProps

  const onSubmit = (data: IFormValues) => {
    actions.save(editLoyaltyReward, data, () => {
      onClose()
      loyaltyProgramDetailsStore.Initialize(
        editLoyaltyReward.loyaltyProgramId.toString()
      )
    })
  }

  const rewardTypeOption = watch('RewardTypeOption')
  const rewardCurrencyOption = watch('RewardCurrencyOption')

  if (editLoyaltyReward === undefined) {
    return null
  }

  const renderRewardCoupon = () => {
    if (
      rewardTypeOption === undefined ||
      rewardTypeOption.value !== LoyaltyRewardTypes.CouponReward.toString()
    ) {
      return null
    }

    return (
      <>
        <Col md={6}>
          <MySelect
            formObject={viewModel}
            useFormProps={useFormProps}
            name="RewardCouponOption"
            label={t.common.coupon}
            options={couponOptions}
          />
        </Col>
        <Col md={6}>
          <MyTextbox
            formObject={viewModel}
            label={t.common.amountTypes.default.title}
            rules={{
              required: t.common.amountTypes.default.required,
            }}
            name="AmountCents"
            inputMask={InputMasks.Number}
            maxLength={2}
            useFormProps={useFormProps}
            formGroupStyle={{ marginBottom: 5 }}
          />
        </Col>
      </>
    )
  }

  const renderRewardCurrency = () => {
    if (
      rewardCurrencyOption === undefined ||
      rewardTypeOption === undefined ||
      (rewardTypeOption.value !==
        LoyaltyRewardTypes.CurrencyStaticReward.toString() &&
        rewardTypeOption.value !==
          LoyaltyRewardTypes.CurrencyOrderPercentageReward.toString())
    ) {
      return null
    }

    const currency = currencies.find(
      (x) => x.CurrencyId.toString() === rewardCurrencyOption.value
    )

    const renderCurrencyOrPercentage = () => {
      if (
        rewardTypeOption.value ===
        LoyaltyRewardTypes.CurrencyStaticReward.toString()
      ) {
        return (
          <MyCurrencyInput
            formObject={viewModel}
            label={t.common.amountTypes.currency.title}
            currency={currency}
            onChangeCents={(amountCents) => {
              setValue('AmountCents', amountCents)
            }}
            name="DisplayAmount"
            useFormProps={useFormProps}
          />
        )
      }

      if (
        rewardTypeOption.value ===
        LoyaltyRewardTypes.CurrencyOrderPercentageReward.toString()
      ) {
        return (
          <MyTextbox
            formObject={viewModel}
            label={t.common.amountTypes.percentage.title}
            icon="fa-percent"
            rules={{
              required: t.common.amountTypes.percentage.required,
            }}
            name="AmountCents"
            inputMask={InputMasks.Number}
            maxLength={3}
            useFormProps={useFormProps}
          />
        )
      }

      return null
    }

    return (
      <>
        <Col md={6}>
          <MySelect
            formObject={viewModel}
            useFormProps={useFormProps}
            name="RewardCurrencyOption"
            label={t.common.currency}
            options={currencyOptions}
          />
        </Col>
        <Col md={6}>{renderCurrencyOrPercentage()}</Col>
      </>
    )
  }

  const renderContent = () => {
    if (isLoading || viewModel === undefined) {
      return <LoaderIcon IsLoading={isLoading} />
    }

    return (
      <>
        <Row>
          <Col md={6}>
            <MySelect
              formObject={viewModel}
              useFormProps={useFormProps}
              name="RewardTypeOption"
              label={t.common.type}
              options={rewardTypeOptions}
              onSelectionChange={(option: ISelectOption) => {
                switch (option.value) {
                  case LoyaltyRewardTypes.CurrencyOrderPercentageReward.toString():
                    setValue('AmountCents', 1)
                    break
                  case LoyaltyRewardTypes.CouponReward.toString():
                    setValue('AmountCents', 1)
                    break
                  case LoyaltyRewardTypes.CurrencyStaticReward.toString():
                    {
                      const currency = currencies.find(
                        (x) =>
                          x.CurrencyId.toString() === rewardCurrencyOption.value
                      )

                      setValue('AmountCents', currency.IsDecimal ? 100 : 1)
                      setValue('DisplayAmount', '1')
                    }
                    break
                }
              }}
            />
          </Col>
        </Row>
        <Row>
          {renderRewardCoupon()}
          {renderRewardCurrency()}
        </Row>
      </>
    )
  }

  return (
    <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
      <McModal
        closeClicked={onClose}
        closeOnBackdrop={false}
        title={t.common.edit}
        footer={
          <ButtonToolbar className="pull-right">
            <McButton onClick={onClose}>{t.common.cancel}</McButton>
            <McButton bsStyle="primary" type="submit" isLoading={isSubmitting}>
              {t.common.edit}
            </McButton>
          </ButtonToolbar>
        }
      >
        {renderContent()}
      </McModal>
    </form>
  )
}

export default EditRewardModal
