import React from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { GetDateText } from 'src/Helpers/DateHelpers'
import { GetImageFileUrl } from 'src/Images'
import ISavingCardDetailsDTO from 'src/Models/Server/Response/SavingCard/ISavingCardDetailsDTO'
import { t } from '../../I18n'
import LoyaltyStatusBadge from './LoyaltyStatusBadge'

interface ISavingCardDetailsReport {
  savingcardDetails: ISavingCardDetailsDTO
  //onClick: () => void
}

const SavingCardDetailsReport: React.FC<ISavingCardDetailsReport> = ({
  savingcardDetails,
  //onClick
}) => {
  return (
    <div className="portlet savingcard details portlet-boxed">
      <div className="portlet-header">
        <h4 className="portlet-title">{savingcardDetails.SavingCard.Title}</h4>
        <h5 className="portlet-title">
          {GetDateText(
            savingcardDetails.SavingCard.ValidFrom,
            savingcardDetails.SavingCard.ValidTill
          )}
          <LoyaltyStatusBadge status={savingcardDetails.SavingCard.Status} />
        </h5>
      </div>
      <div
        className="portlet-body"
        style={{
          backgroundImage: `url(
            ${GetImageFileUrl(savingcardDetails.SavingCard.ImageFileGuid)}
          )`,
        }}
      >
        <div className="portlet-footer">
          {savingcardDetails.PercentsPerStamp.map((percent, index) => {
            const stamp = index + 1
            return (
              <OverlayTrigger
                trigger="click"
                placement="top"
                rootClose
                key={index}
                overlay={
                  <Popover
                    id="totalCardsPopover"
                    title={t.savingcardDetails.percentTitle}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t
                          .formatString(
                            t.savingcardDetails.percentText,
                            percent,
                            stamp
                          )
                          .toString(),
                      }}
                    />

                    {/* {t.savingcard.totalCards.start}
                    <strong>{savingcardDetails.TotalSavingCards}</strong>
                    {t.savingcard.totalCards.end} */}
                  </Popover>
                }
              >
                <div
                  className="circle-stat"
                  onClick={(e) => e.stopPropagation()}
                >
                  <h4>{percent}</h4>
                  <span>%</span>
                </div>
              </OverlayTrigger>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default SavingCardDetailsReport
