import React from 'react'
import { Badge } from 'react-bootstrap'
import { t } from 'src/I18n'
import { LoyaltyStatusDTO } from 'src/Models/Server/Enums'
interface ILoyaltyStatusBadgeProps {
  status: LoyaltyStatusDTO
}

const LoyaltyStatusBadge: React.FC<ILoyaltyStatusBadgeProps> = ({ status }) => {
  switch (status) {
    case LoyaltyStatusDTO.Active:
      return (
        <Badge className="badge-primary">
          {t.enums.savingCardStatusDTO.Active}
        </Badge>
      )
    case LoyaltyStatusDTO.NotYetActive:
      return (
        <Badge className="badge-default">
          {t.enums.savingCardStatusDTO.NotYetActive}
        </Badge>
      )
    case LoyaltyStatusDTO.Expired:
      return (
        <Badge className="badge-warning">
          {t.enums.savingCardStatusDTO.Expired}
        </Badge>
      )
    case LoyaltyStatusDTO.Deactivated:
      return (
        <Badge className="badge-danger">
          {t.enums.savingCardStatusDTO.Deactivated}
        </Badge>
      )

    default:
      return null
  }
}

export default LoyaltyStatusBadge
