import { action, makeObservable, observable, runInAction } from 'mobx'
import { ISelectOption } from 'src/Models/Client'
import IGenericError from 'src/Models/Server/IGenericError'
import { SelectOptionsFromNameGuidVTO } from 'src/Models/Server/INameGuidVTO'
import CurrencyVTO from 'src/Models/Server/Response/Currency/CurrencyVTO'
import IWalletVTO from 'src/Models/Server/Response/Wallet/IWalletVTO'
import { ITransactionExtendedSTO } from 'src/Models/Server/Response/Transaction/ITransactionExtendedSTO'
import CurrencyController from 'src/Services/ToprCoreApi/Axios/Controllers/CurrencyController'
import TransactionController from 'src/Services/ToprCoreApi/Axios/Controllers/TransactionController'
import WalletController from 'src/Services/ToprCoreApi/Axios/Controllers/WalletController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'
import { ITransactionsFormValues } from './TransactionsPortlet/TransactionsPortlet'

export interface IFormValues {}

export class Store extends BaseStore<IFormValues> {
  public Wallets: IWalletVTO[] = []
  public MerchantCurrencies: CurrencyVTO[] = []
  public UserCurrencies: ISelectOption[] = []
  public Transactions: ITransactionExtendedSTO[] = []
  public UserGuid = ''

  constructor() {
    super()
    makeObservable(this, {
      Wallets: observable,
      MerchantCurrencies: observable,
      UserCurrencies: observable,
      Transactions: observable,
      UserGuid: observable,
      Initialize: action,
      GetTransactions: action,
    })
  }

  public Initialize(userGuid: string) {
    this.UserGuid = userGuid
    this.IsLoading = true
    Promise.all([
      WalletController.GetWalletsUser(this.UserGuid),
      CurrencyController.GetMyMerchantCurrencies(),
      CurrencyController.GetUserCurrencies(this.UserGuid),
    ])
      .then((values) => {
        runInAction(() => {
          this.Wallets = values[0]
          this.MerchantCurrencies = values[1]
          this.UserCurrencies = SelectOptionsFromNameGuidVTO(values[2])
          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        runInAction(() => {
          this.IsLoading = false
        })
      })
  }

  public GetTransactions(data: ITransactionsFormValues) {
    if (data.SelectedCurrencies.length > 0) {
      const currencyGuids = data.SelectedCurrencies.map((x) => x.value)
      this.Transactions = []

      //console.log('GetTransactions', this.UserGuid, currencyGuids.length)

      TransactionController.GetTransactionsForCurrencies(
        this.UserGuid,
        currencyGuids,
        data.StartDate,
        data.EndDate
      )
        .then((transactions) => {
          runInAction(() => {
            this.Transactions = transactions
          })
        })
        .catch((error: IGenericError) => {
          appUiStore.ShowError(error)
        })
    }
  }
}

export const userWalletsStore = new Store()
