import { action, makeObservable, observable, runInAction } from 'mobx'
import {
  FormatDateLocalized,
  GetFirstDayOfThisMonth,
  GetToday,
} from 'src/Helpers/DateHelpers'
import { t } from 'src/I18n'
import { IDateRange, ISelectOption } from 'src/Models/Client'
import IGenericError from 'src/Models/Server/IGenericError'
import { ToSelectOptions } from 'src/Models/Server/INameIdVTO'
import ReportController from 'src/Services/ToprCoreApi/Axios/Controllers/ReportController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export interface IFormValues {
  DateRange: IDateRange
  CustomTitle: string
  SelectedCompany: ISelectOption
}

export default class Store extends BaseStore<IFormValues> {
  public Companies: ISelectOption[] = []
  constructor() {
    super()
    makeObservable(this, {
      Companies: observable,
      Initialize: action,
      GetSiedlerChargeGuthaben: action,
    })
  }

  public Initialize() {
    this.IsLoading = true

    ReportController.SiedlerGetCompanies()
      .then((companies) => {
        runInAction(() => {
          this.Companies = [
            { label: t.common.all, value: '-1' },
            ...ToSelectOptions(companies),
          ]

          const dateRange = {
            StartDate: GetFirstDayOfThisMonth(),
            EndDate: GetToday(),
          }
          this.ViewModel = {
            DateRange: dateRange,
            CustomTitle: `${FormatDateLocalized(
              dateRange.StartDate
            )} - ${FormatDateLocalized(dateRange.EndDate)}`,
            SelectedCompany: this.Companies[0],
          }

          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsLoading = false))
        appUiStore.ShowError(error)
      })
  }

  public GetSiedlerChargeGuthaben(data: IFormValues) {
    this.IsSubmitting = true
    ReportController.GetSiedlerChargeGuthaben(
      data.CustomTitle,
      data.DateRange,
      data.SelectedCompany.value
    )
      .then(() => {
        runInAction(() => (this.IsSubmitting = false))
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsSubmitting = false))
        appUiStore.ShowError(error)
      })
  }
}

export const siedlerChargeGuthabenStore = new Store()
