import IGenericError from 'src/Models/Server/IGenericError'
import TagController from 'src/Services/ToprCoreApi/Axios/Controllers/TagController'
import { appUiStore } from 'src/Stores/AppUiStore'
import { userInfoStore } from '../Store'

export interface IState {
  isAddUserTagModalVisible: boolean
}

export const initialState: IState = {
  isAddUserTagModalVisible: false,
}

export type Actions = {
  type: 'toggleAddModal'
  isVisible: boolean
}

export const reducer = (state: IState, action: Actions): IState => {
  switch (action.type) {
    case 'toggleAddModal':
      return { ...state, isAddUserTagModalVisible: action.isVisible }

    default:
      throw new Error('Invalid Reducer Action')
  }
}

export const reducerActions = (
  state: IState,
  dispatch: (action: Actions) => void
) => {
  return {
    toggleAddModal: (isVisible: boolean) => {
      dispatch({ type: 'toggleAddModal', isVisible })
    },
    deleteUserTag: (userGuid: string, tagId: number) => {
      TagController.UserTagDelete(userGuid, tagId)
        .then(() => {
          userInfoStore.GetUserTags(userGuid)
        })
        .catch((error: IGenericError) => {
          appUiStore.ShowError(error)
        })
    },
  }
}
