import { action, makeObservable, observable, runInAction } from 'mobx'
import FileDTO from 'src/Models/Server/File/FileDTO'
import IGenericError from 'src/Models/Server/IGenericError'
import FileController from 'src/Services/ToprCoreApi/Axios/Controllers/FileController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export default class Store extends BaseStore {
  public File: FileDTO = undefined

  constructor() {
    super()

    makeObservable(this, {
      File: observable,
      GetFileDetails: action,
    })
  }

  public Initialize(fileId: string) {
    this.BaseInitialize()

    this.GetFileDetails(fileId)
  }

  public GetFileDetails(fileId: string) {
    this.IsLoading = true
    FileController.Details(fileId)
      .then((file) => {
        runInAction(() => {
          this.IsLoading = false
          this.File = file
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsLoading = false))
        appUiStore.ShowError(error)
      })
  }
}

export const fileDetailsStore = new Store()
