import { action, makeObservable, observable } from 'mobx'
import IEditImageFileRequest from 'src/Models/Server/Request/File/IEditImageFileRequest'
import BaseStore from 'src/Stores/BaseStore'

export default class Store extends BaseStore<IEditImageFileRequest> {
  public ShowEditModal = false

  constructor() {
    super()

    makeObservable(this, {
      Initialize: action,
      ToggleShowEditModal: action,
      ShowEditModal: observable,
    })
  }

  public Initialize(fileGuid: string) {
    this.BaseInitialize({
      FileGuid: fileGuid,
      ImageFile: null,
    })
  }

  public ToggleShowEditModal(showEditModal: boolean) {
    this.ShowEditModal = showEditModal
  }
}

export const editImageModalStore = new Store()
