import { action, makeObservable, observable, runInAction } from 'mobx'
import IGenericError from 'src/Models/Server/IGenericError'
import { IMerchantRefundFileDetailsDto } from 'src/Models/Server/Response/Refund/IMerchantRefundFileDetailsDto'
import MerchantRefundController from 'src/Services/ToprCoreApi/Axios/Controllers/MerchantRefundController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export class Store extends BaseStore {
  public MerchantRefundFileDetails: IMerchantRefundFileDetailsDto = undefined

  constructor() {
    super()
    this.BaseInitialize()

    makeObservable(this, {
      MerchantRefundFileDetails: observable,
      Initialize: action,
    })
  }

  public Initialize(merchantRefundFileId: string) {
    this.IsLoading = true

    MerchantRefundController.Details(merchantRefundFileId)
      .then((details) => {
        runInAction(() => {
          this.MerchantRefundFileDetails = details
          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsLoading = false))
        appUiStore.ShowError(error)
      })
  }
}

export const merchantRefundDetailsStore = new Store()
