import { observer } from 'mobx-react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import { MainPage } from 'src/Components/My'
import { t } from 'src/I18n'
import SavingCardForm from './Components/SavingCardForm'
import { IFormValues, savingCardInsertStore } from './Store'

interface IProps {}

const Insert: React.FC<IProps> = () => {
  const navigate = useNavigate()

  const onSubmit = (data: IFormValues) => {
    savingCardInsertStore.InsertSavingCard(data, navigate)
  }

  return (
    <MainPage>
      <BreadCrumbs
        currentPageTitle={t.savingcardInsert.title}
        previousPages={{ title: t.common.savingcards, to: '/savingcards' }}
      />
      <SavingCardForm
        store={savingCardInsertStore}
        onSubmit={onSubmit}
        initialize={() => savingCardInsertStore.InitializeInsert()}
      />
    </MainPage>
  )
}

export default observer(Insert)
