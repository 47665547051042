import { action, makeObservable, observable, runInAction } from 'mobx'
import IGenericError from 'src/Models/Server/IGenericError'
import INameIdVTO, { Sort } from 'src/Models/Server/INameIdVTO'
import ReportController from 'src/Services/ToprCoreApi/Axios/Controllers/ReportController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export interface IFormValues {}

export class Store extends BaseStore<IFormValues> {
  public Companies: INameIdVTO<number>[] = []
  public FieldValueId?: number = undefined
  public AddFieldValueModalIsVisible = false

  constructor() {
    super()
    makeObservable(this, {
      Companies: observable,
      FieldValueId: observable,
      AddFieldValueModalIsVisible: observable,
      Initialize: action,
      ToggleEditModal: action,
      ToggleAddModal: action,
    })
  }

  public Initialize() {
    this.IsLoading = true
    this.AddFieldValueModalIsVisible = false
    this.FieldValueId = undefined

    ReportController.SiedlerGetCompanies()
      .then((companies) => {
        runInAction(() => {
          this.Companies = Sort(companies)
          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsLoading = false))
        appUiStore.ShowError(error)
      })
  }

  public ToggleEditModal(fieldValueId?: number) {
    this.FieldValueId = fieldValueId
  }

  public ToggleAddModal() {
    this.AddFieldValueModalIsVisible = !this.AddFieldValueModalIsVisible
  }
}

export const siedlerCompaniesStore = new Store()
