import { t } from 'src/I18n'
import {
  format,
  sub,
  startOfDay,
  endOfMonth,
  startOfWeek,
  addDays,
  addMonths,
  addWeeks,
  addYears,
} from 'date-fns'
import { ISelectOption } from 'src/Models/Client'
import { persistedStore } from 'src/Stores/PersistedStore'
import enGb from 'date-fns/locale/en-GB'
import { UserCouponStatus } from 'src/Models/Server/Enums'

/**
 *
 * @param date
 * @returns Formatted datetime to user locale
 */
export const FormatDateTimeLocalized = (date: string | null) => {
  if (date === null) {
    return ''
  }

  const locale = persistedStore.GetCurrentDateLocale()

  const formattedDate = format(new Date(date), 'Pp', {
    locale,
  })
  return formattedDate
}

/**
 *
 * @param date
 * @returns Formatted date to user locale
 */
export const FormatDateLocalized = (date: Date | string | null) => {
  if (date === null) {
    return ''
  }

  const locale = persistedStore.GetCurrentDateLocale()

  const formattedDate = format(new Date(date), 'P', {
    locale,
  })
  return formattedDate
}

export const FormatYearMonthDay = (date: Date) => {
  return format(date, 'yyyyMMdd')
}

export const FormatYearMonthDayHourMinutes = (date: Date) => {
  return format(date, 'yyyyMMddHHmm')
}

export const GetCurrentYear = () => {
  return new Date().getFullYear()
}

export const GetDateText = (
  validFrom: Date | string,
  validTill: Date | string
): string => {
  let result = `${t.common.validity}: `

  if (validFrom === null && validTill === null) {
    result += ` ${t.common.always}`
  } else {
    if (validFrom !== null) {
      if (validTill === null) {
        result += ` ${t.common.from}`
      }
      const fromToFormat =
        typeof validFrom === 'string' ? new Date(validFrom) : validFrom
      result += ` ${DayFullMonth(fromToFormat)}`
    }

    if (validTill !== null) {
      result += ` ${t.common.untill}`
      const tillToFormat =
        typeof validTill === 'string' ? new Date(validTill) : validTill
      result += ` ${DayFullMonth(tillToFormat)}`
    }
  }

  return result
}

export const GetToday = () => {
  return startOfDay(new Date())
}

export const GetFirstDayOfThisMonth = () => {
  const today = new Date()
  return new Date(today.getFullYear(), today.getMonth(), 1)
}

export const addDaysToDate = (
  date: Date,
  amount: number,
  includeFirstDay: boolean = true
) => {
  return addDays(date, includeFirstDay ? amount - 1 : amount)
}

export const addWeeksToDate = (
  date: Date,
  amount: number,
  includeFirstDay: boolean = true
) => {
  const result = addWeeks(date, amount)

  if (includeFirstDay) {
    return addDays(result, -1)
  }

  return result
}

export const addMonthsToDate = (
  date: Date,
  amount: number,
  includeFirstDay: boolean = true
) => {
  const result = addMonths(date, amount)

  if (includeFirstDay) {
    return addDays(result, -1)
  }

  return result
}

export const addyearsToDate = (
  date: Date,
  amount: number,
  includeFirstDay: boolean = true
) => {
  const result = addYears(date, amount)

  if (includeFirstDay) {
    return addDays(result, -1)
  }

  return result
}

export const EndOfMonth = (date: Date, time: boolean = false) => {
  //date with time
  if (time) {
    return endOfMonth(date)
  }

  return startOfDay(endOfMonth(date))
}

export const FileDateTime = () => {
  const locale = persistedStore.GetCurrentDateLocale()
  return format(new Date(), 'yyyyMMdd_HHmm', {
    locale,
  })
}

export const FormatDateTimeNL = (date: Date | string) => {
  if (date === undefined || date === null) {
    return ''
  }

  const dateToFormat = typeof date === 'string' ? new Date(date) : date
  const locale = persistedStore.GetCurrentDateLocale()
  return format(dateToFormat, 'dd-MM-yyyy HH:mm', {
    locale,
  })
}

export const FormatDateNL = (date: Date | string) => {
  if (date === undefined || date === null) {
    return ''
  }

  const locale = persistedStore.GetCurrentDateLocale()
  const dateToFormat = typeof date === 'string' ? new Date(date) : date
  return format(dateToFormat, 'dd-MM-yyyy', {
    locale,
  })
}

export const DayFullMonth = (date: Date | string) => {
  if (date === undefined || date === null) {
    return ''
  }
  const locale = persistedStore.GetCurrentDateLocale()
  const dateToFormat = typeof date === 'string' ? new Date(date) : date
  return format(dateToFormat, 'd MMMM', {
    locale,
  })
}

export const GetLastMonthsOptions = (count: number) => {
  const options: ISelectOption[] = []
  const today = new Date()
  const locale = persistedStore.GetCurrentDateLocale()

  for (let index = 0; index < count; index++) {
    const month = sub(new Date(today.getFullYear(), today.getMonth(), 1), {
      months: index,
    })
    options.push({
      label: format(month, 'MMMM', {
        locale,
      }),
      value: month.toString(),
    })
  }

  return options
}

export const getExpirationDateInMs = (expiresIn: string) => {
  const expiryDateInMs = new Date(expiresIn).valueOf()
  return expiryDateInMs
}

export const GetLast5YearOptions = () => {
  const today = new Date()
  const options: ISelectOption[] = []
  for (let index = 0; index < 5; index++) {
    const newDate = sub(new Date(today.getFullYear(), today.getMonth(), 1), {
      years: index,
    })

    options.push({
      label: newDate.getFullYear().toString(),
      value: newDate.getFullYear().toString(),
    })
  }

  return options
}

export const GetCurrentMonthOption = (): ISelectOption => {
  const today = new Date()
  const locale = persistedStore.GetCurrentDateLocale()
  return {
    label: format(today, 'MMMM', {
      locale,
    }),
    value: format(today, 'M', {
      locale,
    }),
  }
}

export const GetCurrentMonthLocalized = () => {
  const today = new Date()
  const locale = persistedStore.GetCurrentDateLocale()
  return format(today, 'MMMM', {
    locale,
  })
}

export const GetMonthOption = (month: string) => {
  const options = GetAllMonthsOptions()

  return options.find((x) => x.value === month)
}

export const GetAllMonthsOptions = () => {
  const locale = persistedStore.GetCurrentDateLocale()
  const options: ISelectOption[] = []
  for (let index = 0; index < 12; index++) {
    const year = new Date().getFullYear()
    const monthDate = new Date(year, index, 1)

    options.push({
      label: format(monthDate, 'MMMM', {
        locale,
      }),
      value: format(monthDate, 'M'),
    })
  }

  return options
}

export const Today = () => {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  return today
}

export const GetWeekDays = () => {
  const locale = persistedStore.GetCurrentDateLocale()

  const firstDOW = startOfWeek(new Date(), { weekStartsOn: 1 })
  const weekdays: ISelectOption[] = Array.from(Array(7)).map((e, i) => {
    return {
      value: format(addDays(firstDOW, i), 'EEEE', { locale: enGb }),
      label: format(addDays(firstDOW, i), 'EEEE', { locale }),
    }
  })
  return weekdays
}

export const GetWeekDaysOptions = () => {
  const locale = persistedStore.GetCurrentDateLocale()

  const firstDOW = startOfWeek(new Date(), { weekStartsOn: 1 })
  const weekdays: ISelectOption[] = Array.from(Array(7)).map((e, i) => {
    return {
      value: (i + 1).toString(),
      label: format(addDays(firstDOW, i), 'EEEE', { locale }),
    }
  })
  return weekdays
}

export const GetConsoleDate = () => {
  return format(new Date(), '[HH:mm:ss]')
}

export const GetUserCouponStatus = (
  validFrom: string | null,
  validTill: string | null,
  redeemed: string | null
): UserCouponStatus => {
  if (redeemed !== null) {
    return UserCouponStatus.Redeemed
  }

  if (validFrom !== null && new Date(validFrom) > new Date()) {
    return UserCouponStatus.NotYetActive
  }

  if (validTill !== null && new Date(validTill) < new Date()) {
    return UserCouponStatus.Expired
  }

  return UserCouponStatus.Active
}

// improve loginh with adding a timestamp
//cool but hides the source of the call :/
/*
const log = console.log
console.log = function (...args) {
  const first_parameter = args[0]
  const other_parameters = Array.prototype.slice.call(args, 1)

  function formatConsoleDate(date) {
    const hour = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()
    const milliseconds = date.getMilliseconds()

    return (
      '[' +
      (hour < 10 ? '0' + hour : hour) +
      ':' +
      (minutes < 10 ? '0' + minutes : minutes) +
      ':' +
      (seconds < 10 ? '0' + seconds : seconds) +
      '.' +
      ('00' + milliseconds).slice(-3) +
      '] '
    )
  }

  log.apply(
    console,
    [formatConsoleDate(new Date()) + first_parameter].concat(other_parameters)
  )
}
*/
