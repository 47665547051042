import { AxiosError, AxiosResponse } from 'axios'
import FileDownload from 'js-file-download'
import qs from 'qs'
import { FormatYearMonthDay } from 'src/Helpers/DateHelpers'
import { handleException } from 'src/Helpers/ResponseHelpers'
import { IDateRange, IMonthYear, ISelectOption } from 'src/Models/Client'
import { IChartResponse } from 'src/Models/Server/Chart/IChartData'
import { INameGuidVTO } from 'src/Models/Server/INameGuidVTO'
import INameIdVTO from 'src/Models/Server/INameIdVTO'
import IPageReportVTO, {
  IPageReportDataTableVTO,
} from 'src/Models/Server/Report/IPagedReport'
import { IMerchantPaymentVTO } from 'src/Models/Server/Response/Report/IMerchantPaymentVTO'
import { ITotalTransactionAmountPerUserDTOnsPerDayDTO } from 'src/Models/Server/Response/Transaction/ITotalTransactionAmountPerUserDTOnsPerDayDTO'

import IMonthlyTransactionTotalVTO from 'src/Models/Server/Response/Transaction/IMonthlyTransactionTotalVTO'
import { ITransactionExtendedSTO } from 'src/Models/Server/Response/Transaction/ITransactionExtendedSTO'
import { axiosAuth } from '../AuthAxios'

export default {
  GetDefaultFilter() {
    return new Promise<{
      Merchants: INameGuidVTO[]
      Currencies: INameGuidVTO[]
    }>((resolve, reject) => {
      axiosAuth
        .get(`/api/v1/reports/filter/default`)
        .then(
          (
            response: AxiosResponse<{
              Merchants: INameGuidVTO[]
              Currencies: INameGuidVTO[]
            }>
          ) => {
            resolve(response.data)
          }
        )
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  GetDefaultFilterV2() {
    //v2: with int ids instead of guids
    return new Promise<{
      Merchants: Array<INameIdVTO<number>>
      Currencies: Array<INameIdVTO<number>>
    }>((resolve, reject) => {
      axiosAuth
        .get(`/api/v2/reports/filter/default`)
        .then(
          (
            response: AxiosResponse<{
              Merchants: Array<INameIdVTO<number>>
              Currencies: Array<INameIdVTO<number>>
            }>
          ) => {
            resolve(response.data)
          }
        )
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },

  GetTokenTypeFilter() {
    return new Promise<{
      TokenTypes: Array<INameIdVTO<number>>
      Currencies: INameGuidVTO[]
    }>((resolve, reject) => {
      axiosAuth
        .get(`/api/v1/reports/filter/tokentype`)
        .then(
          (
            response: AxiosResponse<{
              TokenTypes: Array<INameIdVTO<number>>
              Currencies: INameGuidVTO[]
            }>
          ) => {
            resolve(response.data)
          }
        )
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  GetPosFilter(merchantId: string) {
    return new Promise<{
      Currencies: Array<INameIdVTO<number>>
      Poses: Array<INameIdVTO<number>>
      Merchant: INameIdVTO<number>
    }>((resolve, reject) => {
      axiosAuth
        .get(`/api/v1/reports/filter/pos/${merchantId}`)
        .then(
          (
            response: AxiosResponse<{
              Currencies: Array<INameIdVTO<number>>
              Poses: Array<INameIdVTO<number>>
              Merchant: INameIdVTO<number>
            }>
          ) => {
            resolve(response.data)
          }
        )
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  GetPaymentsTokenType(
    tokenTypeIds: string[],
    currencyGuids: string[],
    startDate: Date,
    endDate: Date
  ) {
    return new Promise<void>((resolve, reject) => {
      axiosAuth
        .get(`/api/v1/reports/payments/tokentype`, {
          params: {
            tokenTypeIds,
            currencyGuids,
            startDate,
            endDate,
          },
          paramsSerializer: (params) => {
            return qs.stringify(params)
          },
        })
        .then((response: AxiosResponse<any>) => {
          FileDownload(
            response.data,
            `tokentypepayments_${FormatYearMonthDay(
              startDate
            )}_${FormatYearMonthDay(endDate)}.csv`
          )

          resolve()
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  GetPaymentTransactions(
    merchantGuids: string[],
    currencyGuids: string[],
    startDate: any,
    endDate: any,
    page: number,
    pageSize: number,
    sortField: string,
    sortOrder: string
  ) {
    return new Promise<IPageReportVTO<ITransactionExtendedSTO>>(
      (resolve, reject) => {
        axiosAuth
          .get(`/api/v1/reports/paymenttransactions`, {
            params: {
              merchantGuids,
              currencyGuids,
              startDate,
              endDate,
              page,
              pageSize,
              sortField,
              sortOrder,
            },
            paramsSerializer: (params) => {
              return qs.stringify(params)
            },
          })
          .then(
            (
              response: AxiosResponse<IPageReportVTO<ITransactionExtendedSTO>>
            ) => {
              resolve(response.data)
            }
          )
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },

  GetCardPaymentTotals(
    TokenTypeId: string,
    CurrencyGuid: string,
    Start: any,
    End: any,
    Page: number,
    PageSize: number,
    SortField: string,
    SortOrder: string,
    ExportCsv: boolean = false
  ) {
    return new Promise<
      IPageReportVTO<ITotalTransactionAmountPerUserDTOnsPerDayDTO>
    >((resolve, reject) => {
      axiosAuth
        .get(`/api/v1/reports/cardpaymenttotals`, {
          params: {
            CurrencyGuid,
            TokenTypeId,
            Start,
            End,
            Page,
            PageSize,
            SortField,
            SortOrder,
            ExportCsv,
          },
          paramsSerializer: (params) => {
            return qs.stringify(params)
          },
        })
        .then(
          (
            response: AxiosResponse<
              IPageReportVTO<ITotalTransactionAmountPerUserDTOnsPerDayDTO>
            >
          ) => {
            if (ExportCsv) {
              FileDownload(
                response.data as any,
                `tokentypepayments_${FormatYearMonthDay(
                  Start
                )}_${FormatYearMonthDay(End)}.csv`
              )
            }

            resolve(response.data)
          }
        )
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },

  MonthlyTransactionTotals(
    merchantGuids: string[],
    currencyGuids: string[],
    startDate: any,
    endDate: any
  ) {
    return new Promise<IMonthlyTransactionTotalVTO[]>((resolve, reject) => {
      axiosAuth
        .get(`/api/v1/reports/monthlytransactiontotals`, {
          params: {
            merchantGuids,
            currencyGuids,
            startDate,
            endDate,
          },
          paramsSerializer: (params) => {
            return qs.stringify(params)
          },
        })
        .then((response: AxiosResponse<IMonthlyTransactionTotalVTO[]>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  GetMerchantPayments(merchantGuids: string[], startDate: any, endDate: any) {
    return new Promise<IMerchantPaymentVTO[]>((resolve, reject) => {
      axiosAuth
        .get(`/api/v1/reports/merchantpayments`, {
          params: {
            merchantGuids,
            startDate,
            endDate,
          },
          paramsSerializer: (params) => {
            return qs.stringify(params)
          },
        })
        .then((response: AxiosResponse<IMerchantPaymentVTO[]>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  GetMerchantPaymentTransactions(merchantPaymentId: string) {
    return new Promise<ITransactionExtendedSTO[]>((resolve, reject) => {
      axiosAuth
        .get(`/api/v1/reports/merchantpayment/${merchantPaymentId}`)
        .then((response: AxiosResponse<ITransactionExtendedSTO[]>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  GetPaymentsPerDayMerchant(
    currencyId: string,
    merchantIds: string[],
    month: string
  ) {
    return new Promise<IChartResponse>((resolve, reject) => {
      axiosAuth
        .get(`/api/v1/reports/payments/day/merchant`, {
          params: {
            currencyId,
            merchantIds,
            month,
          },
          paramsSerializer: (params) => {
            return qs.stringify(params)
          },
        })
        .then((response: AxiosResponse<IChartResponse>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  GetPaymentsPerDayMerchantDetails(
    merchantId: string,
    currencyId: string,
    posIds: string[],
    month: string
  ) {
    return new Promise<IChartResponse>((resolve, reject) => {
      axiosAuth
        .get(`/api/v1/reports/payments/day/merchant/${merchantId}`, {
          params: {
            currencyId,
            posIds,
            month,
          },
          paramsSerializer: (params) => {
            return qs.stringify(params)
          },
        })
        .then((response: AxiosResponse<IChartResponse>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  GetPaymentsDashboard() {
    return new Promise<IChartResponse[]>((resolve, reject) => {
      axiosAuth
        .get(`/api/v1/reports/payments/dashboard`)
        .then((response: AxiosResponse<IChartResponse[]>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  GetUserBalances(tillDate: any, page: number, pageSize: number) {
    return new Promise<IPageReportDataTableVTO>((resolve, reject) => {
      axiosAuth
        .get(`/api/v1/reports/user/balances/${page}/${pageSize}`, {
          params: {
            tillDate,
          },
        })
        .then((response: AxiosResponse<IPageReportDataTableVTO>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  GetSiedlerCompanyCoupons(
    customTitle: string,
    dateRange: IDateRange,
    selectedCompany: ISelectOption
  ) {
    return new Promise<void>((resolve, reject) => {
      const request = {
        CustomTitle: customTitle,
        ...dateRange,
      }

      axiosAuth
        .post(
          `/api/v1/reports/siedler/company-coupons/${selectedCompany.value}`,
          request,
          {
            responseType: 'blob',
          }
        )
        .then((response: AxiosResponse<any>) => {
          FileDownload(response.data, `CompanyCoupons.pdf`, 'application/pdf')

          resolve()
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  GetSiedlerChargeGuthaben(
    customTitle: string,
    dateRange: IDateRange,
    companyId: string
  ) {
    return new Promise<void>((resolve, reject) => {
      const request = {
        CustomTitle: customTitle,
        ...dateRange,
      }
      axiosAuth
        .post(`/api/v1/reports/siedler/charge-guthaben/${companyId}`, request, {
          responseType: 'blob',
        })
        .then((response: AxiosResponse<any>) => {
          FileDownload(response.data, `Kartenaufbuchung.pdf`, 'application/pdf')

          resolve()
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  SiedlerGetCompanies() {
    return new Promise<INameIdVTO<number>[]>((resolve, reject) => {
      axiosAuth
        .get(`/api/v1/reports/siedler/companies`, {
          //responseType: 'blob',
        })
        .then((response: AxiosResponse<INameIdVTO<number>[]>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  GetSiedlerInvoiceCoupons(
    selectedMonthYear: IMonthYear,
    selectedMerchant: ISelectOption
  ) {
    return new Promise<void>((resolve, reject) => {
      axiosAuth
        .get(
          `/api/v1/reports/siedler/invoice-coupons/${selectedMonthYear.Year}/${selectedMonthYear.Month}/${selectedMerchant.value}`,
          {
            responseType: 'blob',
          }
        )
        .then((response: AxiosResponse<any>) => {
          FileDownload(
            response.data,
            `InvoiceCoupons_${selectedMerchant.label.replace(' ', '')}_${
              selectedMonthYear.Year
            }_${selectedMonthYear.Month}.pdf`,
            'application/pdf'
          )

          resolve()
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  GetSiedlerBearbeitungsGebuhr(
    selectedMonthYear: IMonthYear,
    selectedMerchant: ISelectOption
  ) {
    return new Promise<void>((resolve, reject) => {
      axiosAuth
        .get(
          `/api/v1/reports/siedler/bearbeitungsgebuhr/${selectedMonthYear.Year}/${selectedMonthYear.Month}/${selectedMerchant.value}`,
          {
            responseType: 'blob',
          }
        )
        .then((response: AxiosResponse<any>) => {
          FileDownload(
            response.data,
            `BearbeitungsGebuhr_${selectedMerchant.label.replace(
              ' ',
              ''
            )}_Guthaben_${selectedMonthYear.Year}_${
              selectedMonthYear.Month
            }.pdf`,
            'application/pdf'
          )

          resolve()
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  GetSiedlerGebuhrKnodels(
    selectedMonthYear: IMonthYear,
    selectedMerchant: ISelectOption
  ) {
    return new Promise<void>((resolve, reject) => {
      axiosAuth
        .get(
          `/api/v1/reports/siedler/gebuhr-knodels/${selectedMonthYear.Year}/${selectedMonthYear.Month}/${selectedMerchant.value}`,
          {
            responseType: 'blob',
          }
        )
        .then((response: AxiosResponse<any>) => {
          FileDownload(
            response.data,
            `Gebuhr${selectedMerchant.label.replace(' ', '')}_Knödels_${
              selectedMonthYear.Year
            }_${selectedMonthYear.Month}.pdf`,
            'application/pdf'
          )

          resolve()
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  GetSiedlerInvoiceCurrency(
    selectedMonthYear: IMonthYear,
    selectedMerchant: ISelectOption,
    SelectedCurrency: ISelectOption
  ) {
    return new Promise<void>((resolve, reject) => {
      axiosAuth
        .get(
          `/api/v1/reports/siedler/invoice-currency/${selectedMonthYear.Year}/${selectedMonthYear.Month}/${selectedMerchant.value}/${SelectedCurrency.value}`,
          {
            responseType: 'blob',
          }
        )
        .then((response: AxiosResponse<any>) => {
          FileDownload(
            response.data,
            `AbrechnungCurrency_${selectedMerchant.label.replace(' ', '')}_${
              SelectedCurrency.label
            }_${selectedMonthYear.Year}_${selectedMonthYear.Month}.pdf`,
            'application/pdf'
          )

          resolve()
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
}
