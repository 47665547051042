export default {
  addAppUserModal: {
    fields: {
      Email: {
        label: 'E-Mail-Adresse',
        errors: {
          required: 'Bitte geben Sie die E-Mail-Adresse ein.',
          regex: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
        },
      },
      Name: {
        label: 'Namen',
        errors: {
          required: 'Geben Sie den Namen ein.',
        },
      },
      Password: {
        label: 'Passwort',
        errors: {
          required: 'Bitte geben Sie das Passwort ein.',
        },
      },
      Currency: {
        label: 'Zahlungsmethode',
        errors: {
          required: 'Wählen Sie eine Zahlungsmethode aus.',
        },
      },
    },
    checkEmail: 'E-Mail-Adresse prüfen',
  },
  addManagerUserModal: {
    fields: {
      Email: {
        label: 'E-Mail-Adresse',
        errors: {
          required: 'Bitte geben Sie die E-Mail-Adresse ein.',
          regex: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
        },
      },
      Name: {
        label: 'Namen',
        errors: {
          required: 'Geben Sie den Namen ein.',
        },
      },
      Password: {
        label: 'Passwort',
        errors: {
          required: 'Bitte geben Sie das Passwort ein.',
        },
      },
    },
  },
  automaticChargeInsert: {
    title: 'Automatisch aufladen einrichten (SEPA)',
    fields: {
      AccountHolder: {
        label: 'Kontoinhaber',
        errors: {
          required: 'Geben Sie den Kontoinhaber ein.',
        },
      },
      AccountNumber: {
        label: 'Kontonummer (IBAN)',
        errors: {
          required: 'Geben Sie die Kontonummer ein.',
          iban: 'Geben Sie eine gültige IBAN-Kontonummer ein.',
        },
      },

      City: {
        label: 'Namen der Stadt',
        errors: {
          required: 'Geben Sie den Namen der Stadt ein.',
        },
      },
      EmailAddress: {
        label: 'E-Mail-Adresse',
        errors: {
          required: 'Bitte geben Sie die E-Mail-Adresse ein.',
          regex: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
        },
      },
      LastName: {
        label: 'Nachname',
        errors: {
          required: 'Geben Sie den Nachnamen ein.',
        },
      },
      PhoneNumber: {
        label: 'Telefonnummer',
        errors: {
          required: 'Geben Sie die Telefonnummer ein.',
        },
      },
      SelectedBank: {
        label: 'Bank',
        info: 'Bank des Kunden',
        errors: {
          required: 'Bank auswählen.',
          bank: 'Ungültige Bank für diese Kontonummer.',
        },
      },
      SelectedCurrency: {
        info: 'Die Zahlungsmethode, die aufgeladen wird.',
        errors: {
          required: 'Wählen Sie eine Zahlungsmethode aus.',
        },
      },
      SepaFileData: {
        label: 'SEPA-Formular',
        info: 'Das vom Kunden gesendete SEPA-Formular.',
        dropFile:
          'Klicken Sie hier, um das SEPA-Formular hochzuladen (.pdf/.jpg/.png).',
        errors: {
          required: 'SEPA-Formular hochladen.',
        },
      },
      StepAmount: {
        label: 'Aufladebetrag',
        info: 'Der aufzuladende Betrag (mindestens 10 € und maximal 150 €).',
        errors: {
          required: 'Geben Sie den Aufladebetrag ein.',
        },
      },
      StepLimit: {
        label: 'Limit (pro Woche)',
        info: 'Die maximale Anzahl an Aufladungen, die pro Woche durchgeführt werden können (mindestens 1, maximal 10).',
        errors: {
          required: 'Geben Sie das Limit ein.',
        },
      },
      StreetName: {
        label: 'Straßenname',
        errors: {
          required: 'Geben Sie den Straßennamen ein.',
        },
      },
      StreetNumber: {
        label: 'Hausnummer',
        errors: {
          required: 'Geben Sie die Hausnummer ein.',
        },
      },
      Zipcode: {
        label: 'Postleitzahl',
        errors: {
          required: 'Geben Sie die Postleitzahl ein.',
        },
      },
    },
    success: 'Automatisches Aufladen aktiviert für: {currencyName}',
  },
  automaticChargeEdit: {
    title: 'Automatische Belastung ändern',
    fields: {
      FileData: {
        label: 'SEPA-Änderungsformular',
        info: 'Das vom Kunden gesendete SEPA-Änderungsformular.',
        dropFile:
          'Klicken Sie hier, um das SEPA-Änderungsformular hochzuladen (.pdf/.jpg/.png).',
        errors: {
          required: 'SEPA-Änderungsformular hochladen.',
        },
      },
      AccountHolder: {
        label: 'Kontoinhaber',
        errors: {
          required: 'Geben Sie den Kontoinhaber ein.',
        },
      },
      AccountNumber: {
        label: 'Kontonummer (IBAN)',
        errors: {
          required: 'Geben Sie die Kontonummer ein.',
          iban: 'Geben Sie eine gültige IBAN-Kontonummer ein.',
        },
      },
      SelectedBank: {
        label: 'Bank',
        info: 'Bank des Kunden',
        errors: {
          required: 'Bank auswählen.',
          bank: 'Ungültige Bank für diese Kontonummer.',
        },
      },
      StepAmount: {
        label: 'Aufladebetrag',
        info: 'Der aufzuladende Betrag (mindestens 10 € und maximal 150 €).',
        errors: {
          required: 'Geben Sie den Aufladebetrag ein.',
        },
      },
      StepLimit: {
        label: 'Limit (pro Woche)',
        info: 'Die maximale Anzahl an Aufladungen, die pro Woche durchgeführt werden können (mindestens 1, maximal 10).',
        errors: {
          required: 'Geben Sie das Limit ein.',
        },
      },
    },
    sepaChangeForm: 'Formular ändern',
    success: 'Automatisches Upgrade geändert.',
  },
  automaticChargesPortlet: {
    chargeSettings: 'Einstellungen',
    stepAmount: 'Aufladebetrag',
    stepLimit: 'Maximal pro Woche',
    turnOff: {
      action: 'Deaktivieren',
      confirmMessage:
        'Dieses automatische Aufladen ({currencyName}) wird <b>deaktiviert</b>.<br/>Sind Sie sicher?',
      success: 'Automatisches Upgrade deaktiviert.',
    },
  },
  common: {
    accountNumber: 'Konto',
    active: 'activ',
    activate: 'Aktivieren',
    activationCode: 'Aktivierungscode',
    accepted: 'akzeptiert',
    add: 'Hinzufügen',
    address: 'Adresse',
    always: 'immer',
    all: '<Alle>',
    amountTypes: {
      default: {
        title: 'Menge',
        required: 'Geben Sie die Menge ein.',
      },
      currency: {
        title: 'Betrag',
        required: 'Geben Sie den Betrag ein.',
      },
      percentage: {
        title: 'Prozent (%)',
        required: 'Geben Sie den Prozentsatz ein.',
      },
    },
    amount: 'Betrag',
    amountText: 'Betrag',
    authenticatedUser: 'eingeloggter Benutzer',
    back: 'Zurück',
    backTo: 'Zurück zu',
    bank: 'Bank',
    blocked: 'Blockiert',
    cancel: 'Abbrechen',
    city: 'Ortsname',
    close: 'Schließen',
    charge: 'Upgrade',
    confirm: 'Bestätigen',
    consumer: 'Kunde',
    consumers: 'Kunden',
    consumerName: 'Kundenname',
    coupon: 'Gutschein',
    coupons: 'Gutscheine',
    company: 'Unternehmen',
    created: 'Erstellt',
    currencies: 'Zahlungsmitteln',
    currency: 'Zahlungsmittel',
    custom: 'benutzerdefiniert...',
    date: 'Datum',
    day: 'Tag',
    deactivate: 'Deaktivieren',
    delete: 'Löschen',
    description: 'Beschreibung',
    deselectAll: 'Alle abwählen',
    details: 'Einzelheiten',
    download: 'Herunterladen',
    dropImage: 'Klicken Sie hier, um ein Bild hinzuzufügen',
    edit: 'Ändern',
    email: 'E-Mail-Adresse',
    error: 'Fehler',
    example: 'Zum Beispiel',
    extraInformation: 'Zusätzliche Informationen',
    file: 'Datei',
    filter: 'Filter',
    from: 'Von',
    generate: 'Generieren',
    grid: {
      name: {
        label: 'Namen',
        placeholder: 'Nach Name suchen',
      },
      email: {
        label: 'E-Mail-Adresse',
        placeholder: 'Suche nach E-Mail-Adresse',
      },
    },
    hidden: 'versteckt',
    inActive: 'nicht activ',
    info: 'Info',
    insert: 'Hinzufügen',
    image: 'Bild',
    lastName: 'Nachname',
    location: 'Standort',
    locations: 'Standorte',
    logoff: 'Abmelden',
    loyaltyProgram: 'Sparprogramm',
    loyaltyPrograms: 'Sparprogramme',
    merchant: 'Händler',
    merchants: 'Händlers',
    modified: 'Kaufleute',
    module: 'Modul',
    month: 'Monat',
    myProfile: 'Mein Profil',
    myCustomers: 'Meine Kunden',
    name: 'Namen',
    no: 'Nein',
    noData: 'Kein Daten',
    other: 'Andere',
    pagingText: 'Zeige {von} bis {bis} von {size} Zeilen',
    preview: 'Vorschau',
    password: 'Passwort',
    payments: 'Passwort',
    period: 'Zeitraum',
    phoneNumber: 'Telefonnummer',
    pincode: 'PIN',
    pos: 'Pos',
    processed: 'Verarbeitet',
    redeemed: 'Eingelöst',
    revenue: 'Einnahmen',
    reward: 'Belohnung',
    sam: 'Sam',
    save: 'Speichern',
    savingcard: 'Sparkarte',
    savingcards: 'Sparkarten',
    search: 'Suchen',
    selectAll: 'Alle auswählen',
    serverErrors: {
      defaultError: {
        Title: 'Fehler',
        Code: 'DefaultError',
        Message: 'Entschuldigung, etwas ist schief gelaufen.',
      },
    },
    settings: 'Einstellungen',
    status: 'Status',
    streetName: 'Straßenname',
    streetNumber: 'Nummer',
    sum: 'Betrag',
    subscriptions: 'Betrag',
    tenant: 'Tenant',
    tenants: 'Tenants',
    terminal: 'Terminal',
    terminals: 'Terminals',
    ticket: 'Ticket',
    tickets: 'Tickets',
    till: 'Bis zu',
    title: 'Titel',
    tokens: 'Zahlungsschlüssel',
    tokenTypes: 'Kartentypen',
    total: 'Gesamt',
    totals: 'Summen',
    transactions: 'Transaktionen',
    turnoff: 'Ausschalten',
    type: 'Typ',
    validFrom: 'Aus',
    validTill: 'Bis einschließlich',
    validity: 'Gültig',
    wallets: 'Geldbörsen',
    warning: 'Warnung',
    week: 'Woche',
    withdraw: 'Verringern',
    trip: 'Reise',
    time: 'Zeit',
    unlimited: 'Unbegrenzt',
    untill: 'Bis zu',
    user: 'Benutzer',
    users: 'Benutzers',
    username: 'Benutzername',
    year: 'Jahr',
    zipCode: 'Postleitzahl',
  },
  commonErrors: {
    localizedStringRequired: 'Geben Sie den {0} ({1}) ein.',
  },
  commonFields: {
    Amount: {
      label: 'Betrag',
      placeholder: {
        number: '0',
        decimal: '0,00',
      },
      errors: {
        required: 'Geben Sie den Betrag ein.',
        minNumber: 'Der Mindestbetrag ist 1',
        minDecimal: 'Der Mindestbetrag ist 0,01',
      },
    },
  },
  confirmationSend: {
    header: 'Du hast es fast geschafft!',
    heading: 'Bestätigung gesendet',
    bodyTextLine1:
      '<p>Vielen Dank für die Registrierung von <b>{0}</b>!</p>' +
      '<p>Eine E-Mail wurde an <b>{1}</b> gesendet, die einen Bestätigungslink enthält.' +
      ' Wenn Sie auf diesen Link klicken, wird Ihr Konto bestätigt und Sie können Ihre erste Sparkarte erstellen!</p>',
  },
  coupons: {
    deleteUserCouponConfirm:
      'Sind Sie sicher, dass Sie <i>{fullTitle}</i> löschen möchten?',
    userCouponAdd: {
      title: 'Gutscheine hinzufügen',
      fields: {
        amount: {
          label: 'Gutscheinbetrag',
          required: 'Gutscheinbetrag eingeben.',
        },
        coupon: {
          label: 'Gutschein',
          required: 'Gutschein auswählen.',
        },
      },
      successMessage:
        '{amount}x {couponTitle}wurden zu {count} Kunden hinzugefügt.',
    },
  },
  dashboard: {
    totalUsers: 'Gesamtzahl der Benutzer',
    totalActivated: 'Total aktivierte Benutzer',
    newUsersThisWeek: 'Neue Benutzer diese Woche',
    activeUsersThisWeek: 'aktive Benutzer diese Woche',
  },
  DashboardBericht: {
    revenueToday: 'Umsatz heute',
    paymentsToday: 'Zahlungen heute',
    revenueMonth: 'Umsatz diesen Monat',
    paymentsMonth: 'Zahlungen in diesem Monat',
    revenueStats: 'Umsatzstatistiken',
    top10Pos: 'Top 10 in diesem Monat',
    revenueHour: 'Umsatz pro Stunde',
    registeredUsers: 'Registrierte Benutzer',
    averageAmount: 'Durchschnittliche Zahlung',
    outstandingBalance: 'Ausstehender Saldo',
    redeemedCouponsToday: 'Heute eingelöste Gutscheine',
    issuedCouponsToday: 'Heute ausgestellte Gutscheine',
    redeemedCouponsMonth: 'Diesen Monat eingelöste Gutscheine',
    issuedCouponsMonth: 'Diesen Monat ausgestellte Gutscheine',
    paymentsPerLocation: 'Zahlungen pro Standort heute',
    revenuePerLocation: 'Heutiger Umsatz pro Standort',
  },
  editEmail: {
    title: 'E-Mail-Adresse ändern',
    emailField: {
      errors: {
        required: 'Geben Sie eine E-Mail-Adresse ein',
        validateEmail: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
      },
    },
    success: 'Die E-Mail-Adresse wurde aktualisiert.',
  },
  editName: {
    title: 'Name ändern',
    nameField: {
      errors: {
        required: 'Geben Sie einen Namen ein',
      },
    },
    success: 'Der Name wurde aktualisiert.',
  },
  editPassword: {
    title: 'Passwort ändern',
    nameField: {
      errors: {
        required: 'Passwort ändern',
      },
    },
    success: 'Das Passwort wurde aktualisiert.',
  },
  editUserName: {
    title: 'Benutzernamen ändern',
    userNameField: {
      errors: {
        required: 'Benutzernamen ändern',
      },
    },
    success: 'Der Benutzername wurde aktualisiert.',
  },
  enums: {
    savingCardStatusDTO: {
      Active: 'Activ',
      NotYetActive: 'Noch nicht activ',
      Expired: 'Abgelaufen',
      Deactivated: 'Deaktiviert',
    },
    LoyaltyRuleTypes: {
      Article: 'Artikel',
      ArticleGroup: 'Artikelgruppe',
      Balance: 'Balance',
      TransactionAmountPositive: 'Charge', //opwaardering
      TransactionAmountNegative: 'Zahlung', //betaling
      RedeemCoupon: 'Gutschein einlösen',
    },
    LoyaltyRewardTypes: {
      CouponReward: 'Gutschein',
      CurrencyStaticReward: 'Währung, statischer Betrag',
      CurrencyOrderPercentageReward: 'Währung, Prozentbetrag',
    },
  },
  event: {
    index: {
      title: 'Veranstaltungen',
      confirmDelete:
        'Sind Sie sicher, dass Sie die Veranstaltung <b>{name}</b> entfernen möchten?',
    },
    edit: {
      title: 'Veranstaltung bearbeiten',
    },
    insert: {
      title: 'Veranstaltung hinzufügen',
      fields: {
        name: {
          label: 'Namen',
          required: 'Geben Sie einen Namen ein.',
        },
        description: {
          label: 'Beschreibung',
          required: 'Geben Sie eine Beschreibung ein.',
        },
        start: {
          title: 'Startzeit',
          required: 'Startzeit auswählen.',
        },
        end: {
          title: 'Endzeit',
          required: 'End auswählen.',
          greaterThan: 'Die Endzeit muss größer als die Startzeit sein.',
        },
      },
    },
  },
  eventTicket: {
    insert: {
      title: 'Ticket hinzufügen',
      fields: {
        name: {
          label: 'Namen',
          info: 'Zum Beispiel: Kind bis 13 Jahre, Erwachsener oder Bronze, Silber, Gold etc.',
          required: 'Geben Sie einen Namen ein.',
        },
        onSale: {
          title: 'Verfügbar für den Online-Verkauf.',
        },
        price: {
          title: 'Pries',
          required: 'Geben Sie einen Preis ein.',
        },
      },
    },
  },
  addTicket: {
    title: 'Ticket hinzufügen',
    fields: {
      ticket: {
        title: 'Ticket',
      },
      amount: {
        title: 'Anzahl von',
        required: 'Geben Sie einen Anzahl von ein.',
      },
    },
  },
  fileEdit: {
    title: 'Datei bearbeiten',
    form: {
      dataField: {
        label: 'Datei (.*)',
        dropfile: 'Klicken Sie hier, um die Datei hochzuladen (.*).',
        error: {
          required: 'Geben Sie die Datei ein.',
        },
      },
      failed: 'Dateiaktualisierung fehlgeschlagen',
      success: 'Das Aktualisieren der Datei war ERFOLGREICH',
    },
  },
  fileInsert: {
    title: 'Datei hinzufügen',
    form: {
      dataField: {
        label: 'Datei (.*)',
        dropfile: 'Klicken Sie hier, um die Datei hochzuladen (.*).',
        error: {
          required: 'Geben Sie die Datei ein.',
        },
      },
      failed: 'Dateiaktualisierung fehlgeschlagen',
      success: 'Das Aktualisieren der Datei war ERFOLGREICH',
    },
  },
  forgotPassword: {
    fieldEmailAddress: {
      label: 'E-Mail-Adresse',
      errors: {
        required: 'Geben Sie die E-Mail-Adresse ein.',
        regex: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
      },
    },
    header: 'Passwort vergessen',
    login: 'Einloggen',
    info: 'Geben Sie Ihre E-Mail-Adresse ein und wir senden Ihnen einen Link zum Zurücksetzen Ihres Passworts.',
    submitText: 'Senden',
    success:
      'Eine E-Mail mit einem Link zum Zurücksetzen Ihres Passworts wurde gesendet.',
  },
  generateAutomaticCharge: {
    availableAutomaticChargesText:
      'Es stehen {count} Lastschriften zum Generieren.',
    noFile: 'Kein Datei',
    title: 'SEPA-Datei generieren',
    fileBeingGenerated: 'Die Datei wird generiert.',
  },
  helpdesk: {
    fieldSearch: {
      label: 'Suche',
      placeholder:
        'Name, Benutzername, Aktivierungscode, E-Mail-Adresse (SiedlerKarte)',
    },
    fieldCardNumberSearch: {
      placeholder: 'Kartennummer (Mybility)',
    },
    loginUserUser: 'Inloggen als gebruiker',
    selectTenantModal: {
      header: 'Select ein tenant',
      loginActionText: 'Anmeldung',
    },
    submitText: 'Anmeldung',
    title: 'Helpdesk',
  },
  location: {
    insert: {
      title: 'Neuer Standort',
      fields: {
        City: {
          label: 'Ort',
        },
        Email: {
          label: 'E-Mail-Adresse',
        },
        Lat: {
          label: 'Latitude',
        },
        Long: {
          label: 'Longitude',
        },
        Name: {
          label: 'Name',
          errors: {
            required: 'Geben Sie den Namen ein.',
          },
        },
        IsVisibleInApp: {
          label: 'IsVisibleInApp',
        },
        PhoneNumber: {
          label: 'Telefonnummer',
        },
        POSOrderUrl: {
          label: 'POSOrderUrl',
        },
        StreetName: {
          label: 'Straßenname',
        },
        StreetNumber: {
          label: 'Hausnummer',
        },
        ZipCode: {
          label: 'Postleitzahl',
        },
      },
      getCoordinates: 'Koordinaten abrufen',
      showMap: 'Karte anzeigen',
    },
    setVisibleActive: 'Sichtbar aktivieren ',
    setVisibleInActive: 'Sichtbar inaktiv setzen ',
  },
  login: {
    createAccount: 'Konto erstellen!',
    fieldEmailAddress: {
      label: 'E-Mail-Adresse',
      errors: {
        required: 'Geben Sie Ihre E-Mail-Adresse ein.',
      },
    },
    fieldPassword: {
      label: 'Passwort',
      errors: {
        required: 'Geben Sie Ihr Passwort ein.',
      },
    },
    forgotPassword: 'Passwort vergessen?',
    noAccount: 'Kein Konto?',
    header: 'Willkommen zurück bei {name}!',
    remeberMe: 'Willkommen zurück bei {name}!',
    submitText: 'Login',
  },
  loyaltyprogram: {
    insert: {
      title: 'Neues Sparprogramm',
      fields: {
        Name: {
          label: 'Namen',
          errors: {
            required: 'Geben Sie einen Namen ein.',
          },
        },
        LoyaltyProgramTypeId: {
          label: 'Type',
          errors: {
            required: 'Wählen Sie einen Sparprogrammtyp aus',
          },
        },
        CurrencyId: {
          label: 'Währung',
          errors: {
            required: 'Währung auswählen.',
          },
        },
        CouponId: {
          label: 'Gutschein',
          errors: {
            required: 'Gutschein auswählen.',
          },
        },
        ArticleCode: {
          label: 'Artikelcode',
          errors: {
            required: 'Geben Sie einen Artikelcode ein.',
          },
        },
        ArticleGroup: {
          label: 'Artikelgruppe',
          errors: {
            required: 'Geben Sie einen Artikelgruppe ein.',
          },
        },
        StartDate: {
          label: 'Anfangsdatum',
          required: 'Geben Sie ein Startdatum ein.',
        },
        EndDate: {
          label: 'Tot und erfüllt',
          errors: {
            greaterThan: 'Das Datum muss nach dem Anfangsdatum liegen.',
          },
        },
      },
    },
    details: {
      confirmDelete:
        'Sind Sie sicher, dass Sie dieses Sparprogramm entfernen möchten?',
      deleteSuccess: 'Sparprogramm entfernt.',
      rules: 'Regels',
      ruleRunOn: {
        Article: 'Ein Artikel ({name} - {articleCode}) wird bezahlt.',
        ArticleGroup:
          'Ein Artikel aus dieser Artikelgruppe ({name} - {articleGroup}) wird abgerechnet.',
        TransactionAmountNegative:
          'Bei Zahlung mit dieser Zahlungsmethode ({currencyName}).',
        RedeemCoupon: "Wenn ein '{couponTitle}'-Gutschein eingelöst wird.",
      },
      runWhen: 'Wann ausgeführt',
    },
  },
  merchant: {
    index: {},
    insert: {
      title: 'Nieuwe merchant',
      fields: {
        FinanceEmailAddress: {
          label: 'Finance e-mailadres',
        },
        Name: {
          label: 'Naam',
          errors: {
            required: 'Voer de naam in.',
          },
        },
        RelationID: {
          label: 'Relatie',
          errors: {
            required: 'Selecteer een relatie.',
          },
        },
      },
    },
    details: {
      appUsers: {
        addPosModule: 'Module toevoegen',
        addUser: 'App Login toevoegen',
        editUser: 'App Login wijzigen',
        deleteAppUserConfirm:
          'Weet je zeker dat je de app login <b>{0}</b>  wilt verwijderen?',
        deletePosModuleConfirm:
          'Weet je zeker dat je de module <b>{moduleName} ({currencyName})</b> wilt verwijderen?',
        title: 'App logins',
      },
      managerUsers: {
        addUser: 'Manager Login toevoegen',
        editUser: 'Manager Login wijzigen',
        deleteConfirm:
          'Weet je zeker dat je de manager login <b>{0}</b> wilt verwijderen?',

        title: 'Manager logins',
      },
    },
    addConsumerUser: {
      title: 'Verbraucher hinzufügen',
      fields: {
        activationCode: {
          label: 'Aktivierungscode',
          required: 'Geben Sie einen Aktivierungscode ein.',
          minLength: 'Ein Aktivierungscode ist immer 8 Zeichen lang.',
        },
      },
      successMessage: '{activationCode} wurde hinzugefügt.',
    },
  },
  merchantPayments: {
    title: 'Händler Auszahlungen',
  },
  merchantRefunds: {
    accountName: 'Kontoinhaber',
    accountNumber: 'Kontonummer',
    amount: 'Anzahl der Rückerstattungen',
    amountText: 'Betrag',
    downloaded: 'Heruntergeladen am',
    generateFile: 'Datei generieren',
    refunds: 'Stornierungen',
    title: 'Händlererstattungen',
  },
  merchantPaymentDetails: {
    title: 'Transaktionen',
  },
  months: {
    januari: 'Januar',
    februari: 'Februar',
    march: 'März',
    april: 'April',
    may: 'Mai',
    june: 'Juni',
    july: 'Juli',
    august: 'August',
    september: 'September',
    october: 'Oktober',
    november: 'November',
    december: 'Dezember',
  },
  montlyTransactionTotals: {
    amountTotal: 'Gesamtbetrag',
    amountValue: 'Betrag mit Wert',
    amountNoValue: 'Betrag ohne Wert',
  },
  modules: {
    POS_PAYMENT: 'Bezahlen',
    POS_TOPUP: 'Upgrade',
    POS_DEDUCT: 'Abschreibung',
    LOYALTY_REDEEM_COUPON: 'Gutschein einlösen',
    LOYALTY_PUSH_COUPON: 'Gutschein ausstellen',
    POS_PRODUCT_REGISTER: 'Produkte zur Kasse',
    USER_INFO: 'Benutzerinfo',
    LOYALTY_SAVINGCARDS: 'Sparkarten',
    MYB_TRIPS: 'Trips module',
    PSDPP_PAY_BALANCE: 'PSDPP PAY BALANCE',
    PSDPP_BALANCE: 'PSDPP BALANCE',
    USER_ACCESS_CONTROL: 'Zugriffskontrolle (Abonnement)',
    EVENT_USER_ACCESS_CONTROL: 'Zugriffskontrolle (Ereignis)',
    SAVING_ARTICLE: 'Über Artikel speichern',
  },
  noMatch: {
    header: 'Seite nicht gefunden',
    text: 'Die angeforderte Seite wurde nicht gefunden. ',
  },
  paymentsPerDay: {
    title: 'Zahlungen pro Tag',
  },
  paymentTransactions: {
    title: 'Zahlungen',
  },
  paymentsTokenType: {
    title: 'Zahlungen nach Kartentyp',
  },
  pos: {
    activate: {
      action: 'Activeren',
      confirmMessage:
        'Weet je zeker dat je deze pos({name}) wilt <b>activeren</b>?.',
      success: 'Pos({name}) is geactiveerd.',
    },
    deactivate: {
      action: 'Deactiveren',
      confirmMessage:
        'Weet je zeker dat je deze pos({name}) wilt <b>deactiveren</b>?.',
      success: 'Pos({name}) is gedeactiveerd.',
    },
    insert: {
      title: 'Nieuwe pos',
      fields: {
        Name: {
          label: 'Naam',
          errors: {
            required: 'Voer de naam in.',
          },
        },
        PosTypeID: {
          label: 'Type',
          errors: {
            required: 'Selecteer een type.',
          },
        },
      },
    },
  },
  register: {
    header: 'Wordt ook een Topr!',
    heading: 'Registreren',
    headerHow: 'Hoe werkt het?',
    textHow:
      'Registreer je via het formulier aan de rechterkant. Je ontvangt ons een bevestigings mailtje waarna je meteen aan de slag kan met je eerste spaarkaart!',
    fieldBic: {
      label: 'Bank',
      info: 'De bank waar de automatische incasso wordt geincasseerd.<br/>Na de 1e 60 dagen gaat het abonnement in.',
    },
    fieldContactName: {
      label: 'Contactpersoon',
      errors: {
        required: 'Voer de contactpersoon in.',
      },
    },
    fieldMerchantName: {
      label: 'Bedrijfsnaam',
      errors: {
        required: 'Voer de bedrijfsnaam in.',
      },
    },
    submitText: 'Ik ben een Topr!',
    fieldPhonenumber: {
      label: 'Telefoonnummer',
      errors: {
        required: 'Voer het telefoonnummer in.',
      },
    },
    fieldEmailAddress: {
      label: 'E-Mail-Adresse',
      errors: {
        required: 'Voer het e-mailadres in.',
        regex: 'Voer een geldig e-mailadres in.',
      },
    },
    fieldConfirmEmailAddress: {
      label: 'Bevestig e-mailaddress',
      errors: {
        required: 'Voer het bevestig e-mailadres in.',
        regex: 'Voer een geldig bevestig e-mailadres in.',
        match:
          'Het bevestig e-mailadres komt niet overeen met het e-mailadres.',
      },
    },
    fieldZipcode: {
      label: 'Postcode',
      errors: {
        required: 'Voer de postcode in.',
      },
    },
    fieldHouseNumber: {
      label: 'Hausnummer',
      errors: {
        required: 'Voer het huisnummer in.',
      },
    },
    fieldCity: {
      label: 'Ortnaam',
      errors: {
        required: 'Voer de plaatsnaam in.',
      },
    },
    fieldStreet: {
      label: 'Straat',
      errors: {
        required: 'Voer de straat in.',
      },
    },
    fieldPassword: {
      label: 'Passwort',
      placeholder: 'Mindestens 6 Zeichen',
      errors: {
        required: 'Geben Sie das Passwort ein.',
        min: 'Geben Sie mindestens 6 Zeichen ein.',
      },
    },
    fieldConfirmPassword: {
      label: 'Bevestig wachtwoord',
      errors: {
        required: 'Voer het bevestig wachtwoord in.',
        min: 'Geben Sie mindestens 6 Zeichen ein.',
        match: 'Het bevestig wachtwoord komt niet overeen met het wachtwoord.',
      },
    },
  },
  registerConfirm: {
    header: 'Aan het werk!',
    headingConfirming: 'Account bevestigen',
    headingRegisterSuccess: 'Account bevestigd',
    loaderText: 'Bevestigen...',
    succesText:
      'Je wordt in {0}s automatisch doorgestuurd om je eerste spaarkaart te maken ' +
      'of klik <a href="{1}">hier</a>!',
  },
  resetPassword: {
    fields: {
      password: {
        label: 'Passwort',
        placeholder: 'Mindestens 6 Zeichen',
        errors: {
          required: 'Geben Sie das Passwort ein.',
          min: 'Geben Sie mindestens 6 Zeichen ein.',
        },
      },
      passwordConfirm: {
        label: 'Passwort bestätigen',
        errors: {
          required: 'Passwort bestätigen',
          min: 'Geben Sie mindestens 6 Zeichen ein.',
          match:
            'Das Bestätigungspasswort stimmt nicht mit dem Passwort überein.',
        },
      },
    },

    header: 'Passwort zurücksetzen',
    info: 'Geben Sie unten Ihr neues Passwort ein. Das Passwort muss mindestens 6 Zeichen lang sein.',
    serverErrors: {
      REQUESTIDENTIFIER_NOT_FOUND:
        "<span>Dieser Link zum Zurücksetzen ist nicht mehr aktiv. </span><a href='/forgotpassword'>Neuen Link zum Zurücksetzen senden</a>.",
    },
    submitText: 'Passwort zurücksetzen',
    success: 'Ihr Passwort wurde geändert',
  },
  sam: {
    block: {
      title: 'Blokkeren',
      success: 'Sam ({SamId}) is geblokkeerd.',
    },
    insert: {
      title: 'Nieuwe VSAM',
      fields: {
        SamTypeID: {
          label: 'Type',
          errors: {
            required: 'Selecteer een type.',
          },
        },
      },
    },
    link: {
      title: 'Link sam',
      samId: {
        label: 'SamID',
        errors: {
          required: 'Voer een SamID in.',
          alreadyLinked:
            'Deze sam is al gelinkt aan <a href="/terminal/details/{terminalId}">deze terminal</a>. ',
        },
      },
    },
    unblock: {
      title: 'Deblokkeren',
      success: 'Sam ({SamId}) is gedeblokkeerd.',
    },
    unlink: {
      action: 'Ontkoppel',
      confirmMessage:
        'Weet je zeker dat je de sam({SamId}) wilt <b>ontkoppelen</b>?.',
      success: 'Sam({SamId}) is ontkoppeld.',
    },
  },
  savingcard: {
    averageStamps: {
      text: 'Pro Sparkarte wurden durchschnittlich <strong>{0}</strong> Briefmarken ausgegeben.',
      title: 'Durchschnittliche Anzahl Briefmarken',
    },
    totalCards: {
      text: 'Es wurden <strong>{0}</strong> Sparkarten ausgegeben.',
      title: 'Anzahl Sparkarten',
    },
    totalCardsProcessed: {
      text: 'Es wurden <strong>{0}</strong> Treuekarten abgeschlossen.',
      title: 'Anzahl der abgeschlossenen Kundenkarten',
    },
    confirmDelete:
      'Sind Sie sicher, dass Sie diese Sparkarte löschen möchten?<br/>' +
      'HINWEIS: Diese Sparkarte wird auch für Ihre Benutzer entfernt.',
  },
  savingcardDetails: {
    couponsIssued: 'Ausgegebene Gutscheine insgesamt',
    couponsRedeemed: 'Gutscheine eingelöst',
    couponsNotRedeemed: '<i>Nicht</i> eingelöste Gutscheine',
    percentText:
      '<strong>{0} %</strong> der Sparkarten haben <strong>{1}</strong> Stempel',
    percentTitle: 'Prozentsatz pro Stempel',
    savingcardsAverage:
      'Briefmarken werden durchschnittlich pro Sparkarte ausgegeben.',
    savingcardsCompleted: 'Speichern der Karten abgeschlossen',
    savingcardsIssued: 'Sparkarten ausgestellt',
  },
  savingCardCreated: {
    title: 'Speicherkarte erstellt',
    contentIntro: 'Die Sparkarte <i>{0}</i> wurde erstellt.',
  },
  savingcardInsert: {
    title: 'Neue Sparkarte',
    fields: {
      AutoRefresh: {
        label: 'Automatische Aktualisierung',
        info: 'Wenn <b>Automatische Verlängerung</b> angekreuzt ist, erhält der Kunde sofort eine neue Sparkarte, wenn seine Sparkarte fertig ist.',
      },
      CouponDescription: {
        label: 'Beschreibung',
        info:
          'Die Beschreibung/Erklärung des Gutscheins. <ul>' +
          '<li>Was der Kunde bei Abgabe dieses Gutscheins erhält.</li>' +
          '<li>Für welche Produkte/Dienstleistungen gilt dieser Gutschein.</li>' +
          '<li>An welchen Stellen kann der Kunde diesen Gutschein abgeben</li>' +
          '</ul>' +
          'Beispiel: <i>Sie erhalten gegen Vorlage dieses Gutscheins eine köstliche Tasse Kaffee.' +
          '<br/><br/>Gilt nicht für Latte Macchiato.</i>',

        errors: {
          required: 'Geben Sie die Beschreibung ein ({0}).',
        },
      },
      Description: {
        label: 'Beschreibung',
        info:
          'Die Beschreibung/Erklärung der Sparkarte. <ul>' +
          '<li>Wie man einen Stempel verdient.</li>' +
          '<li>Welche Belohnung erhält der Kunde mit einer vollen Sparkarte.</li>' +
          '<li>Bei Abschluss einer Sparkarte erhält der Kunde einen Gutschein für das Sparziel.</li>' +
          '</ul>' +
          'Beispiel: <i>Sie erhalten 1 Stempel für jeden Besuch. Mit 5 Stempeln erhalten Sie einen Gutschein für eine Tasse Kaffee gratis.</i>',
        errors: {
          required: 'Geben Sie eine Beschreibung ein ({0}).',
        },
      },
      DiscountAmount: {
        Amount: {
          label: 'Mengenrabatt',
          placeholder: '1,50',
          info: 'Der Rabattbetrag des Gutscheins.<br/>Bsp.: <i>€ 1,50</i>',
          errors: {
            required: 'Geben Sie den Rabattbetrag ein.',
          },
        },
        Percentage: {
          label: 'Prozentrabatt',
          placeholder: '33',
          info: 'Der prozentuale Rabatt des Gutscheins.<br/>zB: <i>33%</i>',
          errors: {
            required: 'Geben Sie den Prozentsatz ein',
          },
        },
      },
      ImageFile: {
        label: 'Bild',
        info: 'Das Bild für die Sparkarte',
        errors: {
          required: 'Geben Sie ein Bild ein.',
        },
      },
      Reward: {
        label: 'Text der Belohnungsmail',
        info:
          'Der Text für die Benachrichtigungs-E-Mail, die ein Kunde erhält, wenn seine Sparkarte fertig ist. <ul>' +
          '</ul>',

        errors: {
          required: 'Geben Sie den Text der Belohnungsmail ein ({0}).',
        },
      },
      RewardAmount: {
        label: 'Betrag',
        placeholder: '1,50',
        info: 'Die Höhe der Prämie.<br/>Bsp.: <i>1,50 €</i>',
        errors: {
          required: 'Geben Sie die Höhe der Prämie ein.',
        },
      },
      RewardType: {
        label: 'Belohnungsart',
      },
      Title: {
        label: 'Titel',
        info: 'Der Titel der Sparkarte. B. "Gratis-Tasse Kaffee"',
        errors: {
          required: 'Geben Sie einen Titel ein ({0}).',
        },
      },
      StampCount: {
        label: 'Anzahl Stempel',
        info: 'Die Anzahl der Stempel der Sparkarte.',
        errors: {
          required: 'Geben Sie die Anzahl der Stempel ein.',
        },
      },
      ValidFrom: {
        label: 'Datum des Inkrafttretens',
        info: 'Gültigkeitsdatum der Sparkarte. Wird kein Gültigkeitsdatum eingetragen, ist die Sparkarte sofort aktiv.',
      },
      ValidTill: {
        label: 'Enddatum',
        info:
          'Enddatum der Sparkarte.<ul>' +
          '<li>Wird kein Enddatum eingetragen, bleibt die Sparkarte immer aktiv.</li>' +
          '<li>Das Enddatum ist bis einschließlich, wenn also das Enddatum der 15.11.2018 ist, ist die Sparkarte an diesem Tag noch aktiv</li>' +
          '</ul>',
        errors: {
          fromGreater: 'Das Enddatum muss nach dem Wirksamkeitsdatum liegen.',
        },
      },
      VoucherType: {
        label: 'Gutscheintyp',
        info:
          'Art des Gutscheins, den der Kunde mit einer vollen Sparkarte erhält.<ul>' +
          '<li><b>Produkt verschenken</b>, Der Kunde erhält einen Gutschein für ein kostenloses Produkt.</li>' +
          '<li><b>Prozentrabatt</b>, Der Kunde erhält einen Gutschein über einen prozentualen Rabatt auf ein oder mehrere Produkte.</li>' +
          '<li><b>Mengenrabatt</b>, Der Kunde erhält einen Gutschein über einen Mengenrabatt auf ein oder mehrere Produkte.</li>' +
          '</ul>',
      },
    },
    VoucherTypes: {
      FreeProduct: 'Produkt verschenken',
      AmountDiscount: 'Betragsrabatt',
      PercentageDiscount: 'Prozenter Rabatt',
    },

    untill: 'Bis',
  },
  savingcardEdit: {
    title: 'Sparkarte ändern',
    saveSuccess: 'Die Sparkarte wurde gespeichert.',
  },
  siedlerChargeGuthaben: {
    title: 'Kartenaufbuchung',
    actionText: 'Generieren',
  },
  siedlerCompanyCoupons: {
    title: 'Unternehemen Wertgutscheine',
    actionText: 'Generieren',
    customerTitle: 'Zeitraum title',
  },
  siedlerInvoiceCoupons: {
    title: 'Abrechnung Wertgutscheine',
    actionText: 'Generieren',
  },
  siedlerGebuhrCurrency: {
    title: 'Gebuhr Knödels',
    actionText: 'Generieren',
  },
  siedlerBearbeitungsGebuhr: {
    title: 'Bearbetingsgebuhr Guthaben',
    actionText: 'Generieren',
  },
  siedlerInvoiceCurrency: {
    title: 'Abrechnung Währung',
    actionText: 'Generieren',
  },
  siedlerRemove: {
    action: 'Aus Siedler entfernen',
    confirmMessage:
      '{name} wird von Siedler <b>entfernt</b> wirden.<br/>Sind Sie sicher?',
    success: '{name} ist aus Siedler entfernt.',
  },
  stornos: {
    title: "Upload Storno's",
    form: {
      stornoField: {
        label: 'Storno bestand (.csv)',
        dropfile: 'Klik hier om het storno bestand (.csv) te uploaden.',
        error: {
          required: 'Voer het storno bestand in.',
        },
      },
      failed: "Het verwerken van de storno's is MISLUKT",
      success: "Het verwerken van de storno's is  GELUKT",
    },
  },
  stornoStatus: {
    title: "Storno's",
    status: {
      Canceled: 'Gestorneerd',
      CanceledMailMerged: 'Brief verstuurt',
      ReadyForBlock: 'Gereed voor blokkeren',
      Blocked: 'Geblokkeerd',
      Collected: 'Betaald',
    },
  },
  subscription: {
    index: {
      title: 'Abonnements',
      confirmDelete:
        'Möchten Sie item <b>{name}</b> von <i>{subscriptionName}</i> wirklich löschen?',
    },
    editItem: {
      title: 'Item ändern',
    },
    insert: {
      title: 'Abonnement hinzufügen',
      fields: {
        name: {
          label: 'Namen',
          required: 'Geben Sie einen Namen ein.',
        },
        systemName: {
          label: 'Systemname',
          required: 'Geben Sie einen Systemnamen ein.',
        },
      },
    },
    edit: {
      title: 'Abonnement ändern',
    },
    insertItem: {
      title: 'Item hinzufügen',

      fields: {
        amount: {
          label: 'Anzahl von',
          labelEnum: {
            Day: 'Anzahl der Tage',
            Week: 'Anzahl der Woche',
            Month: 'Anzahl der Monate',
            Year: 'Aantal Jahre',
            Trips: 'Aantal Fahrten',
            Unlimited: 'Unlimited',
          },
          required: 'Geben Sie eine Zahl ein',
        },
        name: {
          label: 'Name',
          required: 'Geben Sie eine Name ein',
        },
        description: {
          label: 'Beschreibung',
          required: 'Geben Sie eine Beschreibung ein',
        },
      },
    },
    insertUserSubscription: {
      //
      title: 'Abonnement Hinzufügen',
      fields: {
        ItemType: {
          label: 'Abonnement',
          errors: {
            required: 'Wählen Sie ein Abonnement aus.',
          },
        },
        StartDate: {
          label: 'Anfangsdatum',
          required: 'Geben Sie ein Startdatum ein.',
        },
        EndDate: {
          label: 'Tot und erfüllt',
          errors: {
            greaterThan: 'Das Datum muss nach dem Anfangsdatum liegen.',
          },
        },
      },
    },
    editUserSubscription: {
      title: 'Abonnement bearbeiten',
    },
    expiredMessage: {
      header: 'Je abonnement is verlopen.',
      text: 'Ga naar de <a href="/subscriptions">abonnementen pagina</a> om je abonnement te vernieuwen',
    },
  },
  tags: {
    index: {
      title: 'Tags',
    },
    insert: {
      title: 'Tag hinzufügen',
      fields: {
        name: {
          title: 'Tag-Namen',
          required: 'Geben Sie einen Tag-Namen ein.',
        },
      },
    },
    insertUserTag: {
      fields: {
        tag: { title: 'Tag', required: 'Wählen Sie ein tag aus.' },
      },
    },
    deleteUserTagConfirm:
      'Möchten Sie das Tag <b>{tagName}</b> wirklich entfernen?',
    edit: {
      title: 'Tag ändern',
    },
  },
  terminal: {
    insert: {
      title: 'Nieuwe terminal',
      fields: {
        Description: {
          label: 'Beschreibung',
          errors: {
            required: 'Voer de omschrijving in.',
          },
        },
        TerminalTypeID: {
          label: 'Type',
          errors: {
            required: 'Selecteer een type.',
          },
        },
      },
    },
  },
  topMenu: {
    changeMerchant: {
      label: 'Händler wechseln',
      required: 'Wählen einen Händler',
    },
    consumers: {
      helpdesk: 'Kunden finden',
      tags: 'Tags',
    },
    finance: {
      title: 'Finanzen',
      merchantPayments: 'Händlerauszahlungen',
      montlyTransactionTotals: 'Monatliche Auszahlungen',
    },
    loyalty: {
      title: 'Loyalty',
      savingCards: 'Sparkarten',
      vouchers: 'Gutscheine',
    },
    reports: {
      title: 'Berichte',
      payments: {
        groupName: 'Zahlungen',
        paymentsPeriod: 'Zahlungen in Periode',
        paymentsDay: 'Zahlungen pro Tag',
      },
      wallets: {
        groupName: 'Brieftaschen',
      },
      managerReports: {
        Mybility: {
          MybTrips: 'Reisen',
        },
        Pdx: {
          PdxCardPaymentTotals: 'Zahlungen per Karte',
        },
        Siedler: {
          SiedlerInvoiceCoupons: 'Abrechnung Wertgutscheine',
          SiedlerGebuhrKnodels: 'Knödelabrechnung an Betreiber Gebühr',
          SiedlerinvoiceCurrency: 'Abrechnung Currencies',
          SiedlerBearbeitungsGebuhr: 'Bearbeitungsgebühr Guthaben',
          SiedlerCouponsUnternehmen: 'Wertgutscheine pro Unternehmen',
          SiedlerChargeGuthaben: 'Kartenaufbuchung',
        },
      },
    },
  },
  tokens: {
    activationCode: 'Aktivierungscode',
    blockToken: {
      action: 'Blockieren',
      confirmMessage:
        'Der Zahlungsschlüssel wird <b>gesperrt</b>.<br/>Sind Sie sicher?',
      success: 'Der Zahlungsschlüssel wurde gesperrt.',
    },
    dateActivated: 'Aktiviert',
    validThru: 'Gültig bis',
    unblockToken: {
      action: 'Blockierung aufheben',
      confirmMessage:
        'Der Zahlungsschlüssel wird <b>entsperrt</b>.<br/>Sind Sie sicher?',
      success: 'Der Zahlungsschlüssel wurde entsperrt.',
    },
  },
  tokenStatus: {
    activated: 'Aktiv',
    notActivated: 'Nicht aktiv',
    blocked: 'Blockiert',
    expired: 'Abgelaufen',
  },
  transaction: {
    title: 'Transaktion suchen',
  },
  trips: {
    bookedTrips: 'Geboekte ritten',
    checkedInTrips: 'Ingecheckte ritten',
    completedTrips: 'Voltooide ritten',
    merchants: 'Centrales',
    title: 'Ritten',
    name: {
      label: 'Naam',
      placeholder: 'Zoek op naam',
    },
  },
  userBalances: {
    title: 'Benutzerguthaben',
  },
  userDetails: {
    blockedStornos: 'Stornos gesperrt',
    childUserConnections: 'Untergeordnete Benutzer',
    parentUserConnections: 'Übergeordneter Benutzer',
    resetPassword: {
      action: 'Passwort zurücksetzen',
      confirmResetPassword:
        'Das Passwort wird auf <b>welcome123</b> zurückgesetzt.<br/>Sind Sie sicher?',
      success: 'Das Passwort wurde aktualisiert',
    },
    saveUserFieldSuccess: '{fieldName} gespeichert',
  },
  userScheduleBonus: {
    edit: {
      title: 'UserScheduleBonus ändern',
    },
    insert: {
      title: 'UserScheduleBonus planen',
      fields: {
        Type: {
          label: 'Typ',
          errors: {
            required: 'Typ auswählen.',
          },
        },
        ReplenishCurrency: {
          label: 'Auffüllen bis?',
          tooltip:
            'Wenn dies ausgewählt ist, wird das Zahlungsmittel für diesen Benutzer um den Betrag ergänzt.',
        },
        ReplenishCoupon: {
          label: 'Auffüllen bis?',
          tooltip:
            'Wenn dies ausgewählt ist, werden die Coupons für diesen Benutzer um den Betrag ergänzt.',
        },
        ScheduleType: {
          label: 'Intervall',
          enum: {
            Daily: 'Täglich',
            Weekly: 'Wöchentlich',
            Monthly: 'Monatlich',
          },
        },
        ScheduleDay: {
          label: 'Tag',
        },
        StartDate: {
          label: 'Anfangsdatum',
          required: 'Geben Sie ein Startdatum ein.',
        },
        EndDate: {
          label: 'Tot und erfüllt',
          errors: {
            greaterThan: 'Das Datum muss nach dem Anfangsdatum liegen.',
          },
        },
      },
    },
    status: {
      Active: 'Activ',
      NotYetActive: 'Noch nicht aktiv',
      Expired: 'Abgelaufen',
    },
  },
  walletBulkChange: {
    title: 'Guthaben aktualisieren (Bulk)',
    tabGenerateFile: {
      title: '1. Vorlagendatei generieren',
      info:
        '<ol>' +
        '<li>Zahlungsmethode auswählen</li>' +
        '<li>Klicken Sie auf Vorlagendatei erstellen</li>' +
        '</ol>',
      action: 'Vorlagendatei erstellen',
    },
    tabUploadChanges: {
      title: '2. Kontostandsänderungen hochladen',
      info:
        'Sie können die generierte Vorlagendatei für mehrere verwenden' +
        'Kunden passen das Guthaben an.<br />' +
        'Pro Zeile in der Importdatei können Sie für einen Kunden' +
        "seinen/ihren Kontostand aktualisieren.<br /><br /> Über die Spalte '<i>Betrag</i>'" +
        'kann den Betrag in Cent eingeben (geben Sie also 750 für einen Betrag von 7,50 ein).<br />' +
        "Über die Spalte '<i>Modifier</i>' können Sie folgende Änderungen vornehmen:" +
        "<ul><li><b>+</b>, füllen Sie das Guthaben des Kunden mit dem Betrag in der Spalte '<i>Betrag</i>' auf.</li>" +
        '<li><b>-</b>, notieren Sie den Kontostand des Kunden mit dem ' +
        "Betrag in der Spalte '<i>Betrag</i>'.</li>" +
        '<li><b>=</b>, das Guthaben des Kunden bis einschließlich ' +
        "aufladen der Betrag in der Spalte '<i>Betrag</i>'.</li>" +
        '<li><b>0</b>, setzen Sie das Guthaben des Kunden auf 0 zurück.</li>' +
        '</ul>',
      codeExplained:
        '<li>Johns <b>{{currencyName}}</b>-Guthaben wird mit <i>7,40</i></li> aufgeladen',
    },
  },
  walletCharge: {
    title: 'Aufladen',
    fields: {
      Currency: {
        label: 'Zahlungsmethode',
      },
      Description: {
        label: 'Beschreibung',
      },
    },
    successAlert: {
      message:
        '{amountFormatted} {currencyName} wurde aufgeladen für {count} Kunden.',
    },
    failedAlert: {
      message: 'Aufladen bei {count} Kunden fehlgeschlagen. ',
    },
  },
  walletWithdraw: {
    title: 'Herabstufen',
    fields: {
      Description: {
        label: 'Beschreibung',
      },
    },
    successAlert: {
      message:
        'Es wurde {amountFormatted} {currencyName} abgeschrieben für {count} Kunden.',
    },
    failedAlert: {
      message: 'Herabstufen bei {count} Kunden fehlgeschlagen. ',
    },
  },
}
