import { action, makeObservable, observable, runInAction } from 'mobx'
import { IExceptionError } from 'src/Models/Server/Response/Exception/ExceptionError'
import IMerchantVTO from 'src/Models/Server/Response/Merchant/IMerchantVTO'
import MerchantController from 'src/Services/ToprCoreApi/Axios/Controllers/MerchantController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export default class Store extends BaseStore<IMerchantVTO> {
  public EditMerchant: IMerchantVTO = null

  constructor() {
    super()

    makeObservable(this, {
      Initialize: action,
      ToggleEditMerchantModal: action,
      EditMerchant: observable,
    })
  }

  public Initialize(merchantGuid: string) {
    this.IsLoading = true

    MerchantController.GetMerchant(merchantGuid)
      .then((response) => {
        runInAction(() => {
          this.ViewModel = response
          this.IsLoading = false
        })
      })
      .catch((error: IExceptionError) => {
        appUiStore.ShowError(error)
        runInAction(() => (this.IsLoading = false))
      })
  }

  public ToggleEditMerchantModal(editMerchant: IMerchantVTO) {
    this.EditMerchant = editMerchant
  }
}

export const merchantDetailsStore = new Store()
