import React from 'react'
//import GoogleMapReact from 'google-map-react'
import { apikey } from '../Helpers/MapHelpers'

import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'

interface IProps {
  lat?: number
  lng?: number
  zoom: number
}

const containerStyle = {
  width: '400px',
  height: '400px',
}

const McMap: React.FC<IProps> = ({ lat, lng, zoom }) => {
  if (lat === null && lng === null) {
    return null
  }

  const center = {
    lat,
    lng,
  }

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: apikey,
  })

  return isLoaded ? (
    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={zoom}>
      <Marker position={center} />
      <></>
    </GoogleMap>
  ) : (
    <></>
  )
}

export default McMap
