import { CentsToCurrencyAmount } from 'src/Helpers/CurrencyHelpers'
import { getCurrencyOptions } from 'src/Helpers/SelectHelpers'
import { t } from 'src/I18n'
import { ISelectOption } from 'src/Models/Client'
import { LoyaltyRewardTypes } from 'src/Models/Server/Enums'
import IGenericError from 'src/Models/Server/IGenericError'
import { IEditLoyaltyRewardRequest } from 'src/Models/Server/Request/LoyaltyProgram/IEditLoyaltyRewardRequest'
import CurrencyVTO from 'src/Models/Server/Response/Currency/CurrencyVTO'
import { ILoyaltyRewardVTO } from 'src/Models/Server/Response/LoyaltyProgram/ILoyaltyRewardVTO'
import CouponController from 'src/Services/ToprCoreApi/Axios/Controllers/CouponController'
import CurrencyController from 'src/Services/ToprCoreApi/Axios/Controllers/CurrencyController'
import LoyaltyProgramController from 'src/Services/ToprCoreApi/Axios/Controllers/LoyaltyProgramController'
import { appUiStore } from 'src/Stores/AppUiStore'

export const rewardTypeOptions: ISelectOption[] = [
  {
    value: LoyaltyRewardTypes.CurrencyStaticReward.toString(),
    label: t.enums.LoyaltyRewardTypes.CurrencyStaticReward,
  },
  {
    value: LoyaltyRewardTypes.CurrencyOrderPercentageReward.toString(),
    label: t.enums.LoyaltyRewardTypes.CurrencyOrderPercentageReward,
  },
  {
    value: LoyaltyRewardTypes.CouponReward.toString(),
    label: t.enums.LoyaltyRewardTypes.CouponReward,
  },
]

export interface IEditLoyaltyReward {
  loyaltyProgramId: number
  loyaltyReward: ILoyaltyRewardVTO
}

export interface IState {
  isLoading: boolean
  isSubmitting: boolean
  currencies: CurrencyVTO[]
  couponOptions: ISelectOption[]
  viewModel: IFormValues
}

export const initialState: IState = {
  isLoading: true,
  isSubmitting: false,
  currencies: [],
  couponOptions: [],
  viewModel: undefined,
}

export interface IFormValues {
  RewardTypeOption: ISelectOption
  RewardCurrencyOption: ISelectOption
  RewardCouponOption: ISelectOption
  DisplayAmount: string
  AmountCents: number
}

export type Actions =
  | {
      type: 'initialize'
      viewModel: IFormValues
      currencies: CurrencyVTO[]
      couponOptions: ISelectOption[]
    }
  | {
      type: 'setIsSubmitting'
      isSubmitting: boolean
    }

export const reducer = (state: IState, action: Actions): IState => {
  switch (action.type) {
    case 'initialize':
      return {
        ...state,
        viewModel: action.viewModel,
        currencies: action.currencies,
        couponOptions: action.couponOptions,
        isLoading: false,
      }
    case 'setIsSubmitting':
      return { ...state, isSubmitting: action.isSubmitting }
    default:
      throw new Error('Invalid Reducer Action')
  }
}

export const reducerActions = (
  state: IState,
  dispatch: (action: Actions) => void
) => {
  return {
    initialize: ({ loyaltyProgramId, loyaltyReward }: IEditLoyaltyReward) => {
      Promise.all([
        CurrencyController.GetMyMerchantCurrencies(),
        CouponController.GetCouponsMerchant(),
      ])
        .then((values) => {
          const currencies = values[0].filter((x) => x.CurrencyId !== 239)
          const currencyOptions = getCurrencyOptions(currencies)
          const couponOptions = values[1].map((coupon) => {
            return {
              label: coupon.FullTitle,
              value: coupon.CouponId.toString(),
            }
          })

          const viewModel: IFormValues = {
            RewardTypeOption: rewardTypeOptions.find(
              (x) => x.value === loyaltyReward.LoyaltyRewardTypeId.toString()
            ),
            RewardCurrencyOption:
              loyaltyReward.Currency !== null
                ? currencyOptions.find(
                    (x) =>
                      x.value === loyaltyReward.Currency.CurrencyId.toString()
                  )
                : currencyOptions[0],
            RewardCouponOption:
              loyaltyReward.Coupon !== null
                ? couponOptions.find(
                    (x) => x.value === loyaltyReward.Coupon.CouponId.toString()
                  )
                : couponOptions[0],
            DisplayAmount:
              loyaltyReward.Currency !== null
                ? CentsToCurrencyAmount(
                    loyaltyReward.Amount,
                    loyaltyReward.Currency.IsDecimal,
                    loyaltyReward.Currency.StringFormat
                  )
                : loyaltyReward.Amount.toString(),
            AmountCents: loyaltyReward.Amount,
          }

          dispatch({
            type: 'initialize',
            viewModel,
            currencies,
            couponOptions,
          })
        })
        .catch((error: IGenericError) => {
          appUiStore.ShowError(error)
        })
    },

    save: (
      editLoyaltyReward: IEditLoyaltyReward,
      data: IFormValues,
      onComplete: () => void
    ) => {
      const isCouponReward =
        data.RewardTypeOption.value ===
        LoyaltyRewardTypes.CouponReward.toString()

      const request: IEditLoyaltyRewardRequest = {
        RewardCouponId: isCouponReward ? data.RewardCouponOption.value : null,
        RewardCurrencyId: !isCouponReward
          ? data.RewardCurrencyOption.value
          : null,
        LoyaltyRewardTypeId: data.RewardTypeOption.value,
        Amount: data.AmountCents,
        LoyaltyRewardId: editLoyaltyReward.loyaltyReward.LoyaltyRewardId,
        LoyaltyProgramId: editLoyaltyReward.loyaltyProgramId,
      }

      dispatch({ type: 'setIsSubmitting', isSubmitting: true })
      LoyaltyProgramController.EditLoyaltyReward(request)
        .then(() => {
          onComplete()
          dispatch({ type: 'setIsSubmitting', isSubmitting: false })
        })
        .catch((error: IGenericError) => {
          appUiStore.ShowError(error)
          dispatch({ type: 'setIsSubmitting', isSubmitting: false })
        })
    },
  }
}
