import { action, makeObservable, observable, runInAction } from 'mobx'
import FileDTO from 'src/Models/Server/File/FileDTO'
import IGenericError from 'src/Models/Server/IGenericError'
import ILocationVTO from 'src/Models/Server/Response/Location/ILocationVTO'
import LocationController from 'src/Services/ToprCoreApi/Axios/Controllers/LocationController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export default class Store extends BaseStore<ILocationVTO> {
  public ShowEditLocation: boolean = false

  constructor() {
    super()
    makeObservable(this, {
      ShowEditLocation: observable,
      Initialize: action,
      ToggleShowEditLocation: action,
      EditLocationImage: action,
    })
  }

  public Initialize(locationGuid: string) {
    this.IsLoading = true

    LocationController.GetLocation(locationGuid)
      .then((response) => {
        runInAction(() => {
          this.ViewModel = response
          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        runInAction(() => {
          this.IsLoading = false
        })
      })
  }

  public ToggleShowEditLocation(showEditLocation: boolean) {
    this.ShowEditLocation = showEditLocation
  }

  public EditLocationImage(imageFile: FileDTO) {
    this.IsSubmitting = true
    LocationController.EditImage({
      ImageFile: imageFile,
      LocationGuid: this.ViewModel.LocationGuid,
    })
      .then((response) => {
        runInAction(() => {
          this.ViewModel = response
          this.IsSubmitting = false
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        runInAction(() => {
          this.IsSubmitting = false
        })
      })
  }
}

export const locationDetailsStore = new Store()
