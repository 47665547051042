import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query'
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { GetBaseUrl } from 'src/Helpers/ServiceHelpers'
import { getFullLanguage } from 'src/I18n'
import { persistedStore } from 'src/Stores/PersistedStore'
import { mutex } from '../Axios/AuthAxios'
import AuthenticationController from '../Axios/Controllers/AuthenticationController'

const baseQuery = fetchBaseQuery({
  baseUrl: GetBaseUrl(),
  prepareHeaders: (headers) => {
    //TODO: replace by redux version
    const { AuthenticateUserResponse } = persistedStore
    if (AuthenticateUserResponse && AuthenticateUserResponse.AccessToken) {
      headers.set(
        'authorization',
        `Bearer ${AuthenticateUserResponse.AccessToken}`
      )
    }
    headers.set('accept-language', getFullLanguage())

    // By default, if we have a token in the store, let's use that for authenticated requests
    // const token = (getState() as RootState).auth.token
    // if (token) {
    //   headers.set('authorization', `Bearer ${token}`)
    // }
    return headers
  },
})
//not authenticated calls
export const baseAuthQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock()

  let result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()
      try {
        // try to get a new token
        const {
          AuthenticateUserResponse: { RefreshToken },
        } = persistedStore

        const response = await AuthenticationController.RefreshToken(
          RefreshToken
        )

        console.log('*** token refreshed *** :)')
        persistedStore.SetAuthenticatedUserResponse(response)

        // retry the initial query
        result = await baseQuery(args, api, extraOptions)
      } finally {
        release()
      }
    } else {
      //wait for refresh
      //console.log('wait for refresh 1')
      await mutex.waitForUnlock()
      result = await baseQuery(args, api, extraOptions)
    }
  }

  return result
}
