import React from 'react'
import Portlet from 'src/Components/Authenticated/Portlet'
import { t } from 'src/I18n'
import { IPaymentTransactionTotalReportDto } from 'src/Models/Server/Response/Dashobard/IPaymentTransactionTotalReportDto'
interface IProps {
  weekTotal: IPaymentTransactionTotalReportDto
}

const WeekTotal: React.FC<IProps> = ({ weekTotal }) => {
  return (
    <Portlet title={`${weekTotal.CurrencyName} week ${weekTotal.Week}`}>
      <table className="table keyvalue-table">
        <tbody>
          <tr>
            <td className="kv-key">
              <i className="fa fa-euro-sign kv-icon kv-icon-primary" />
              {t.common.revenue}
            </td>
            <td className="kv-value">{weekTotal.TotalAmountFormatted}</td>
          </tr>
          <tr>
            <td className="kv-key">
              <i className="fa fa-exchange-alt kv-icon kv-icon-tertiary" />
              {t.common.transactions}
            </td>
            <td className="kv-value">{weekTotal.Transactions}</td>
          </tr>
        </tbody>
      </table>
    </Portlet>
  )
}

export default WeekTotal
