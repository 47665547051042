import { action, makeObservable, observable, runInAction } from 'mobx'
import { ISelectOption } from 'src/Models/Client'
import { GetMonthOption } from 'src/Helpers/DateHelpers'
import { IChartResponse } from 'src/Models/Server/Chart/IChartData'
import IGenericError from 'src/Models/Server/IGenericError'
import { ToSelectOptions } from 'src/Models/Server/INameIdVTO'
import ReportController from 'src/Services/ToprCoreApi/Axios/Controllers/ReportController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export interface IFormValues {
  SelectedCurrency: ISelectOption
  SelectedPoses: ISelectOption[]
  SelectedMonth: ISelectOption
  Merchant: string
}

export class Store extends BaseStore<IFormValues> {
  public Currencies: ISelectOption[] = []
  public Poses: ISelectOption[] = []
  public ChartResponse?: IChartResponse = undefined

  public MerchantId = '-1'

  constructor() {
    super()
    makeObservable(this, {
      Currencies: observable,
      Poses: observable,
      ChartResponse: observable,
      MerchantId: observable,
      GetPaymentsPerDayMerchantDetails: action,
      Initialize: action,
    })
  }

  public Initialize(merchantId: string, currencyId: string, month: string) {
    this.ViewModel = undefined
    this.ChartResponse = undefined
    this.MerchantId = merchantId

    ReportController.GetPosFilter(merchantId)
      .then((response) => {
        runInAction(() => {
          const currencyOptions = ToSelectOptions<number>(response.Currencies)
          const posOptions = ToSelectOptions<number>(response.Poses)

          const selectedCurrency =
            currencyId !== undefined
              ? currencyOptions.find((x) => x.value === currencyId)
              : currencyOptions[0]
          this.Currencies = currencyOptions
          this.Poses = posOptions
          this.ViewModel = {
            SelectedCurrency: selectedCurrency,

            SelectedPoses: posOptions,
            SelectedMonth: GetMonthOption(month),
            Merchant: response.Merchant.Name,
          }

          this.GetPaymentsPerDayMerchantDetails(this.ViewModel)

          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsLoading = false))
        appUiStore.ShowError(error)
      })
  }

  public GoBack() {
    window.history.back()
  }

  public dashboard() {
    window.location.href = '/dashboard'
  }

  public GetPaymentsPerDayMerchantDetails(data: IFormValues) {
    const currencyId = data.SelectedCurrency.value
    const posIds = data.SelectedPoses.map((x) => x.value)
    ReportController.GetPaymentsPerDayMerchantDetails(
      this.MerchantId,
      currencyId,
      posIds,
      data.SelectedMonth.value
    )
      .then((result) => {
        runInAction(() => {
          this.ChartResponse = result
          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsLoading = false))
        appUiStore.ShowError(error)
      })
  }
}

export const paymentsPerDayMerchantsDetailsStore = new Store()
