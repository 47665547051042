import { action, makeObservable, observable, runInAction } from 'mobx'
import IGenericError from 'src/Models/Server/IGenericError'
import ITerminalVTO from 'src/Models/Server/Response/Terminal/ITerminalVTO'
import TerminalController from 'src/Services/ToprCoreApi/Axios/Controllers/TerminalController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export default class Store extends BaseStore<ITerminalVTO> {
  public ShowEditTerminal: boolean = false

  constructor() {
    super()

    makeObservable(this, {
      Initialize: action,
      ShowEditTerminal: observable,
      ToggleShowEditTerminal: action,
    })
  }

  public Initialize(terminalId: string) {
    this.IsLoading = true

    TerminalController.GetTerminal(terminalId)
      .then((terminal) => {
        runInAction(() => {
          this.ViewModel = terminal
          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        runInAction(() => (this.IsLoading = false))
      })
  }

  public ToggleShowEditTerminal(showEditTerminal: boolean) {
    this.ShowEditTerminal = showEditTerminal
  }
}

export const terminalDetailsStore = new Store()
