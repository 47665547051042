import React from 'react'
import { useParams } from 'react-router-dom'
import { LoaderIcon } from 'src/Components'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import { MainPage } from 'src/Components/My'
import { t } from 'src/I18n'
import { IEditConsumerSubscriptionItemRequest } from 'src/Models/Server/Request/ConsumerSubscription/IEditConsumerSubscriptionItemRequest'
import {
  useGetEditConsumerSubscriptionItemQuery,
  useEditConsumerSubscriptionItemMutation,
} from 'src/Services/ToprCoreApi/RTK/Slices/SubscriptionSlice'
import SubscriptionItemForm from '../Components/SubscriptionItemForm/SubscriptionItemForm'

const EditItem: React.FC = () => {
  const params = useParams<'consumerSubscriptionItemId'>()

  const { data: editItem, isLoading } = useGetEditConsumerSubscriptionItemQuery(
    params.consumerSubscriptionItemId
  )

  //post edit
  const [saveEditItem, { isLoading: isSubmitting }] =
    useEditConsumerSubscriptionItemMutation()

  const renderForm = () => {
    if (isLoading) {
      return <LoaderIcon IsLoading />
    }

    return (
      <SubscriptionItemForm
        onSave={(data) => {
          const request: IEditConsumerSubscriptionItemRequest = {
            ConsumerSubscriptionItemId: params.consumerSubscriptionItemId,
            ConsumerSubscriptionItemTypeId: data.TypeOption.value,
            Amount: data.Amount,
            Description: data.Description,
            Name: data.Name,
            ImageFile: {
              ...editItem.ImageFile,
              Data: data.ImageFile.Data,
              MimeType: data.ImageFile.MimeType,
              Name: data.ImageFile.Name,
            },
          }

          saveEditItem(request)
            .unwrap()
            .then(() => history.back())
            .catch((rejected) => console.error(rejected))
        }}
        title={t.subscription.editItem.title}
        isSubmitting={isSubmitting}
        editItem={editItem}
      />
    )
  }

  return (
    <>
      <MainPage>
        <BreadCrumbs
          currentPageTitle={t.subscription.editItem.title}
          previousPages={[
            {
              to: `/subscriptions`,
              title: t.subscription.index.title,
            },
          ]}
        />
        {renderForm()}
      </MainPage>
    </>
  )
}

export default EditItem
