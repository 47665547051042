import { Middleware } from '@reduxjs/toolkit'

import { isRejectedWithValue } from '@reduxjs/toolkit'
import { t } from 'src/I18n'
import { appUiStore } from 'src/Stores/AppUiStore'
export const ToprErrorMiddleware: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    showError(action.payload)
  }
  return next(action)
}

const showError = (payload: { data: any; status: number }) => {
  if (payload.status !== 401) {
    if (payload && payload.data && payload.data.Code) {
      appUiStore.ShowError(payload.data)
    } else {
      appUiStore.ShowError(t.common.serverErrors.defaultError)
      console.warn('ToprErrorMiddleware: unhandled error', payload)
    }
  }
}
