import { action, makeObservable, runInAction } from 'mobx'
import IGenericError from 'src/Models/Server/IGenericError'
import ICouponDetailsStatsDTO from 'src/Models/Server/Response/Coupon/ICouponDetailStatsDTO'
import CouponController from 'src/Services/ToprCoreApi/Axios/Controllers/CouponController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export default class Store extends BaseStore<ICouponDetailsStatsDTO[]> {
  constructor() {
    super()
    //this.ViewModel = []
    makeObservable(this, {
      Initialize: action,
    })
  }

  /** Sets Initial state */

  public Initialize() {
    this.IsLoading = true
    CouponController.GetCouponStatsMerchant()
      .then((response) => {
        runInAction(() => {
          this.ViewModel = response
          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        runInAction(() => {
          this.IsLoading = false
        })
      })
  }
}

export const couponsStore = new Store()
