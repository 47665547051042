import { AxiosError, AxiosResponse } from 'axios'
import { handleException } from 'src/Helpers/ResponseHelpers'
import { IDashboardVTO } from 'src/Models/Server/Response/Dashobard/IDashboardVTO'
import { axiosAuth } from '../AuthAxios'

export default {
  Boot() {
    return new Promise<string>((resolve, reject) => {
      axiosAuth
        .get(`/api/v1/dashboard/boot`)
        .then((response: AxiosResponse<string>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  Env() {
    return new Promise<string>((resolve, reject) => {
      axiosAuth
        .get(`/api/v1/dashboard/env`)
        .then((response: AxiosResponse<string>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  GetDashboard() {
    return new Promise<IDashboardVTO>((resolve, reject) => {
      axiosAuth
        .get(`/api/v1/dashboard`)
        .then((response: AxiosResponse<IDashboardVTO>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
}
