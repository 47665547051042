import { AxiosError, AxiosResponse } from 'axios'
import { handleException } from 'src/Helpers/ResponseHelpers'
import { ITokenSTO } from 'src/Models/Server/Response/Token/ITokenSTO'
import { axiosAuth } from '../AuthAxios'

export default {
  GetTokensUser(id: string) {
    return new Promise<ITokenSTO[]>((resolve, reject) => {
      axiosAuth
        .get(`/api/v1/tokens/user/${id}`)
        .then((response: AxiosResponse<ITokenSTO[]>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  Block(tokenGuid: string, userGuid: string) {
    return new Promise<void>((resolve, reject) => {
      axiosAuth
        .post(`/api/v1/token/block/${tokenGuid}/${userGuid}`, null)
        .then(() => {
          resolve()
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  Unblock(tokenGuid: string, userGuid: string) {
    return new Promise<void>((resolve, reject) => {
      axiosAuth
        .post(`/api/v1/token/unblock/${tokenGuid}/${userGuid}`, null)
        .then(() => {
          resolve()
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
}
