import React, { useEffect, useReducer } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { LoaderIcon, McButton } from 'src/Components'
import Portlet from 'src/Components/Authenticated/Portlet'
import { MyDatePicker, MySelect } from 'src/Components/My'
import {
  addDaysToDate,
  addMonthsToDate,
  addWeeksToDate,
  FormatDateLocalized,
} from 'src/Helpers/DateHelpers'

import { t } from 'src/I18n'
import { ConsumerSubscriptionItemType } from 'src/Models/Server/Enums'
import { ICreateUserSubscriptionRequest } from 'src/Models/Server/Request/ConsumerSubscription/ICreateUserSubscriptionRequest'
import { IEditUserSubscriptionRequest } from 'src/Models/Server/Request/ConsumerSubscription/IEditUserSubscriptionRequest'
import { IUserSubscriptionVTO } from 'src/Models/Server/Response/ConsumerSubscription/IUserSubscriptionVTO'
import {
  useEditUserSubscriptionMutation,
  useGetConsumerSubscriptionQuery,
  useInsertUserSubscriptionMutation,
} from 'src/Services/ToprCoreApi/RTK/Slices/SubscriptionSlice'
import Reducer, {
  initialize,
  initialState,
  itemsToOptions,
  IUserSubscriptionForm,
} from './Reducer'
interface IProps {
  consumerSubscriptionId: string
  userGuid: string
  title: string
  userSubscription?: IUserSubscriptionVTO
}

const UserSubscriptionForm: React.FC<IProps> = ({
  consumerSubscriptionId,
  userGuid,
  title,
  userSubscription,
}) => {
  const [state, dispatch] = useReducer(Reducer, initialState)

  const { data: consumerSubscription, isLoading } =
    useGetConsumerSubscriptionQuery(consumerSubscriptionId)

  const [insertUserSubscription, { isLoading: insertIsSubmitting }] =
    useInsertUserSubscriptionMutation()

  const [editUserSubscription, { isLoading: editIsLoading }] =
    useEditUserSubscriptionMutation()

  const isSubmitting = editIsLoading || insertIsSubmitting
  const { viewModel, consumerSubscriptionItems } = state

  useEffect(() => {
    if (consumerSubscription !== undefined) {
      dispatch(initialize(consumerSubscription, userSubscription))
    }
  }, [consumerSubscription])

  useEffect(() => {
    reset(viewModel)
  }, [viewModel])

  const useFormProps: UseFormReturn<IUserSubscriptionForm> =
    useForm<IUserSubscriptionForm>()
  const { handleSubmit, watch, reset, getValues } = useFormProps
  const isEdit = userSubscription !== undefined

  const onSubmit = (data: IUserSubscriptionForm) => {
    if (!isEdit) {
      //insert
      const request: ICreateUserSubscriptionRequest = {
        ConsumerSubscriptionItemId: data.ConsumerSubscriptionItemOption.value,
        StartDate: data.StartDate,
        UserGuid: userGuid,
      }
      insertUserSubscription(request)
        .unwrap()
        .then(() => history.back())
        .catch()
    } else {
      //update
      const request: IEditUserSubscriptionRequest = {
        UserSubscriptionId: userSubscription.UserSubscriptionId.toString(),
        StartDate: data.StartDate,
        EndDate: data.EndDate,
      }
      editUserSubscription(request)
        .unwrap()
        .then(() => history.back())
        .catch()
    }
  }

  const renderContent = () => {
    if (isLoading || viewModel === undefined) {
      return <LoaderIcon IsLoading={isLoading} />
    }

    const consumerSubscriptionItemOptions = itemsToOptions(
      consumerSubscriptionItems
    )

    const startDate = watch('StartDate')
    const consumerSubscriptionItemOption = watch(
      'ConsumerSubscriptionItemOption'
    )

    const renderDates = () => {
      if (consumerSubscriptionItemOption === undefined) {
        return null
      }

      const consumerSubscriptionItem = consumerSubscriptionItems.find(
        (x) =>
          x.ConsumerSubscriptionItemId.toString() ===
          consumerSubscriptionItemOption.value
      )
      if (consumerSubscriptionItem === undefined) {
        return null
      }

      const renderEndDateEdit = () => {
        return (
          <Col md={6}>
            <MyDatePicker
              formObject={viewModel}
              useFormProps={useFormProps}
              name="EndDate"
              label={t.subscription.insertUserSubscription.fields.EndDate.label}
              rules={{
                validate: (endDate: Date) => {
                  if (endDate !== undefined && endDate !== null) {
                    const startDate = getValues('StartDate')
                    if (startDate > endDate) {
                      return t.subscription.insertUserSubscription.fields
                        .EndDate.errors.greaterThan
                    }
                  }

                  return true
                },
              }}
            />
          </Col>
        )
      }

      //when insert, show calculated enddate
      const renderEndDateLabel = () => {
        if (
          consumerSubscriptionItem.ConsumerSubscriptionItemTypeId ===
            ConsumerSubscriptionItemType.Trips ||
          consumerSubscriptionItem.ConsumerSubscriptionItemTypeId ===
            ConsumerSubscriptionItemType.Unlimited
        ) {
          return null
        }

        let formattedEndDate = ''

        switch (consumerSubscriptionItem.ConsumerSubscriptionItemTypeId) {
          case ConsumerSubscriptionItemType.Day:
            formattedEndDate = FormatDateLocalized(
              addDaysToDate(startDate, consumerSubscriptionItem.Amount)
            )
            break
          case ConsumerSubscriptionItemType.Week:
            formattedEndDate = FormatDateLocalized(
              addWeeksToDate(startDate, consumerSubscriptionItem.Amount)
            )
            break
          case ConsumerSubscriptionItemType.Month:
            formattedEndDate = FormatDateLocalized(
              addMonthsToDate(startDate, consumerSubscriptionItem.Amount)
            )
            break
          case ConsumerSubscriptionItemType.Year:
            formattedEndDate = FormatDateLocalized(
              addDaysToDate(startDate, consumerSubscriptionItem.Amount)
            )
            break

          default:
            break
        }

        return (
          <Col md={6}>
            <div>
              <label>
                {t.subscription.insertUserSubscription.fields.EndDate.label}
              </label>
            </div>
            {formattedEndDate}
          </Col>
        )
      }

      const renderEndDate = () => {
        if (isEdit) {
          return renderEndDateEdit()
        }

        return renderEndDateLabel()
      }

      return (
        <Row>
          <Col md={6}>
            <MyDatePicker
              formObject={viewModel}
              useFormProps={useFormProps}
              name="StartDate"
              label={
                t.subscription.insertUserSubscription.fields.StartDate.label
              }
              rules={{
                required:
                  t.subscription.insertUserSubscription.fields.StartDate
                    .required,
              }}
            />
          </Col>
          {renderEndDate()}
        </Row>
      )
    }

    return (
      <>
        <MySelect
          formObject={viewModel}
          useFormProps={useFormProps}
          name="ConsumerSubscriptionItemOption"
          label={t.subscription.insertUserSubscription.fields.ItemType.label}
          options={consumerSubscriptionItemOptions}
          isDisabled={isEdit}
        />
        {renderDates()}
        <McButton
          block
          bsStyle="primary"
          bsSize="large"
          type="submit"
          isLoading={isSubmitting}
        >
          {t.common.save}
        </McButton>
      </>
    )
  }

  return (
    <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
      <Portlet title={title}>{renderContent()}</Portlet>
    </form>
  )
}

export default UserSubscriptionForm
