import { observer } from 'mobx-react'
import React, { Fragment, useEffect } from 'react'
import { ButtonToolbar } from 'react-bootstrap'
import LoaderIcon from 'src/Components/LoaderIcon'
import McButton from 'src/Components/McButton'
import { MyDataTable } from 'src/Components/My'
import { t } from 'src/I18n'
import IAppUserVTO from 'src/Models/Server/Response/User/IAppUserVTO'
import { appUiStore } from 'src/Stores/AppUiStore'
import { persistedStore } from 'src/Stores/PersistedStore'
import AddManagerUserModal from './AddModal/AddManagerUserModal'
import { managerUsersStore } from './Store'

interface IProps {
  merchantGuid: string
}

const ManagerUserGrid: React.FC<IProps> = ({ merchantGuid }) => {
  useEffect(() => {
    managerUsersStore.Inialize(merchantGuid)
  }, [null])
  const { ViewModel, IsLoading, ShowAddUser, MerchantGuid } = managerUsersStore

  const actionsCellFormatter = (cell, data: IAppUserVTO) => {
    return (
      <ButtonToolbar>
        <McButton
          iconLeft="fa-ban"
          bsStyle="danger"
          bsSize="small"
          onClick={() => {
            appUiStore.ShowConfirm({
              message: t.formatString(
                t.merchant.details.managerUsers.deleteConfirm,
                data.Name
              ),
              confirmAction: () =>
                managerUsersStore.DeleteManagerUser(data.UserGuid),
              confirmActionText: t.common.delete,
            })
          }}
        >
          {t.common.delete}
        </McButton>
        <McButton
          iconLeft="fa-login"
          bsSize="small"
          bsStyle="default"
          onClick={async () => {
            await persistedStore.SwitchSelectedManagerUser(data.UserGuid)
          }}
        >
          {t.helpdesk.selectTenantModal.loginActionText}
        </McButton>
      </ButtonToolbar>
    )
  }

  const columns = [
    {
      dataField: 'Name',
      text: t.common.grid.name.label,
      //filter: textFilter({ placeholder: t.common.grid.name.placeholder }),
    },
    {
      dataField: 'UniqueEmailAddress',
      text: t.common.grid.email.label,
      //filter: textFilter({ placeholder: t.common.grid.email.placeholder }),
    },
    {
      dataField: 'TempPassword',
      text: t.common.password,
    },
    {
      dataField: 'actions',
      csvExport: false,
      text: '',
      formatter: actionsCellFormatter,
    },
  ]

  return (
    <Fragment>
      <McButton
        bsStyle="primary"
        type="submit"
        onClick={() => managerUsersStore.ToggleAddUserModal(true)}
      >
        {t.merchant.details.managerUsers.addUser}
      </McButton>
      <LoaderIcon IsLoading={IsLoading} />
      {!IsLoading && <MyDataTable data={ViewModel} columns={columns} />}

      {ShowAddUser && (
        <AddManagerUserModal
          merchantGuid={MerchantGuid}
          onClose={() => managerUsersStore.ToggleAddUserModal(false)}
          onSubmitSuccess={(managerUser) =>
            managerUsersStore.AddElement(managerUser)
          }
        />
      )}
    </Fragment>
  )
}

export default observer(ManagerUserGrid)
