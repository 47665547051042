import axios, { AxiosError, AxiosResponse } from 'axios'
import { handleException } from 'src/Helpers/ResponseHelpers'
import { getFullLanguage, t } from 'src/I18n'
import { IExceptionError } from 'src/Models/Server/Response/Exception/ExceptionError'
import { AuthenticateUserResponse } from 'src/Models/Server/Response/User/AuthenticateUserResponse'
import { GetBaseUrl } from 'src/Helpers/ServiceHelpers'

export default {
  AuthenticateMerchantUser(userName: string, password: string) {
    return new Promise<AuthenticateUserResponse>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axios
          .post(
            encodeURI(GetBaseUrl() + '/api/v1/authenticate/merchant'),
            { UserName: userName, Password: password },
            {
              headers: { 'accept-language': getFullLanguage() },
            }
          )
          .then((response: AxiosResponse<AuthenticateUserResponse>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            if (error.response && error.response.data) {
              reject(error.response.data)
            }

            if (error.message) {
              reject({ Code: 'Unhandled_Exception', Message: error.message })
            }

            reject(t.common.serverErrors.defaultError)
          })
      }
    )
  },

  RefreshToken(refreshToken: string) {
    return new Promise<AuthenticateUserResponse>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axios
          .post(
            encodeURI(GetBaseUrl() + `/api/v1/authenticate/refresh`),
            { RefreshToken: refreshToken },
            {
              headers: {
                'accept-language': getFullLanguage(),
                'Content-type': 'application/json',
              },
            }
          )
          .then((response: AxiosResponse<AuthenticateUserResponse>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
}
