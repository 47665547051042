import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Button, Col, Row, Tab, Tabs } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import SavingCardSummaryReport from 'src/Components/Authenticated/SavingCardSummaryReport'
import LoadContent from 'src/Components/LoadContent'
import { MainPage } from 'src/Components/My'
import { appUiStore } from 'src/Stores/AppUiStore'
import { t } from '../../../../I18n'

import { savingCardsStore } from './Store'

interface IProps {}

const SavingCards: React.FC<IProps> = () => {
  useEffect(() => {
    savingCardsStore.Initialize()
  }, [null])
  const navigate = useNavigate()
  const { ViewModel, IsLoading } = savingCardsStore

  return (
    <MainPage>
      <BreadCrumbs currentPageTitle={t.common.savingcards} />
      <Link to="/savingcard/insert">
        <Button bsStyle="primary">{t.savingcardInsert.title}</Button>
      </Link>
      <br className="xs-10" />
      <Portlet>
        <Tabs id="tabs">
          <Tab eventKey={1} title={[t.common.active]}>
            <LoadContent IsLoading={IsLoading}>
              <Row>
                {ViewModel !== undefined &&
                  ViewModel.ActiveToday.map((x, index) => (
                    <Col md={6} key={index}>
                      <SavingCardSummaryReport
                        savingcardSummary={x}
                        onDetailsClick={() => {
                          navigate(
                            `/savingcard/details/${x.SavingCard.SavingCardGuid}`
                          )
                        }}
                        onEditClick={() => {
                          navigate(
                            `/savingcard/edit/${x.SavingCard.SavingCardGuid}`
                          )
                        }}
                        setIsActive={(
                          savingCardGuid: string,
                          isActive: boolean
                        ) => {
                          savingCardsStore.SetIsActive(savingCardGuid, isActive)
                        }}
                        onDeleteClick={() => {
                          appUiStore.ShowConfirm({
                            confirmAction: () => {
                              savingCardsStore.DeleteSavingCard(
                                x.SavingCard.SavingCardGuid
                              )
                            },
                            confirmActionText: t.common.delete,
                            message: t.savingcard.confirmDelete,
                          })
                        }}
                      />
                    </Col>
                  ))}
              </Row>
            </LoadContent>
          </Tab>
          <Tab eventKey={2} title={t.common.inActive}>
            <LoadContent IsLoading={IsLoading && ViewModel !== undefined}>
              <Row>
                {ViewModel !== undefined &&
                  ViewModel.NotActiveToday.map((x, index) => (
                    <Col md={6} key={index}>
                      <SavingCardSummaryReport
                        savingcardSummary={x}
                        onDetailsClick={() => {
                          navigate(
                            `/savingcard/details/${x.SavingCard.SavingCardGuid}`
                          )
                        }}
                        onEditClick={() => {
                          navigate(
                            `/savingcard/edit/${x.SavingCard.SavingCardGuid}`
                          )
                        }}
                        setIsActive={(
                          savingCardGuid: string,
                          isActive: boolean
                        ) => {
                          savingCardsStore.SetIsActive(savingCardGuid, isActive)
                        }}
                        onDeleteClick={() => {
                          appUiStore.ShowConfirm({
                            confirmAction: () => {
                              savingCardsStore.DeleteSavingCard(
                                x.SavingCard.SavingCardGuid
                              )
                            },
                            confirmActionText: t.common.delete,
                            message: t.savingcard.confirmDelete,
                          })
                        }}
                      />
                    </Col>
                  ))}
              </Row>
            </LoadContent>
          </Tab>
        </Tabs>
      </Portlet>
    </MainPage>
  )
}

export default observer(SavingCards)
