import React, { useEffect, useReducer } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import { McButton } from 'src/Components'
import Portlet from 'src/Components/Authenticated/Portlet'
import { MyLocalizedStringTabs, MyTextbox } from 'src/Components/My'
import { t } from 'src/I18n'
import { IEditConsumerSubscriptionVTO } from 'src/Models/Server/Response/ConsumerSubscription/IEditConsumerSubscriptionVTO'

import {
  initialState,
  ISubscriptionFormValues,
  reducer,
  reducerActions,
} from './Reducer'

interface IProps {
  title: string
  onSave: (data: ISubscriptionFormValues) => void
  isSubmitting: boolean
  editItem?: IEditConsumerSubscriptionVTO
}

const SubscriptionForm: React.FC<IProps> = ({
  title,
  onSave,
  isSubmitting,
  editItem,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const actions = reducerActions(state, dispatch)

  useEffect(() => {
    actions.initialize(editItem)
  }, [editItem])

  const { viewModel } = state

  useEffect(() => {
    reset(viewModel)
  }, [viewModel])

  const useFormProps: UseFormReturn<ISubscriptionFormValues> =
    useForm<ISubscriptionFormValues>()

  const { handleSubmit, reset } = useFormProps

  const onSubmit = (data: ISubscriptionFormValues) => {
    onSave(data)
  }

  const renderContent = () => {
    if (viewModel === undefined) {
      return null
    }

    return (
      <>
        <MyLocalizedStringTabs
          formObject={viewModel}
          useFormProps={useFormProps}
          name="Name"
          label={t.subscription.insert.fields.name.label}
          isRequired
        />
        <MyTextbox
          formObject={viewModel}
          useFormProps={useFormProps}
          name="SystemName"
          label={t.subscription.insert.fields.systemName.label}
          maxLength={20}
          rules={{
            required: t.subscription.insert.fields.systemName.required,
          }}
        />
        <McButton
          block
          bsStyle="primary"
          bsSize="large"
          type="submit"
          isLoading={isSubmitting}
        >
          {t.common.save}
        </McButton>
      </>
    )
  }

  return (
    <>
      <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
        <Portlet title={title}>{renderContent()}</Portlet>
      </form>
    </>
  )
}

export default SubscriptionForm
