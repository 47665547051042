import { AxiosError, AxiosResponse } from 'axios'
import { handleException } from 'src/Helpers/ResponseHelpers'
import IInsertSavingCardRequest from 'src/Models/Server/Request/SavingCard/IInsertSavingCardRequest'
import ISavingCardEditVTO from 'src/Models/Server/Request/SavingCard/ISavingCardEditVTO'
import { IExceptionError } from 'src/Models/Server/Response/Exception/ExceptionError'
import IGetSavingCardSummariesResponse from 'src/Models/Server/Response/IGetSavingCardSummariesResponse'
import ISavingCardDetailsDTO from 'src/Models/Server/Response/SavingCard/ISavingCardDetailsDTO'
import { axiosAuth } from '../AuthAxios'

export default {
  Insert(request: IInsertSavingCardRequest) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/savingcard/insert`, request)
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  Edit(request: ISavingCardEditVTO) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/savingcard/edit`, request)
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  SetActive(savingCardGuid: string, isActive: boolean) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(
            `/api/v1/savingcard/isactive/${savingCardGuid}/${isActive}`,
            null
          )
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  Delete(savingCardGuid: string) {
    return new Promise<void>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .delete(`/api/v1/savingcard/delete/${savingCardGuid}`)
          .then(() => {
            resolve()
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  GetSavingCardSummaries() {
    return new Promise<IGetSavingCardSummariesResponse>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/savingcards/summary`)
          .then((response: AxiosResponse<IGetSavingCardSummariesResponse>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  GetSavingCardDetails(savingcardGuid: string) {
    return new Promise<ISavingCardDetailsDTO>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/savingcard/details/${savingcardGuid}`)
          .then((response: AxiosResponse<ISavingCardDetailsDTO>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  GetSavingCardEdit(savingcardGuid: string) {
    return new Promise<ISavingCardEditVTO>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/savingcard/edit/${savingcardGuid}`)
          .then((response: AxiosResponse<ISavingCardEditVTO>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
}
