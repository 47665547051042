import ToprTenant from './Topr/ToprTenant'
import ITenant from './ITenant'

export const getTenant = (): ITenant => {
  switch (window.location.host) {
    case 'manager.mybility':
    case 'mybility':
      return ToprTenant
    case 'topr':
      return ToprTenant
    case 'localhost':
    case 'demomanager.mijncashless.nl':
    default:
      return ToprTenant
  }
}

const Tenant = getTenant()
export default Tenant
