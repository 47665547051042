import { AxiosError, AxiosResponse } from 'axios'
import qs from 'qs'
import { handleException } from 'src/Helpers/ResponseHelpers'
import { ITransactionExtendedSTO } from 'src/Models/Server/Response/Transaction/ITransactionExtendedSTO'
import { axiosAuth } from '../AuthAxios'

export default {
  GetTransactionsForCurrencies(
    id: string,
    currencyGuids: string[],
    startDate: Date,
    endDate: Date
  ) {
    return new Promise<ITransactionExtendedSTO[]>((resolve, reject) => {
      axiosAuth
        .get(`/api/v1/transactions/currencies`, {
          params: {
            id,
            currencyGuids,
            startDate,
            endDate,
          },
          paramsSerializer: (params) => {
            return qs.stringify(params)
          },
        })
        .then((response: AxiosResponse<ITransactionExtendedSTO[]>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
  Find(search: string, currencyGuid: string, searchType: string) {
    return new Promise<ITransactionExtendedSTO>((resolve, reject) => {
      axiosAuth
        .post(`/api/v1/transaction/find/${currencyGuid}/${searchType}`, {
          Search: search,
        })
        .then((response: AxiosResponse<ITransactionExtendedSTO>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
}
