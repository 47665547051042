import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'

import { useForm, UseFormReturn } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import LoaderIcon from 'src/Components/LoaderIcon'
import McButton from 'src/Components/McButton'

import { MainPage, MySelect, MyTextbox } from 'src/Components/My'
import { t } from '../../../../I18n'

import { IFormValues, merchantInsertStore } from './Store'

const Insert: React.FC = () => {
  const navigate = useNavigate()
  useEffect(() => {
    merchantInsertStore.Initialize()
  }, [null])

  // const {
  //   register,
  //   control,
  //   getValues,
  //   formState: { errors },
  //   handleSubmit,
  // } = useForm<IFormValues>({})
  //UseFormReturn

  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({
    defaultValues: {
      Name: '',
      FinanceEmailAddress: '',
    },
  })

  const { handleSubmit } = useFormProps

  const onSubmit = (data: IFormValues) => {
    //console.log(data)
    merchantInsertStore.Insert(data, navigate)
  }

  const { IsLoading, IsSubmitting, Relations } = merchantInsertStore

  return (
    <MainPage>
      <LoaderIcon IsLoading={IsLoading} />

      <br className="xs-10" />
      {!IsLoading && (
        <>
          <BreadCrumbs
            currentPageTitle={t.merchant.insert.title}
            previousPages={{
              to: '/merchants',
              title: t.common.merchants,
            }}
          />

          <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
            <Portlet title={t.common.merchant}>
              <Row>
                <Col md={6}>
                  <MyTextbox
                    formObject={{} as IFormValues}
                    label={t.merchant.insert.fields.Name.label}
                    name="Name"
                    rules={{
                      required: t.merchant.insert.fields.Name.errors.required,
                    }}
                    useFormProps={useFormProps}
                    placeholder={t.merchant.insert.fields.Name.label}
                    tabIndex={1}
                  />
                  <MyTextbox
                    formObject={{} as IFormValues}
                    label={t.merchant.insert.fields.FinanceEmailAddress.label}
                    placeholder={
                      t.merchant.insert.fields.FinanceEmailAddress.label
                    }
                    name="FinanceEmailAddress"
                    useFormProps={useFormProps}
                  />
                  <MySelect
                    formObject={{} as IFormValues}
                    useFormProps={useFormProps}
                    label={t.merchant.insert.fields.RelationID.label}
                    name="SelectedRelation"
                    rules={{
                      required:
                        t.merchant.insert.fields.RelationID.errors.required,
                    }}
                    options={Relations}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col mdOffset={8} md={4}>
                  <McButton
                    block
                    bsStyle="primary"
                    type="submit"
                    isLoading={IsSubmitting}
                  >
                    {t.common.save}
                  </McButton>
                </Col>
              </Row>
            </Portlet>
          </form>
        </>
      )}
    </MainPage>
  )
}

export default observer(Insert)
