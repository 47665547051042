import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { FormGroup } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { Link, Navigate, useLocation } from 'react-router-dom'
import McButton from 'src/Components/McButton'
import McPageAlert from 'src/Components/McPageAlert'
import { MainPage, MyTextbox } from 'src/Components/My'
import { getlocationState } from 'src/Helpers/UrlHelpers'
import { t } from 'src/I18n'
import { persistedStore } from 'src/Stores/PersistedStore'
import Tenant from 'src/Tenants/Tenant'
import { ILoginViewModel, loginStore } from './Store'

const Login: React.FC = () => {
  const { IsSubmitting, PageAlert } = loginStore

  const location = useLocation()

  useEffect(() => {
    loginStore.BaseInitialize()
  }, [null])

  const useFormProps: UseFormReturn<ILoginViewModel> = useForm<ILoginViewModel>(
    {
      defaultValues: {
        EmailAddress: '',
        Password: '',
      },
    }
  )
  const { handleSubmit } = useFormProps

  const onSubmit = (data: ILoginViewModel) => {
    loginStore.Login(data.EmailAddress, data.Password)
  }

  if (persistedStore.IsAuthenticated) {
    const state = getlocationState<{ from: Location }>(location)
    const from = state !== null ? state.from.pathname : 'dashboard'
    return <Navigate to={from} />
  }

  return (
    <>
      <MainPage>
        <br className="xs-80" />
        <McPageAlert pageAlert={PageAlert} />
        <div className="account-wrapper">
          <div className="account-body">
            <h3>{t.formatString(t.login.header, { name: Tenant.Name })}</h3>
            <form
              className="parsley-form  account-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <MyTextbox
                formObject={{} as ILoginViewModel}
                label={t.login.fieldEmailAddress.label}
                name="EmailAddress"
                labelClassName="placeholder-hidden"
                useFormProps={useFormProps}
                rules={{
                  required: t.login.fieldEmailAddress.errors.required,
                }}
                maxLength={100}
                placeholder={t.login.fieldEmailAddress.label}
                tabIndex={1}
              />
              <MyTextbox
                formObject={{} as ILoginViewModel}
                label={t.login.fieldPassword.label}
                labelClassName="placeholder-hidden"
                name="Password"
                useFormProps={useFormProps}
                rules={{
                  required: t.login.fieldPassword.errors.required,
                }}
                type="password"
                placeholder={t.login.fieldPassword.label}
                tabIndex={2}
              />
              <div className="form-group clearfix">
                <div className="pull-right">
                  <small>
                    <Link to="/forgotpassword" tabIndex={5}>
                      {t.login.forgotPassword}
                    </Link>
                  </small>
                </div>
              </div>
              <FormGroup>
                <McButton
                  block
                  bsStyle="primary"
                  bsSize="large"
                  type="submit"
                  isLoading={IsSubmitting}
                  tabIndex={3}
                >
                  {t.login.submitText}
                </McButton>
              </FormGroup>
            </form>
          </div>
        </div>
      </MainPage>
    </>
  )
}

export default observer(Login)
