import { Today } from 'src/Helpers/DateHelpers'
import { enumToText } from 'src/Helpers/TextHelpers'
import { t } from 'src/I18n'
import { ISelectOption } from 'src/Models/Client'
import { LoyaltyRewardTypes, LoyaltyRuleTypes } from 'src/Models/Server/Enums'
import IGenericError from 'src/Models/Server/IGenericError'
import { ICreateLoyaltyProgramRequest } from 'src/Models/Server/Request/LoyaltyProgram/ICreateLoyaltyProgramRequest'
import CurrencyVTO from 'src/Models/Server/Response/Currency/CurrencyVTO'
import CouponController from 'src/Services/ToprCoreApi/Axios/Controllers/CouponController'
import CurrencyController from 'src/Services/ToprCoreApi/Axios/Controllers/CurrencyController'
import LoyaltyProgramController from 'src/Services/ToprCoreApi/Axios/Controllers/LoyaltyProgramController'
import { appUiStore } from 'src/Stores/AppUiStore'
import { getCurrencyOptions } from '../../UserScheduleBonus/Components/Reducer'

export const rewardTypeOptions: ISelectOption[] = [
  {
    value: LoyaltyRewardTypes.CurrencyStaticReward.toString(),
    label: t.enums.LoyaltyRewardTypes.CurrencyStaticReward,
  },
  {
    value: LoyaltyRewardTypes.CurrencyOrderPercentageReward.toString(),
    label: t.enums.LoyaltyRewardTypes.CurrencyOrderPercentageReward,
  },
  {
    value: LoyaltyRewardTypes.CouponReward.toString(),
    label: t.enums.LoyaltyRewardTypes.CouponReward,
  },
]

export const typeOptions = [
  {
    value: LoyaltyRuleTypes.Article.toString(),
    label: enumToText(
      LoyaltyRuleTypes.Article,
      LoyaltyRuleTypes,
      t.enums.LoyaltyRuleTypes
    ),
  },
  {
    value: LoyaltyRuleTypes.ArticleGroup.toString(),
    label: enumToText(
      LoyaltyRuleTypes.ArticleGroup,
      LoyaltyRuleTypes,
      t.enums.LoyaltyRuleTypes
    ),
  },
]

export interface IFormValues {
  TypeOption: ISelectOption
  Name: string
  ArticleCode: string
  ArticleGroup: string
  RunOnCurrencyOption: ISelectOption
  RewardTypeOption: ISelectOption
  RewardCurrencyOption: ISelectOption
  RewardCouponOption: ISelectOption
  DisplayAmount: string
  AmountCents: number
  StartDate: Date
  EndDate?: Date
}

export interface IState {
  isLoading: boolean
  isSubmitting: boolean
  currencies: CurrencyVTO[]
  couponOptions: ISelectOption[]
  viewModel: IFormValues
}

export const initialState: IState = {
  isLoading: true,
  isSubmitting: false,
  currencies: [],
  couponOptions: [],
  viewModel: undefined,
}

export type Actions =
  | {
      type: 'initialize'
      viewModel: IFormValues
      currencies: CurrencyVTO[]
      couponOptions: ISelectOption[]
    }
  | {
      type: 'setIsSubmitting'
      isSubmitting: boolean
    }
  | {
      type: 'initializeEdit'
      viewModel: IFormValues
      currencies: CurrencyVTO[]
    }

export const reducer = (state: IState, action: Actions): IState => {
  switch (action.type) {
    case 'initialize':
      return {
        ...state,
        viewModel: action.viewModel,
        currencies: action.currencies,
        couponOptions: action.couponOptions,
        isLoading: false,
      }
    case 'initializeEdit':
      return {
        ...state,
        viewModel: action.viewModel,
        currencies: action.currencies,
        isLoading: false,
      }
    case 'setIsSubmitting':
      return { ...state, isSubmitting: action.isSubmitting }
    default:
      throw new Error('Invalid Reducer Action')
  }
}

export const reducerActions = (
  state: IState,
  dispatch: (action: Actions) => void
) => {
  return {
    initialize: () => {
      Promise.all([
        CurrencyController.GetMyMerchantCurrencies(),
        CouponController.GetCouponsMerchant(),
      ])
        .then((values) => {
          //filter: don't show coupons from existingUserBonuses
          const currencies = values[0].filter((x) => x.CurrencyId !== 239)

          const couponOptions = values[1].map((coupon) => {
            return {
              label: coupon.FullTitle,
              value: coupon.CouponId.toString(),
            }
          })

          const viewModel: IFormValues = {
            TypeOption: typeOptions[0],
            RewardTypeOption: rewardTypeOptions[0],
            RewardCurrencyOption: getCurrencyOptions(currencies)[0],
            RewardCouponOption: couponOptions[0],
            DisplayAmount: '1',
            AmountCents: 100,
            ArticleCode: '',
            ArticleGroup: '',
            Name: '',
            RunOnCurrencyOption: getCurrencyOptions(currencies)[0],
            StartDate: Today(),
          }

          dispatch({
            type: 'initialize',
            viewModel,
            currencies,
            couponOptions,
          })
        })
        .catch((error: IGenericError) => {
          appUiStore.ShowError(error)
        })
    },
    save: (data: IFormValues) => {
      const isCouponReward =
        data.RewardTypeOption.value ===
        LoyaltyRewardTypes.CouponReward.toString()

      const isArticle =
        data.TypeOption.value === LoyaltyRuleTypes.Article.toString()
      const request: ICreateLoyaltyProgramRequest = {
        ArticleCode: isArticle ? data.ArticleCode : null,
        ArticleGroup: !isArticle ? data.ArticleGroup : null,
        Name: data.Name,
        LoyaltyRuleTypeId: data.TypeOption.value,
        CurrencyId: data.RunOnCurrencyOption.value,
        RewardCouponId: isCouponReward ? data.RewardCouponOption.value : null,
        RewardCurrencyId: !isCouponReward
          ? data.RewardCurrencyOption.value
          : null,
        LoyaltyRewardTypeId: data.RewardTypeOption.value,
        Amount: data.AmountCents,
        StartDate: data.StartDate,
        EndDate: data.EndDate,
      }

      dispatch({ type: 'setIsSubmitting', isSubmitting: true })
      LoyaltyProgramController.Insert(request)
        .then(() => {
          history.back()
          dispatch({ type: 'setIsSubmitting', isSubmitting: false })
        })
        .catch((error: IGenericError) => {
          appUiStore.ShowError(error)
          dispatch({ type: 'setIsSubmitting', isSubmitting: false })
        })
    },
  }
}
