import React from 'react'

interface IProps {
  text: string
}

const TopHeader: React.FC<IProps> = ({ text }) => {
  return (
    <div className="masthead">
      <div className="container">
        <h1 className="masthead-subtitle">{text}</h1>
      </div>
    </div>
  )
}

export default TopHeader
