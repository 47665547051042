import { ISelectOption } from 'src/Models/Client'
import CurrencyVTO from 'src/Models/Server/Response/Currency/CurrencyVTO'

export const getCurrencyOptions = (
  currencies: CurrencyVTO[]
): ISelectOption[] => {
  return currencies.map((cur) => {
    return {
      label: cur.Name,
      value: cur.CurrencyId.toString(),
    }
  })
}
