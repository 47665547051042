import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Col, FormGroup, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import LoaderIcon from 'src/Components/LoaderIcon'
import McButton from 'src/Components/McButton'
import { MainPage } from 'src/Components/My'
import { t } from '../../../../I18n'
import AppUserGrid from './AppUserGrid/AppUsersGrid'
import EditMerchantModal from './EditMerchantModal/EditMerchantModal'
import LocationsGrid from './LocationsGrid/LocationsGrid'
import ManagerUserGrid from './ManagerUsersGrid/ManagerUserGrid'
import { merchantDetailsStore } from './Store'

const Details: React.FC = () => {
  const params = useParams<'id'>()
  useEffect(() => {
    merchantDetailsStore.Initialize(params.id)
  }, [null])

  const { ViewModel, IsLoading } = merchantDetailsStore

  const RenderEditMerchantModal = () => {
    const { EditMerchant } = merchantDetailsStore

    if (EditMerchant === null) {
      return null
    }

    return (
      <EditMerchantModal
        editMerchant={EditMerchant}
        onClose={() => {
          merchantDetailsStore.ToggleEditMerchantModal(null)
        }}
      />
    )
  }

  return (
    <MainPage>
      <LoaderIcon IsLoading={IsLoading} />

      <br className="xs-10" />
      {!IsLoading && ViewModel !== undefined && (
        <>
          <BreadCrumbs
            currentPageTitle={ViewModel.Name}
            previousPages={{
              to: '/merchants',
              title: t.common.merchants,
            }}
          />

          <Portlet title={t.common.merchant}>
            <Row className="details">
              <Col md={6}>
                <FormGroup>
                  <label>Id</label>
                  <span>{ViewModel.MerchantId}</span>
                </FormGroup>
                <FormGroup>
                  <label>{t.register.fieldMerchantName.label}</label>
                  <span>{ViewModel.Name}</span>
                </FormGroup>
                <FormGroup>
                  <label>
                    {t.merchant.insert.fields.FinanceEmailAddress.label}
                  </label>
                  <span>{ViewModel.FinanceEmailAddress}</span>
                </FormGroup>
                <FormGroup>
                  <label>{t.merchant.insert.fields.RelationID.label}</label>
                  <span>{ViewModel.RelationName}</span>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <label>{t.register.fieldContactName.label}</label>
                  <span>{ViewModel.ContactName}</span>
                </FormGroup>
                <FormGroup>
                  <label>{t.register.fieldConfirmEmailAddress.label}</label>
                  <span>{ViewModel.ContactEmailAddress}</span>
                </FormGroup>
                <FormGroup>
                  <label>{t.register.fieldStreet.label}</label>
                  <span>
                    {ViewModel.StreetName} {ViewModel.StreetNumber}
                  </span>
                </FormGroup>
                <FormGroup>
                  <label>{t.register.fieldZipcode.label}</label>
                  <span>{ViewModel.ZipCode}</span>
                </FormGroup>
              </Col>
            </Row>
            <hr />
            <McButton
              bsStyle="primary"
              pullRight
              onClick={() =>
                merchantDetailsStore.ToggleEditMerchantModal(
                  merchantDetailsStore.ViewModel
                )
              }
            >
              {t.common.edit}
            </McButton>
          </Portlet>
          <Portlet title={t.common.locations}>
            <LocationsGrid merchantGuid={ViewModel.MerchantGuid} />
          </Portlet>

          <Portlet title={t.merchant.details.appUsers.title}>
            <AppUserGrid merchantId={ViewModel.MerchantId} />
          </Portlet>

          <Portlet title={t.merchant.details.managerUsers.title}>
            <ManagerUserGrid merchantGuid={ViewModel.MerchantGuid} />
          </Portlet>
        </>
      )}
      {RenderEditMerchantModal()}
    </MainPage>
  )
}

export default observer(Details)
