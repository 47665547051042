import React, { PropsWithChildren } from 'react'

interface IProps {
  align?: 'left' | 'right'
}

const ButtonRow: React.FC<PropsWithChildren<IProps>> = ({
  children,
  align,
}) => {
  const css = 'button-row ' + align
  return <div className={css}>{children}</div>
}

export default ButtonRow

ButtonRow.defaultProps = {
  align: 'left',
}
