import React from 'react'
import { ButtonToolbar } from 'react-bootstrap'
import { t } from 'src/I18n'
import McButton from '../McButton'
import McModal from './McModal'

export interface IConfirmMessage {
  message: string | string[]
  confirmActionText: string
  confirmAction: () => void
}

interface IConfirmModalProps {
  confirmMessage?: IConfirmMessage
  closeClicked: () => void
  isConfirming?: boolean
}

const ConfirmModal: React.FC<IConfirmModalProps> = ({
  closeClicked,
  confirmMessage,
  isConfirming,
}) => {
  if (confirmMessage !== null) {
    return (
      <McModal
        closeClicked={closeClicked}
        title={t.common.confirm}
        footer={
          <ButtonToolbar className="pull-right">
            <McButton disabled={isConfirming} onClick={closeClicked}>
              {t.common.no}
            </McButton>
            <McButton
              bsStyle="primary"
              isLoading={isConfirming}
              onClick={() => {
                confirmMessage.confirmAction()
              }}
            >
              {confirmMessage.confirmActionText}
            </McButton>
          </ButtonToolbar>
        }
      >
        <div
          dangerouslySetInnerHTML={{
            __html: confirmMessage.message.toString(),
          }}
        />
      </McModal>
    )
  }

  return null
}

export default ConfirmModal
