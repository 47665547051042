import { action, makeObservable, observable, runInAction } from 'mobx'
import IGenericError from 'src/Models/Server/IGenericError'
import { IAutomaticChargeCollectionVTO } from 'src/Models/Server/Response/AutomaticCharge/IAutomaticChargeCollectionVTO'
import { IAutomaticChargeVTO } from 'src/Models/Server/Response/AutomaticCharge/IAutomaticChargeVTO'
import { IRefundVTO } from 'src/Models/Server/Response/Refund/IRefundVTO'
import AutomaticChargeController from 'src/Services/ToprCoreApi/Axios/Controllers/AutomaticChargeController'
import RefundController from 'src/Services/ToprCoreApi/Axios/Controllers/RefundController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export interface IFormValues {}

export class Store extends BaseStore<IFormValues> {
  public AutomaticCharges: IAutomaticChargeVTO[] = []
  public BlockedStornos: IAutomaticChargeCollectionVTO[] = []
  public Refunds: IRefundVTO[] = []

  constructor() {
    super()
    makeObservable(this, {
      AutomaticCharges: observable,
      BlockedStornos: observable,
      Refunds: observable,
      Initialize: action,
      TurnoffAutomaticCharge: action,
    })
  }

  public Initialize(userGuid: string) {
    this.IsLoading = true

    Promise.all([
      AutomaticChargeController.Last(userGuid),
      AutomaticChargeController.GetBlockedStornosByUser(userGuid),
      RefundController.GetUserRefunds(userGuid),
    ])
      .then((values) => {
        runInAction(() => {
          this.AutomaticCharges = values[0]
          this.BlockedStornos = values[1]
          this.Refunds = values[2]
          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        runInAction(() => {
          this.IsLoading = false
        })
      })
  }

  public TurnoffAutomaticCharge(automaticChargeId: number) {
    //AutomaticChargeStatusId
    const ac = this.AutomaticCharges.find(
      (x) => x.AutomaticChargeId === automaticChargeId
    )

    if (ac) {
      ac.AutomaticChargeStatusId = 1
      ac.AutomaticChargeStatus = 'Off'
      this.AutomaticCharges = [...this.AutomaticCharges]
    }
  }
}

export const userFinanceStore = new Store()
