import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { ButtonToolbar } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { McPageAlert } from 'src/Components'
import McButton from 'src/Components/McButton'
import McModal from 'src/Components/Modals/McModal'
import { MyTextbox } from 'src/Components/My'
import { t } from 'src/I18n'
import IHelpDeskUserVTO from 'src/Models/Server/Response/User/IHelpDeskUserVTO'
import { editNameStore, IFormValues } from './Store'

interface IProps {
  onClose: () => void
  onSubmitSuccess: (user: IHelpDeskUserVTO) => void
  user: IHelpDeskUserVTO
}

const EditNameModal: React.FC<IProps> = ({
  user,
  onClose,
  onSubmitSuccess,
}) => {
  useEffect(() => {
    editNameStore.Initialize(user)
  }, [null])

  const { ViewModel, PageAlert, IsSubmitting } = editNameStore
  useEffect(() => {
    reset(ViewModel)
  }, [ViewModel])

  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({})
  const { handleSubmit, reset } = useFormProps

  const onSubmit = (data: IFormValues) => {
    editNameStore.EditName(data.Name).then((user) => {
      onClose()
      onSubmitSuccess(user)
    })
  }
  return (
    <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
      <McModal
        title={t.editName.title}
        footer={
          <div>
            <ButtonToolbar className="pull-right">
              <McButton onClick={() => onClose()}>{t.common.cancel}</McButton>
              <McButton
                bsStyle="primary"
                type="submit"
                isLoading={IsSubmitting}
              >
                {t.common.save}
              </McButton>
            </ButtonToolbar>
          </div>
        }
        closeClicked={() => onClose()}
      >
        <MyTextbox
          formObject={{} as IFormValues}
          label={t.common.name}
          name="Name"
          useFormProps={useFormProps}
          rules={{
            required: t.editName.nameField.errors.required,
          }}
          maxLength={50}
          placeholder={t.common.name}
          tabIndex={1}
        />

        <McPageAlert pageAlert={PageAlert} />
      </McModal>
    </form>
  )
}

export default observer(EditNameModal)
