import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import LoaderIcon from 'src/Components/LoaderIcon'
import { MainPage, MyDataTable } from 'src/Components/My'
import { IColumn } from 'src/Models/Client'
import { INameGuidVTO } from 'src/Models/Server/INameGuidVTO'
import { t } from '../../../../I18n'
import { merchantsStore } from './Store'

const Merchants: React.FC = () => {
  useEffect(() => {
    merchantsStore.Initialize()
  }, [null])

  const { ViewModel, IsLoading } = merchantsStore

  const cellFormatter = (cell, merchant: INameGuidVTO) => {
    return <Link to={`/merchant/details/${merchant.Id}`}>{merchant.Name}</Link>
  }

  const columns: IColumn[] = [
    {
      dataField: 'Name',
      text: t.common.grid.name.label,
      filter: true,
      // filter: textFilter({
      //   placeholder: t.common.grid.name.placeholder,
      // }),
      formatter: cellFormatter,
    },
  ]

  return (
    <MainPage>
      <BreadCrumbs currentPageTitle={t.common.merchants} />
      <Link to="/merchant/insert">
        <Button bsStyle="primary">{t.merchant.insert.title}</Button>
      </Link>
      <br className="xs-10" />
      <LoaderIcon IsLoading={IsLoading} />
      {!IsLoading && ViewModel !== undefined && (
        <Portlet>
          <MyDataTable data={ViewModel} columns={columns} />
        </Portlet>
      )}
    </MainPage>
  )
}

export default observer(Merchants)
