import { observer } from 'mobx-react'
import React, { Fragment, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { McPageAlert } from 'src/Components'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import LoaderIcon from 'src/Components/LoaderIcon'
import McButton from 'src/Components/McButton'
import { MainPage, MySelect, MyTextbox } from 'src/Components/My'
import { t } from '../../../../I18n'
import { IFormValues, insertPosStore } from './Store'

const Insert: React.FC = () => {
  const params = useParams<'locationGuid' | 'locationName' | 'merchantGuid'>()
  const navigate = useNavigate()
  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({
    defaultValues: {
      Name: '',
    },
  })
  const { handleSubmit } = useFormProps

  useEffect(() => {
    insertPosStore.Initialize(
      params.locationGuid,
      params.merchantGuid,
      params.locationName
    )
  }, [null])

  const onSubmit = (data: IFormValues) => {
    insertPosStore.Insert(
      data.SelectedPosType.value,
      params.locationGuid,
      params.merchantGuid,
      data.Name,
      navigate
    )
  }

  const { ViewModel, IsLoading, IsSubmitting, Merchant, PosTypes, PageAlert } =
    insertPosStore

  return (
    <MainPage>
      <LoaderIcon IsLoading={IsLoading} />

      <br className="xs-10" />
      {!IsLoading && ViewModel !== undefined && Merchant !== undefined && (
        <Fragment>
          <BreadCrumbs
            currentPageTitle={t.pos.insert.title}
            previousPages={[
              {
                to: '/merchants',
                title: t.common.merchants,
              },
              {
                to: `/merchant/details/${params.merchantGuid}`,
                title: Merchant.Name,
              },
              {
                to: `/location/details/${params.locationGuid}`,
                title: params.locationName,
              },
            ]}
          />

          <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
            <Portlet title={t.common.pos}>
              <Row>
                <Col md={6}>
                  <MyTextbox
                    formObject={{} as IFormValues}
                    label={t.location.insert.fields.Name.label}
                    name="Name"
                    useFormProps={useFormProps}
                    rules={{
                      required: t.location.insert.fields.Name.errors.required,
                    }}
                    tabIndex={1}
                  />
                  <MySelect
                    formObject={{} as IFormValues}
                    useFormProps={useFormProps}
                    label={t.pos.insert.fields.PosTypeID.label}
                    name="SelectedPosType"
                    rules={{
                      required: t.pos.insert.fields.PosTypeID.errors.required,
                    }}
                    options={PosTypes}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col mdOffset={8} md={4}>
                  <McButton
                    block
                    bsStyle="primary"
                    type="submit"
                    isLoading={IsSubmitting}
                  >
                    {t.common.save}
                  </McButton>
                </Col>
              </Row>
            </Portlet>
          </form>
        </Fragment>
      )}
      <McPageAlert pageAlert={PageAlert} />
    </MainPage>
  )
}

export default observer(Insert)
