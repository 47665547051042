import { observer } from 'mobx-react'
import React from 'react'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import { MainPage } from 'src/Components/My'
import { t } from 'src/I18n'

import SubscriptionsPortlet from './Components/SubscriptionsPortlet'
interface IProps {}

const Subscriptions: React.FC<IProps> = (props) => {
  return (
    <>
      <MainPage>
        <BreadCrumbs currentPageTitle={t.subscription.index.title} />
        <SubscriptionsPortlet />
      </MainPage>
    </>
  )
}

export default observer(Subscriptions)
