import * as signalR from '@microsoft/signalr'
//const URL = process.env.HUB_ADDRESS ?? 'https://localhost:44382/hubs/merchant' //or whatever your backend port is

const URL = '/hubs/merchant'

export default class Connector {
  private connection: signalR.HubConnection
  public ReveivedEvents: (
    onReceiveMerchantMessage: (message: string) => void,
    onUserConnected: (connectionId: string) => void,
    onUserDisconnected: (connectionId: string) => void
  ) => void
  static instance: Connector
  constructor() {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(URL)
      .withAutomaticReconnect()
      .build()
    this.connection.start().catch((err) => document.write(err))
    this.ReveivedEvents = (
      onReceiveMerchantMessage,
      onUserConnected,
      onUserDisconnected
    ) => {
      this.connection.on('ReceiveMerchantMessage', (message) => {
        onReceiveMerchantMessage(message)
      })
      this.connection.on('UserConnected', (connectionId) => {
        onUserConnected(connectionId)
      })
      this.connection.on('UserDisconnected', (connectionId) => {
        onUserDisconnected(connectionId)
      })
    }
  }

  /*
  public InitializedConection() {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(URL)
      .withAutomaticReconnect()
      .build()

    this.connection.start().catch((err) => document.write(err))

    this.ReveivedEvents = (
      onReceiveMerchantMessage,
      onUserConnected,
      onUserDisconnected
    ) => {
      this.connection.on('ReceiveMerchantMessage', (message) => {
        onReceiveMerchantMessage(message)
      })

      this.connection.on('UserConnected', (connectionId) => {
        onUserConnected(connectionId)
      })

      this.connection.on('UserDisconnected', (connectionId) => {
        onUserDisconnected(connectionId)
      })
    }
    console.log('InitializedConection done')
  }
*/
  public SendMerchantMessage = (message: string) => {
    this.connection
      .send('SendMerchantMessage', 1, message)
      .then((x) => console.log('SendMerchantMessage sent'))
      .catch((ex) => {
        console.error('Signalr error', ex)
      })
  }
  public static getInstance(): Connector {
    if (!Connector.instance) Connector.instance = new Connector()
    return Connector.instance
  }
}
