import { AxiosError, AxiosResponse } from 'axios'
import { handleException } from 'src/Helpers/ResponseHelpers'
import { IBankDTO } from 'src/Models/Server/Response/Bank/IBankDTO'
import { axiosAuth } from '../AuthAxios'

export default {
  GetAllBanks() {
    return new Promise<IBankDTO[]>((resolve, reject) => {
      axiosAuth
        .get(`/api/v1/banks/all`)
        .then((response: AxiosResponse<IBankDTO[]>) => {
          resolve(response.data)
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
}
