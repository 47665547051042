import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import { MyDateRangePicker, MySelect } from 'src/Components/My'
import { ISelectOption } from 'src/Models/Client'
import { EndOfMonth } from 'src/Helpers/DateHelpers'
import { t } from 'src/I18n'
import { IFormValues, monthPeriodStore } from './Store'

interface IProps {
  onChange: (startDate: Date, endDate: Date) => void
}

const MyMonthPeriodSelect: React.FC<IProps> = ({ onChange }) => {
  useEffect(() => {
    monthPeriodStore.Initialize()
  }, [null])

  const { Months, ViewModel, ShowCustomDate } = monthPeriodStore

  useEffect(() => {
    reset(ViewModel)
  }, [ViewModel])

  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({})
  const { reset } = useFormProps

  const handleMonthSelectChange = (option: ISelectOption) => {
    if (option.value === 'custom') {
      //show custom date selector
      monthPeriodStore.SetShowCustomDate(true)
    } else {
      monthPeriodStore.SetShowCustomDate(false)

      const startDate = new Date(option.value)

      const endDate = EndOfMonth(startDate)
      console.log('end', endDate)
      // const endDate = moment(startDate).endOf('month').toDate()
      // endDate.setHours(0, 0, 0)
      onChange(startDate, endDate)
    }
  }

  const renderDateRangePicker = () => {
    if (!ShowCustomDate) {
      return null
    }

    return (
      <MyDateRangePicker
        formObject={{} as IFormValues}
        name="DateRange"
        useFormProps={useFormProps}
        onDateRangeChange={(dateRange) => {
          onChange(dateRange.StartDate, dateRange.EndDate)
        }}
      />
    )
  }
  return (
    <>
      <MySelect
        formObject={{} as IFormValues}
        useFormProps={useFormProps}
        label={t.common.period}
        name="SelectedMonth"
        options={Months}
        onSelectionChange={(option: ISelectOption) => {
          handleMonthSelectChange(option)
        }}
      />
      {renderDateRangePicker()}
    </>
  )
}

export default observer(MyMonthPeriodSelect)
