import React from 'react'
import { ButtonProps } from 'react-bootstrap'
interface IProps extends ButtonProps {
  label: string
  pullRight?: boolean
}

const MyDropDownButton: React.FC<IProps> = ({
  label,
  bsStyle,
  style,
  disabled,
  pullRight,
  children,
}) => {
  const pullRightClass = pullRight ? 'pull-right' : ''
  return (
    <div className={`btn-group ${pullRightClass}`} style={style}>
      <button
        type="button"
        className={`btn btn-${bsStyle} dropdown-toggle`}
        data-toggle="dropdown"
        style={style}
        disabled={disabled}
      >
        {label} <span className="caret" />
      </button>
      <ul className="dropdown-menu" role="menu">
        {children}
        {/*
        <li className="divider" />
        <li>
          <a href="javascript:;">Separated link</a>
        </li> */}
      </ul>
    </div>
  )
}

export default MyDropDownButton

MyDropDownButton.defaultProps = {
  bsStyle: 'primary',
}
