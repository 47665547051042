import IGenericError from 'src/Models/Server/IGenericError'
import { IEventVTO } from 'src/Models/Server/Response/Event/IEventVTO'
import { IUserEventSto } from 'src/Models/Server/Response/Event/IUserEventSto'

import EventController from 'src/Services/ToprCoreApi/Axios/Controllers/EventController'
import { appUiStore } from 'src/Stores/AppUiStore'

export interface IState {
  isLoading: boolean
  addEventId?: number
  userEvents: IUserEventSto[]
  events: IEventVTO[]
}

export const initialState: IState = {
  isLoading: true,
  userEvents: [],
  events: [],
  addEventId: undefined,
}

export type Actions =
  | {
      type: 'initialize'
      userEvents: IUserEventSto[]
      events: IEventVTO[]
    }
  | {
      type: 'toggleAddTicket'
      addEventId?: number
    }

export const reducer = (state: IState, action: Actions): IState => {
  switch (action.type) {
    case 'initialize':
      return {
        ...state,
        isLoading: false,
        userEvents: action.userEvents,
        events: action.events,
      }
    case 'toggleAddTicket':
      return { ...state, addEventId: action.addEventId }
    default:
      throw new Error('Invalid Reducer Action')
  }
}

export const reducerActions = (
  state: IState,
  dispatch: (action: Actions) => void
) => {
  const initialize = (userGuid: string) => {
    Promise.all([
      EventController.GetUserEventTickets(userGuid),
      EventController.GetEvents(),
    ])
      .then((values) => {
        dispatch({
          type: 'initialize',
          userEvents: values[0],
          events: values[1],
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
      })
  }

  return {
    initialize,
    delete: (userEventTicketId: number, userGuid: string) => {
      EventController.DeleteUserEventTicket(userEventTicketId)
        .then(() => {
          initialize(userGuid)
        })
        .catch((error: IGenericError) => {
          appUiStore.ShowError(error)
        })
    },
    toggleAddTicket: (addEventId?: number) => {
      dispatch({ type: 'toggleAddTicket', addEventId })
    },
  }
}
