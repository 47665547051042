import React, { Fragment, ReactNode } from 'react'
import { Panel } from 'react-bootstrap'
import { formatCentsToEuro } from 'src/Helpers/CurrencyHelpers'
import { GetDateText } from 'src/Helpers/DateHelpers'
import { GetLocalizedImageFileUrl } from 'src/Images'
import { CouponTypesDTO } from 'src/Models/Server/Enums'
import ICouponDetailsStatsDTO from 'src/Models/Server/Response/Coupon/ICouponDetailStatsDTO'
import ICouponDTO from 'src/Models/Server/Response/Coupon/ICouponDTO'
import CircleStat from '../../../../../Components/Authenticated/CircleStat'
import FlexRow from '../../../../../Components/Authenticated/FlexRow'
import LoyaltyStatusBadge from '../../../../../Components/Authenticated/LoyaltyStatusBadge'
import { t } from '../../../../../I18n'

interface ICouponDetailsReport {
  couponDetailsStats: ICouponDetailsStatsDTO
  footer?: ReactNode

  //onClick: () => void
}

/*
portlet todo: 
- subtitle

*/

const renderCircleStatsCoupon = (stat: number, coupon: ICouponDTO) => {
  const calculateValue = (amount: number) => {
    return formatCentsToEuro(amount * coupon.DiscountAmount, {
      symbol: '',
    })
  }

  return (
    <Fragment>
      <CircleStat stat={stat} iconText="#" />
      {coupon.CouponTypeId === CouponTypesDTO.Euro && (
        <CircleStat
          stat={calculateValue(stat)}
          iconText="€"
          additionalClassName="blue"
        />
      )}
    </Fragment>
  )
}

const CouponDetailsReport: React.FC<ICouponDetailsReport> = ({
  couponDetailsStats,
  footer,
}) => {
  const notRedeemd = couponDetailsStats.Issued - couponDetailsStats.Redeemed

  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title componentClass="h4">
          {couponDetailsStats.Coupon.Title}
        </Panel.Title>
        <Panel.Title componentClass="h5">
          {GetDateText(
            couponDetailsStats.Coupon.ValidFrom,
            couponDetailsStats.Coupon.ValidTill
          )}
          <LoyaltyStatusBadge status={couponDetailsStats.Coupon.Status} />
        </Panel.Title>
      </Panel.Heading>
      <Panel.Body className="nopad">
        <FlexRow>
          <div
            className="bgimg min-y-300 f-col-sm-6"
            style={{
              backgroundImage: `url(${GetLocalizedImageFileUrl(
                couponDetailsStats.Coupon.CouponLocalizedImageGuid
              )})`,
            }}
          />
          <div className="f-col-sm-6 p-xy-15 circle-stats even">
            <div className="circle-stat-row">
              <div className="circle-header">
                <h5>{t.common.amount}</h5>
                {couponDetailsStats.Coupon.CouponTypeId ===
                  CouponTypesDTO.Euro && <h5>{t.common.sum}</h5>}
              </div>
            </div>
            <div className="circle-stat-row">
              {renderCircleStatsCoupon(
                couponDetailsStats.Redeemed,
                couponDetailsStats.Coupon
              )}
              <span>{t.savingcardDetails.couponsRedeemed}</span>
            </div>
            <div className="circle-stat-row">
              {renderCircleStatsCoupon(notRedeemd, couponDetailsStats.Coupon)}
              <span
                dangerouslySetInnerHTML={{
                  __html: t.savingcardDetails.couponsNotRedeemed,
                }}
              />
            </div>
            {/* <hr /> */}
            <div className="circle-stat-row">
              {renderCircleStatsCoupon(
                couponDetailsStats.Issued,
                couponDetailsStats.Coupon
              )}
              <span>{t.savingcardDetails.couponsIssued}</span>
            </div>
          </div>
        </FlexRow>
      </Panel.Body>
      {footer}
    </Panel>
  )
}

export default CouponDetailsReport
