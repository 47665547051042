import { action, observable, makeObservable, runInAction } from 'mobx'
import IGenericError from 'src/Models/Server/IGenericError'
import IAppUserVTO from 'src/Models/Server/Response/User/IAppUserVTO'
import IManagerUserGridVTO from 'src/Models/Server/Response/User/IManagerUserGridVTO'
import ManagerUserController from 'src/Services/ToprCoreApi/Axios/Controllers/ManagerUserController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export default class Store extends BaseStore<IManagerUserGridVTO[]> {
  public ShowAddUser: boolean = false
  public MerchantGuid: string = ''
  public EditUser: IManagerUserGridVTO = null

  constructor() {
    super()
    this.BaseInitialize([])
    makeObservable(this, {
      ShowAddUser: observable,
      MerchantGuid: observable,
      EditUser: observable,
      Inialize: action,
      ToggleAddUserModal: action,
      DeleteManagerUser: action,
      ToggleEditUserModal: action,
    })
  }

  public Inialize(merchantGuid: string) {
    this.MerchantGuid = merchantGuid
    this.IsLoading = true

    ManagerUserController.GetMerchantManagerUsers(merchantGuid)
      .then((managerUsers) => {
        runInAction(() => {
          this.ViewModel = managerUsers
          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        runInAction(() => (this.IsLoading = false))
      })
  }

  public ToggleAddUserModal(showAddUserModal: boolean) {
    this.ShowAddUser = showAddUserModal
  }

  public DeleteManagerUser(userGuid: string) {
    this.IsConfirming = true

    ManagerUserController.Delete(userGuid)
      .then(() => {
        runInAction(() => {
          const managerUser = this.ViewModel.find(
            (x) => x.UserGuid === userGuid
          )
          this.DeleteElement(managerUser)
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
      })
  }

  public ToggleEditUserModal(editAppUser?: IAppUserVTO) {
    this.EditUser = editAppUser
  }
}

export const managerUsersStore = new Store()
