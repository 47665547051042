export class LatLng {
  // lat: number
  // lng: number

  /**
   *
   */
  /**
   *
   */
  constructor(public lat: number, public lng: number) {}
}
