import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import React from 'react'
import { Bar } from 'react-chartjs-2'
import Portlet from 'src/Components/Authenticated/Portlet'
import { getCurrencySymbol } from 'src/Helpers/CurrencyHelpers'
import { GetCurrentMonthLocalized } from 'src/Helpers/DateHelpers'
import { t } from 'src/I18n'
import { IChartData, IChartResponse } from 'src/Models/Server/Chart/IChartData'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

//https://react-chartjs-2.js.org/examples/vertical-bar-chart

interface IProps {
  chartResponse: IChartResponse
  onDetailsClick?: (id: string) => void
}

const colors = ['#C2D102', '#B20252', '#391091']

const setChartColors = (chartData: IChartData) => {
  chartData.datasets.forEach((dataset, index) => {
    dataset.backgroundColor = colors[index]
  })
}

const MyChart: React.FC<IProps> = ({ chartResponse, onDetailsClick }) => {
  const options: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      // title: {
      //   display: true,
      //   text: 'chart title',
      // },
      tooltip: {
        callbacks: {
          label: (item) => {
            return `${getCurrencySymbol(chartResponse.Currency.StringFormat)}${
              item.dataset.label
            }`
          },
          title: (item) => {
            return `${item[0].label} ${GetCurrentMonthLocalized()}`
          },
        },
      },
    },
  }

  const renderCharts = () => {
    if (chartResponse === undefined) {
      return null
    }

    return chartResponse.ChartDatas.map((chartData, key) => {
      setChartColors(chartData)
      return (
        <Portlet
          title={chartData.Title}
          key={key}
          headerContent={
            chartData.Id === null ? null : (
              <a
                onClick={() => {
                  if (onDetailsClick && chartData.Id) {
                    onDetailsClick(chartData.Id.toString())
                  }
                }}
                className="pull-right"
              >
                {t.common.details}
                <i className="fa fa-arrow-right ml-10" />
              </a>
            )
          }
        >
          <Bar height={100} options={options} data={chartData} />
        </Portlet>
      )
    })
  }

  return <>{renderCharts()}</>
}

export default MyChart
