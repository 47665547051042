import { observer } from 'mobx-react'
import React, { Fragment, useEffect } from 'react'
import { ButtonToolbar } from 'react-bootstrap'

import ActiveIcon from 'src/Components/Icons/ActiveIcon'
import LoaderIcon from 'src/Components/LoaderIcon'
import McButton from 'src/Components/McButton'

import { MyDataTable } from 'src/Components/My'
import { t } from 'src/I18n'
import ISamVTO from 'src/Models/Server/Response/Sam/ISamVTO'
import { appUiStore } from 'src/Stores/AppUiStore'
import LinkSamModal from './LinkSamModal/LinkSamModal'
import { samsGridStore } from './Store'
interface IProps {
  terminalId: string
}

const SamsGrid: React.FC<IProps> = ({ terminalId }) => {
  useEffect(() => {
    samsGridStore.GetSamsByTerminal(terminalId)
  }, [null])

  const { ViewModel, IsLoading, TerminalId, ShowLinkSam } = samsGridStore

  const actionsCellFormatter = (cell, data: ISamVTO) => {
    const isSubmitting =
      samsGridStore.EditSamId === data.SamId && samsGridStore.IsSubmitting
    return (
      <ButtonToolbar>
        {data.IsBlocked && (
          <McButton
            iconLeft="fa-check"
            bsSize="small"
            bsStyle="default"
            isLoading={isSubmitting}
            onClick={() => {
              samsGridStore.UnblockSam(data.SamId)
            }}
          >
            {t.sam.unblock.title}
          </McButton>
        )}
        {!data.IsBlocked && (
          <McButton
            iconLeft="fa-ban"
            bsSize="small"
            bsStyle="danger"
            isLoading={isSubmitting}
            onClick={() => {
              samsGridStore.BlockSam(data.SamId)
            }}
          >
            {t.sam.block.title}
          </McButton>
        )}
        <McButton
          iconLeft="fa-ban"
          bsSize="small"
          bsStyle="danger"
          isLoading={isSubmitting}
          onClick={() => {
            appUiStore.ShowConfirm({
              message: t.formatString(t.sam.unlink.confirmMessage, {
                SamId: data.SamId,
              }) as string,

              confirmAction: () => samsGridStore.Unlink(data.SamId),
              confirmActionText: t.sam.unlink.action,
            })
          }}
        >
          {t.sam.unlink.action}
        </McButton>
      </ButtonToolbar>
    )
  }

  const samCellFormatter = (cell, data: ISamVTO) => {
    return <ActiveIcon active={!data.IsBlocked} text={data.SamId.toString()} />
  }

  const columns = [
    {
      dataField: 'SamId',
      text: 'SamId',
      formatter: samCellFormatter,
      // filter: textFilter({
      //   placeholder: 'SamId',
      // }),
    },
    {
      dataField: 'Uid',
      text: 'Uid',
      // filter: textFilter({
      //   placeholder: 'Uid',
      // }),
    },
    {
      dataField: 'SamTypeName',
      text: t.sam.insert.fields.SamTypeID.label,
      // filter: textFilter({
      //   placeholder: t.sam.insert.fields.SamTypeID.label,
      // }),
    },
    {
      dataField: 'actions',
      csvExport: false,
      text: '',
      formatter: actionsCellFormatter,
    },
  ]

  return (
    <Fragment>
      <ButtonToolbar className="pull-left">
        <McButton
          bsStyle="primary"
          type="submit"
          onClick={() => samsGridStore.InsertSam()}
          isLoading={samsGridStore.IsSubmitting}
        >
          {t.sam.insert.title}
        </McButton>
        <McButton
          bsStyle="default"
          type="submit"
          onClick={() => samsGridStore.ToggleLinkSamModal(true)}
        >
          {t.sam.link.title}
        </McButton>
      </ButtonToolbar>
      <LoaderIcon IsLoading={IsLoading} />
      {!IsLoading && <MyDataTable data={ViewModel} columns={columns} />}
      {ShowLinkSam && (
        <LinkSamModal
          terminalId={TerminalId}
          onClose={() => samsGridStore.ToggleLinkSamModal(false)}
          onSubmitSuccess={() => samsGridStore.GetSamsByTerminal(TerminalId)}
        />
      )}
    </Fragment>
  )
}

export default observer(SamsGrid)
