import axios, { AxiosError, AxiosResponse } from 'axios'
import { GetGeoCodeUrl } from 'src/Helpers/MapHelpers'
import { handleException } from 'src/Helpers/ResponseHelpers'
import { IExceptionError } from 'src/Models/Server/Response/Exception/ExceptionError'

export default {
  GeoCodeAddress(
    city: string,
    streetName: string,
    streetNumber: string,
    zipcode: string
  ) {
    return new Promise<{
      results: google.maps.GeocoderResult[]
      status: google.maps.GeocoderStatus
    }>((resolve, reject: (error: IExceptionError) => void) => {
      axios
        .get(encodeURI(GetGeoCodeUrl(city, streetName, streetNumber, zipcode)))
        .then(
          (
            response: AxiosResponse<{
              results: google.maps.GeocoderResult[]
              status: google.maps.GeocoderStatus
            }>
          ) => {
            resolve(response.data)
          }
        )
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
}
