import { observer } from 'mobx-react'
import React from 'react'
import { ButtonToolbar } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { McButton } from 'src/Components'
import { McModal } from 'src/Components/Modals'
import { MySelect } from 'src/Components/My'
import { t } from 'src/I18n'
import { IFormValues, mainPageStore } from '../Store'
interface IProps {}

const SelectManagerUserModal: React.FC<IProps> = () => {
  const { IsSubmitting, ManagerUserOptions, ShowManagerUserModal } =
    mainPageStore

  const navigate = useNavigate()
  const useFormProps: UseFormReturn<IFormValues> = useForm<IFormValues>({})
  const { handleSubmit } = useFormProps

  const close = () => {
    if (!mainPageStore.IsSubmitting) {
      mainPageStore.ToggleManagerUserModal(false)
    }
  }

  const onSubmit = (data: IFormValues) => {
    mainPageStore.SwitchManagerUser(
      data.SelectedManagerUserOption.value,
      navigate
    )
  }

  if (!ShowManagerUserModal) {
    return null
  }

  return (
    <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
      <McModal
        title={t.topMenu.changeMerchant.label}
        footer={
          <div>
            <ButtonToolbar className="pull-right">
              <McButton onClick={() => close()}>{t.common.cancel}</McButton>
              <McButton
                bsStyle="primary"
                type="submit"
                isLoading={IsSubmitting}
              >
                {t.common.save}
              </McButton>
            </ButtonToolbar>
          </div>
        }
        closeClicked={() => close()}
      >
        <MySelect
          formObject={{} as IFormValues}
          useFormProps={useFormProps}
          label={t.topMenu.changeMerchant.label}
          name="SelectedManagerUserOption"
          rules={{
            required: t.topMenu.changeMerchant.required,
          }}
          options={ManagerUserOptions}
        />
      </McModal>
    </form>
  )
}

export default observer(SelectManagerUserModal)
