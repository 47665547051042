import axios, { AxiosError, AxiosResponse } from 'axios'
import { handleException } from 'src/Helpers/ResponseHelpers'
import { GetBaseUrl } from 'src/Helpers/ServiceHelpers'
import { getFullLanguage } from 'src/I18n'
import FileDTO from 'src/Models/Server/File/FileDTO'
import { IEditFileRequest } from 'src/Models/Server/Request/File/IEditFileRequest'
import { IInsertFileRequest } from 'src/Models/Server/Request/File/IInsertFileRequest'
import { IExceptionError } from 'src/Models/Server/Response/Exception/ExceptionError'
import FileDownload from 'js-file-download'
import { FileDateTime } from 'src/Helpers/DateHelpers'
import { axiosAuth } from '../AuthAxios'
export default {
  Insert(request: IInsertFileRequest) {
    return new Promise<string>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/file/insert`, request)
          .then((response: AxiosResponse<string>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  Edit(request: IEditFileRequest) {
    return new Promise<string>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .post(`/api/v1/file/edit`, request)
          .then((response: AxiosResponse<string>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  Details(fileId: string) {
    return new Promise<FileDTO>(
      (resolve, reject: (error: IExceptionError) => void) => {
        axiosAuth
          .get(`/api/v1/file/details/${fileId}`)
          .then((response: AxiosResponse<FileDTO>) => {
            resolve(response.data)
          })
          .catch((error: AxiosError) => {
            handleException(error, reject)
          })
      }
    )
  },
  DownloadFile(fileId: number) {
    return new Promise<void>((resolve, reject) => {
      axios
        .get(encodeURI(GetBaseUrl() + `/api/content/v1/file/data/${fileId}`), {
          headers: {
            'accept-language': getFullLanguage(),
            //Authorization: 'Bearer ' + accessToken,
          },
        })
        .then((response: AxiosResponse<any>) => {
          FileDownload(response.data as any, `sepafile_${FileDateTime()}.xml`)
          resolve()
        })
        .catch((error: AxiosError) => {
          handleException(error, reject)
        })
    })
  },
}
