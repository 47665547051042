import { action, makeObservable, observable, runInAction } from 'mobx'
import { ISelectOption } from 'src/Models/Client'
import IGenericError from 'src/Models/Server/IGenericError'
import { SelectOptionsFromNameGuidVTO } from 'src/Models/Server/INameGuidVTO'
import IMonthlyTransactionTotalVTO from 'src/Models/Server/Response/Transaction/IMonthlyTransactionTotalVTO'
import ReportController from 'src/Services/ToprCoreApi/Axios/Controllers/ReportController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export interface IFormValues {
  Merchants: ISelectOption[]
  SelectedMerchants: ISelectOption[]

  Currencies: ISelectOption[]
  SelectedCurrencies: ISelectOption[]
  StartDate: Date
  EndDate: Date
}

export default class Store extends BaseStore<IFormValues> {
  public LoadingTotals: boolean = false

  public MonthlyTransactionTotals: IMonthlyTransactionTotalVTO[] = []

  constructor() {
    super()
    this.BaseInitialize()
    makeObservable(this, {
      LoadingTotals: observable,
      MonthlyTransactionTotals: observable,
      Initialize: action,
      GetMonthlyTransactionTotals: action,
    })
  }

  public Initialize() {
    this.IsLoading = true
    ReportController.GetDefaultFilter()
      .then((response) => {
        const merchantOptions = SelectOptionsFromNameGuidVTO(response.Merchants)
        const currencyOptions = SelectOptionsFromNameGuidVTO(
          response.Currencies
        )
        const today = new Date()
        runInAction(() => {
          this.ViewModel = {
            Merchants: merchantOptions,
            SelectedMerchants: merchantOptions,
            Currencies: currencyOptions,
            SelectedCurrencies: currencyOptions,
            StartDate: new Date(today.getFullYear(), today.getMonth(), 1),
            EndDate: today,
          }

          this.GetMonthlyTransactionTotals(this.ViewModel)

          this.IsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsLoading = false))
        appUiStore.ShowError(error)
      })
  }

  public GetMonthlyTransactionTotals(data: IFormValues) {
    const merchantGuids = data.SelectedMerchants.map((x) => x.value)
    const currencyGuids = data.SelectedCurrencies.map((x) => x.value)
    const startDate = data.StartDate
    const endDate = data.EndDate

    this.LoadingTotals = true
    ReportController.MonthlyTransactionTotals(
      merchantGuids,
      currencyGuids,
      startDate,
      endDate
    )
      .then((result) => {
        runInAction(() => {
          this.MonthlyTransactionTotals = result
          this.LoadingTotals = false
        })
      })
      .catch((error: IGenericError) => {
        runInAction(() => (this.IsLoading = false))
        appUiStore.ShowError(error)
      })
  }
}
export const monthlyTransactionsStore = new Store()
