import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
//import paginationFactory from 'react-bootstrap-table2-paginator'
import { LoaderIcon, McButton } from 'src/Components'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import Portlet from 'src/Components/Authenticated/Portlet'
import { MainPage, MyDataTable } from 'src/Components/My'
import { FormatDateTimeLocalized } from 'src/Helpers/DateHelpers'
import { t } from 'src/I18n'
import { IMerchantFileSTO } from 'src/Models/Server/Response/Merchant/IMerchantFile'
import { sepaFileStore } from './Store'

const SepaFile: React.FC = () => {
  useEffect(() => {
    sepaFileStore.Initialize()
  }, [null])

  const {
    AvailableAutomaticChargeCollections,
    IsLoading,
    IsSubmitting,
    MerchantFiles,
    Page,
    PageSize,
    TotalRows,
  } = sepaFileStore

  const renderContent = () => {
    if (IsLoading) {
      return <LoaderIcon IsLoading={IsLoading} />
    }

    const columns = [
      {
        dataField: 'FileId',
        text: t.common.file,
        formatter: (cell, data: IMerchantFileSTO) => {
          if (data.Processed === null || data.Processed === undefined) {
            return <>{t.generateAutomaticCharge.fileBeingGenerated}</>
          }

          if (data.FileId === null) {
            return <>{t.generateAutomaticCharge.noFile}</>
          }

          return (
            <a onClick={() => sepaFileStore.DownloadSepaFile(data.FileId)}>
              {t.common.file} {data.FileId}
            </a>
          )
        },
      },
      {
        dataField: 'Processed',
        text: t.common.processed,
        formatter: (cell, data: IMerchantFileSTO) => {
          return FormatDateTimeLocalized(data.Processed)
        },
      },
      {
        dataField: 'ErrorMessage',
        text: t.common.error,
      },
    ]

    return (
      <>
        {t.formatString(
          t.generateAutomaticCharge.availableAutomaticChargesText,
          {
            count: AvailableAutomaticChargeCollections,
          }
        )}
        <McButton
          bsSize="small"
          bsStyle="primary"
          type="button"
          disabled={AvailableAutomaticChargeCollections === 0}
          isLoading={IsSubmitting}
          onClick={() => {
            sepaFileStore.GenerateAutomaticChargeCollectionFile()
          }}
        >
          {t.common.generate}
        </McButton>
        <hr />
        <MyDataTable
          data={MerchantFiles}
          columns={columns}
          serverPaging={{
            page: Page,
            pageSize: PageSize,
            totalRows: TotalRows,
            onPagination: (newPage, newPageSize) => {
              sepaFileStore.SetPagination(newPage, newPageSize)
            },
          }}
        />
      </>
    )
  }
  return (
    <>
      <MainPage>
        <BreadCrumbs currentPageTitle={t.generateAutomaticCharge.title} />
        <Portlet title={t.generateAutomaticCharge.title}>
          {renderContent()}
        </Portlet>
      </MainPage>
    </>
  )
}

export default observer(SepaFile)
