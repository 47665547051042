import React from 'react'
import { t } from 'src/I18n'
import IGenericError from 'src/Models/Server/IGenericError'
import McModal from './McModal'

interface IErrorModalProps {
  error?: IGenericError
  closeClicked: () => void
}

const ErrorModal: React.FC<IErrorModalProps> = ({ error, closeClicked }) => {
  if (error === null || error === undefined) {
    return null
  }
  return (
    <McModal title={t.common.info} closeClicked={closeClicked}>
      {error.Message}
    </McModal>
  )
}

export default ErrorModal
