import { observer } from 'mobx-react'

import React, { PropsWithChildren } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { persistedStore } from 'src/Stores/PersistedStore'

const RequireAuthentication: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const { IsAuthenticated } = persistedStore
  const location = useLocation()

  if (!IsAuthenticated) {
    return <Navigate to="/" state={{ from: location }} />
  }

  return <>{children}</>
}

export default observer(RequireAuthentication)
