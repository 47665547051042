import { ISelectOption } from 'src/Models/Client'
import IGenericError from 'src/Models/Server/IGenericError'
import { ITagVTO } from 'src/Models/Server/Response/Tag/ITagVTO'
import TagController from 'src/Services/ToprCoreApi/Axios/Controllers/TagController'
import { appUiStore } from 'src/Stores/AppUiStore'

export interface IState {
  isSubmitting: boolean
  isLoading: boolean
  viewModel: IAddUserTagForm
  tagOptions: ISelectOption[]
}

export const initialState: IState = {
  isSubmitting: false,
  isLoading: true,
  viewModel: undefined,
  tagOptions: [],
}

export interface IAddUserTagForm {
  tagOption?: ISelectOption
}

export type Actions =
  | {
      type: 'setIsSubmitting'
      isSubmitting: boolean
    }
  | {
      type: 'initialize'
      //viewModel: IAddUserTagForm
      tagOptions: ISelectOption[]
    }

export const reducer = (state: IState, action: Actions): IState => {
  switch (action.type) {
    case 'initialize':
      return {
        ...state,
        isLoading: false,
        //viewModel: action.viewModel,
        tagOptions: action.tagOptions,
      }
    case 'setIsSubmitting':
      return { ...state, isSubmitting: action.isSubmitting }

    default:
      throw new Error('Invalid Reducer Action')
  }
}

export const reducerActions = (
  state: IState,
  dispatch: (action: Actions) => void
) => {
  return {
    initialize: (userTags: ITagVTO[]) => {
      TagController.GetTags()
        .then((tags) => {
          const tagOptions: ISelectOption[] = tags
            .filter((x) => {
              //filter out tags the user already has
              return userTags.findIndex((ut) => ut.TagId === x.TagId) === -1
            })
            .sort((a, b) => {
              return a.Name.localeCompare(b.Name)
            })
            .map((tag) => ({
              label: tag.Name,
              value: tag.TagId.toString(),
            }))

          dispatch({ type: 'initialize', tagOptions })
        })
        .catch((error: IGenericError) => {
          appUiStore.ShowError(error)
        })
    },
    save: (userGuid: string, data: IAddUserTagForm, onComplete: () => void) => {
      dispatch({ type: 'setIsSubmitting', isSubmitting: true })

      TagController.UserTagInsert(userGuid, data.tagOption.value)
        .then(() => {
          onComplete()
          dispatch({ type: 'setIsSubmitting', isSubmitting: false })
        })
        .catch((error: IGenericError) => {
          appUiStore.ShowError(error)
          dispatch({ type: 'setIsSubmitting', isSubmitting: false })
        })
    },
  }
}
