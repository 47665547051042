import React from 'react'
import { useParams } from 'react-router-dom'
import { LoaderIcon } from 'src/Components'
import BreadCrumbs from 'src/Components/Authenticated/BreadCrumbs'
import { MainPage } from 'src/Components/My'
import { t } from 'src/I18n'
import {
  useGetEditConsumerSubscriptionQuery,
  useEditConsumerSubscriptionMutation,
} from 'src/Services/ToprCoreApi/RTK/Slices/SubscriptionSlice'
import SubscriptionForm from '../Components/Subscription/SubscriptionForm'

const SubscriptionEdit: React.FC = () => {
  const params = useParams<'id'>()

  const { data: editItem, isLoading } = useGetEditConsumerSubscriptionQuery(
    params.id
  )

  const [editSubscription, { isLoading: isSubmitting }] =
    useEditConsumerSubscriptionMutation()

  const renderForm = () => {
    if (isLoading) {
      return <LoaderIcon IsLoading />
    }

    return (
      <SubscriptionForm
        isSubmitting={isSubmitting}
        onSave={(data) => {
          editSubscription({
            ConsumerSubscriptionId: params.id,
            Name: data.Name,
            SystemName: data.SystemName,
          })
            .unwrap()
            .then(() => history.back())
            .catch((rejected) => console.error(rejected))
        }}
        title={t.subscription.edit.title}
        editItem={editItem}
      />
    )
  }
  return (
    <MainPage>
      <BreadCrumbs
        currentPageTitle={t.subscription.edit.title}
        previousPages={[
          {
            to: `/subscriptions`,
            title: t.subscription.index.title,
          },
        ]}
      />
      {renderForm()}
    </MainPage>
  )
}

export default SubscriptionEdit
