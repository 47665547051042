import IGenericError from 'src/Models/Server/IGenericError'
import { ICreateEventRequest } from 'src/Models/Server/Request/Event/ICreateEventRequest'
import { IEditEventRequest } from 'src/Models/Server/Request/Event/IEditEventRequest'
import { IEventEditVTO } from 'src/Models/Server/Response/Event/IEventEditVTO'
import { IEventTicketVTO } from 'src/Models/Server/Response/Event/IEventTicketVTO'
import { IEventVTO } from 'src/Models/Server/Response/Event/IEventVTO'
import EventController from 'src/Services/ToprCoreApi/Axios/Controllers/EventController'
import { appUiStore } from 'src/Stores/AppUiStore'
import { IEventFormValues } from '../Components/Reducer'

export interface IState {
  event?: IEventVTO
  eventTickets: IEventTicketVTO[]
}

export const initialState: IState = {
  event: undefined,
  eventTickets: [],
}

export type Actions = {
  type: 'initialized'
  event: IEventVTO
  eventTickets: IEventTicketVTO[]
}
export const reducer = (state: IState, action: Actions): IState => {
  switch (action.type) {
    case 'initialized':
      return {
        ...state,
        event: action.event,
        eventTickets: action.eventTickets,
      }
    default:
      throw new Error('Invalid Reducer Action')
  }
}

export const reducerActions = (
  state: IState,
  dispatch: (action: Actions) => void
) => {
  return {
    initialize: (eventId: string) => {
      Promise.all([
        EventController.GetEvent(eventId),
        EventController.GetEventTickets(eventId),
      ])
        .then((values) => {
          dispatch({
            type: 'initialized',
            event: values[0],
            eventTickets: values[1],
          })
        })
        .catch((error: IGenericError) => {
          appUiStore.ShowError(error)
        })
    },
  }
}
