import { LocalizedStringValueDTO } from './LocalizedStringValueDTO'
import { t } from 'src/I18n'
import { persistedStore } from 'src/Stores/PersistedStore'

export class LocalizedStringDTO {
  public LocalizedStringId: number
  public TextBoxTypeId: number
  public SourceName: string
  public LocalizedStringValues: LocalizedStringValueDTO[] = []

  constructor(sourceName: string, type: TextBoxType = TextBoxType.TextBox) {
    this.LocalizedStringId = 0
    this.SourceName = sourceName
    this.TextBoxTypeId = type

    SetMissingLanguageValues(this)
  }
}

export enum TextBoxType {
  TextBox = 1,
  TextArea = 2,
  TextAreaHtml = 3,
  TextAreaBbcode = 4,
  CodeMirrorHtml = 20,
}

export const Languages = {
  China: 'CN',
  Germany: 'DE',
  United_Kingdom: 'EN',
  Spain: 'ES',
  France: 'FR',
  Netherlands: 'NL',
  Thailand: 'TH',
  Turkey: 'TR',
}

export const SetMissingLanguageValues = (
  localizedString: LocalizedStringDTO
) => {
  if (localizedString !== null && localizedString !== undefined) {
    const { SelectedManagerUser } = persistedStore
    SelectedManagerUser.MerchantLanguages.forEach((lang) => {
      const llsvalue = localizedString.LocalizedStringValues.find(
        (x) => x.LanguageId === lang
      )
      if (llsvalue === undefined) {
        localizedString.LocalizedStringValues.push({
          LanguageId: lang,
          LocalizedStringId: localizedString.LocalizedStringId,
          LocalizedStringValueId: 0,
          Value: '',
        })
      }
    })
  }

  return localizedString
}

export const GetValue = (localizedString: LocalizedStringDTO) => {
  const lang = t.getLanguage().toUpperCase()
  const llsValue = localizedString.LocalizedStringValues.find(
    (x) => x.LanguageId === lang
  )
  if (llsValue !== undefined) {
    return llsValue.Value
  }
  return null
}

export const GetValueForLang = (
  localizedString: LocalizedStringDTO,
  lang: string
) => {
  //const lang = t.getLanguage()

  const llsValue = localizedString.LocalizedStringValues.find(
    (x) => x.LanguageId === lang
  )
  if (llsValue !== undefined) {
    return llsValue.Value
  }
  return null
}
