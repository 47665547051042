import { action, makeObservable, observable, runInAction } from 'mobx'
import { ISelectOption } from 'src/Models/Client'

import IGenericError from 'src/Models/Server/IGenericError'
import { IBaseUserVTO } from 'src/Models/Server/Response/User/IBaseUserVTO'
import TagController from 'src/Services/ToprCoreApi/Axios/Controllers/TagController'
import UserController from 'src/Services/ToprCoreApi/Axios/Controllers/UserController'
import { appUiStore } from 'src/Stores/AppUiStore'
import BaseStore from 'src/Stores/BaseStore'

export interface IFormValues {
  Search: string
  Cardnumber: string
  TagOption: ISelectOption
}

export default class Store extends BaseStore<IFormValues> {
  public LoadingUsers: boolean = false
  public Users: IBaseUserVTO[] = []
  public TagOptions: ISelectOption[] = []
  public TagsLoading = true
  public SelectIds: any[] = []
  constructor() {
    super()

    this.BaseInitialize()

    makeObservable(this, {
      LoadingUsers: observable,
      Users: observable,
      TagOptions: observable,
      TagsLoading: observable,
      SelectIds: observable,
      GetTags: action,
      FindUsers: action,
      FindUserByCardnumber: action,
      FindUsersByTagId: action,
      Initialize: action,
      SetSelectIds: action,
    })
  }

  public Initialize() {
    this.BaseInitialize()
    this.IsLoading = false
    this.Users = []
    this.SelectIds = []
    this.GetTags()
  }

  public SetSelectIds(selectIds: any[]) {
    this.SelectIds = selectIds
  }

  public GetTags() {
    this.TagsLoading = true
    TagController.GetTags()
      .then((tags) => {
        const tagOptions: ISelectOption[] = tags
          .sort((a, b) => {
            return a.Name.localeCompare(b.Name)
          })
          .map((tag) => ({
            label: tag.Name,
            value: tag.TagId.toString(),
          }))
        runInAction(() => {
          this.TagOptions = tagOptions
          this.TagsLoading = false
        })
      })
      .catch((error: IGenericError) => {
        appUiStore.ShowError(error)
        this.TagsLoading = false
      })
  }

  public FindUsers(search: string) {
    if (!this.IsSubmitting && search.length > 2) {
      this.SelectIds = []
      this.LoadingUsers = true
      UserController.Find(search)
        .then((users) => {
          runInAction(() => {
            this.LoadingUsers = false
            this.Users = users
          })
        })
        .catch((error: IGenericError) => {
          appUiStore.ShowError(error)
          runInAction(() => (this.LoadingUsers = false))
        })
    }
  }

  public FindUserByCardnumber(cardNumber: string) {
    if (!this.IsSubmitting && cardNumber.length > 2) {
      this.SelectIds = []
      this.LoadingUsers = true
      UserController.FindCard(cardNumber)
        .then((users) => {
          runInAction(() => {
            this.LoadingUsers = false
            this.Users = users
          })
        })
        .catch((error: IGenericError) => {
          appUiStore.ShowError(error)
          runInAction(() => (this.LoadingUsers = false))
        })
    }
  }

  public FindUsersByTagId(search: string) {
    if (!this.IsSubmitting) {
      this.SelectIds = []
      this.LoadingUsers = true
      UserController.FindByTagId(search)
        .then((users) => {
          runInAction(() => {
            this.LoadingUsers = false
            this.Users = users
          })
        })
        .catch((error: IGenericError) => {
          appUiStore.ShowError(error)
          runInAction(() => (this.LoadingUsers = false))
        })
    }
  }
}

export const usersStore = new Store()
