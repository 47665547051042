import React, { useEffect, useReducer } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm, UseFormReturn } from 'react-hook-form'
import { McButton } from 'src/Components'
import Portlet from 'src/Components/Authenticated/Portlet'
import {
  InputMasks,
  MyCheckbox,
  MyLocalizedStringTabs,
  MyTextbox,
} from 'src/Components/My'
import { t } from 'src/I18n'
import { IEventTicketEditVTO } from 'src/Models/Server/Response/Event/IEventTicketEditVTO'

import {
  IEventFormValues,
  initialState,
  reducer,
  reducerActions,
} from './Reducer'

interface IProps {
  title: string
  onSave: (data: IEventFormValues) => void
  isSubmitting: boolean
  eventTicketEdit?: IEventTicketEditVTO
}

const EventTicketForm: React.FC<IProps> = ({
  title,
  onSave,
  isSubmitting,
  eventTicketEdit,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const actions = reducerActions(state, dispatch)

  useEffect(() => {
    actions.initialize(eventTicketEdit)
  }, [eventTicketEdit])

  const { viewModel } = state

  useEffect(() => {
    reset(viewModel)
  }, [viewModel])

  const useFormProps: UseFormReturn<IEventFormValues> =
    useForm<IEventFormValues>()

  const { handleSubmit, reset } = useFormProps

  const onSubmit = (data: IEventFormValues) => {
    onSave(data)
  }

  const renderContent = () => {
    if (viewModel === undefined) {
      return null
    }

    return (
      <>
        <Row>
          <Col md={6}>
            <MyLocalizedStringTabs
              formObject={viewModel}
              useFormProps={useFormProps}
              name="Name"
              infoText={t.eventTicket.insert.fields.name.info}
              label={t.eventTicket.insert.fields.name.label}
              isRequired
            />
            <MyTextbox
              formObject={viewModel}
              label={t.eventTicket.insert.fields.price.title}
              name="Price"
              useFormProps={useFormProps}
              inputMask={InputMasks.Currency}
              icon="fa-euro-sign"
              placeholder="0,00"
              rules={{ required: t.eventTicket.insert.fields.price.required }}
            />
            <MyCheckbox
              formObject={viewModel}
              label={t.eventTicket.insert.fields.onSale.title}
              name="OnSale"
              useFormProps={useFormProps}
            />
          </Col>
        </Row>
        <McButton
          block
          bsStyle="primary"
          bsSize="large"
          type="submit"
          isLoading={isSubmitting}
        >
          {t.common.save}
        </McButton>
      </>
    )
  }

  return (
    <>
      <form className="parsley-form" onSubmit={handleSubmit(onSubmit)}>
        <Portlet title={title}>{renderContent()}</Portlet>
      </form>
    </>
  )
}

export default EventTicketForm
